import { createSlice } from "@reduxjs/toolkit";
import { logDebug } from "../../utils/logger";

const initialState = {
  form: {
    oldpass: "",
    pass1: "",
    pass2: "",
  },
};

const reducers = {
  setForm: (state, action) => {
    state.form = action.payload;
    logDebug("passwordReducer", "setForm", { form: state.form });
  },
  clearForm: (state, action) => {
    state.form = initialState;
    logDebug("passwordReducer", "clearForm", { form: state.form });
  },
};

const slice = createSlice({
  name: "password",
  initialState: initialState,
  reducers: reducers,
});

export const { setForm, clearForm } = slice.actions;
export default slice.reducer;
