import * as yup from "yup";

const getUpdateTransferSchema = (t) => {
  return yup.object().shape({
    title: yup
      .string()
      .notRequired(t("validators.notEmpty"))
      .min(0, t("validators.minLength", { value: 0 }))
      .max(40, t("validators.maxLength", { value: 40 })),
    message: yup
      .string()
      .notRequired(t("validators.notEmpty"))
      .min(0, t("validators.minLength", { value: 0 }))
      .max(400, t("validators.maxLength", { value: 400 })),
  });
};

export default getUpdateTransferSchema;
