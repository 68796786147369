import { useState } from "react";

type CopiedValue = string | null;
type CopyFn = (text: string) => Promise<boolean>; // Return success

/**
 * Hook that provides a copy method to save a string in the clipboard and the copied value
 *
 * @returns the copied value and the copy method
 * @see https://usehooks-ts.com/react-hook/use-copy-to-clipboard
 */
function useCopyToClipboard(): [CopiedValue, CopyFn] {
  const [copiedText, setCopiedText] = useState<CopiedValue>(null);

  function isOS() {
    return navigator.userAgent.match(/ipad|iphone/i);
  }

  const copy: CopyFn = async (text) => {
    if (!isOS()) {
      if (!navigator?.clipboard) {
        console.warn("Clipboard not supported");
        return false;
      }
      // Try to save to clipboard then save it in the state if worked
      try {
        await navigator.clipboard.writeText(text);
        setCopiedText(text);
        return true;
      } catch (error) {
        console.warn("Copy failed", error);
        setCopiedText(null);
        return false;
      }
    } else {
      const el = document.createElement("textarea");
      el.value = text;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      setCopiedText(text);
      return true;
    }
  };

  return [copiedText, copy];
}

export default useCopyToClipboard;
