import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import useOnClickOutside from "../../hooks/useOnClickOutside";
import useSubscriptionConfirm from "../../hooks/useSubscriptionConfirm";

import {
  setBlur,
  setShowSubscriptionDialog,
} from "../../redux/reducers/uiReducer";

import SubPaypalButton from "../paypal-buttons/subscription";

import { errorToast, successToast, warnToast } from "../toast";
import { logDebug, logError, logWarn } from "../../utils/logger";
import { isEmpty } from "../../utils/validators";

function SubscriptionDialog() {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { ui } = useSelector((state) => state);
  const { user } = useSelector((state) => state.user);

  const [subscriptionID, setSubscriptionID] = useState(null);

  const userId = useMemo(() => user.id, [user.id]);

  const closeDialogHandler = useCallback(() => {
    dispatch(setShowSubscriptionDialog(!ui.show_subscription_dialog));
    dispatch(setBlur(!ui.is_blur));
    setSubscriptionID(null);
  }, [dispatch, ui.show_subscription_dialog, ui.is_blur]);
  const subscriptionDialogRef = useRef(null);
  useOnClickOutside(subscriptionDialogRef, () => closeDialogHandler());

  const { loading, data, error, getSubscriptionConfirm } =
    useSubscriptionConfirm();
  useEffect(() => {
    if (!isEmpty(subscriptionID) && !loading) {
      if (!isEmpty(error)) {
        if (!isEmpty(data)) {
          closeDialogHandler();
          logDebug("SubscriptionDialog", "getSubscriptionConfirm", {
            key: data.message,
            msg: t(`message.${data.message}`, t("message.default")),
          });
          successToast(
            "SubscriptionDialog",
            data.message,
            t(`message.${data.message}`, t("message.default"))
          );
        }
      } else {
        logError("SubscriptionDialog", "getSubscriptionConfirm", {
          key: error,
          msg: t(`errors.${error}`, t("errors.default")),
        });
        errorToast(
          "SubscriptionDialog",
          error,
          t(`errors.${error}`, t("errors.default"))
        );
      }
    }
  }, [subscriptionID, loading, data, error, t, closeDialogHandler]);

  const onApproveHandler = useCallback(
    (data, actions) => {
      logDebug("SubscriptionDialog", "onApproveHandler", {
        data: data,
        actions: actions,
      });
      if (!isEmpty(data) && !isEmpty(data.subscriptionID)) {
        setSubscriptionID(data.subscriptionID);
        getSubscriptionConfirm(data.subscriptionID);
      } else {
        setSubscriptionID(null);
      }
    },
    [getSubscriptionConfirm]
  );

  const onCancelHandler = useCallback(
    (data) => {
      logWarn("SubscriptionDialog", "onCancelHandler", {
        data: data,
        error_key: "errors.cancel_subcription_process",
        msg: t("errors.cancel_subcription_process", t("errors.default")),
      });
      warnToast(
        "SubscriptionDialog",
        "errors.cancel_subcription_process",
        t("errors.cancel_subcription_process", t("errors.default"))
      );
    },
    [t]
  );

  const onErrorHandler = useCallback(
    (error) => {
      logError("SubscriptionDialog", "onErrorHandler", {
        error: error,
        error_key: "errors.error_subscription",
        msg: t("errors.error_subscription", t("errors.default")),
      });
      errorToast(
        "SubscriptionDialog",
        "errors.error_subscription",
        t("errors.error_subscription", t("errors.default"))
      );
    },
    [t]
  );

  return (
    <div>
      <div className="wrapper"></div>
      <div ref={subscriptionDialogRef} className="subscription__dialog">
        <div className="subscription__dialog__header">
          <div className="subscription__dialog__header__logo">
            <span className="icon icon-up-round-arrow">
              <span className="path1"></span>
              <span className="path2"></span>
            </span>
          </div>
          <div className="subscription__dialog__header__title header-3">
            {t("subscription_dialog.title")}
          </div>
          <div className="subscription__dialog__header__subtitle text-body">
            {t("subscription_dialog.subtitle")}
          </div>
        </div>
        <div className="subscription__dialog__body">
          <SubPaypalButton
            custom_id={userId}
            plan_id={process.env.REACT_APP_PAYPAL_1DAY_SUB_PLAN_ID}
            showSpinner
            onApprove={(data, actions) => onApproveHandler(data, actions)}
            onCancel={(data) => onCancelHandler(data)}
            onError={(error) => onErrorHandler(error)}
          />
          <SubPaypalButton
            custom_id={userId}
            plan_id={process.env.REACT_APP_PAYPAL_2DAYS_SUB_PLAN_ID}
            showSpinner
            onApprove={(data, actions) => onApproveHandler(data, actions)}
            onCancel={(data) => onCancelHandler(data)}
            onError={(error) => onErrorHandler(error)}
          />
        </div>
        <div className="subscription__dialog__footer"></div>
      </div>
    </div>
  );
}

export default SubscriptionDialog;
