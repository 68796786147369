import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logDebug } from "../../utils/logger";
import { equalsIgnoringCase, isEmpty } from "../../utils/validators";
import { setHeaderMenuOpen } from "../../redux/reducers/uiReducer";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useGetLocalePath from "../../hooks/useGetLocalePath";
import { GLOBAL_STATUS } from "../uploader-component/utils/config";

function Logo() {
  const { uploader } = useSelector((state) => state.uploader);

  const { t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { getLocalePath } = useGetLocalePath();

  const goHome = () => {
    if (
      equalsIgnoringCase(
        location.pathname,
        getLocalePath(t("routes.common.home"))
      )
    ) {
      const el = document.getElementById("landing");
      if (!isEmpty(el)) {
        el.scrollIntoView({
          block: "start",
          inline: "start",
          behavior: "smooth",
        });
      }

      const headerAboutLink = document.getElementById("header-about-link");
      if (!isEmpty(headerAboutLink)) {
        headerAboutLink.classList.toggle("active", false);
      }
      const footerAboutLink = document.getElementById("footer-about-link");
      if (!isEmpty(footerAboutLink)) {
        footerAboutLink.classList.toggle("active", false);
      }
      const headerPlansLink = document.getElementById("header-plans-link");
      if (!isEmpty(headerPlansLink)) {
        headerPlansLink.classList.toggle("active", false);
      }
      const footerPlansLink = document.getElementById("footer-plans-link");
      if (!isEmpty(footerPlansLink)) {
        footerPlansLink.classList.toggle("active", false);
      }
    } else {
      navigate(getLocalePath(t("routes.common.home")), {
        state: { targetId: "landing" },
      });
    }

    dispatch(setHeaderMenuOpen(false));
  };

  const [srcIdx, setSrcIdx] = useState(0);
  const src = useMemo(
    () => [
      // Logo Animation
      "../../../assets/images/logo1.svg",
      "../../../assets/images/logo2.svg",
      "../../../assets/images/logo3.svg",
      // Success
      "../../../assets/images/logo4.svg",
      // Special Occasion
      // ...
    ],
    []
  );

  const intervalRef = useRef(null);

  const initialHandler = useCallback(() => {
    if (!isEmpty(intervalRef) && !isEmpty(intervalRef.current)) {
      clearInterval(intervalRef.current);
    }
    setSrcIdx(0);
    logDebug("logo", "initialHandler", { step: 0, logo: src[0] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadingHandler = useCallback(() => {
    intervalRef.current = setInterval(() => {
      setSrcIdx((prev) => {
        if (prev === 2) {
          const step = 0;
          logDebug("logo", "loadingHandler", { step: step, logo: src[step] });
          return step;
        } else {
          const step = prev >= 3 ? 0 : prev + 1;
          logDebug("logo", "loadingHandler", {
            step: step,
            logo: src[step],
          });
          return step;
        }
      });
    }, 300);
    return () => clearInterval(intervalRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const successHandler = useCallback(() => {
    if (!isEmpty(intervalRef) && !isEmpty(intervalRef.current)) {
      clearInterval(intervalRef.current);
    }
    setSrcIdx(3);
    logDebug("logo", "successHandler", { step: 3, logo: src[3] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Init State
    if (equalsIgnoringCase(uploader.globalStatus, GLOBAL_STATUS.INIT)) {
      initialHandler();
      // Uploading State
    } else if (
      equalsIgnoringCase(uploader.globalStatus, GLOBAL_STATUS.UPLOADING)
    ) {
      loadingHandler();
      // Success State
    } else if (
      equalsIgnoringCase(uploader.globalStatus, GLOBAL_STATUS.SUCCESS)
    ) {
      successHandler();
    }
  }, [uploader.globalStatus, initialHandler, loadingHandler, successHandler]);

  return (
    <div className="logo-container" onClick={() => goHome()}>
      <img
        className="logo"
        src={src[srcIdx]}
        loading="eager"
        alt={t("logo.alt")}
        title={t("logo.title")}
        height="auto"
        width="auto"
      ></img>
      <img
        className="wordmark"
        src="../../../assets/images/ydray-wordmark-black.svg"
        loading="eager"
        alt={t("wordmark.alt")}
        title={t("wordmark.title")}
        height="auto"
        width="auto"
      ></img>
    </div>
  );
}

export default Logo;
