import { useMemo } from "react";

import { useTranslation } from "react-i18next";

import Dialog from "../../dialog";

import { isEmpty } from "../../../utils/validators";

function ShareTransferDialog(props) {
  const { t } = useTranslation();

  const { idTransfer, showDialog, closeHandler } = props;

  const sharedLink = useMemo(() => {
    return !isEmpty(idTransfer)
      ? `${process.env.REACT_APP_PUBLIC_URL}/get/t/${idTransfer}`
      : "";
  }, [idTransfer]);

  const mailLink = useMemo(() => {
    return !isEmpty(sharedLink)
      ? encodeURI(
          `mailto:?subject=${t("social_media.share_msg")}&body=${sharedLink}`
        )
      : "";
  }, [sharedLink, t]);

  const gmailLink = useMemo(() => {
    return !isEmpty(sharedLink)
      ? encodeURI(
          `https://mail.google.com/mail/?view=${"cm"}&su=${t(
            "social_media.share_msg"
          )}&body=${sharedLink}`
        )
      : "";
  }, [sharedLink, t]);

  const twitterLink = useMemo(() => {
    return !isEmpty(sharedLink)
      ? encodeURI(
          `https://twitter.com/intent/tweet?text=${t(
            "social_media.share_msg"
          )}&url=${sharedLink}&via=${`Ydray_`}&hashtags=YDRAY`
        )
      : "";
  }, [sharedLink, t]);

  const facebookLink = useMemo(() => {
    return !isEmpty(sharedLink)
      ? encodeURI(
          `https://www.facebook.com/sharer/sharer.php?text=${t(
            "social_media.share_msg"
          )}&display="page"&href=${sharedLink}&hashtag=YDRAY`
        )
      : "";
  }, [sharedLink, t]);

  const whatsappLink = useMemo(() => {
    return !isEmpty(sharedLink)
      ? encodeURI(
          `whatsapp://send?text="${t("social_media.share_msg")} ${sharedLink}"`
        )
      : "";
  }, [sharedLink, t]);

  const HeaderDialog = (props) => {
    return (
      <div className="dialog__header__container">
        <span className="dialog__header__logo share"></span>
        <div className="dialog__header__title header-3">
          {t("dialog.types.share")}
        </div>
      </div>
    );
  };

  const BodyDialog = (props) => {
    return (
      <div className="dialog__body__container">
        <a
          href={mailLink}
          className="share__link"
          title={t("social_media.mail")}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="share__icon icon-mail"></span>
          <span className="share__title">{t("social_media.mail")}</span>
        </a>
        <a
          href={gmailLink}
          className="share__link"
          title={t("social_media.gmail")}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="share__icon icon-gmail"></span>
          <span className="share__title">{t("social_media.gmail")}</span>
        </a>
        <a
          href={twitterLink}
          className="share__link"
          title={t("social_media.twitter")}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="share__icon icon-twitter-x"></span>
          <span className="share__title">{t("social_media.twitter")}</span>
        </a>
        <a
          href={facebookLink}
          className="share__link"
          title={t("social_media.facebook")}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="share__icon icon-facebook"></span>
          <span className="share__title">{t("social_media.facebook")}</span>
        </a>
        <a
          href={whatsappLink}
          className="share__link"
          title={t("social_media.whatsapp")}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="share__icon icon-whatsapp"></span>
          <span className="share__title">{t("social_media.whatsapp")}</span>
        </a>
      </div>
    );
  };

  const FooterDialog = (props) => {
    return (
      <input
        className="share__link__input"
        value={sharedLink}
        title={sharedLink}
      />
    );
  };

  return (
    <Dialog
      header={<HeaderDialog />}
      body={<BodyDialog />}
      footer={<FooterDialog />}
      showDialog={showDialog}
      closeHandler={(evt) => closeHandler(evt)}
    />
  );
}

export default ShareTransferDialog;
