import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useUserResetPassword from "../../../hooks/useUserResetPassword";

import { isEmpty, isEmptyList } from "../../../utils/validators";

import getResetPasswordUserSchema from "../../../schemas/resetPasswordUserSchema";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  setBlur,
  setShowResetPasswordDialog,
} from "../../../redux/reducers/uiReducer";

import Button from "../../button";
import TextField from "../../text-field";
import { errorToast, successToast } from "../../toast";

import { logDebug } from "../../../utils/logger";

function ResetPasswordForm({ recaptcha }) {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { ui } = useSelector((state) => state);
  const { settings } = useSelector((state) => state.settings);

  const token = useMemo(
    () =>
      !isEmpty(settings) && !isEmpty(settings.csrf_token)
        ? settings.csrf_token
        : "",
    [settings]
  );

  const resetPasswordUserSchema = getResetPasswordUserSchema(t);
  const {
    register,
    control,
    watch,
    setValue,
    getValues,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      csrf_token: token,
      "g-recaptcha-response": "",
    },
    values: {
      email: "",
      csrf_token: token,
      "g-recaptcha-response": "",
    },
    mode: "all",
    criteriaMode: "all",
    resolver: yupResolver(resetPasswordUserSchema),
  });

  const changeRecaptcha = useCallback(
    (token) => {
      setValue("g-recaptcha-response", token);
      recaptcha.ref.reset();
    },
    [recaptcha, setValue]
  );

  const resetRecaptcha = useCallback(() => {
    setValue("g-recaptcha-response", "");
    recaptcha.ref.reset();
  }, [recaptcha, setValue]);

  const { loading, data, error, resetPasswordHandler } = useUserResetPassword();
  useEffect(() => {
    if (!loading) {
      if (error) {
        errorToast(
          "useUserResetPassword",
          error,
          t(`errors.${error}`, t("errors.default"))
        );
      } else if (!error && !isEmptyList(data)) {
        reset({
          email: "",
          csrf_token: token,
          "g-recaptcha-response": "",
        });
        resetRecaptcha();
        dispatch(setShowResetPasswordDialog(!ui.show_register_dialog));
        dispatch(setBlur(!ui.is_blur));
        successToast("useUserResetPassword", data, t("message.remind_me"));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, data, error]);

  const onSubmit = async (data, evt) => {
    if (recaptcha.loaded) {
      const token = !isEmpty(getValues("g-recaptcha-response"))
        ? getValues("g-recaptcha-response")
        : await recaptcha.ref.executeAsync();

      if (!isEmpty(token)) {
        recaptcha.ref.reset();
        setValue("g-recaptcha-response", token);
        logDebug("ResetPasswordForm", "onSubmit", { data: data, evt: evt });
        resetPasswordHandler(getValues());
      }
    }
  };

  const onError = async (errors, evt) => {
    logDebug("ResetPasswordForm", "onError", { errors: errors, evt: evt });
    recaptcha.ref.reset();
    errorToast("onError", errors, t("errors.error_form", t("errors.default")));
  };

  return (
    <form
      id="user_reset_password_form"
      className="reset-password__form"
      onSubmit={handleSubmit(onSubmit, onError)}
    >
      <TextField
        label={t("reset_password_dialog.form.email")}
        autoComplete={"email"}
        value={watch("email")}
        register={register("email", {
          ...resetPasswordUserSchema.email,
          onChange: (evt) => setValue("email", evt.target.value),
          onBlur: (evt) => setValue("email", evt.target.value),
        })}
        error={errors?.email?.message}
        required
      />
      <Controller
        name="csrf_token"
        control={control}
        defaultValue={token}
        render={({ field }) => <input hidden {...field}></input>}
      />
      <Controller
        name="g-recaptcha-response"
        control={control}
        defaultValue=""
        onChange={(token) => changeRecaptcha("g-recaptcha-response", token)}
        onExpired={() => resetRecaptcha()}
        onError={() => resetRecaptcha()}
        render={({ field }) => (
          <input hidden value={getValues("g-recaptcha-response")} {...field} />
        )}
      />
      <div className="reset-password__form__footer">
        <Button type="submit" primary text loading={loading}>
          <span className="label">
            {t("reset_password_dialog.button.remind_me")}
          </span>
        </Button>
      </div>
    </form>
  );
}

export default ResetPasswordForm;
