import { useCallback, useMemo, useRef } from "react";

import { useTranslation } from "react-i18next";

import Button from "../../button";

import { isEmpty } from "../../../utils/validators";
import { logDebug } from "../../../utils/logger";
import PasswordField from "../../password-field";

function DownloaderShareForm(props) {
  const { loading, transferId, password, onChangePassword, onSubmit } = props;

  const { t } = useTranslation();

  const passwordFieldRef = useRef(null);

  const isDisabledButton = useMemo(() => {
    return loading || isEmpty(transferId) || isEmpty(password);
  }, [loading, transferId, password]);

  const onChangePasswordHandler = useCallback(
    (evt) => {
      logDebug("DownloaderShareForm", "onChangePasswordHandler", {
        evt: evt,
      });
      onChangePassword(evt);
    },
    [onChangePassword]
  );

  const onSumbitHandler = useCallback(
    (evt) => {
      evt.preventDefault();
      logDebug("DownloaderShareForm", "onClickHandler", {
        evt: evt,
      });
      onSubmit && onSubmit(evt);
    },
    [onSubmit]
  );

  return (
    <div className="downloader-form-container">
      <div className="downloader-form__body">
        <form
          id="downloader_form"
          className="downloader-form__form"
          noValidate
          onSubmit={(evt) => onSumbitHandler(evt)}
        >
          <div className="downloader-form__row">
            <PasswordField
              value={password}
              register={{
                name: "password",
                ref: passwordFieldRef,
              }}
              onChange={(evt) => onChangePasswordHandler(evt)}
              disabled={loading}
            />
          </div>
          <div className="downloader-form__row">
            <Button
              type={"submit"}
              primary
              text
              loading={loading}
              disabled={isDisabledButton}
            >
              <span className="label">{t("button.send")}</span>
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default DownloaderShareForm;
