import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import classNames from "classnames";

import getSendFilesSchema from "../../../schemas/sendFilesSchema";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import useWindowSize from "../../../hooks/useWindowSize";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import useSendData from "../../../hooks/useSendData";
import useGetLocalePath from "../../../hooks/useGetLocalePath";
import useGetUserSubscriptionStatus from "../../../hooks/useGetUserSubscriptionStatus";

import {
  setBlur,
  setFooterMenuCollapse,
  setLoading,
  setShowContactDialog,
  setShowFooterMenu,
  setTransferInit,
  setUserInit,
} from "../../../redux/reducers/uiReducer";
import {
  removeFiles,
  setShareType,
  setUploadStatus,
  setGlobalSize,
  setDisabled,
  setGlobalProgress,
  setGlobalStatus,
  clearUploadInfo,
  setUploadInfo,
} from "../../../redux/reducers/uploaderReducer";
import {
  clearFilters,
  clearItems,
  clearOrderBy,
  clearPagination,
} from "../../../redux/reducers/transfersTableReducer";

import {
  equalsIgnoringCase,
  isEmpty,
  isEmptyList,
} from "../../../utils/validators";
import { GLOBAL_STATUS, SHARE_TYPE, STATUS_UPLOADER } from "../utils/config";
import { FILE_STATES } from "@rpldy/uploady";
import { logDebug, logError } from "../../../utils/logger";

import Button from "../../button";
import Switch from "../../switch";
import Divider from "../../divider";
import TextArea from "../../text-area";
import DropDown from "../../dropdown";
import TextField from "../../text-field";
import ProgressBar from "../../progress-bar";
import PasswordField from "../../password-field";
import ProAdComponent from "../../pro-ad-component";
import AddFilesComponent from "../add-files-component";
import ShareFilesComponent from "../share-files-component";
import { Tooltip } from "react-tooltip";
import { errorToast, successToast } from "../../toast";
import UploadDropZone from "@rpldy/upload-drop-zone";

function FormComponent() {
  const { t } = useTranslation();

  const location = useLocation();
  const { getLocalePath } = useGetLocalePath();

  const dispatch = useDispatch();
  const { ui } = useSelector((state) => state);
  const { uploader } = useSelector((state) => state.uploader);

  const windowSize = useWindowSize();
  const { isUnregisteredUser, isFreeUser, isProUser } =
    useGetUserSubscriptionStatus();
  const { loading, data, error, sendDataHandler } = useSendData();

  const [showTitle, setShowTitle] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [transferId, setTransferId] = useState(null);
  const [showMessage, setShowMessage] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const isGenerate = useMemo(
    () => equalsIgnoringCase(uploader.shareType, SHARE_TYPE.GENERATE),
    [uploader.shareType]
  );

  const isCopy = useMemo(
    () => equalsIgnoringCase(uploader.shareType, SHARE_TYPE.COPY),
    [uploader.shareType]
  );

  const expirationOpts = useMemo(() => {
    const list = [
      {
        label: t("dropdown.dayWithCount", { count: 1 }),
        value: "1",
      },
      {
        label: t("dropdown.dayWithCount", { count: 7 }),
        value: "7",
      },
      {
        label: t("dropdown.dayWithCount", { count: 10 }),
        value: "10",
      },
    ];
    if (isProUser) {
      list.push({
        label: t("dropdown.undefined"),
        value: "0",
      });
    }
    return list;
  }, [isProUser, t]);

  const uploadFilesTitle = useMemo(() => {
    const files = Array.from(uploader.files);
    const uploadedFiles =
      !isEmptyList(files) &&
      files.filter((item) => item.state === FILE_STATES.FINISHED);
    const isAllError =
      !isEmptyList(files) &&
      files.every((file) =>
        [
          FILE_STATES.CANCELLED,
          FILE_STATES.ERROR,
          FILE_STATES.ABORTED,
        ].includes(file.state)
      );
    if (isEmptyList(files) || isAllError) {
      return t("uploader.upload_files_content.add_files");
    } else if (parseFloat(uploader.globalProgress) < 100.0) {
      return t("uploader.upload_files_content.uploading_fileWithCount", {
        count: files.length,
      });
    } else if (isGenerate) {
      return t("uploader.upload_files_content.uploaded_fileWithCount", {
        count: uploadedFiles.length,
      });
    } else if (isCopy) {
      return t("uploader.upload_files_content.fileWithCount_one", {
        count: uploadedFiles.length,
      });
    } else {
      return "";
    }
  }, [isGenerate, isCopy, uploader.files, uploader.globalProgress, t]);

  const globalUploaderStatus = useMemo(() => {
    let result = "";
    switch (uploader.globalStatus) {
      case GLOBAL_STATUS.ADDING:
      case GLOBAL_STATUS.UPLOADING:
        result = "info";
        break;
      case GLOBAL_STATUS.SUCCESS:
        result = "success";
        break;
      case GLOBAL_STATUS.ERROR:
        result = "error";
        break;
      default:
        result = "detail";
        break;
    }
    return result;
  }, [uploader.globalStatus]);

  const defaultValues = useMemo(() => {
    return {
      title: "",
      message: "",
      days: isProUser ? "0" : "7",
      pass: "",
    };
  }, [isProUser]);

  const values = useMemo(() => {
    return {
      title:
        !isEmpty(uploader) &&
        !isEmpty(uploader.uploadInfo) &&
        !isEmpty(uploader.uploadInfo.title)
          ? uploader.uploadInfo.title
          : "",
      message:
        !isEmpty(uploader) &&
        !isEmpty(uploader.uploadInfo) &&
        !isEmpty(uploader.uploadInfo.message)
          ? uploader.uploadInfo.message
          : "",
      days:
        !isEmpty(uploader) &&
        !isEmpty(uploader.uploadInfo) &&
        !isEmpty(uploader.uploadInfo.days)
          ? uploader.uploadInfo.days
          : isProUser
          ? "0"
          : "7",
      pass:
        !isEmpty(uploader) &&
        !isEmpty(uploader.uploadInfo) &&
        !isEmpty(uploader.uploadInfo.pass)
          ? uploader.uploadInfo.pass
          : "",
    };
  }, [uploader, isProUser]);

  const sharedLink = useMemo(() => {
    return !isEmpty(transferId)
      ? `${process.env.REACT_APP_PUBLIC_URL}/get/t/${transferId}`
      : "";
  }, [transferId]);

  const showPlansFeature = useMemo(() => {
    return (
      false && ["DEVELOPMENT"].includes(process.env.REACT_APP_ENV) && !isProUser
    );
  }, [isProUser]);

  const showProPlanTooltip = useMemo(() => {
    return showPlansFeature && windowSize.width >= 576;
  }, [showPlansFeature, windowSize.width]);

  const showProPlanToast = useMemo(() => {
    return showPlansFeature && windowSize.width < 576;
  }, [showPlansFeature, windowSize.width]);

  const enableUploader = useMemo(
    () => isGenerate && !uploader.disabled,
    [isGenerate, uploader.disabled]
  );

  const sendFilesSchema = getSendFilesSchema(t);
  const {
    register,
    control,
    watch,
    setValue,
    getValues,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValues,
    values: values,
    mode: "all",
    criteriaMode: "all",
    resolver: yupResolver(sendFilesSchema),
  });

  useEffect(() => {
    if (!loading) {
      if (uploader.uploadStatus === STATUS_UPLOADER.FORM && isGenerate) {
        if (!isEmpty(data)) {
          if (isEmpty(error)) {
            dispatch(setShareType(SHARE_TYPE.COPY));
            dispatch(setFooterMenuCollapse(false));
            setTransferId(data.result);
            successToast("useSendData", data, t("message.default"));
            if (
              equalsIgnoringCase(
                location.pathname,
                getLocalePath(t("routes.private.uploads.index"))
              )
            ) {
              dispatch(clearItems());
              dispatch(clearFilters());
              dispatch(clearOrderBy());
              dispatch(clearPagination());
              dispatch(setUserInit(true));
              dispatch(setLoading(true));
              dispatch(setTransferInit(true));
              dispatch(clearUploadInfo());
              reset(defaultValues);
            }
          } else {
            dispatch(setShareType(SHARE_TYPE.GENERATE));
            dispatch(setFooterMenuCollapse(true));
            setTransferId(null);
            logError("FormComponent", "useSendData", { error: error });
            errorToast(
              "useSendData",
              error,
              t(`errors.${error}`, t("errors.default"))
            );
          }
        }
      }
    } else {
      if (uploader.uploadStatus === STATUS_UPLOADER.FORM && isGenerate) {
        dispatch(setDisabled(true));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, data, error]);

  const onSubmit = useCallback(
    (data, evt) => {
      logDebug("UploaderCompoment.FormComponent", "onSubmit", {
        data: data,
        evt: evt,
      });
      sendDataHandler(getValues());
    },
    [getValues, sendDataHandler]
  );

  const onError = useCallback(
    (errors, evt) => {
      logError("UploaderCompoment.FormComponent", "onError", {
        errors: errors,
        evt: evt,
      });
      errorToast(
        "onError",
        errors,
        t("errors.error_form", t("errors.default"))
      );
    },
    [t]
  );

  const goUploadDetail = useCallback(() => {
    dispatch(setUploadStatus(STATUS_UPLOADER.DETAIL));
    dispatch(setShowFooterMenu(false));
  }, [dispatch]);

  const goNewUpload = useCallback(() => {
    dispatch(removeFiles());
    dispatch(setGlobalSize(0));
    dispatch(setGlobalProgress(0));
    dispatch(setGlobalStatus(GLOBAL_STATUS.INIT));
    dispatch(setDisabled(false));
    dispatch(setShareType(SHARE_TYPE.GENERATE));
    dispatch(setUploadStatus(STATUS_UPLOADER.DRAG));
    dispatch(setFooterMenuCollapse(false));
    dispatch(clearUploadInfo());
    reset(defaultValues);
  }, [defaultValues, dispatch, reset]);

  const onInputTextArea = useCallback((evt) => {
    const textArea = evt.target;
    if (!isEmpty(textArea.value)) {
      textArea.setAttribute(
        "style",
        `height: ${textArea.scrollHeight}px; overflow-y: hidden;`
      );
    } else {
      textArea.setAttribute("style", "height: 43px;");
    }
  }, []);

  const showTitleClickHandler = useCallback(
    (evt) => {
      if (!isEmpty(uploader.uploadInfo) && isEmpty(uploader.uploadInfo.title)) {
        const el = document.querySelector("#uploader_form #title");
        if (!isEmpty(el)) {
          el.focus();
        }
        setShowTitle(true);
      }
    },
    [uploader.uploadInfo]
  );

  const showMessageClickHandler = useCallback(
    (evt) => {
      if (
        !isEmpty(uploader.uploadInfo) &&
        isEmpty(uploader.uploadInfo.message)
      ) {
        const el = document.querySelector("#uploader_form #message");
        if (!isEmpty(el)) {
          el.focus();
        }
        setShowMessage(true);
      }
    },
    [uploader.uploadInfo]
  );

  const showPasswordClickHandler = useCallback(
    (evt) => {
      if (!isEmpty(uploader.uploadInfo) && isEmpty(uploader.uploadInfo.pass)) {
        const el = document.querySelector("#uploader_form #pass");
        if (!isEmpty(el)) {
          el.focus();
        }
        setShowPassword(true);
      }
    },
    [uploader.uploadInfo]
  );

  const CustomTooltip = (props) => {
    const { anchorSelect } = props;

    const openContactDialog = useCallback((evt) => {
      dispatch(setShowContactDialog(!ui.show_contact_dialog));
      dispatch(setBlur(!ui.is_blur));
    }, []);

    return (
      <Tooltip
        anchorSelect={anchorSelect}
        place={"right"}
        className="custom-tooltip__container"
        clickable
        closeOnEsc
        closeOnScroll
        closeOnResize
      >
        <div className="custom-tooltip__content">
          <p className="custom-tooltip__message">
            {t("tooltip.feature_pro_message")}
          </p>
          <div
            role="button"
            className="custom-tooltip__button"
            onClick={(evt) => openContactDialog(evt)}
          >
            {t("button.contact")}
          </div>
        </div>
      </Tooltip>
    );
  };

  const CustomToast = (props) => {
    const ref = useRef(null);
    useOnClickOutside(ref, () => setShowToast(false));

    const openContactDialog = useCallback((evt) => {
      dispatch(setShowContactDialog(!ui.show_contact_dialog));
      dispatch(setBlur(!ui.is_blur));
      setShowToast(false);
    }, []);

    return (
      <div ref={ref} className="custom-toast__container">
        <p className="custom-toast__message">
          {t("tooltip.feature_pro_message")}
        </p>
        <div
          role="button"
          className="custom-toast__button"
          onClick={(evt) => openContactDialog(evt)}
        >
          {t("button.contact")}
        </div>
      </div>
    );
  };

  const showToastHandler = useCallback(
    (value) => {
      setShowToast(windowSize.width < 576 ? value : false);
    },
    [windowSize.width]
  );

  const onBlurTitleHandler = useCallback(
    (evt) => {
      setValue("title", evt.target.value);
      dispatch(
        setUploadInfo({
          ...uploader.uploadInfo,
          title: evt.target.value,
        })
      );
      setShowTitle(!isEmpty(evt.target.value));
    },
    [uploader, setValue, dispatch]
  );

  const onBlurMessageHandler = useCallback(
    (evt) => {
      setValue("message", evt.target.value);
      dispatch(
        setUploadInfo({
          ...uploader.uploadInfo,
          message: evt.target.value,
        })
      );
      setShowMessage(!isEmpty(evt.target.value));
    },
    [uploader, setValue, dispatch]
  );

  const onChangeDaysHandler = useCallback(
    (value) => {
      setValue("days", value);
      dispatch(
        setUploadInfo({
          ...uploader.uploadInfo,
          days: value,
        })
      );
    },
    [uploader, setValue, dispatch]
  );

  const onBlurPasswordHandler = useCallback(
    (evt) => {
      setValue("pass", evt.target.value);
      dispatch(
        setUploadInfo({
          ...uploader.uploadInfo,
          pass: evt.target.value,
        })
      );
      setShowPassword(!isEmpty(evt.target.value));
    },
    [uploader, setValue, dispatch]
  );

  useEffect(() => {
    if (!isEmpty(uploader.uploadInfo)) {
      setShowTitle(!isEmpty(uploader.uploadInfo.title));
      setShowMessage(!isEmpty(uploader.uploadInfo.message));
      setShowPassword(!isEmpty(uploader.uploadInfo.pass));
    }
  }, [uploader.uploadInfo]);

  useEffect(() => {
    if (isCopy) {
      setShowTitle(false);
      setShowMessage(false);
    }
  }, [isCopy]);

  return (
    <UploadDropZone
      className="form-container"
      grouped={false}
      shouldHandleDrag={enableUploader}
    >
      <form
        id="uploader_form"
        className="form-upload"
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        {(isEmptyList(uploader.files) ||
          (!isEmptyList(uploader.files) &&
            uploader.globalProgress !== 100)) && (
          <div>
            {(isUnregisteredUser || isFreeUser) && <ProAdComponent />}
            {isProUser && <AddFilesComponent form={true} />}
          </div>
        )}

        {!isEmptyList(uploader.files) && uploader.globalProgress === 100 && (
          <ShareFilesComponent loading={loading} sharedLink={sharedLink} />
        )}
        <Divider />
        <div className="list">
          <div className="list-item">
            <div className="row">
              <div className="content clickable" onClick={goUploadDetail}>
                <span className="item-icon icon-folder"></span>
                <span className="item-text small-title">
                  {uploadFilesTitle}
                </span>
                <span className="item-icon icon-nav-arrow-right"></span>
              </div>
              {!isCopy && !uploader.disabled && (
                <Button secondary icon onClick={goUploadDetail}>
                  <span className="icon icon-circle-add-alternate"></span>
                  <span className="label small-title">{t("button.add")}</span>
                </Button>
              )}
            </div>
            {!isCopy && !uploader.disabled && (
              <ProgressBar
                type={globalUploaderStatus}
                value={uploader.globalProgress * 100}
              />
            )}
          </div>
          {isGenerate && (
            <div style={{ width: "100%" }}>
              <div className="list-item">
                <div className="row">
                  <div className="content">
                    <span className="item-icon icon-edit"></span>
                    <span className="item-text small-title">
                      {t("uploader.upload_files_content.title")}
                    </span>
                  </div>
                  <Button
                    secondary
                    icon
                    onClick={(evt) => showTitleClickHandler(evt)}
                  >
                    <span className="icon icon-circle-add-alternate"></span>
                    <span className="label small-title">{t("button.add")}</span>
                  </Button>
                </div>
                <div
                  className={classNames("row", {
                    hidden: !showTitle,
                  })}
                >
                  <div className="field">
                    <TextField
                      autoComplete="new-title"
                      value={watch("title")}
                      register={register("title", {
                        ...sendFilesSchema.title,
                        onBlur: (evt) => onBlurTitleHandler(evt),
                      })}
                      disabled={loading}
                      error={errors?.title?.message}
                    />
                  </div>
                </div>
              </div>
              <Divider />
              <div className="list-item">
                <div className="row">
                  <div className="content">
                    <span className="item-icon icon-chat-bubble-empty"></span>
                    <span className="item-text small-title">
                      {t("uploader.upload_files_content.comment")}
                    </span>
                  </div>
                  <Button
                    secondary
                    icon
                    onClick={(evt) => showMessageClickHandler(evt)}
                  >
                    <span className="icon icon-circle-add-alternate"></span>
                    <span className="label small-title">{t("button.add")}</span>
                  </Button>
                </div>
                <div
                  className={classNames("row", {
                    hidden: !showMessage,
                    message: showMessage,
                  })}
                >
                  <div className="field">
                    <TextArea
                      autoComplete="new-message"
                      value={watch("message")}
                      register={register("message", {
                        ...sendFilesSchema.message,
                        onBlur: (evt) => onBlurMessageHandler(evt),
                      })}
                      onInput={(evt) => onInputTextArea(evt)}
                      disabled={loading}
                      error={errors?.message?.message}
                    />
                  </div>
                </div>
              </div>
              <Divider />
              <div
                className={classNames("list-item", {
                  "plans-feature": showPlansFeature,
                })}
                onClick={(evt) => showToastHandler(true)}
              >
                <div className="row">
                  <div className="content" style={{ width: "100%" }}>
                    <span className="item-icon icon-clock-outline"></span>
                    <span className="item-text small-title">
                      {t("uploader.upload_files_content.deleteIn")}
                    </span>
                  </div>
                  <Controller
                    name="days"
                    control={control}
                    render={({ register }) => (
                      <DropDown
                        options={expirationOpts}
                        value={watch("days")}
                        onChange={(value) => onChangeDaysHandler(value)}
                        disabled={loading || showProPlanTooltip}
                        register={register}
                      />
                    )}
                  />
                </div>
              </div>
              <Divider />
              <div
                className={classNames("list-item", {
                  "plans-feature": showPlansFeature,
                })}
                onClick={(evt) => showToastHandler(true)}
              >
                <div className="row">
                  <div className="content">
                    <span className="item-icon icon-lock"></span>
                    <span className="item-text small-title">
                      {t("uploader.upload_files_content.password")}
                    </span>
                  </div>
                  <Switch
                    name="is_password"
                    disabled={uploader.disabled || showProPlanTooltip}
                    checked={showPassword}
                    onChange={(evt) => showPasswordClickHandler(evt)}
                  />
                </div>
                <div
                  className={classNames("row", {
                    hidden: !showPassword,
                  })}
                >
                  <div className="field">
                    <PasswordField
                      autoComplete="new-password"
                      value={watch("pass")}
                      register={register("pass", {
                        ...sendFilesSchema.pass,
                        onBlur: (evt) => onBlurPasswordHandler(evt),
                      })}
                      disabled={loading || showProPlanTooltip}
                      error={errors?.pass?.message}
                    />
                  </div>
                </div>
              </div>
              {showProPlanTooltip && (
                <CustomTooltip anchorSelect={".plans-feature"} />
              )}
              {showProPlanToast && showToast && <CustomToast />}
            </div>
          )}
          {isCopy && (
            <>
              <Divider />
              <Button primary text onClick={goNewUpload}>
                <span className="label">
                  {t("uploader.share_content.button.new")}
                </span>
              </Button>
            </>
          )}
        </div>
      </form>
    </UploadDropZone>
  );
}

export default FormComponent;
