import LandingHeader from "../components/landing/landing-header";
import LandingBody from "../components/landing/landing-body";
import LandingFooter from "../components/landing/landing-footer";
import LandingIframe from "../components/landing/landing-iframe";
import Media from "react-media";
import { useTranslation } from "react-i18next";
import SEO from "../components/seo";
import { useCallback, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { equalsIgnoringCase, isEmpty } from "../utils/validators";
import { useDispatch, useSelector } from "react-redux";
import {
  setBlur,
  setShowContactDialog,
  setShowNewsletterRegisterDialog,
  setShowNewsletterUnregisterDialog,
} from "../redux/reducers/uiReducer";
import useGetLocalePath from "../hooks/useGetLocalePath";

function Landing({ params }) {
  const { t, i18n } = useTranslation();

  const location = useLocation();
  const { getLocalePath } = useGetLocalePath();

  const dispatch = useDispatch();
  const { ui } = useSelector((state) => state);

  const openContactDialog = useCallback(() => {
    dispatch(setShowContactDialog(!ui.show_contact_dialog));
    dispatch(setBlur(!ui.is_blur));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openRegNewsletterDialog = useCallback(() => {
    dispatch(
      setShowNewsletterRegisterDialog(!ui.show_newsletter_register_dialog)
    );
    dispatch(setBlur(!ui.is_blur));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openUnRegNewsletterDialog = useCallback(() => {
    dispatch(
      setShowNewsletterUnregisterDialog(!ui.show_newsletter_unregister_dialog)
    );
    dispatch(setBlur(!ui.is_blur));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const targetId = location?.state?.targetId || params?.targetId;
    if (ui.is_iframe_show) {
      if (!ui.is_iframe_loading) {
        if (!isEmpty(targetId)) {
          if (equalsIgnoringCase(targetId, "contact")) {
            openContactDialog();
          } else if (equalsIgnoringCase(targetId, "newsletter_register")) {
            openRegNewsletterDialog();
          } else if (equalsIgnoringCase(targetId, "newsletter_unregister")) {
            openUnRegNewsletterDialog();
          } else {
            const el = document.getElementById(targetId);
            if (!isEmpty(el)) {
              el.scrollIntoView({
                block: "start",
                inline: "start",
                behavior: "smooth",
              });
            }
          }
        }
      } else {
        if (!isEmpty(targetId)) {
          if (equalsIgnoringCase(targetId, "contact")) {
            openContactDialog();
          } else if (equalsIgnoringCase(targetId, "newsletter_register")) {
            openRegNewsletterDialog();
          } else if (equalsIgnoringCase(targetId, "newsletter_unregister")) {
            openUnRegNewsletterDialog();
          } else {
            const el = document.getElementById(targetId);
            if (!isEmpty(el)) {
              el.scrollIntoView({
                block: "start",
                inline: "start",
                behavior: "smooth",
              });
            }
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ui.is_iframe_show, ui.is_iframe_loading]);

  const titleSEO = useMemo(() => {
    const path = location.pathname;

    if (equalsIgnoringCase(path, getLocalePath(t("routes.public.about")))) {
      return `${t("app.name")} - ${t("landing.body.about_section.title")}`;
    } else if (
      equalsIgnoringCase(path, getLocalePath(t("routes.public.plans")))
    ) {
      return `${t("app.name")} - ${t("landing.body.plans_section.subtitle")}`;
    } else if (
      equalsIgnoringCase(path, getLocalePath(t("routes.public.contact")))
    ) {
      return `${t("app.name")} - ${t("contact.title")}`;
    } else if (
      equalsIgnoringCase(path, getLocalePath(t("routes.public.newsletter_reg")))
    ) {
      return `${t("app.name")} - ${t("reg_newsletter_dialog.title")}`;
    } else if (
      equalsIgnoringCase(
        path,
        getLocalePath(t("routes.public.newsletter_unreg"))
      )
    ) {
      return `${t("app.name")} - ${t("reg_newsletter_dialog.title")}`;
    } else {
      return `${t("app.name")} - ${t("landing.title")}`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, location.pathname, t, getLocalePath]);

  const descriptionSEO = useMemo(() => {
    const path = location.pathname;

    if (equalsIgnoringCase(path, getLocalePath(t("routes.public.about")))) {
      return `${t("app.name")} - ${t(
        "landing.body.about_section.description"
      )}`;
    } else if (
      equalsIgnoringCase(path, getLocalePath(t("routes.public.plans")))
    ) {
      return `${t("app.name")} - ${t("landing.body.plans_section.subtitle")}`;
    } else if (
      equalsIgnoringCase(path, getLocalePath(t("routes.public.contact")))
    ) {
      return `${t("app.name")} - ${t("contact.description")}`;
    } else if (
      equalsIgnoringCase(path, getLocalePath(t("routes.public.newsletter_reg")))
    ) {
      return `${t("app.name")} - ${t("reg_newsletter_dialog.subtitle")}`;
    } else if (
      equalsIgnoringCase(
        path,
        getLocalePath(t("routes.public.newsletter_unreg"))
      )
    ) {
      return `${t("app.name")} - ${t("reg_newsletter_dialog.subtitle")}`;
    } else {
      return t("landing.description");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, location.pathname, t, getLocalePath]);

  const urlSEO = useMemo(() => {
    const publicURL = process.env.REACT_APP_PUBLIC_URL;
    const path = location.pathname;

    if (path.startsWith("/get/t/")) {
      return `${publicURL}${path}`;
    } else if (
      equalsIgnoringCase(path, getLocalePath(t("routes.public.about")))
    ) {
      return `${publicURL}${getLocalePath(
        getLocalePath(t("routes.public.about"))
      )}`;
    } else if (
      equalsIgnoringCase(path, getLocalePath(t("routes.public.plans")))
    ) {
      return `${publicURL}${getLocalePath(t("routes.public.plans"))}`;
    } else if (
      equalsIgnoringCase(path, getLocalePath(t("routes.public.contact")))
    ) {
      return `${publicURL}${getLocalePath(t("routes.public.contact"))}`;
    } else if (
      equalsIgnoringCase(path, getLocalePath(t("routes.public.newsletter_reg")))
    ) {
      return `${publicURL}${getLocalePath(t("routes.public.newsletter_reg"))}`;
    } else if (
      equalsIgnoringCase(
        path,
        getLocalePath(t("routes.public.newsletter_unreg"))
      )
    ) {
      return `${publicURL}${getLocalePath(
        t("routes.public.newsletter_unreg")
      )}`;
    } else {
      return `${publicURL}${getLocalePath(t("routes.common.home"))}`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, location.pathname, t, getLocalePath]);

  const altLinksSEO = useMemo(() => {
    const publicURL = process.env.REACT_APP_PUBLIC_URL;
    let path = location.pathname;

    if (equalsIgnoringCase(path, getLocalePath(t("routes.public.about")))) {
      path = "routes.public.about";
    } else if (
      equalsIgnoringCase(path, getLocalePath(t("routes.public.plans")))
    ) {
      path = "routes.public.plans";
    } else if (
      equalsIgnoringCase(path, getLocalePath(t("routes.public.contact")))
    ) {
      path = "routes.public.contact";
    } else if (
      equalsIgnoringCase(path, getLocalePath(t("routes.public.newsletter_reg")))
    ) {
      path = "routes.public.newsletter_reg";
    } else if (
      equalsIgnoringCase(
        path,
        getLocalePath(t("routes.public.newsletter_unreg"))
      )
    ) {
      path = "routes.public.newsletter_unreg";
    } else {
      path = "";
    }

    const result = i18n.languages.map((lng) => {
      return {
        lng: lng,
        url: `${publicURL}/${lng}/${
          !isEmpty(path) ? t(path, { lng: lng }) : ""
        }`,
      };
    });

    const defaultLng = result.find((url) => url.lng === "en");
    result.push({
      lng: "x-default",
      url: defaultLng.url,
    });

    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, i18n.languages, location.pathname, t, getLocalePath]);

  return (
    <div>
      <SEO
        title={titleSEO}
        description={descriptionSEO}
        url={urlSEO}
        canonical={urlSEO}
        altLinks={altLinksSEO}
      />
      <div id="landing" className="landing-container">
        <Media
          queries={{
            xs: "(min-width: 0px) and (max-width: 576px)",
            sm: "(min-width: 577px) and (max-width: 768px)",
            md: "(min-width: 769px) and (max-width: 992px)",
            lg: "(min-width: 993px) and (max-width: 1200px)",
            xl: "(min-width: 1201px) and (max-width: 1400px)",
            xxl: "(min-width: 1401px)",
          }}
        >
          {(matches) =>
            [matches.md, matches.lg, matches.xl, matches.xxl].some(
              (i) => i === true
            ) && <LandingIframe />
          }
        </Media>
        <LandingHeader />
        <LandingBody />
        <LandingFooter />
      </div>
    </div>
  );
}

export default Landing;
