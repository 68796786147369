import classNames from "classnames";
import { isEmpty } from "../../utils/validators";
import { useEffect, useMemo, useState } from "react";

function LinkField(props) {
  const {
    label,
    disabled,
    hidden,
    value,
    placeholder,
    error,
    required,
    register,
  } = props;
  const { name, ref } = register;

  const [isActive, setIsActive] = useState(false);

  const title = useMemo(() => {
    if (isEmpty(placeholder)) {
      if (!isEmpty(value)) {
        return `${value}`;
      } else {
        return "";
      }
    } else {
      if (!isEmpty(value)) {
        return `${placeholder} - ${value}`;
      } else {
        return `${placeholder}`;
      }
    }
  }, [placeholder, value]);

  useEffect(() => {
    setIsActive(!isEmpty(value));
  }, [value]);

  return (
    <div
      className={classNames("link-field__container", {
        unlabelled: isEmpty(label),
        hidden: hidden,
        disabled: disabled,
      })}
      title={title}
    >
      <div className="link-field__content">
        <div className="link-field__ellipsis">
          <a
            ref={ref}
            className="link-field__value link"
            href={value}
            target="_blank"
            rel="noreferrer"
            {...register}
          >
            {value}
          </a>
        </div>
        {label && (
          <label
            htmlFor={name}
            className={classNames("link-field__label", "smaller", {
              active: isActive,
            })}
          >
            {`${label}${required ? " *" : ""}`}
          </label>
        )}
      </div>
      <p className={classNames("link-field__error", { show: error })}>
        {error}
      </p>
    </div>
  );
}

export default LinkField;
