import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { equalsIgnoringCase, isEmpty, isEmptyList } from "../utils/validators";
import {
  addFile,
  clearUploadInfo,
  removeFiles,
  setDisabled,
  setGlobalProgress,
  setGlobalSize,
  setGlobalStatus,
  setShareType,
  setUploadStatus,
} from "../redux/reducers/uploaderReducer";
import {
  GLOBAL_STATUS,
  SHARE_TYPE,
  START_WITH_FILE,
  STATUS_UPLOADER,
} from "../components/uploader-component/utils/config";
import { logDebug } from "../utils/logger";
import { errorToast, infoToast } from "../components/toast";
import {
  setFooterMenuCollapse,
  setLoading,
  setTransferInit,
} from "../redux/reducers/uiReducer";
import {
  clearItems,
  clearPagination,
  clearSelectedItem,
  clearSelectedSubitem,
} from "../redux/reducers/transfersTableReducer";
import { SERVER_STATUS } from "./useInit";

const useUnsharedFiles = (status, loading, files, error) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const isOnline = useMemo(
    () =>
      !isEmpty(status)
        ? equalsIgnoringCase(status, SERVER_STATUS.ONLINE)
        : false,
    [status]
  );

  useEffect(() => {
    if (isOnline && !loading) {
      if (isEmpty(error)) {
        if (!isEmptyList(files)) {
          dispatch(removeFiles());
          files.forEach((file, idx) => {
            dispatch(
              addFile({
                batchId: "batch-9999",
                id: `batch-9999.item-${idx + 1}`,
                completed: 100,
                loaded: file.size,
                previousBatch: null,
                recycled: false,
                state: "finished",
                file: {
                  lastModified: null,
                  lastModifiedDate: null,
                  name: file.name,
                  serverName: !file.name.startsWith(START_WITH_FILE)
                    ? `${START_WITH_FILE}${file.name}`
                    : file.name,
                  size: file.size,
                  type: null,
                  webkitRelativePath: null,
                },
                uploadResponse: {
                  results: [],
                },
              })
            );
          });
          infoToast(
            "useUnsharedFiles",
            { files: files },
            t("message.have_not_uploaded_files", t("message.default"))
          );
          logDebug("useUnsharedFiles", "useUnsharedFiles", { files: files });

          const initSize = files.reduce((sum, file) => sum + file.size, 0);
          dispatch(setGlobalSize(initSize));
          dispatch(setGlobalProgress(100));
          dispatch(setGlobalStatus(GLOBAL_STATUS.SUCCESS));
          dispatch(setUploadStatus(STATUS_UPLOADER.FORM));
          dispatch(setFooterMenuCollapse(true));
        } else {
          dispatch(removeFiles());
          dispatch(setGlobalSize(0));
          dispatch(setGlobalProgress(0));
          dispatch(setGlobalStatus(GLOBAL_STATUS.INIT));
          dispatch(setUploadStatus(STATUS_UPLOADER.DRAG));
          dispatch(clearUploadInfo());
        }
        dispatch(setDisabled(false));
        dispatch(setShareType(SHARE_TYPE.GENERATE));
      } else {
        logDebug("useUnsharedFiles", "useUnsharedFiles", { error: error });
        errorToast(
          "useUnsharedFiles",
          { error: error },
          t(`errors.${error}`, t("errors.default"))
        );
        dispatch(setGlobalStatus(GLOBAL_STATUS.INIT));
        dispatch(setUploadStatus(STATUS_UPLOADER.ERROR));
      }
      dispatch(clearItems());
      dispatch(clearPagination());
      dispatch(clearSelectedItem());
      dispatch(clearSelectedSubitem());
      dispatch(setLoading(true));
      dispatch(setTransferInit(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnline, loading, files, error]);
};

export default useUnsharedFiles;
