const APP_ENV = process.env.REACT_APP_ENV;

const logDebug = (file, func, params) => {
  if (["PREPRODUCTION", "DEVELOPMENT"].includes(APP_ENV)) {
    console.log(file, func, params);
  }
};

const logInfo = (file, func, params) => {
  if (["PREPRODUCTION", "DEVELOPMENT"].includes(APP_ENV)) {
    console.info(file, func, params);
  }
};

const logWarn = (file, func, params) => {
  if (["PREPRODUCTION", "DEVELOPMENT"].includes(APP_ENV)) {
    console.warn(file, func, params);
  }
};

const logError = (file, func, params) => {
  if (["PRODUCTION", "PREPRODUCTION", "DEVELOPMENT"].includes(APP_ENV)) {
    console.error(file, func, params);
  }
};

export { logDebug, logInfo, logWarn, logError };
