import { createSlice } from "@reduxjs/toolkit";
import { START_WITH_FILE } from "../../components/uploader-component/utils/config";
import { logDebug } from "../../utils/logger";

const migrations = {
  0: (state) => {
    return {
      ...state,
      uploader: {
        ...state.uploader,
        loading: false,
        disabled: false,
        globalProgress: 0,
        globalStatus: "INIT",
        globalSize: 0,
        uploadStatus: "UPLOADER_INIT",
        shareType: "SHARE_GENERATE",
        files: [],
      },
    };
  },
  1: (state) => {
    return {
      ...state,
      uploader: {
        ...state.uploader,
        uploadInfo: {
          title: "",
          message: "",
          days: "",
          pass: "",
        },
      },
    };
  },
};
const currentVersion = 1;

const slice = createSlice({
  name: "uploader",
  initialState: {
    uploader: {
      loading: false,
      disabled: false,
      globalProgress: 0,
      globalStatus: "INIT",
      globalSize: 0,
      uploadStatus: "UPLOADER_INIT",
      shareType: "SHARE_GENERATE",
      uploadInfo: {
        title: "",
        message: "",
        days: "",
        pass: "",
      },
      files: [],
    },
  },
  reducers: {
    setLoading: (state, action) => {
      state.uploader.loading = action.payload;
      logDebug("uploaderReducer", "setLoading", {
        loading: state.uploader.loading,
      });
    },
    setDisabled: (state, action) => {
      state.uploader.disabled = action.payload;
      logDebug("uploaderReducer", "setDisabled", {
        disabled: state.uploader.disabled,
      });
    },
    setGlobalProgress: (state, action) => {
      state.uploader.globalProgress = action.payload;
      logDebug("uploaderReducer", "setGlobalProgress", {
        globalProgress: state.uploader.globalProgress,
      });
    },
    setGlobalStatus: (state, action) => {
      state.uploader.globalStatus = action.payload;
      logDebug("uploaderReducer", "setGlobalStatus", {
        globalStatus: state.uploader.globalStatus,
      });
    },
    setGlobalSize: (state, action) => {
      state.uploader.globalSize = action.payload;
      logDebug("uploaderReducer", "setGlobalSize", {
        globalSize: state.uploader.globalSize,
      });
    },
    setUploadStatus: (state, action) => {
      state.uploader.uploadStatus = action.payload;
      logDebug("uploaderReducer", "setUploadStatus", {
        uploadStatus: state.uploader.uploadStatus,
      });
    },
    setShareType: (state, action) => {
      state.uploader.shareType = action.payload;
      logDebug("uploaderReducer", "setShareType", {
        shareType: state.uploader.shareType,
      });
    },
    setUploadInfo: (state, action) => {
      state.uploader.uploadInfo = {
        ...state.uploader.uploadInfo,
        ...action.payload,
      };
      logDebug("uploaderReducer", "setUploadInfo", {
        uploadInfo: state.uploader.uploadInfo,
      });
    },
    clearUploadInfo: (state, action) => {
      state.uploader.uploadInfo = {
        title: "",
        message: "",
        days: "",
        pass: "",
      };
      logDebug("uploaderReducer", "clearUploadInfo", {
        uploadInfo: state.uploader.uploadInfo,
      });
    },
    setFiles: (state, action) => {
      state.uploader.files = action.payload;
      logDebug("uploaderReducer", "setFiles", { files: state.uploader.files });
    },
    removeFiles: (state, action) => {
      state.uploader.files = [];
      logDebug("uploaderReducer", "removeFiles", {
        files: state.uploader.files,
      });
    },
    addFile: (state, action) => {
      const files = [...state.uploader.files];
      const file = { ...action.payload };
      const idx = files.findIndex((item) => item.file.name === file.file.name);
      if (idx === -1) {
        const objectFile = {
          lastModified: file.file.lastModified,
          lastModifiedDate: file.file.lastModifiedDate,
          name: file.file.name,
          serverName: !file.file.name.startsWith(START_WITH_FILE)
            ? `${START_WITH_FILE}${file.file.name}`
            : file.file.name,
          size: file.file.size,
          type: file.file.type,
          webkitRelativePath: file.file.webkitRelativePath,
        };
        file.file = objectFile;
        files.push(file);
        state.uploader.files = files;
      }
      logDebug("uploaderReducer", "addFile", { files: state.uploader.files });
    },
    updateFile: (state, action) => {
      const files = [...state.uploader.files];
      const file = { ...action.payload };
      const idx = files.findIndex((item) => item.file.name === file.file.name);
      if (idx > -1) {
        const objectFile = {
          lastModified: file.file.lastModified,
          lastModifiedDate: file.file.lastModifiedDate,
          name: file.file.name,
          serverName: file.file.serverName,
          size: file.file.size,
          type: file.file.type,
          webkitRelativePath: file.file.webkitRelativePath,
        };
        file.file = objectFile;
        files.splice(idx, 1, file);
        state.uploader.files = files;
      }
      logDebug("uploaderReducer", "updateFile", {
        files: state.uploader.files,
      });
    },
    removeFile: (state, action) => {
      const files = [...state.uploader.files];
      const file = { ...action.payload };
      const idx = files.findIndex((item) => item.file.name === file.file.name);
      if (idx > -1) {
        files.splice(idx, 1);
        state.uploader.files = files;
      }
      logDebug("uploaderReducer", "removeFile", {
        files: state.uploader.files,
      });
    },
  },
});

export const {
  setLoading,
  setDisabled,
  setGlobalProgress,
  setGlobalStatus,
  setGlobalSize,
  setUploadStatus,
  setShareType,
  setUploadInfo,
  clearUploadInfo,
  setFiles,
  removeFiles,
  addFile,
  updateFile,
  removeFile,
} = slice.actions;
export { migrations, currentVersion };
export default slice.reducer;
