import { createSlice } from "@reduxjs/toolkit";
import { logDebug } from "../../utils/logger";

const initialState = {
  form: {
    name: "",
    email: "",
    email2: "",
    tlf: "",
    reason: "",
    message: "",
    recaptcha: "",
    consent: false,
    url: "",
    resolution: "",
  },
};

const reducers = {
  setForm: (state, action) => {
    state.form = action.payload;
    logDebug("passwordReducer", "setForm", { form: state.form });
  },
  clearForm: (state, action) => {
    state.form = initialState;
    logDebug("passwordReducer", "clearForm", { form: state.form });
  },
};

const slice = createSlice({
  name: "contact",
  initialState: initialState,
  reducers: reducers,
});

export const { setForm, clearForm } = slice.actions;
export default slice.reducer;
