import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import classNames from "classnames";

import { logDebug, logError } from "../../../../utils/logger";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useWindowSize from "../../../../hooks/useWindowSize";
import useTransferFiles from "../../../../hooks/useTransferFiles";
import useGetLocalePath from "../../../../hooks/useGetLocalePath";
import useCopyToClipboard from "../../../../hooks/useCopyToClipboard.ts";
import useDeleteTransfer from "../../../../hooks/useDeleteTransfer";
import useDeleteTransferFile from "../../../../hooks/useDeleteTransferFile";

import Table from "../../../table";
import LoadingPage from "../../../loading-page";
import InfiniteScroll from "react-infinite-scroll-component";
import DeleteTransferDialog from "../../delete-transfer-dialog";
import ShareTransferDialog from "../../share-transfer-dialog";
import { errorToast, successToast } from "../../../toast";
import DeleteTransferFileDialog from "../../delete-transfer-file-dialog";
import FloatingMenu from "../../../floating-menu";

import {
  clearItems,
  clearPagination,
  clearSelectedItem,
  clearSelectedSubitem,
  setSelectedItem,
  setSelectedSubitem,
} from "../../../../redux/reducers/transfersTableReducer";
import {
  setLoading,
  setTransferInit,
  setUserInit,
} from "../../../../redux/reducers/uiReducer";

import {
  humanFileSize,
  isEmpty,
  isEmptyList,
} from "../../../../utils/validators";
import { START_WITH_FILE } from "../../../uploader-component/utils/config";
import {
  DATE_FORMAT,
  expirationFormat,
  format,
} from "../../../../utils/moment";

import moment from "moment";
import "moment-timezone";

function TransferTable(props) {
  const { loading, items, total, more, nextHandler } = props;

  const dispatch = useDispatch();
  const { transfersTable } = useSelector((state) => state);

  const selectedItemMemo = useMemo(() => {
    return JSON.parse(transfersTable.selectedItem);
  }, [transfersTable.selectedItem]);

  const selectedSubitemMemo = useMemo(() => {
    return JSON.parse(transfersTable.selectedSubitem);
  }, [transfersTable.selectedSubitem]);

  const { t, i18n } = useTranslation();
  const windowSize = useWindowSize();
  const navigate = useNavigate();
  const { getLocalePath } = useGetLocalePath();

  const [showShareDialog, setShowShareDialog] = useState(false);
  const [showDeleteTransferDialog, setShowDeleteTransferDialog] =
    useState(false);
  const [deleteTransfer, setDeleteTransfer] = useState(false);
  const [showDeleteTransferFileDialog, setShowDeleteTransferFileDialog] =
    useState(false);

  const data = useMemo(() => items, [items]);

  const getNestedRoute = useCallback(
    (nestedRoute) => {
      return getLocalePath(
        `${t("routes.private.uploads.index")}/${nestedRoute}`
      );
    },
    [getLocalePath, t]
  );

  const getTransferTitle = useCallback(
    (value, row) => {
      const title = !isEmpty(value) ? `${value} · ` : "";
      const dispatched = format(
        row.original.dispatched,
        DATE_FORMAT[`default_${i18n.language}`]
      );
      return `${title}${dispatched}`;
    },
    [i18n.language]
  );

  const getTransferExpirationDateTitle = useCallback(
    (value, row) => {
      const localTimezone = moment.tz.guess();
      const now = moment.tz(localTimezone);
      const expDateServer = moment.tz(
        row.original.until,
        "YYYY-MM-DD HH:mm:ss",
        i18n.language,
        true,
        localTimezone
      );
      const expDate = moment.tz(
        expDateServer,
        "YYYY-MM-DD HH:mm:ss",
        i18n.language,
        true,
        process.env.REACT_APP_TIMEZONE
      );
      const humanFormatExpDate = expirationFormat(now, expDate, i18n.language);
      const expDateLocal = format(
        expDate,
        DATE_FORMAT[`default_${i18n.language}`]
      );
      return now.isSameOrAfter(expDate)
        ? `${t("errors.exp_transfer_date")} - ${humanFormatExpDate}`
        : `${humanFormatExpDate} - ${expDateLocal}`;
    },
    [t, i18n.language]
  );

  const getTransferExpirationDate = useCallback(
    (value, row) => {
      const now = moment().tz(moment.tz.guess());
      const expDateServer = moment(row.original.until).tz(
        process.env.REACT_APP_TIMEZONE
      );
      const expDate = expDateServer.tz(process.env.REACT_APP_TIMEZONE);
      const humanFormatExpDate = expirationFormat(now, expDate, i18n.language);
      return now.isSameOrAfter(expDate)
        ? `${t("errors.expired_date")}`
        : `${humanFormatExpDate}`;
    },
    [t, i18n.language]
  );

  const getTransferFileCount = useCallback(
    (value, row) => {
      return t("uploads.table.rows.fileWithCount", { count: Number(value) });
    },
    [t]
  );

  const getTransferSize = useCallback(
    (value, row) => {
      return humanFileSize(value, i18n.language, true, 2);
    },
    [i18n.language]
  );

  const getTransferSubtitle = useCallback(
    (row) => {
      const transferFileCount = getTransferFileCount(row.original.num_files);
      const transferSize = getTransferSize(row.original.size);
      return `${transferFileCount} · ${transferSize}`;
    },
    [getTransferFileCount, getTransferSize]
  );

  const goToTransferDetail = useCallback(
    (row) => {
      const path = getNestedRoute(t("routes.private.uploads.detail"));
      const transfer = row.original;

      navigate(path, {
        state: {
          ...transfer,
        },
      });
    },
    [getNestedRoute, navigate, t]
  );

  const columns = useMemo(() => {
    if (windowSize.width >= 992) {
      return [
        {
          id: "title",
          accessor: "title",
          Header: () => t("uploads.table.columns.title"),
          Cell: ({ value, row }) => (
            <div
              className="cell__container"
              title={getTransferTitle(value, row)}
            >
              <div className="ellipsis__container">
                <span
                  role="link"
                  className="ellipsis__value link"
                  onClick={() => goToTransferDetail(row)}
                >
                  {getTransferTitle(value, row)}
                </span>
              </div>
            </div>
          ),
          Footer: () => {},
        },
        {
          id: "until",
          accessor: "until",
          Header: () => t("uploads.table.columns.until"),
          Cell: ({ value, row }) => (
            <div
              className="cell__container"
              title={getTransferExpirationDateTitle(value, row)}
            >
              <div className="ellipsis__container">
                <span className="ellipsis__value">
                  {getTransferExpirationDate(value, row)}
                </span>
              </div>
            </div>
          ),
          Footer: () => {},
        },
        {
          id: "num_files",
          accessor: "num_files",
          Header: () => t("uploads.table.columns.num_files"),
          Cell: ({ value, row }) => (
            <div
              className="cell__container"
              title={getTransferFileCount(value, row)}
            >
              <div className="ellipsis__container">
                <span className="ellipsis__value">
                  {getTransferFileCount(value, row)}
                </span>
              </div>
            </div>
          ),
          Footer: () => {},
        },
        {
          id: "size",
          accessor: "size",
          Header: () => t("uploads.table.columns.size"),
          Cell: ({ value, row }) => (
            <div
              className="cell__container"
              title={getTransferSize(value, row)}
            >
              <div className="ellipsis__container">
                <span className="ellipsis__value">
                  {getTransferSize(value, row)}
                </span>
              </div>
            </div>
          ),
          Footer: () => {},
        },
        {
          id: "actions",
          accessor: "actions",
          Header: () => t("uploads.table.columns.actions"),
          Cell: ({ row }) => <RowTransferActions row={row} />,
          Footer: () => {},
        },
      ];
    } else {
      return [
        {
          id: "title",
          accessor: "title",
          Header: () => t("uploads.table.columns.title"),
          Cell: ({ value, row }) => (
            <div className="cell__container title-more-info">
              <div className="cell__container__row">
                <div className="ellipsis__container">
                  <span
                    className="ellipsis__value link title"
                    title={getTransferTitle(value, row)}
                    onClick={() => goToTransferDetail(row)}
                  >
                    {getTransferTitle(value, row)}
                  </span>
                </div>
              </div>
              <div className="cell__container__row">
                <div className="ellipsis__container">
                  <span
                    className="ellipsis__value subtitle"
                    title={getTransferSubtitle(row)}
                  >
                    {getTransferSubtitle(row)}
                  </span>
                </div>
              </div>
            </div>
          ),
          Footer: () => {},
        },
        {
          id: "actions",
          accessor: "actions",
          Header: () => null,
          Cell: ({ row }) => <RowTransferActions row={row} />,
          Footer: () => {},
        },
      ];
    }
  }, [
    windowSize.width,
    t,
    getTransferTitle,
    goToTransferDetail,
    getTransferExpirationDate,
    getTransferExpirationDateTitle,
    getTransferFileCount,
    getTransferSize,
    getTransferSubtitle,
  ]);

  const RowTransferActions = (props) => {
    const { row } = props;

    const containerRef = useRef(
      document.getElementById("scrollable-transfer-table")
    );

    const menuItems = useMemo(() => {
      return [
        {
          label: t("uploads.table.actions.menu.copy"),
          icon: "icon-copy",
          value: "copy",
          disabled: false,
        },
        {
          label: t("uploads.table.actions.menu.share"),
          icon: "icon-share",
          value: "share",
          disabled: false,
        },
        {
          label: t("uploads.table.actions.menu.delete"),
          icon: "icon-delete-circled-outline",
          value: "delete",
          disabled: false,
        },
      ];
    }, []);

    const [copiedText, copy] = useCopyToClipboard();
    const sharedLink = useCallback((idTransfer) => {
      return !isEmpty(idTransfer)
        ? `${process.env.REACT_APP_PUBLIC_URL}/get/t/${idTransfer}`
        : "";
    }, []);

    const copyTransferHandler = useCallback(
      (evt, row) => {
        logDebug("TransferTable", "copyTransferHandler", {
          evt: evt,
          row: row,
        });
        const sharedLinkURL = sharedLink(row.original.idTransfer);
        copy(sharedLinkURL);
        successToast(
          "copyTransferHandler",
          sharedLinkURL,
          t("message.clipboard")
        );
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [copiedText, copy, sharedLink, t]
    );

    const shareTransferHandler = useCallback((evt, row) => {
      logDebug("TransferTable", "shareTransferHandler", { evt: evt, row: row });
      dispatch(setSelectedItem(row.original));
      setShowShareDialog(true);
    }, []);

    const deleteTransferHandler = useCallback((evt, row) => {
      logDebug("TransferTable", "deleteTransferHandler", {
        evt: evt,
        row: row,
      });
      dispatch(setSelectedItem(row.original));
      setShowDeleteTransferDialog(true);
    }, []);

    const expandTransferHandler = useCallback((evt, row) => {
      logDebug("TransferTable", "expandTransferHandler", {
        evt: evt,
        row: row,
      });
    }, []);

    const detailTransferHandler = useCallback((evt, row) => {
      logDebug("TransferTable", "detailTransferHandler", {
        evt: evt,
        row: row,
      });
      dispatch(setSelectedItem(row.original));
      goToTransferDetail(row);
    }, []);

    const menuItemClickHandler = useCallback(
      (opt, row) => {
        if (!isEmpty(opt)) {
          switch (opt) {
            case "copy":
              const sharedLinkURL = sharedLink(row.original.idTransfer);
              copy(sharedLinkURL);
              successToast(
                "menuItemClickHandler - copy",
                sharedLinkURL,
                t("message.clipboard")
              );
              logDebug("TransferTable", "menuItemClickHandler - copy", {
                opt: opt,
                row: row,
              });
              break;
            case "share":
              logDebug("TransferTable", "menuItemClickHandler - share", {
                opt: opt,
                row: row,
              });
              dispatch(setSelectedItem(row.original));
              setShowShareDialog(true);
              break;
            case "delete":
              logDebug("TransferTable", "menuItemClickHandler - delete", {
                opt: opt,
                row: row,
              });
              dispatch(setSelectedItem(row.original));
              setShowDeleteTransferDialog(true);
              break;
            default:
              break;
          }
        }
      },
      [copy, sharedLink]
    );

    return windowSize.width >= 992 ? (
      <div className="cell__container actions">
        <span
          role="button"
          className="action__icon icon-copy"
          title={t("uploads.table.actions.copy")}
          onClick={(evt) => copyTransferHandler(evt, row)}
        ></span>
        <span
          role="button"
          className="action__icon icon-share"
          title={t("uploads.table.actions.share")}
          onClick={(evt) => shareTransferHandler(evt, row)}
        ></span>
        <span
          role="button"
          className="action__icon icon-delete-circled-outline"
          title={t("uploads.table.actions.delete")}
          onClick={(evt) => deleteTransferHandler(evt, row)}
        ></span>
        <span
          role="button"
          className={classNames("action__icon", {
            "icon-nav-arrow-down": !row.isExpanded,
            "icon-nav-arrow-up": row.isExpanded,
          })}
          onClick={(evt) => expandTransferHandler(evt, row)}
          {...row.getToggleRowExpandedProps()}
          title={t("uploads.table.actions.expand")}
        ></span>
      </div>
    ) : (
      <div className="cell__container actions">
        <FloatingMenu
          containerRef={containerRef}
          align="end"
          menuItems={menuItems}
          menuButton={<span className="icon-more-horiz"></span>}
          menuItemClick={(data) => menuItemClickHandler(data.value, row)}
        />
        <span
          role="button"
          className="action__icon icon-nav-arrow-right"
          title={t("uploads.table.actions.detail")}
          onClick={(evt) => detailTransferHandler(evt, row)}
        ></span>
      </div>
    );
  };

  const FileDetail = (props) => {
    const { row } = props;

    const [files, setFiles] = useState([]);

    const {
      loading: loadingFiles,
      data: dataFiles,
      error: errorFiles,
      handler: getFilesHandler,
    } = useTransferFiles();
    useEffect(() => {
      if (!isEmpty(row.original) && !isEmpty(row.original.idTransfer)) {
        getFilesHandler(row.original.idTransfer);
      }
    }, [row.original, getFilesHandler]);

    useEffect(() => {
      if (!loadingFiles) {
        if (isEmpty(errorFiles)) {
          if (!isEmptyList(dataFiles)) {
            setFiles([...dataFiles]);
          } else {
            setFiles([]);
          }
        } else {
          errorToast(
            "transferTable",
            errorFiles,
            t(`errors.${errorFiles}`, t("errors.default"))
          );
          setFiles([]);
        }
      }
    }, [loadingFiles, dataFiles, errorFiles]);

    const LoadingFiles = () => {
      return (
        <tr
          className={classNames("table__tbody__tr", {
            loading: loadingFiles,
          })}
          role="row"
        >
          <td className="table__tbody__td" colSpan={5}>
            <LoadingPage />
          </td>
        </tr>
      );
    };

    const RowFileActions = (props) => {
      const { transfer, file } = props;

      const deleteFileHandler = useCallback((evt, transfer, file) => {
        logDebug("TransferTable", "deleteFileHandler", {
          evt: evt,
          transfer: transfer,
          file: file,
        });
        dispatch(setSelectedSubitem(file));
        dispatch(setSelectedItem(transfer));
        setDeleteTransfer(parseInt(transfer.num_files) === 1);
        setShowDeleteTransferFileDialog(true);
      }, []);

      return (
        <div className="cell__container actions">
          <span
            role="button"
            className="action__icon icon-delete-circled-outline"
            title={t("uploads.table.actions.delete")}
            onClick={(evt) => deleteFileHandler(evt, transfer, file)}
          ></span>
        </div>
      );
    };

    const FileRowItem = (props) => {
      const { transfer, file } = props;

      const fileName = useMemo(() => {
        return file.fileName.replace(START_WITH_FILE, "");
      }, [file.fileName]);

      return (
        <tr
          className={classNames("table__tbody__tr", {
            expanded: row.isExpanded,
          })}
          role="row"
        >
          <td className="table__tbody__td" colSpan={3}>
            <div class="cell__container subrow" title={fileName}>
              <span className="icon icon-empty-page"></span>
              <div className="ellipsis__container">
                <span className="ellipsis__value title">{fileName}</span>
              </div>
            </div>
          </td>
          <td className="table__tbody__td">
            <div
              className="cell__container subrow"
              title={humanFileSize(file.fileSize, i18n.language, true, 2)}
            >
              <div className="ellipsis__container">
                <span className="ellipsis__value">
                  {humanFileSize(file.fileSize, i18n.language, true, 2)}
                </span>
              </div>
            </div>
          </td>
          <td className="table__tbody__td">
            <span className="cell__container actions">
              <RowFileActions transfer={transfer} file={file} />
            </span>
          </td>
        </tr>
      );
    };

    return (
      <>
        {loadingFiles && <LoadingFiles />}
        {!isEmptyList(files)
          ? files.map((file) => (
              <FileRowItem transfer={row.original} file={file} />
            ))
          : null}
      </>
    );
  };

  const renderRowComponent = useCallback(
    ({ row }) => {
      return (
        <>
          {windowSize.width <= 576 ? (
            <tr className="table__tbody__tr" {...row.getRowProps()}>
              <td className="table__tbody__td"></td>
              <td className="table__tbody__td"></td>
            </tr>
          ) : (
            <tr className="table__tbody__tr" {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  <td className="table__tbody__td" {...cell.getCellProps()}>
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          )}
        </>
      );
    },
    [windowSize.width]
  );

  const renderRowSubComponent = useCallback(({ row }) => {
    return <FileDetail row={row} />;
  }, []);

  const closeDeleteTransferHandler = useCallback(
    (evt) => {
      logDebug("TransferTable", "closeDeleteTransferHandler", { evt: evt });
      setShowDeleteTransferDialog(false);
      dispatch(clearSelectedItem());
    },
    [dispatch]
  );

  const {
    loading: loadingDeleteTransfer,
    data: dataDeleteTransfer,
    error: errorDeleteTransfer,
    deleteTransferHandler,
  } = useDeleteTransfer();
  const acceptDeleteTransferHandler = useCallback(
    (evt) => {
      logDebug("TransferTable", "acceptDeleteTransferHandler", { evt: evt });
      if (!isEmpty(selectedItemMemo) && !isEmpty(selectedItemMemo.idTransfer)) {
        deleteTransferHandler(selectedItemMemo.idTransfer);
      }
    },
    [deleteTransferHandler, selectedItemMemo]
  );
  useEffect(() => {
    if (!loadingDeleteTransfer) {
      if (isEmpty(errorDeleteTransfer)) {
        if (!isEmpty(dataDeleteTransfer)) {
          successToast(
            "TransferTable",
            "useDeleteTransfer",
            t("message.default")
          );
          logDebug("TransferTable", "useDeleteTransfer", {
            msg: t("message.default"),
          });
          dispatch(clearItems());
          dispatch(clearPagination());
          dispatch(clearSelectedItem());
          dispatch(clearSelectedSubitem());
          setShowDeleteTransferDialog(false);
          dispatch(setUserInit(true));
          dispatch(setLoading(true));
          dispatch(setTransferInit(true));
        }
      } else {
        errorToast(
          "TransferTable",
          "useDeleteTransfer",
          t(`errors.${errorDeleteTransfer}`, t("errors.default"))
        );
        logError("TransferTable", "useDeleteTransfer", {
          msg: t(`errors.${errorDeleteTransfer}`, t("errors.default")),
        });
        dispatch(clearItems());
        dispatch(clearPagination());
        dispatch(clearSelectedItem());
        dispatch(clearSelectedSubitem());
        setShowDeleteTransferDialog(false);
        dispatch(setUserInit(true));
        dispatch(setLoading(true));
        dispatch(setTransferInit(true));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingDeleteTransfer]);

  const cancelDeleteTransferHandler = useCallback(
    (evt) => {
      logDebug("TransferTable", "cancelDeleteTransferHandler", { evt: evt });
      dispatch(clearSelectedItem());
      setShowDeleteTransferDialog(false);
    },
    [dispatch]
  );

  const closeDeleteTransferFileHandler = useCallback(
    (evt) => {
      logDebug("TransferTable", "closeDeleteTransferFileHandler", { evt: evt });
      dispatch(clearSelectedItem());
      dispatch(clearSelectedSubitem());
      setDeleteTransfer(false);
      setShowDeleteTransferFileDialog(false);
    },
    [dispatch]
  );

  const {
    loading: loadingDeleteTransferFile,
    data: dataDeleteTransferFile,
    error: errorDeleteTransferFile,
    deleteTransferFileHandler,
  } = useDeleteTransferFile();
  const acceptDeleteTransferFileHandler = useCallback(
    (evt) => {
      logDebug("TransferTable", "acceptDeleteTransferFileHandler", {
        evt: evt,
      });
      if (
        !isEmpty(selectedSubitemMemo) &&
        !isEmpty(selectedSubitemMemo.idTransfer) &&
        !isEmpty(selectedSubitemMemo.idFile)
      ) {
        deleteTransferFileHandler(
          selectedSubitemMemo.idTransfer,
          selectedSubitemMemo.idFile
        );
      }
    },
    [deleteTransferFileHandler, selectedSubitemMemo]
  );
  useEffect(() => {
    if (!loadingDeleteTransferFile) {
      if (isEmpty(errorDeleteTransferFile)) {
        if (!isEmpty(dataDeleteTransferFile)) {
          successToast(
            "TransferTable",
            "useDeleteTransferFile",
            t("message.default")
          );
          logDebug("TransferTable", "useDeleteTransferFile", {
            msg: t("message.default"),
          });
          dispatch(clearItems());
          dispatch(clearPagination());
          dispatch(clearSelectedItem());
          dispatch(clearSelectedSubitem());
          dispatch(setUserInit(true));
          dispatch(setLoading(true));
          dispatch(setTransferInit(true));
          setDeleteTransfer(false);
          setShowDeleteTransferFileDialog(false);
        }
      } else {
        errorToast(
          "TransferTable",
          "useDeleteTransferFile",
          t(`errors.${errorDeleteTransferFile}`, t("errors.default"))
        );
        logError("TransferTable", "useDeleteTransferFile", {
          msg: t(`errors.${errorDeleteTransferFile}`, t("errors.default")),
        });
        dispatch(clearItems());
        dispatch(clearPagination());
        dispatch(clearSelectedItem());
        dispatch(clearSelectedSubitem());
        dispatch(setUserInit(true));
        dispatch(setLoading(true));
        dispatch(setTransferInit(true));
        setDeleteTransfer(false);
        setShowDeleteTransferFileDialog(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingDeleteTransferFile]);

  const cancelDeleteTransferFileHandler = useCallback(
    (evt) => {
      logDebug("TransferTable", "cancelDeleteTransferFileHandler", {
        evt: evt,
      });
      dispatch(clearSelectedItem());
      dispatch(clearSelectedSubitem());
      setDeleteTransfer(false);
      setShowDeleteTransferFileDialog(false);
    },
    [dispatch]
  );

  const closeShareHandler = useCallback(
    (evt) => {
      logDebug("TransferTable", "closeShareHandler", { evt: evt });
      dispatch(clearSelectedItem());
      setShowShareDialog(false);
    },
    [dispatch]
  );

  return (
    <InfiniteScroll
      scrollableTarget="scrollable-transfer-table"
      dataLength={total}
      hasMore={more}
      next={nextHandler}
      loader={<LoadingPage />}
    >
      <Table
        loading={loading}
        data={data}
        columns={columns}
        withHeaders={false}
        renderRowComponent={renderRowComponent}
        renderRowSubComponent={renderRowSubComponent}
      />
      <DeleteTransferDialog
        showDialog={showDeleteTransferDialog}
        closeHandler={(evt) => closeDeleteTransferHandler(evt)}
        acceptHandler={(evt) => acceptDeleteTransferHandler(evt)}
        cancelHandler={(evt) => cancelDeleteTransferHandler(evt)}
      />
      <DeleteTransferFileDialog
        showDialog={showDeleteTransferFileDialog}
        deleteTransfer={deleteTransfer}
        closeHandler={(evt) => closeDeleteTransferFileHandler(evt)}
        acceptHandler={(evt) => acceptDeleteTransferFileHandler(evt)}
        cancelHandler={(evt) => cancelDeleteTransferFileHandler(evt)}
      />
      <ShareTransferDialog
        showDialog={showShareDialog}
        idTransfer={selectedItemMemo?.idTransfer}
        closeHandler={(evt) => closeShareHandler(evt)}
      />
    </InfiniteScroll>
  );
}

export default TransferTable;
