import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function BillPageHeader() {
  const { t } = useTranslation();

  return (
    <div className="bills__title">
      <Link to={t("routes.common.go_back")}>
        <div className="link-container">
          <span className="icon icon-nav-arrow-left"></span>
          <span className="label small-title">{t("bills.title")}</span>
        </div>
      </Link>
    </div>
  );
}

export default BillPageHeader;
