import classNames from "classnames";
import { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import Divider from "../divider";

function DropDown({ options, value, placeHolder, disabled, onChange }) {
  const { t } = useTranslation();

  const [showDropdownList, setShowDropdownList] = useState(false);

  const dropDownRef = useRef();
  useOnClickOutside(dropDownRef, () => setShowDropdownList(false));

  const display = useMemo(() => {
    return (
      (!value
        ? placeHolder
        : options?.find((item) => item.value === value)?.label) ||
      t("dropdown.placeholder")
    );
  }, [value, placeHolder, options, t]);

  const isSelected = useCallback(
    (option) => {
      return value ? value === option.value : false;
    },
    [value]
  );

  const onSelectedItemClick = () => {
    setShowDropdownList(!showDropdownList && !disabled);
  };

  const onItemClick = (option) => {
    if (!disabled) {
      onChange(option.value);
      setShowDropdownList(false);
    }
  };

  return (
    <div
      ref={dropDownRef}
      className={classNames("dropdown-container", { disabled: disabled })}
    >
      <div className="dropdown-input" onClick={() => onSelectedItemClick()}>
        <div className="dropdown-selected-value">
          <span>{display}</span>
          <span
            className={classNames({
              "icon-nav-arrow-down": !showDropdownList,
              "icon-nav-arrow-up": showDropdownList,
            })}
          ></span>
        </div>
      </div>
      {options && options.length > 0 && (
        <div
          className={classNames("dropdown-list", { open: showDropdownList })}
        >
          {options.map((option, idx) => (
            <div
              className={classNames("dropdown-item", {
                selected: isSelected(option),
              })}
              key={idx}
              onClick={() => onItemClick(option)}
            >
              <span className="label">{option.label}</span>
              {idx < options.length - 1 && <Divider />}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default DropDown;
