import { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Divider from "../divider";
import { isEmpty, isEmptyList } from "../../utils/validators";

function ShareComponent({ sharedLink, disabled }) {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const shareCompRef = useRef();
  useOnClickOutside(shareCompRef, () => setIsOpen(false));

  const placeHolder = useMemo(() => {
    return t("social_media.share_ph");
  }, [t]);

  const mailLink = useMemo(() => {
    return !isEmpty(sharedLink)
      ? encodeURI(
          `mailto:?subject=${t("social_media.share_msg")}&body=${sharedLink}`
        )
      : "";
  }, [sharedLink, t]);

  const gmailLink = useMemo(() => {
    return !isEmpty(sharedLink)
      ? encodeURI(
          `https://mail.google.com/mail/?view=${"cm"}&su=${t(
            "social_media.share_msg"
          )}&body=${sharedLink}`
        )
      : "";
  }, [sharedLink, t]);

  const twitterLink = useMemo(() => {
    return !isEmpty(sharedLink)
      ? encodeURI(
          `https://twitter.com/intent/tweet?text=${t(
            "social_media.share_msg"
          )}&url=${sharedLink}&via=${`Ydray_`}&hashtags=YDRAY`
        )
      : "";
  }, [sharedLink, t]);

  const facebookLink = useMemo(() => {
    return !isEmpty(sharedLink)
      ? encodeURI(
          `https://www.facebook.com/sharer/sharer.php?text=${t(
            "social_media.share_msg"
          )}&display="page"&href=${sharedLink}&hashtag=YDRAY`
        )
      : "";
  }, [sharedLink, t]);

  const whatsappLink = useMemo(() => {
    return !isEmpty(sharedLink)
      ? encodeURI(
          `whatsapp://send?text="${t("social_media.share_msg")} ${sharedLink}"`
        )
      : "";
  }, [sharedLink, t]);

  const options = useMemo(() => {
    return [
      {
        label: t("social_media.mail"),
        value: "email",
        icon: "icon-mail",
        link: mailLink,
      },
      {
        label: t("social_media.gmail"),
        value: "gmail",
        icon: "icon-gmail",
        link: gmailLink,
      },
      {
        label: t("social_media.twitter"),
        value: "twitter",
        icon: "icon-twitter-x",
        link: twitterLink,
      },
      {
        label: t("social_media.facebook"),
        value: "facebook",
        icon: "icon-facebook",
        link: facebookLink,
      },
      {
        label: t("social_media.whatsapp"),
        value: "whatsapp",
        icon: "icon-whatsapp",
        link: whatsappLink,
      },
    ];
  }, [mailLink, gmailLink, twitterLink, facebookLink, whatsappLink, t]);

  return (
    <div
      ref={shareCompRef}
      className={classNames("share-component__container", {
        disabled: disabled,
      })}
    >
      <div
        className="share-component__input"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="share-component__selected-value">
          {placeHolder}
          <span
            className={classNames({
              "icon-nav-arrow-down": !isOpen,
              "icon-nav-arrow-up": isOpen,
            })}
          ></span>
        </div>
      </div>
      {!isEmptyList(options) && (
        <div className={classNames("share-component__list", { open: isOpen })}>
          {options.map((opt, idx) => (
            <Link
              className="share-component__list-item"
              key={idx}
              to={opt.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="list-item">
                <span className={classNames("icon", opt.icon)}></span>
                <span className="label">{opt.label}</span>
              </div>
              {idx < options.length - 1 && <Divider />}
            </Link>
          ))}
        </div>
      )}
    </div>
  );
}

export default ShareComponent;
