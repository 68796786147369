import { useCallback, useMemo, useState } from "react";
import { parseDataUrl } from "../utils/services.utils";

const useChangePassword = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const url = useMemo(() => {
    return `https://${process.env.REACT_APP_API}/user/changepassword/`;
  }, []);

  const changePassHandler = useCallback(
    (changePasswordData) => {
      try {
        setLoading(true);
        fetch(url, {
          method: "POST",
          mode: "cors",
          credentials: "include",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: parseDataUrl(changePasswordData),
        })
          .then((response) => response.json())
          .then((data) => {
            setError(data.error);
            delete data.error;
            setData(data);
          })
          .catch((error) => setError(error))
          .finally(() => setLoading(false));
      } catch (error) {
        setError(error);
      }
    },
    [url]
  );

  return { loading, data, error, changePassHandler };
};

export default useChangePassword;
