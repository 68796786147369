import * as yup from "yup";

const getRegisterUserSchema = (t) => {
  return yup.object().shape({
    username: yup.string().notRequired(),
    email: yup
      .string()
      .required(t("validators.notEmpty"))
      .email(t("validators.email"))
      .min(0, t("validators.minLength", { value: 0 }))
      .max(45, t("validators.maxLength", { value: 45 })),
    pass1: yup
      .string()
      .required(t("validators.notEmpty"))
      .min(8, t("validators.minLength", { value: 8 }))
      .max(16, t("validators.maxLength", { value: 16 })),
    pass2: yup
      .string()
      .required(t("validators.notEmpty"))
      .min(8, t("validators.minLength", { value: 8 }))
      .max(16, t("validators.maxLength", { value: 16 }))
      .oneOf([yup.ref("pass1"), null], t("validators.compareFields")),
    csrf_token: yup.string().notRequired(),
    "g-recaptcha-response": yup.string().notRequired(),
  });
};

export default getRegisterUserSchema;
