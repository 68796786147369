import { useMemo } from "react";
import { useTranslation } from "react-i18next";

function FloatingMsg(props) {
  const { t } = useTranslation();

  const {
    children,
    closable = true,
    top,
    right,
    bottom,
    left,
    bgColor,
    closeHandler,
  } = props;

  const style = useMemo(() => {
    return {
      ...(top && { top: `${top}px` }),
      ...(right && { right: `${right}px` }),
      ...(bottom && { bottom: `${bottom}px` }),
      ...(left && { left: `${left}px` }),
      ...(bgColor && { backgroundColor: bgColor }),
    };
  }, [top, right, bottom, left, bgColor]);

  return (
    <div className="floating-message__container" style={style}>
      {closable && (
        <span
          className="icon icon-close"
          title={t("button.close")}
          onClick={(evt) => closeHandler(false)}
        ></span>
      )}
      <div className="floating-message__content">{children}</div>
    </div>
  );
}

export default FloatingMsg;
