import FooterMenu from "../footer-menu";

function FooterComponent() {
  return (
    <div className="footer-component">
      <FooterMenu />
    </div>
  );
}

export default FooterComponent;
