import { t } from "i18next";
import store from "../../../redux/store";
import { errorToast } from "../../toast";
import {
  equalsIgnoringCase,
  isEmpty,
  isEmptyList,
} from "../../../utils/validators";
import { FILE_SIZES, MAX_FILES_PER_UPLOAD } from "./config";
import { FILE_STATES } from "@rpldy/uploady";
import { logDebug } from "../../../utils/logger";

/**
 * Max number items Validation
 *
 * @param {File} item Item to validate
 * @param {number} index Index in file array
 * @param {File[]} files File Array to upload
 *
 * @returns True if validation rule is ok, false otherwise
 *
 * @see https://react-uploady.org/docs/api/#filefilter
 * @see https://react-uploady.org/docs/guides/filefilter/
 */
const filterArrayItemCount = (file, index, files) => {
  // Logger
  logDebug("filters", "filterArrayItemCount", {
    file: file,
    index: index,
    files: [...files],
  });

  // Checking max number of files
  if (files.length > MAX_FILES_PER_UPLOAD) {
    // Only show error once
    if (index === 0) {
      // Logger
      logDebug("filters.filterArrayItemCount", "max_file_per_upload", {
        file: file,
        files: [...files],
        max_files: MAX_FILES_PER_UPLOAD,
      });
      errorToast(
        "filters.filterArrayItemCount",
        "max_file_per_upload",
        t("errors.max_file_per_upload", { count: MAX_FILES_PER_UPLOAD })
      );
    }
    // Filter item out
    return false;
  }
  // Filter item in
  return true;
};

/**
 * Max number items Validation (state)
 *
 * @param {File} item Item to validate
 * @param {number} index Index in file array
 * @param {File[]} files File Array to upload
 *
 * @returns True if validation rule is ok, false otherwise
 *
 * @see https://react-uploady.org/docs/api/#filefilter
 * @see https://react-uploady.org/docs/guides/filefilter/
 */
const filterStateItemCount = (file, index, files) => {
  // Logger
  logDebug("filters", "filterStateItemCount", {
    file: file,
    index: index,
    files: [...files],
  });

  // State
  const state = store.getState();
  const uploader = state.uploader.uploader;
  const stateFiles = Array.from(uploader.files);

  // Check preuploaded files
  if (!isEmptyList(stateFiles)) {
    // Preuploaded file count
    const stateFilesCount = Array.from(stateFiles).filter(
      (item) =>
        ![
          FILE_STATES.ABORTED,
          FILE_STATES.ERROR,
          FILE_STATES.CANCELLED,
        ].includes(item.state)
    ).length;
    // Checking max number of files
    if (stateFilesCount + files.length > MAX_FILES_PER_UPLOAD) {
      // Only show error once
      if (index === 0) {
        // Logger
        logDebug("filters.filterStateItemCount", "max_file_per_upload", {
          file: file,
          files: [...files],
          stateFiles: [...stateFiles],
          max_files: MAX_FILES_PER_UPLOAD,
        });
        errorToast(
          "filters.filterStateItemCount",
          "max_file_per_upload",
          t("errors.max_file_per_upload", { count: MAX_FILES_PER_UPLOAD })
        );
      }
      // Filter item out
      return false;
    }
    // Filter item in
    return true;
  }
  // Filter item in
  return true;
};

/**
 * Filesize Validation
 *
 * @param {File} item Item to validate
 * @param {number} index Index in file array
 * @param {File[]} files File Array to upload
 *
 * @returns True if validation rule is ok, false otherwise
 *
 * @see https://react-uploady.org/docs/api/#filefilter
 * @see https://react-uploady.org/docs/guides/filefilter/
 */
const filterArrayItemSize = (file, index, files) => {
  // Logger
  logDebug("filters", "filterArrayItemSize", {
    file: file,
    index: index,
    files: [...files],
  });

  // User State Information
  const state = store.getState();
  const user = state.user.user;

  // Unregistered User File Size Validation
  if (isEmpty(user.subscription)) {
    // Total size of files
    const totalSize = files.reduce((sum, item) => sum + item.size, 0);
    // Check max filesize
    if (totalSize > FILE_SIZES.UNREGISTERED_MAX_FILE_SIZE) {
      // Only show error once
      if (index === 0) {
        // Logger
        logDebug("filters.filterArrayItemSize", "max_upload_size", {
          file: file,
          files: [...files],
          total_size: totalSize,
          max_size: FILE_SIZES.UNREGISTERED_MAX_FILE_SIZE,
        });
        errorToast(
          "filterArrayItemSize",
          "max_upload_size",
          t("errors.max_upload_size", {
            size: "10GB",
          })
        );
      }
      // Filter item out
      return false;
    }
  }

  // Free user File Size Validation
  if (equalsIgnoringCase(user.subscription, t("subscription.free"))) {
    // Total size of files
    const totalSize = files.reduce((sum, item) => sum + item.size, 0);
    // Check max filesize
    if (totalSize > FILE_SIZES.FREE_USER_MAX_FILE_SIZE) {
      // Only show error once
      if (index === 0) {
        // Logger
        logDebug("filters.filterArrayItemSize", "max_upload_size", {
          file: file,
          files: [...files],
          total_size: totalSize,
          max_size: FILE_SIZES.FREE_USER_MAX_FILE_SIZE,
        });
        errorToast(
          "filterArrayItemSize",
          "max_upload_size",
          t("errors.max_upload_size", {
            size: "10GB",
          })
        );
      }
      // Filter item out
      return false;
    }
  }

  // Pro User File Size Validation
  if (equalsIgnoringCase(user.subscription, t("subscription.pro"))) {
    // Total size of files
    const totalSize = files.reduce((sum, item) => sum + item.size, 0);
    // Check max filesize
    if (totalSize > FILE_SIZES.PRO_USER_MAX_SIZE_SIZE) {
      // Only show error once
      if (index === 0) {
        // Logger
        logDebug("filters.filterArrayItemSize", "max_upload_size", {
          file: file,
          files: [...files],
          total_size: totalSize,
          max_size: FILE_SIZES.PRO_USER_MAX_SIZE_SIZE,
        });
        errorToast(
          "filterArrayItemSize",
          "max_upload_size",
          t("errors.max_upload_size", {
            size: "20GB",
          })
        );
      }
      // Filter item out
      return false;
    }
  }
  // Filter item in
  return true;
};

/**
 * Filesize Validation (state)
 *
 * @param {File} item Item to validate
 * @param {number} index Index in file array
 * @param {File[]} files File Array to upload
 *
 * @returns True if validation rule is ok, false otherwise
 *
 * @see https://react-uploady.org/docs/api/#filefilter
 * @see https://react-uploady.org/docs/guides/filefilter/
 */
const filterStateItemSize = (file, index, files) => {
  // Logger
  logDebug("filters", "filterStateItemSize", {
    file: file,
    index: index,
    files: [...files],
  });

  // State
  const state = store.getState();
  const user = state.user.user;
  const uploader = state.uploader.uploader;

  // Unregistered User File Size Validation
  if (isEmpty(user.subscription)) {
    // Total size of files
    const totalSize =
      files.reduce((sum, item) => sum + item.size, 0) + uploader.globalSize;
    // Check max filesize
    if (totalSize > FILE_SIZES.UNREGISTERED_MAX_UPLOADING_SIZE) {
      // Only show error once
      if (index === 0) {
        // Logger
        logDebug("filters.filterStateItemSize", "max_upload_size", {
          file: file,
          files: [...files],
          total_size: totalSize,
          max_size: FILE_SIZES.UNREGISTERED_MAX_UPLOADING_SIZE,
        });
        errorToast(
          "filterStateItemSize",
          "max_upload_size",
          t("errors.max_upload_size", {
            size: "10GB",
          })
        );
      }
      // Filter item out
      return false;
    }
  }

  // Free user File Size Validation
  if (equalsIgnoringCase(user.subscription, t("subscription.free"))) {
    // Total size of files
    const totalSize =
      files.reduce((sum, item) => sum + item.size, 0) + uploader.globalSize;
    // Check max filesize
    if (totalSize > FILE_SIZES.FREE_USER_MAX_UPLOADING_SIZE) {
      // Only show error once
      if (index === 0) {
        // Logger
        logDebug("filters.filterStateItemSize", "max_upload_size", {
          file: file,
          files: [...files],
          total_size: totalSize,
          max_size: FILE_SIZES.FREE_USER_MAX_UPLOADING_SIZE,
        });
        errorToast(
          "filterStateItemSize",
          "max_upload_size",
          t("errors.max_upload_size", {
            size: "10GB",
          })
        );
      }
      // Filter item out
      return false;
    }
  }

  // Pro User File Size Validation
  if (equalsIgnoringCase(user.subscription, t("subscription.pro"))) {
    // Total size of files
    const totalSize =
      files.reduce((sum, item) => sum + item.size, 0) + uploader.globalSize;
    // Check max filesize
    if (totalSize > FILE_SIZES.PRO_USER_MAX_UPLOADING_SIZE) {
      // Only show error once
      if (index === 0) {
        // Logger
        logDebug("filters.filterStateItemSize", "max_upload_size", {
          file: file,
          files: [...files],
          total_size: totalSize,
          max_size: FILE_SIZES.PRO_USER_MAX_UPLOADING_SIZE,
        });
        errorToast(
          "filterStateItemSize",
          "exceed_uploading_size",
          t("errors.max_upload_size", {
            size: "20GB",
          })
        );
      }
      // Filter item out
      return false;
    }
  }
  // Filter item in
  return true;
};

/**
 * Filename Validation
 *
 * @param {File} item Item to validate
 * @param {number} index Index in file array
 * @param {File[]} files File Array to upload
 *
 * @returns True if validation rule is ok, false otherwise
 *
 * @see https://react-uploady.org/docs/api/#filefilter
 * @see https://react-uploady.org/docs/guides/filefilter/
 */
const filterItemCheckExist = async (file, index, files) => {
  // Logger
  logDebug("filters", "filterItemCheckExist", {
    file: file,
    index: index,
    files: [...files],
  });

  // Settings Information
  const state = store.getState();
  const settings = state.settings.settings;
  const url = `https://${settings.server}.${process.env.REACT_APP_DOMAIN}/fileupload/?file=${file.name}`;

  return await fetch(url, {
    method: "GET",
    mode: "cors",
    credentials: "include",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  })
    .then((response) => response.json())
    .then((response) => {
      if (!isEmpty(response)) {
        if (!isEmpty(response.error)) {
          // Logger
          logDebug("filters.filterItemCheckExist", response.error, {
            file: file,
            files: [...files],
          });
          errorToast(
            "filterItemCheckExist",
            response.error,
            t(`errors.${response.error}`, t("errors.default"))
          );
          // Filter item out
          return false;
        } else if (!isEmpty(response.file)) {
          // Logger
          logDebug("filters.filterItemCheckExist", "existed_file", {
            file: file,
            files: [...files],
          });
          errorToast(
            "filterItemCheckExist",
            "existed_file",
            t("errors.existed_file")
          );
          // Filter item out
          return false;
        }
      }
      // Filter item in
      return true;
    });
};

const filters = async (item, index, files) => {
  // Logger
  logDebug("filters", "filters", {
    file: item,
    index: index,
    files: [...files],
  });

  // Filters
  return (
    // Count items Validation
    filterArrayItemCount(item, index, files) &&
    filterStateItemCount(item, index, files) &&
    // Size item Validation
    filterArrayItemSize(item, index, files) &&
    filterStateItemSize(item, index, files) &&
    // Name item Validation
    (await filterItemCheckExist(item, index, files))
  );
};

export default filters;
