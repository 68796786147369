import { useTranslation } from "react-i18next";

function WhyYdraySection() {
  const { t } = useTranslation();

  return (
    <div className="why_ydray__section">
      <div className="why_ydray__section__header"></div>
      <div className="why_ydray__section__body">
        <div className="why_ydray__section__body__content">
          {/* Reason 1 */}
          <div className="why_ydray__section__container">
            <div className="why_ydray__section__container__subtitle">
              <span>{t("landing.body.why_section.subtitle")}</span>
            </div>
            <div className="why_ydray__section__container__title">
              <h2>{t("landing.body.why_section.title")}</h2>
            </div>
            <div
              className="why_ydray__section__container__description"
              dangerouslySetInnerHTML={{
                __html: t("landing.body.why_section.description", {
                  interpolation: { escapeValue: true },
                }),
              }}
            ></div>
          </div>
          {/* Reason 2 */}
          <div className="why_ydray__section__container">
            <div className="why_ydray__section__container__subtitle">
              <span>{t("landing.body.secure_section.subtitle")}</span>
            </div>
            <div className="why_ydray__section__container__title">
              <h2>{t("landing.body.secure_section.title")}</h2>
            </div>
            <div
              className="why_ydray__section__container__description"
              dangerouslySetInnerHTML={{
                __html: t("landing.body.secure_section.description", {
                  interpolation: { escapeValue: true },
                }),
              }}
            ></div>
          </div>
          {/* Reason 3 */}
          <div className="why_ydray__section__container">
            <div className="why_ydray__section__container__subtitle">
              <span>{t("landing.body.privacy_section.subtitle")}</span>
            </div>
            <div className="why_ydray__section__container__title">
              <h2>{t("landing.body.privacy_section.title")}</h2>
            </div>
            <div
              className="why_ydray__section__container__description"
              dangerouslySetInnerHTML={{
                __html: t("landing.body.privacy_section.description", {
                  interpolation: { escapeValue: true },
                }),
              }}
            ></div>
          </div>
        </div>
      </div>
      <div className="why_ydray__section__footer"></div>
    </div>
  );
}

export default WhyYdraySection;
