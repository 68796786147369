import { useCallback, useEffect, useState } from "react";
import BillingTable from "./billing-table";

function BillPageBody() {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const list = [
      {
        id: 1,
        date: new Date().toDateString(),
        plan: "Plan PRO",
        amount: "9,60 €",
        status: "PAGADA",
      },
      {
        id: 2,
        date: new Date().toDateString(),
        plan: "Plan PRO",
        amount: "9,60 €",
        status: "PAGADA",
      },
      {
        id: 3,
        date: new Date().toDateString(),
        plan: "Plan PRO",
        amount: "9,60 €",
        status: "PAGADA",
      },
      {
        id: 4,
        date: new Date().toDateString(),
        plan: "Plan PRO",
        amount: "9,60 €",
        status: "PAGADA",
      },
      {
        id: 5,
        date: new Date().toDateString(),
        plan: "Plan PRO",
        amount: "9,60 €",
        status: "PAGADA",
      },
      {
        id: 6,
        date: new Date().toDateString(),
        plan: "Plan PRO",
        amount: "9,60 €",
        status: "PAGADA",
      },
      {
        id: 7,
        date: new Date().toDateString(),
        plan: "Plan PRO",
        amount: "9,60 €",
        status: "PAGADA",
      },
      {
        id: 8,
        date: new Date().toDateString(),
        plan: "Plan PRO",
        amount: "9,60 €",
        status: "PAGADA",
      },
      {
        id: 9,
        date: new Date().toDateString(),
        plan: "Plan PRO",
        amount: "9,60 €",
        status: "PAGADA",
      },
      {
        id: 10,
        date: new Date().toDateString(),
        plan: "Plan PRO",
        amount: "9,60 €",
        status: "PAGADA",
      },
      {
        id: 11,
        date: new Date().toDateString(),
        plan: "Plan PRO",
        amount: "9,60 €",
        status: "PAGADA",
      },
      {
        id: 12,
        date: new Date().toDateString(),
        plan: "Plan PRO",
        amount: "9,60 €",
        status: "PAGADA",
      },
      {
        id: 13,
        date: new Date().toDateString(),
        plan: "Plan PRO",
        amount: "9,60 €",
        status: "PAGADA",
      },
      {
        id: 14,
        date: new Date().toDateString(),
        plan: "Plan PRO",
        amount: "9,60 €",
        status: "PAGADA",
      },
      {
        id: 15,
        date: new Date().toDateString(),
        plan: "Plan PRO",
        amount: "9,60 €",
        status: "PAGADA",
      },
      {
        id: 16,
        date: new Date().toDateString(),
        plan: "Plan PRO",
        amount: "9,60 €",
        status: "PAGADA",
      },
      {
        id: 17,
        date: new Date().toDateString(),
        plan: "Plan PRO",
        amount: "9,60 €",
        status: "PAGADA",
      },
      {
        id: 18,
        date: new Date().toDateString(),
        plan: "Plan PRO",
        amount: "9,60 €",
        status: "PAGADA",
      },
      {
        id: 19,
        date: new Date().toDateString(),
        plan: "Plan PRO",
        amount: "9,60 €",
        status: "PAGADA",
      },
      {
        id: 20,
        date: new Date().toDateString(),
        plan: "Plan PRO",
        amount: "9,60 €",
        status: "PAGADA",
      },
    ];
    setItems(list);
  }, []);

  const nextHandler = useCallback(() => {
    return [];
  }, []);

  return (
    <div className="bill__content__body">
      <div id="scrollable-billing-table" className="bill__content__body__table">
        <BillingTable
          items={items}
          total={1}
          more={false}
          nextHandler={nextHandler}
        />
      </div>
    </div>
  );
}

export default BillPageBody;
