import * as yup from "yup";

const getSendFilesSchema = (t) => {
  return yup.object().shape({
    title: yup
      .string()
      .max(40, t("validators.maxLength", { value: 40 }))
      .notRequired(),
    message: yup
      .string()
      .max(400, t("validators.maxLength", { value: 400 }))
      .notRequired(),
    days: yup
      .string()
      .required(t("validators.notEmpty"))
      .oneOf(["1", "7", "10", "0"], t("validators.notEmpty")),
    pass: yup.string().notRequired(),
  });
};

export default getSendFilesSchema;
