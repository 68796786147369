import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setShowAds } from "../../redux/reducers/uiReducer";
import { isEmpty } from "../../utils/validators";
import { logDebug, logError } from "../../utils/logger.js";
import initGA4 from "../../utils/google-analytics/index.js";
import initGTM from "../../utils/google-tag-manager/index.js";
import useAdblockDetector from "../../hooks/useAdblockDetector.ts";

function GoogleComponent() {
  const dispatch = useDispatch();

  const { ui } = useSelector((state) => state);

  const [isAdblockCheckComplete, isAdblockDetected] = useAdblockDetector();

  const adBlockDetected = useCallback(() => {
    // Initialize Google Analytics 4 (Anonymized user)
    initGA4({
      gaOptions: {
        anonymize_ip: true,
        allowAdPersonalizationSignals: false,
      },
    });
    // Initalize Google Tag Manager
    initGTM();
  }, []);

  // Initialize analytics
  const initAnalytics = useCallback(() => {
    // Initialize Google Analytics 4
    initGA4();
    // Initalize Google Tag Manager
    initGTM();
    // Initialize Google Ads
    dispatch(setShowAds(true));
    window.googletag.cmd.push(() => {
      window.googletag.pubads().refresh();
    });
  }, [dispatch]);

  const adBlockNotDetected = useCallback(() => {
    // No Block
    window.googlefc.callbackQueue.push({
      CONSENT_DATA_READY: () =>
        window.__tcfapi("addEventListener", 2, (data, success) => {
          if (success) {
            if (data.gdprApplies) {
              switch (data.eventStatus) {
                case "tcloaded":
                case "useractioncomplete":
                  // Initialize analytics
                  initAnalytics();
                  logDebug("GoogleComponent", "adBlockNotDetected", {
                    eventStatus: "useractioncomplete",
                    data: data,
                  });
                  break;
                default:
                  logDebug("GoogleComponent", "adBlockNotDetected", {
                    eventStatus: data.eventStatus,
                    data: data,
                  });
                  break;
              }
            } else {
              // Initialize analytics
              initAnalytics();
            }
          }
        }),
    });
  }, [initAnalytics]);

  useEffect(() => {
    try {
      const cookieName = "FCCDCF";
      const cookieDomain = process.env.REACT_APP_DOMAIN;
      const cookiePath = "/";
      let tc = ("; " + document.cookie).split("; " + cookieName + "=");
      if (tc.length === 2) {
        tc = decodeURIComponent(tc.pop().split(";").shift());
        tc = JSON.parse(tc)[3][0].substring(1, 9);
        tc = Uint8Array.from(window.atob(tc), (v) => v.charCodeAt(0));
        let dt =
          tc[0] * 2 ** 28 +
          tc[1] * 2 ** 20 +
          tc[2] * 2 ** 12 +
          tc[3] * 2 ** 4 +
          (tc[4] >> 4);
        if (Date.now() / 1000 - dt / 10 > 86400 * 365) {
          document.cookie =
            cookieName +
            "=;path=" +
            cookiePath +
            ";domain=" +
            cookieDomain +
            ";expires=" +
            new Date(0).toUTCString();
          logDebug("google-component", "delete FCCDCF cookie", {
            msg: "Timestamp cookie is greater than 12 months, it needs to reload consent",
          });
        } else {
          logDebug("google-component", "delete FCCDCF cookie", {
            msg: "Timestamp cookie is lower than 12 months, it doesn't need to reload consent",
          });
        }
      }
    } catch (e) {
      logError("google-component", "delete FCCDCF cookie", { event: e });
    }
  }, []);

  useEffect(() => {
    // Detect if blockAdBlock exists
    if (isAdblockCheckComplete) {
      if (isAdblockDetected) {
        // Ad block detected
        logDebug(
          "google-component",
          "adBlockDetected",
          "Ad blocker detected... 🤬"
        );
        adBlockDetected();
      } else {
        // Ad block not detected
        logDebug(
          "google-component",
          "adBlockNotDetected",
          "Ad blocker not detected... 😍"
        );
        adBlockNotDetected();
      }
    }
  }, [
    isAdblockCheckComplete,
    isAdblockDetected,
    adBlockDetected,
    adBlockNotDetected,
  ]);

  const [deleted, setDeleted] = useState(false);
  useEffect(() => {
    let intervalId = null;
    if (ui.show_ads) {
      if (!deleted) {
        intervalId = setInterval(() => {
          const root = document.querySelectorAll(
            'div[style*="forced-color-adjust: initial !important;"]'
          )[0];
          const grlp = root?.shadowRoot?.lastChild;
          if (!isEmpty(grlp)) {
            grlp.remove();
            setDeleted(true);
          }
        }, 1);
      } else {
        clearInterval(intervalId);
      }
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [ui.show_ads, deleted]);

  return null;
}

export default GoogleComponent;
