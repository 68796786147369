import { useCallback, useMemo, useState } from "react";

const useSubscriptionConfirm = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const getUrl = useCallback((subscriptionID) => {
    return `https://${process.env.REACT_APP_API}/paypal/confirm/${subscriptionID}`;
  }, []);

  const config = useMemo(() => {
    return {
      method: "GET",
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
  }, []);

  const getSubscriptionConfirm = useCallback(
    (subscriptionID) => {
      try {
        setLoading(true);
        fetch(getUrl(subscriptionID), config)
          .then((response) => response.json())
          .then((data) => {
            setError(data.error);
            delete data.error;
            setData(data);
          })
          .catch((error) => setError(error))
          .finally(() => setLoading(false));
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    },
    [config, getUrl]
  );

  return { loading, data, error, getSubscriptionConfirm };
};

export default useSubscriptionConfirm;
