import { useCallback, useMemo, useRef } from "react";

import classNames from "classnames";

import { retryEnhancer } from "@rpldy/retry-hooks";
import ChunkedUploady from "@rpldy/chunked-uploady";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import useHasScrollbar from "../../hooks/useHasScrollbar";
import useWindowSize from "../../hooks/useWindowSize";

import Media from "react-media";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";

import UploaderComponent from "../uploader-component";
import filters from "../uploader-component/utils/filters";
import { FILE_SIZES } from "../uploader-component/utils/config";
import listenerHandlers from "../uploader-component/utils/listeners";

import LanguagePath from "../../pages/LanguagePath";

import Landing from "../../pages/Landing";
import Press from "../../pages/Press";
import FAQs from "../../pages/FAQs";
import Privacy from "../../pages/Privacy";
import Cookies from "../../pages/Cookies";
import LegalNotice from "../../pages/LegalNotice";

import SendFiles from "../../pages/SendFiles";
import SendBigFiles from "../../pages/SendBigFiles";
import SendFreeFiles from "../../pages/SendFreeFiles";

import ShareFiles from "../../pages/ShareFiles";
import ShareBigFiles from "../../pages/ShareBigFiles";
import ShareFreeFiles from "../../pages/ShareFreeFiles";

import Uploads from "../../pages/Uploads";
import TransferDetail from "../../pages/TransferDetail";
import Preferences from "../../pages/Preferences";
import Plans from "../../pages/Plans";
import Bills from "../../pages/Bills";
import Personal from "../../pages/Personal";
import Pasword from "../../pages/Password";
import Custom from "../../pages/Custom";
import Contact from "../../pages/Contact";

import Uploader from "../../pages/Uploader";
import Downloader from "../../pages/Downloader";

import NoMatch from "../../pages/NoMatch";
import NavigateLng from "../../pages/NavigateLng";

import { parseDataUrl } from "../../utils/services.utils.js";
import { isEmpty } from "../../utils/validators";

function BodyComponent() {
  const { t, i18n } = useTranslation();

  const { ui } = useSelector((state) => state);
  const { settings } = useSelector((state) => state.settings);

  const page1ContainerRef = useRef(null);

  const windowSize = useWindowSize();
  const { hasScrollY } = useHasScrollbar(page1ContainerRef.current);

  const token = useMemo(
    () =>
      !isEmpty(settings) && !isEmpty(settings.csrf_token)
        ? settings.csrf_token
        : "",
    [settings]
  );

  const url = useMemo(() => {
    return !isEmpty(settings)
      ? `https://${settings.server}.${process.env.REACT_APP_DOMAIN}/fileupload/`
      : "";
  }, [settings]);

  const config = useMemo(() => {
    return {
      debug: false,
      autoUpload: true,
      sendWithFormData: true,
      inputFieldName: "files[]",
      withCredentials: true,
      destination: {
        url: url,
        method: "POST",
        mode: "cors",
        body: parseDataUrl({
          csrf_token: token,
        }),
      },
      multiple: true,
      retries: 0,
      parallel: 0,
      chunkSize: FILE_SIZES.CHUNK_FILE_SIZE,
    };
  }, [url, token]);

  const listeners = useMemo(() => ({ ...listenerHandlers }), []);

  const fileFilter = async (item, index, files) =>
    await filters(item, index, files);

  const getPublicRoutes = useCallback(
    (lng) => {
      return (
        <Route>
          <Route path="" element={<Landing />} index />
          <Route
            path={t("routes.public.about", { lng: lng })}
            element={<Landing params={{ targetId: "about" }} />}
          />
          <Route
            path={t("routes.public.plans", { lng: lng })}
            element={<Landing params={{ targetId: "plans" }} />}
          />
          <Route
            path={t("routes.public.contact", { lng: lng })}
            element={<Landing params={{ targetId: "contact" }} />}
          />
          <Route
            path={t("routes.public.newsletter_reg", { lng: lng })}
            element={<Landing params={{ targetId: "newsletter_register" }} />}
          />
          <Route
            path={t("routes.public.newsletter_unreg", { lng: lng })}
            element={<Landing params={{ targetId: "newsletter_unregister" }} />}
          />
          <Route
            path={t("routes.public.uploader", { lng: lng })}
            element={<Uploader />}
          />
          <Route
            path={t("routes.public.press", { lng: lng })}
            element={<Press />}
          />
          <Route
            path={t("routes.public.faq", { lng: lng })}
            element={<FAQs />}
          />
          <Route
            path={t("routes.public.privacy", { lng: lng })}
            element={<Privacy />}
          />
          <Route
            path={t("routes.public.cookies", { lng: lng })}
            element={<Cookies />}
          />
          <Route
            path={t("routes.public.legal", { lng: lng })}
            element={<LegalNotice />}
          />
          <Route path={t("routes.public.send_files.index", { lng: lng })}>
            <Route path="" element={<SendFiles />} />
            <Route
              path={t("routes.public.send_files.big", { lng: lng })}
              element={<SendBigFiles />}
            />
            <Route
              path={t("routes.public.send_files.free", { lng: lng })}
              element={<SendFreeFiles />}
            />
          </Route>
          <Route path={t("routes.public.share_files.index", { lng: lng })}>
            <Route element={<ShareFiles />} index />
            <Route
              path={t("routes.public.share_files.big", { lng: lng })}
              element={<ShareBigFiles />}
            />
            <Route
              path={t("routes.public.share_files.free", { lng: lng })}
              element={<ShareFreeFiles />}
            />
          </Route>
        </Route>
      );
    },
    [t]
  );

  const getPrivateRoutes = useCallback(
    (lng) => {
      return (
        <Route>
          <Route path={t("routes.private.uploads.index", { lng: lng })}>
            <Route path="" element={<Uploads />} index />
            <Route
              path={t("routes.private.uploads.detail", { lng: lng })}
              element={<TransferDetail />}
            />
          </Route>
          <Route path={t("routes.private.prefs.index", { lng: lng })}>
            <Route path="" element={<Preferences />} index />
            <Route
              path={t("routes.private.prefs.plans", { lng: lng })}
              element={<Plans />}
            />
            <Route
              path={t("routes.private.prefs.bills", { lng: lng })}
              element={<Bills />}
            />
            <Route
              path={t("routes.private.prefs.personal", { lng: lng })}
              element={<Personal />}
            />
            <Route
              path={t("routes.private.prefs.pass", { lng: lng })}
              element={<Pasword />}
            />
            <Route
              path={t("routes.private.prefs.custom", { lng: lng })}
              element={<Custom />}
            />
            <Route
              path={t("routes.private.prefs.contact", { lng: lng })}
              element={<Contact />}
            />
          </Route>
        </Route>
      );
    },
    [t]
  );

  const getRoutesLng = useCallback(
    (lng) => {
      return (
        <Route path={`/${lng}`} element={<LanguagePath />}>
          {/* Public Routes */}
          {getPublicRoutes(lng)}
          {/* Private Routes */}
          {getPrivateRoutes(lng)}
        </Route>
      );
    },
    [getPrivateRoutes, getPublicRoutes]
  );

  const scrolled = useMemo(() => {
    return windowSize.width > 576 && hasScrollY;
  }, [hasScrollY, windowSize.width]);

  return (
    <div
      ref={page1ContainerRef}
      className={classNames("page1__container", {
        scrolled: scrolled,
        free: ui.show_top_banner,
      })}
    >
      <ChunkedUploady
        {...config}
        listeners={listeners}
        fileFilter={fileFilter}
        enhancer={retryEnhancer}
      >
        <Media query="(max-width: 576px)">
          <Routes>
            <Route path="/" element={<Outlet />}>
              {/* Routes with language */}
              {i18n.languages.map((lng) => (
                <Route key={lng}>{getRoutesLng(lng)}</Route>
              ))}
              {/* Downloader Link */}
              <Route path="get/t/:id" element={<Downloader />} />
              {/* Legacy routes */}
              <Route
                path="/enviar-archivos"
                element={<Navigate replace to="/es/enviar-archivos" />}
              />
              <Route
                path="/compartir-archivos"
                element={<Navigate replace to="/es/compartir-archivos" />}
              />
              {/* Home route */}
              <Route path="" element={<NavigateLng />} />
              {/* Not found routes */}
              <Route path="*" element={<NoMatch />} />
            </Route>
          </Routes>
        </Media>
        <Media query="(min-width: 577px)">
          <Routes>
            <Route path="/" element={<Outlet />}>
              {/* Downloader Link */}
              <Route path="get/t/:id" element={<Downloader />} />
              {/* Legacy routes */}
              <Route
                path="/enviar-archivos"
                element={<Navigate replace to="/es/enviar-archivos" />}
              />
              <Route
                path="/compartir-archivos"
                element={<Navigate replace to="/es/compartir-archivos" />}
              />
              {/* Home route */}
              <Route path="" element={<NavigateLng />} />
              {/* Not found routes */}
              <Route path="*" element={<UploaderComponent />} />
            </Route>
          </Routes>
        </Media>
      </ChunkedUploady>
    </div>
  );
}

export default BodyComponent;
