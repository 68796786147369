import * as yup from "yup";

const getNewsletterRegSchema = (t) => {
  return yup.object().shape({
    email1: yup
      .string()
      .required(t("validators.notEmpty"))
      .email(t("validators.email"))
      .min(0, t("validators.minLength", { value: 0 }))
      .max(45, t("validators.maxLength", { value: 45 })),
    email2: yup
      .string()
      .required(t("validators.notEmpty"))
      .email(t("validators.email"))
      .oneOf([yup.ref("email1"), null], t("validators.compareFields"))
      .min(0, t("validators.minLength", { value: 0 }))
      .max(45, t("validators.maxLength", { value: 45 })),
    conditions: yup
      .bool()
      .required(t("validators.notEmpty"))
      .equals([true], t("validators.checkTrue")),
  });
};

const getNewsletterUnregSchema = (t) => {
  return yup.object().shape({
    email: yup
      .string()
      .required(t("validators.notEmpty"))
      .email(t("validators.email"))
      .min(0, t("validators.minLength", { value: 0 }))
      .max(45, t("validators.maxLength", { value: 45 })),
  });
};

export { getNewsletterRegSchema, getNewsletterUnregSchema };
