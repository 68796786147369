import { createSlice } from "@reduxjs/toolkit";
import { logDebug } from "../../utils/logger";

const slice = createSlice({
  name: "ui",
  initialState: {
    is_blur: false,
    is_loading: false,
    is_iframe_show: true,
    is_iframe_loading: true,
    header_menu_open: false,
    footer_menu_open: false,
    show_footer_menu: true,
    footer_menu_collapse: false,
    show_contact_dialog: false,
    contact_reason: "",
    show_login_dialog: false,
    show_register_dialog: false,
    show_reset_password_dialog: false,
    show_consent_dialog: false,
    show_newsletter_register_dialog: false,
    show_newsletter_unregister_dialog: false,
    show_subscription_dialog: false,
    show_cancel_subscription_dialog: false,
    show_advantage_list: true,
    show_ads: false,
    transfer_init: false,
    user_init: false,
  },
  reducers: {
    setBlur: (state, action) => {
      state.is_blur = action.payload;
      logDebug("uiReducer", "setBlur", { is_blur: state.is_blur });
    },
    setLoading: (state, action) => {
      state.is_loading = action.payload;
      logDebug("uiReducer", "setLoading", {
        is_loading: state.is_loading,
      });
    },
    setIframeShow: (state, action) => {
      state.is_iframe_show = action.payload;
      logDebug("uiReducer", "setIframeShow", {
        is_iframe_show: state.is_iframe_show,
      });
    },
    setIframeLoading: (state, action) => {
      state.is_iframe_loading = action.payload;
      logDebug("uiReducer", "setIframeLoading", {
        is_iframe_loading: state.is_iframe_loading,
      });
    },
    setHeaderMenuOpen: (state, action) => {
      state.header_menu_open = action.payload;
      logDebug("uiReducer", "setHeaderMenuOpen", {
        header_menu_open: state.header_menu_open,
      });
    },
    setFooterMenuOpen: (state, action) => {
      state.footer_menu_open = action.payload;
      logDebug("uiReducer", "setFooterMenuOpen", {
        footer_menu_open: state.footer_menu_open,
      });
    },
    setShowFooterMenu: (state, action) => {
      state.show_footer_menu = action.payload;
      logDebug("uiReducer", "setShowFooterMenu", {
        show_footer_menu: state.show_footer_menu,
      });
    },
    setFooterMenuCollapse: (state, action) => {
      state.footer_menu_collapse = action.payload;
      logDebug("uiReducer", "setFooterMenuCollapse", {
        footer_menu_collapse: state.footer_menu_collapse,
      });
    },
    setShowContactDialog: (state, action) => {
      state.show_contact_dialog = action.payload;
      logDebug("uiReducer", "setShowContactDialog", {
        show_contact_dialog: state.show_contact_dialog,
      });
    },
    setContactReason: (state, action) => {
      state.contact_reason = action.payload;
      logDebug("uiReducer", "setContactReason", {
        contact_reason: state.contact_reason,
      });
    },
    setShowLoginDialog: (state, action) => {
      state.show_login_dialog = action.payload;
      logDebug("uiReducer", "setShowLoginDialog", {
        show_login_dialog: state.show_login_dialog,
      });
    },
    setShowRegisterDialog: (state, action) => {
      state.show_register_dialog = action.payload;
      logDebug("uiReducer", "setShowRegisterDialog", {
        show_register_dialog: state.show_register_dialog,
      });
    },
    setShowResetPasswordDialog: (state, action) => {
      state.show_reset_password_dialog = action.payload;
      logDebug("uiReducer", "setShowResetPasswordDialog", {
        show_reset_password_dialog: state.show_reset_password_dialog,
      });
    },
    setShowNewsletterRegisterDialog: (state, action) => {
      state.show_newsletter_register_dialog = action.payload;
      logDebug("uiReducer", "setShowNewsletterRegisterDialog", {
        show_newsletter_register_dialog: state.show_newsletter_register_dialog,
      });
    },
    setShowNewsletterUnregisterDialog: (state, action) => {
      state.show_newsletter_unregister_dialog = action.payload;
      logDebug("uiReducer", "setShowNewsletterUnregisterDialog", {
        show_newsletter_unregister_dialog:
          state.show_newsletter_unregister_dialog,
      });
    },
    setShowSubscriptionDialog: (state, action) => {
      state.show_subscription_dialog = action.payload;
      logDebug("uiReducer", "setShowSubscriptionDialog", {
        show_subscription_dialog: state.show_subscription_dialog,
      });
    },
    setShowCancelSubscriptionDialog: (state, action) => {
      state.show_cancel_subscription_dialog = action.payload;
      logDebug("uiReducer", "setShowCancelSubscriptionDialog", {
        show_cancel_subscription_dialog: state.show_cancel_subscription_dialog,
      });
    },
    setShowConsentDialog: (state, action) => {
      state.show_consent_dialog = action.payload;
      logDebug("uiReducer", "setShowConsentDialog", {
        show_consent_dialog: state.show_consent_dialog,
      });
    },
    setShowAdvantageList: (state, action) => {
      state.show_advantage_list = action.payload;
      logDebug("uiReducer", "setShowAdvantageList", {
        show_advantage_list: state.show_advantage_list,
      });
    },
    setShowAds: (state, action) => {
      state.show_ads = action.payload;
      logDebug("uiReducer", "setShowAds", {
        show_ads: state.show_ads,
      });
    },
    setTransferInit: (state, action) => {
      state.transfer_init = action.payload;
      logDebug("uiReducer", "setTransferInit", {
        transfer_init: state.transfer_init,
      });
    },
    setUserInit: (state, action) => {
      state.user_init = action.payload;
      logDebug("uiReducer", "setUserInit", {
        user_init: state.user_init,
      });
    },
  },
});

export const {
  setBlur,
  setLoading,
  setIframeShow,
  setIframeLoading,
  setHeaderMenuOpen,
  setFooterMenuOpen,
  setShowFooterMenu,
  setFooterMenuCollapse,
  setShowContactDialog,
  setContactReason,
  setShowLoginDialog,
  setShowRegisterDialog,
  setShowResetPasswordDialog,
  setShowNewsletterRegisterDialog,
  setShowNewsletterUnregisterDialog,
  setShowSubscriptionDialog,
  setShowCancelSubscriptionDialog,
  setShowConsentDialog,
  setShowAdvantageList,
  setShowAds,
  setTransferInit,
  setUserInit,
} = slice.actions;
export default slice.reducer;
