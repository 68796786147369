import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useGetLocalePath from "../hooks/useGetLocalePath";
import BillPageHeader from "../components/bill-page/header";
import BillPageBody from "../components/bill-page/body";
import Divider from "../components/divider";
import SEO from "../components/seo";

function Bills() {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const { getLocalePath } = useGetLocalePath();

  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    if (!user.logged_in) {
      navigate(getLocalePath(t("routes.common.home")));
    }
  });

  const getNestedRoute = useCallback(
    (nestedRoute) => {
      return getLocalePath(`${t("routes.private.prefs.index")}/${nestedRoute}`);
    },
    [getLocalePath, t]
  );

  const titleSEO = useMemo(() => {
    return `${t("app.name")} - ${t("bills.title")}`;
  }, [t]);

  const descriptionSEO = useMemo(() => {
    return t("bills.description");
  }, [t]);

  const urlSEO = useMemo(() => {
    return `${process.env.REACT_APP_PUBLIC_URL}${getNestedRoute(
      t("routes.private.prefs.bills")
    )}`;
  }, [getNestedRoute, t]);

  const altLinksSEO = useMemo(() => {
    const publicURL = process.env.REACT_APP_PUBLIC_URL;
    const result = i18n.languages.map((lng) => {
      return {
        lng: lng,
        url: `${publicURL}/${lng}/${t("routes.private.prefs.index", {
          lng: lng,
        })}/${t("routes.private.prefs.bills", {
          lng: lng,
        })}`,
      };
    });

    const defaultLng = result.find((url) => url.lng === "en");
    result.push({
      lng: "x-default",
      url: defaultLng.url,
    });

    return result;
  }, [i18n.languages, t]);

  return (
    <div>
      <SEO
        title={titleSEO}
        description={descriptionSEO}
        url={urlSEO}
        canonical={urlSEO}
        altLinks={altLinksSEO}
      />
      <div className="bills__container">
        <div className="bills__content">
          <BillPageHeader />
          <Divider />
          <BillPageBody />
        </div>
      </div>
    </div>
  );
}

export default Bills;
