import TagManager from "react-gtm-module";
import { logDebug } from "../logger";

/**
 * Initialize GTM
 */
const initGTM = () => {
  const args = {
    gtmId: process.env.REACT_APP_GTM_CONTAINER_ID,
  };

  // Initialize GTM
  TagManager.initialize(args);

  // Logger
  logDebug("google-tag-manager", "initGTM", {
    args: { ...args },
    msg: "Google Tag Manager was initialized...",
  });
};

export default initGTM;
