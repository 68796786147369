import { useCallback, useEffect, useMemo } from "react";

import classNames from "classnames";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useWindowSize from "../hooks/useWindowSize";
import useUserLogout from "../hooks/useUserLogout.js";
import { useDispatch, useSelector } from "react-redux";
import useGetLocalePath from "../hooks/useGetLocalePath";
import useGetUserSubscriptionStatus from "../hooks/useGetUserSubscriptionStatus";

import { removeUser } from "../redux/reducers/userReducer";
import {
  clearFilters,
  clearItems,
  clearOrderBy,
  clearPagination,
  setLoading,
} from "../redux/reducers/transfersTableReducer";
import { setTransferInit, setUserInit } from "../redux/reducers/uiReducer";

import SEO from "../components/seo";
import { Link } from "react-router-dom";
import Divider from "../components/divider";

import { isEmpty } from "../utils/validators";

function Preferences() {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const windowSize = useWindowSize();
  const { getLocalePath } = useGetLocalePath();

  const { isFreeUser, isProUser, isBusinessUser } =
    useGetUserSubscriptionStatus();

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    if (!user.logged_in) {
      navigate(getLocalePath(t("routes.common.home")));
    }
  });

  const username = useMemo(() => {
    return !isEmpty(user.username) ? user.username : user.email;
  }, [user.username, user.email]);

  const subscriptionMemo = useMemo(() => {
    if (isFreeUser) {
      return t("subscription.free");
    } else if (isProUser) {
      return t("subscription.pro");
    } else if (isBusinessUser) {
      return t("subscription.business");
    } else {
      return "";
    }
  }, [isFreeUser, isProUser, isBusinessUser, t]);

  const planSubtitleMemo = useMemo(() => {
    if (!isEmpty(subscriptionMemo)) {
      return t("preferences.items.plans.subtitle", {
        subscription: subscriptionMemo,
      });
    }
    return "";
  }, [subscriptionMemo, t]);

  const { loading, logoutHandler } = useUserLogout();
  const logOut = async () => {
    await logoutHandler();
    dispatch(clearItems());
    dispatch(clearFilters());
    dispatch(clearOrderBy());
    dispatch(clearPagination());
    dispatch(setLoading(false));
    dispatch(setUserInit(false));
    dispatch(setTransferInit(false));
  };
  useEffect(() => {
    if (!loading) {
      dispatch(removeUser());
      navigate(getLocalePath(t("routes.common.home")));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const getNestedRoute = useCallback(
    (nestedRoute) => {
      return getLocalePath(`${t("routes.private.prefs.index")}/${nestedRoute}`);
    },
    [getLocalePath, t]
  );

  const titleSEO = useMemo(() => {
    return `${t("app.name")} - ${t("preferences.title")}`;
  }, [t]);

  const descriptionSEO = useMemo(() => {
    return t("preferences.description");
  }, [t]);

  const urlSEO = useMemo(() => {
    return `${process.env.REACT_APP_PUBLIC_URL}${getLocalePath(
      t("routes.private.prefs.index")
    )}`;
  }, [getLocalePath, t]);

  const altLinksSEO = useMemo(() => {
    const publicURL = process.env.REACT_APP_PUBLIC_URL;
    const result = i18n.languages.map((lng) => {
      return {
        lng: lng,
        url: `${publicURL}/${lng}/${t("routes.private.prefs.index", {
          lng: lng,
        })}`,
      };
    });

    const defaultLng = result.find((url) => url.lng === "en");
    result.push({
      lng: "x-default",
      url: defaultLng.url,
    });

    return result;
  }, [i18n.languages, t]);

  const showUserHeader = useMemo(() => {
    return windowSize.width <= 576;
  }, [windowSize.width]);

  const customizePath = useMemo(() => {
    return isProUser || isBusinessUser
      ? getNestedRoute(t("routes.private.prefs.custom"))
      : "";
  }, [isBusinessUser, isProUser, getNestedRoute, t]);

  return (
    <>
      <SEO
        title={titleSEO}
        description={descriptionSEO}
        url={urlSEO}
        canonical={urlSEO}
        altLinks={altLinksSEO}
      />
      <div className="preferences-page__container">
        <div className="preferences-page__content">
          <div className="preferences-page__title">
            <span className="header-4">{t("preferences.title")}</span>
            <Divider />
          </div>
          {showUserHeader && (
            <>
              <div className="preferences-block user">
                <div className="preferences__user__container">
                  <div className="circle">
                    <span className="icon icon-user"></span>
                  </div>
                  <div className="preferences__user__ellipsis">
                    <span className="user-title header-3">
                      {t("preferences.subtitle.username", {
                        username: username,
                      })}
                    </span>
                  </div>
                </div>
              </div>
              <div className="divider-container">
                <Divider />
              </div>
            </>
          )}
          <div className="preferences-container">
            <div className="preferences-content">
              <div className="preferences-block">
                <span className="preferences-block-title">
                  {t("preferences.subtitle.billing")}
                </span>
                <Link
                  to={getNestedRoute(t("routes.private.prefs.plans.index"))}
                  className="preferences-block-item"
                >
                  <div className="block-item-label">
                    <div className="block-item-icon plans">
                      <span className="icon-stats-square-up"></span>
                    </div>
                    <div className="block-item-text">
                      <span className="block-item-title">
                        {t("preferences.items.plans.title")}
                      </span>
                      <span className="dot">&sdot;</span>
                      <span className="block-item-description">
                        {planSubtitleMemo}
                      </span>
                    </div>
                  </div>
                  <div className="block-item-nav">
                    <span className="icon-nav-arrow-right"></span>
                  </div>
                </Link>
                {isProUser && (
                  <Link
                    to={getNestedRoute(t("routes.private.prefs.billing"))}
                    className="preferences-block-item"
                  >
                    <div className="block-item-label">
                      <div className="block-item-icon billing">
                        <span className="icon-credit-card"></span>
                      </div>
                      <div className="block-item-text">
                        <span className="block-item-title">
                          {t("preferences.items.billing.title")}
                        </span>
                        <span className="dot">&sdot;</span>
                        <span className="block-item-description">
                          {t("preferences.items.billing.subtitle")}
                        </span>
                      </div>
                    </div>
                    <div className="block-item-nav">
                      <span className="icon-nav-arrow-right"></span>
                    </div>
                  </Link>
                )}
              </div>
              <div className="divider-container">
                <Divider />
              </div>
              <div className="preferences-block">
                <span className="preferences-block-title">
                  {t("preferences.subtitle.account")}
                </span>
                <Link
                  to={getNestedRoute(t("routes.private.prefs.personal"))}
                  className="preferences-block-item"
                >
                  <div className="block-item-label">
                    <div className="block-item-icon personal">
                      <span className="icon-user"></span>
                    </div>
                    <div className="block-item-text">
                      <span className="block-item-title">
                        {t("preferences.items.personal.title")}
                      </span>
                      <span className="dot">&sdot;</span>
                      <span className="block-item-description">
                        {t("preferences.items.personal.subtitle")}
                      </span>
                    </div>
                  </div>
                  <div className="block-item-nav">
                    <span className="icon-nav-arrow-right"></span>
                  </div>
                </Link>
                {/*
                <Link
                  to={getNestedRoute(t("routes.private.prefs.email"))}
                  className="preferences-block-item"
                >
                  <div className="block-item-label">
                    <div className="block-item-icon email">
                      <span className="icon-mail"></span>
                    </div>
                    <div className="block-item-text">
                      <span className="block-item-title">
                        {t("preferences.items.email.title")}
                      </span>
                      <span className="dot">&sdot;</span>
                      <span className="block-item-description">
                        {t("preferences.items.email.subtitle")}
                      </span>
                    </div>
                  </div>
                  <div className="block-item-nav">
                    <span className="icon-nav-arrow-right"></span>
                  </div>
                </Link>
                */}
                <Link
                  to={getNestedRoute(t("routes.private.prefs.pass"))}
                  className="preferences-block-item"
                >
                  <div className="block-item-label">
                    <div className="block-item-icon password">
                      <span className="icon-shield"></span>
                    </div>
                    <div className="block-item-text">
                      <span className="block-item-title">
                        {t("preferences.items.password.title")}
                      </span>
                      <span className="dot">&sdot;</span>
                      <span className="block-item-description">
                        {t("preferences.items.password.subtitle")}
                      </span>
                    </div>
                  </div>
                  <div className="block-item-nav">
                    <span className="icon-nav-arrow-right"></span>
                  </div>
                </Link>
                <Link
                  to={customizePath}
                  className={classNames("preferences-block-item", {
                    disabled: isFreeUser,
                  })}
                >
                  <div className="block-item-label">
                    <div className="block-item-icon customize">
                      <span className="icon-edit"></span>
                    </div>
                    <div className="block-item-text">
                      <span className="block-item-title">
                        {t("preferences.items.customize.title")}
                      </span>
                      <span className="dot">&sdot;</span>
                      <span className="block-item-description">
                        {t("preferences.items.customize.subtitle")}
                      </span>
                    </div>
                  </div>
                  <div className="block-item-nav">
                    <span className="icon-nav-arrow-right"></span>
                  </div>
                </Link>
              </div>
              <div className="divider-container">
                <Divider />
              </div>
              <div className="preferences-block">
                <Link
                  to={getNestedRoute(t("routes.private.prefs.contact"))}
                  className="preferences-block-item"
                >
                  <div className="block-item-label">
                    <div className="block-item-icon contact">
                      <span className="icon-logo"></span>
                    </div>
                    <div className="block-item-text">
                      <span className="block-item-title">
                        {t("preferences.items.contact.title")}
                      </span>
                      <span className="dot">&sdot;</span>
                      <span className="block-item-description">
                        {t("preferences.items.contact.subtitle")}
                      </span>
                    </div>
                  </div>
                  <div className="block-item-nav">
                    <span className="icon-nav-arrow-right"></span>
                  </div>
                </Link>
              </div>
              <div className="divider-container">
                <Divider />
              </div>
              <div className="preferences-block">
                <Link
                  to={getLocalePath(t("routes.private.prefs.index"))}
                  className="preferences-block-item"
                  onClick={logOut}
                >
                  <div className="block-item-label">
                    <span className="block-item-icon logout">
                      <span className="icon-log-out"></span>
                    </span>
                    <div className="block-item-text">
                      <span className="block-item-title">
                        {t("preferences.items.logout.title")}
                      </span>
                    </div>
                  </div>
                  <div className="block-item-nav">
                    <span className="icon-nav-arrow-right"></span>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Preferences;
