import { useTranslation } from "react-i18next";

import DownloaderShareListItem from "../downloader-list-item";
import Divider from "../../divider";

function DownloaderShareList(props) {
  const { files } = props;

  const { t } = useTranslation();

  return (
    files.length > 0 && (
      <div className="downloader-list-container">
        <div className="downloader-list__header">
          <span className="downloader-title-container">
            {t("downloader.list.fileWithCount", {
              count: files.length,
            })}
          </span>
          <Divider />
        </div>
        <div className="downloader-list__body">
          <div className="downloader-list">
            {files.map((file, idx) => (
              <div key={idx}>
                <DownloaderShareListItem item={file} />
                {idx < files.length - 1 && <Divider />}
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  );
}

export default DownloaderShareList;
