import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Divider from "../components/divider";
import { Link } from "react-router-dom";
import ContactForm from "../components/contact-form";
import ReCAPTCHA from "react-google-recaptcha";
import SEO from "../components/seo";
import useGetLocalePath from "../hooks/useGetLocalePath";

function Contact() {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const { getLocalePath } = useGetLocalePath();

  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    if (!user.logged_in) {
      navigate(getLocalePath(t("routes.common.home")));
    }
  });

  const recaptchaRef = useRef(null);
  const [loaded, setLoaded] = useState(false);
  const asyncScriptOnLoad = useCallback((data) => {
    setLoaded(data.loaded);
  }, []);

  const getNestedRoute = useCallback(
    (nestedRoute) => {
      return getLocalePath(`${t("routes.private.prefs.index")}/${nestedRoute}`);
    },
    [getLocalePath, t]
  );

  const titleSEO = useMemo(() => {
    return `${t("app.name")} - ${t("contact.title")}`;
  }, [t]);

  const descriptionSEO = useMemo(() => {
    return t("contact.description");
  }, [t]);

  const urlSEO = useMemo(() => {
    return `${process.env.REACT_APP_PUBLIC_URL}${getNestedRoute(
      t("routes.private.prefs.contact")
    )}`;
  }, [getNestedRoute, t]);

  const altLinksSEO = useMemo(() => {
    const publicURL = process.env.REACT_APP_PUBLIC_URL;
    const result = i18n.languages.map((lng) => {
      return {
        lng: lng,
        url: `${publicURL}/${lng}/${t("routes.private.prefs.index", {
          lng: lng,
        })}/${t("routes.private.prefs.contact", {
          lng: lng,
        })}`,
      };
    });

    const defaultLng = result.find((url) => url.lng === "en");
    result.push({
      lng: "x-default",
      url: defaultLng.url,
    });

    return result;
  }, [i18n.languages, t]);

  return (
    <>
      <SEO
        title={titleSEO}
        description={descriptionSEO}
        url={urlSEO}
        canonical={urlSEO}
        altLinks={altLinksSEO}
      />
      <div className="contact-page__container">
        <div className="contact-page__content">
          <div className="contact-page__title">
            <Link to={t("routes.common.go_back")}>
              <div className="link-container">
                <span className="icon icon-nav-arrow-left"></span>
                <span className="label small-title">{t("contact.title")}</span>
              </div>
            </Link>
            <Divider />
          </div>
          <div className="contact-container">
            <div className="contact-content">
              <div className="contact-content__header">
                <div className="contact-content__header__title">
                  <span>{t("contact.subtitle")}</span>
                </div>
                <div className="contact-content__header__subtitle">
                  <span>{t("contact.emailLb")}</span>
                  <span>{t("contact.email")}</span>
                </div>
              </div>
              <div className="contact-content__body">
                <ContactForm
                  recaptcha={{
                    ref: recaptchaRef.current,
                    loaded: loaded,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <ReCAPTCHA
          ref={recaptchaRef}
          className="login-reCAPTCHA"
          sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
          badge="bottomright"
          size="invisible"
          asyncScriptOnLoad={(data) => asyncScriptOnLoad(data)}
        />
      </div>
    </>
  );
}

export default Contact;
