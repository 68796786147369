import { createSlice } from "@reduxjs/toolkit";
import { logDebug } from "../../utils/logger";

const migrations = {
  0: (state) => {
    return {
      ...state,
      user: {
        ...state.user,
        id: null,
        logged_in: false,
        name: null,
        surname: null,
        username: null,
        email: null,
        address: null,
        zip_code: null,
        city: null,
        country: null,
        phone: null,
      },
    };
  },
  1: (state) => {
    return {
      ...state,
      user: {
        ...state.user,
        id: null,
        logged_in: false,
        name: null,
        surname: null,
        username: null,
        email: null,
        address: null,
        zip_code: null,
        city: null,
        country: null,
        phone: null,
        numFiles: 0,
        numTransfers: 0,
        space: 0,
        spaceUsed: 0,
      },
    };
  },
  2: (state) => {
    return {
      ...state,
      user: {
        ...state.user,
        id: null,
        logged_in: false,
        name: null,
        surname: null,
        username: null,
        email: null,
        address: null,
        zip_code: null,
        city: null,
        country: null,
        phone: null,
        numFiles: 0,
        numTransfers: 0,
        space: 0,
        spaceUsed: 0,
        subscription: null,
        subscriptionID: null,
        expirationSubDate: null,
        subscriptionStatus: null,
      },
    };
  },
};
const currentVersion = 3;

const slice = createSlice({
  name: "user",
  initialState: {
    user: {
      id: null,
      logged_in: false,
      name: null,
      surname: null,
      username: null,
      email: null,
      address: null,
      zip_code: null,
      city: null,
      country: null,
      phone: null,
      numFiles: 0,
      numTransfers: 0,
      space: 0,
      spaceUsed: 0,
      subscription: null,
      subscriptionID: null,
      expirationSubDate: null,
      subscriptionStatus: null,
    },
  },
  reducers: {
    setUser: (state, action) => {
      state.user = { ...state.user, ...action.payload };
      logDebug("userReducer", "setUser", state.user);
    },
    removeUser: (state, action) => {
      state.user = {
        id: null,
        logged_in: false,
        name: null,
        surname: null,
        username: null,
        email: null,
        address: null,
        zip_code: null,
        city: null,
        country: null,
        phone: null,
        numFiles: 0,
        numTransfers: 0,
        space: 0,
        spaceUsed: 0,
        subscription: null,
        subscriptionID: null,
        expirationSubDate: null,
        subscriptionStatus: null,
      };
      logDebug("userReducer", "removeUser", state.user);
    },
  },
});

export const { setUser, removeUser } = slice.actions;
export { migrations, currentVersion };
export default slice.reducer;
