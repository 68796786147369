import { useCallback, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { isEmpty, isEmptyList } from "../../utils/validators";
import Divider from "../divider";
import { logDebug } from "../../utils/logger";

function Menu(props) {
  const { icon, options, disabled, onChange } = props;

  const [showMenuList, setShowMenuList] = useState(false);

  const iconMemo = useMemo(() => {
    return !isEmpty(icon) ? icon : "icon-menu";
  }, [icon]);

  const menuRef = useRef();
  useOnClickOutside(menuRef, () => setShowMenuList(false));

  const onMenuIconClickHandler = useCallback(
    (evt) => {
      setShowMenuList(!disabled && !showMenuList);
      logDebug("menu", "onSelectedItemClick", { evt: evt });
    },
    [disabled, showMenuList]
  );

  const onItemClick = useCallback(
    (evt, option) => {
      onChange && onChange(option);
      setShowMenuList(false);
      logDebug("menu", "onItemClick", { evt: evt, option: option });
    },
    [onChange]
  );

  return (
    <div
      role="menu"
      ref={menuRef}
      className={classNames("menu__container", { disabled: disabled })}
    >
      <span
        className={classNames("menu__icon", { [iconMemo]: iconMemo })}
        onClick={(evt) => onMenuIconClickHandler(evt)}
      ></span>
      {!isEmptyList(options) && (
        <div
          className={classNames("menu__list__container", {
            open: showMenuList,
          })}
        >
          {options.map((option, idx) => (
            <div
              role="menuitem"
              className="menu__list__item"
              key={option}
              title={option.label}
              onClick={(evt) => onItemClick(evt, option)}
            >
              <div
                className={classNames("label__container", {
                  disabled: option.disabled,
                })}
                disabled={option.disabled}
              >
                {option.icon && (
                  <span
                    className={classNames("icon", {
                      [option.icon]: option.icon,
                    })}
                  ></span>
                )}
                <span className="label">{option.label}</span>
              </div>
              {idx < options.length - 1 && <Divider />}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default Menu;
