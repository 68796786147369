import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import SEO from "../components/seo";
import UploaderComponent from "../components/uploader-component";
import useGetLocalePath from "../hooks/useGetLocalePath";

function Uploader() {
  const { t, i18n } = useTranslation();
  const { getLocalePath } = useGetLocalePath();

  const titleSEO = useMemo(() => {
    return `${t("app.name")} - ${t("uploader_page.title")}`;
  }, [t]);

  const descriptionSEO = useMemo(() => {
    return t("uploader_page.description");
  }, [t]);

  const urlSEO = useMemo(() => {
    return `${process.env.REACT_APP_PUBLIC_URL}${getLocalePath(
      t("routes.public.uploader")
    )}`;
  }, [getLocalePath, t]);

  const altLinksSEO = useMemo(() => {
    const publicURL = process.env.REACT_APP_PUBLIC_URL;
    const result = i18n.languages.map((lng) => {
      return {
        lng: lng,
        url: `${publicURL}/${lng}/${t("routes.public.faq", { lng: lng })}`,
      };
    });

    const defaultLng = result.find((url) => url.lng === "en");
    result.push({
      lng: "x-default",
      url: defaultLng.url,
    });

    return result;
  }, [i18n.languages, t]);

  return (
    <div>
      <SEO
        title={titleSEO}
        description={descriptionSEO}
        url={urlSEO}
        canonical={urlSEO}
        altLinks={altLinksSEO}
      />
      <UploaderComponent />
    </div>
  );
}

export default Uploader;
