import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import AdsComponent from "../../ads-component";
import Divider from "../../divider";
import SEO from "../../seo";

function DownloaderExpired() {
  const { t } = useTranslation();

  const titleSEO = useMemo(() => {
    return `${t("app.name")} - ${t("downloader_expired.title")}`;
  }, [t]);

  const descriptionSEO = useMemo(() => {
    return t("downloader_expired.message");
  }, [t]);

  return (
    <div>
      <SEO title={titleSEO} description={descriptionSEO} />
      <div className="downloader_expired__container">
        <div className="downloader_expired__card">
          <div className="downloader_expired__header">
            <span className="downloader_expired__logo"></span>
          </div>
          <div className="downloader_expired__body">
            <span className="downloader_expired__title">
              {t("downloader_expired.title")}
            </span>
            <span className="downloader_expired__message">
              {t("downloader_expired.message")}
            </span>
          </div>
          <div className="downloader_expired__footer">
            <Divider />
            <AdsComponent
              dataAdSlot={process.env.REACT_APP_G_AD_SLOT_DOWNLOADER}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DownloaderExpired;
