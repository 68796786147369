import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { isEmpty, isEmptyList } from "../utils/validators";
import detectBrowserLanguage from "detect-browser-language";

const useGetLocalePath = () => {
  const { i18n } = useTranslation();
  const location = useLocation();

  const locale = useMemo(() => {
    let result = i18n.languages.find((lng) => {
      const params = location.pathname.split("/");
      return !isEmptyList(params) && params.length >= 2 && params[1] === lng;
    });
    if (isEmpty(result)) {
      result = detectBrowserLanguage();
      result = result.split("-")[0];
      if (!i18n.languages.includes(result)) {
        result = "en";
      }
    }
    return result;
  }, [i18n.languages, location.pathname]);

  const getLocalePath = useCallback(
    (path) => {
      if (path !== -1) {
        if (!isEmpty(locale)) {
          let slug = path.replace(`/${locale}/`, "").replace(`/${locale}`, "");
          slug = slug === "/" ? "" : slug;
          return `/${locale}/${slug}`;
        }
        return path.startsWith("/") ? path : `/${path}`;
      } else {
        return path;
      }
    },
    [locale]
  );

  return { getLocalePath };
};

export default useGetLocalePath;
