import { logDebug } from "../../../utils/logger";

/**
 * uploader/setGlobalProgress Action Handler
 *
 * @param {JSON} payload Value used in action
 * @returns action function
 */
export const setGlobalProgressAction = (payload) => {
  logDebug("actions", "setGlobalProgressAction", { payload: payload });
  return {
    type: "uploader/setGlobalProgress",
    payload,
  };
};

/**
 * uploader/setGlobalStatus Action Handler
 *
 * @param {JSON} payload Value used in action
 * @returns action function
 */
export const setGlobalStatusAction = (payload) => {
  logDebug("actions", "setGlobalStatusAction", { payload: payload });
  return {
    type: "uploader/setGlobalStatus",
    payload,
  };
};

/**
 * uploader/setGlobalSize Action Handler
 *
 * @param {JSON} payload Value used in action
 * @returns action function
 */
export const setGlobalSizeAction = (payload) => {
  logDebug("actions", "setGlobalSizeAction", { payload: payload });
  return {
    type: "uploader/setGlobalSize",
    payload,
  };
};

/**
 * uploader/setUploadStatus Action Handler
 *
 * @param {JSON} payload Value used in action
 * @returns action function
 */
export const setUploadStatusAction = (payload) => {
  logDebug("actions", "setUploadStatusAction", { payload: payload });
  return {
    type: "uploader/setUploadStatus",
    payload,
  };
};

/**
 * uploader/AddFile Action Handler
 *
 * @param {JSON} payload Value used in action
 * @returns action function
 */
export const addFileAction = (payload) => {
  logDebug("actions", "addFileAction", { payload: payload });
  return {
    type: "uploader/addFile",
    payload,
  };
};

/**
 * uploader/updateFile Action Handler
 *
 * @param {JSON} payload Value used in action
 * @returns action function
 */
export const updateFileAction = (payload) => {
  logDebug("actions", "updateFileAction", { payload: payload });
  return {
    type: "uploader/updateFile",
    payload,
  };
};

/**
 * uploader/removeFile Action Handler
 *
 * @param {JSON} payload Value used in action
 * @returns action function
 */
export const removeFileAction = (payload) => {
  logDebug("actions", "removeFileAction", { payload: payload });
  return {
    type: "uploader/removeFile",
    payload,
  };
};

/**
 * ui/setFooterMenuCollapse Action Handler
 *
 * @param {JSON} payload Value used in action
 * @returns action function
 */
export const setFooterMenuCollapseAction = (payload) => {
  logDebug("actions", "setFooterMenuCollapseAction", { payload: payload });
  return {
    type: "ui/setFooterMenuCollapse",
    payload,
  };
};
