import { useCallback, useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useGetLocalePath from "../hooks/useGetLocalePath";
import { Link, useLocation, useNavigate } from "react-router-dom";

import {
  setLoading,
  setTransferInit,
  setUserInit,
} from "../redux/reducers/uiReducer";

import {
  clearItems,
  clearPagination,
  clearSelectedItem,
  clearSelectedSubitem,
} from "../redux/reducers/transfersTableReducer";

import SEO from "../components/seo";
import Divider from "../components/divider";
import TransferDetailInfo from "../components/transfer-detail/transfer-detail-info";
import TransferDetailTable from "../components/transfer-detail/transfer-detail-table";

import { logDebug } from "../utils/logger";
import { isEmptyObject } from "../utils/validators";

function TransferDetail() {
  const { t, i18n } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();
  const { getLocalePath } = useGetLocalePath();

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);

  const [mode, setMode] = useState("detail");
  const [transfer, setTransfer] = useState(location.state || {});

  const getNestedRoute = useCallback(
    (nestedRoute) => {
      return getLocalePath(
        `${t("routes.private.uploads.index")}/${nestedRoute}`
      );
    },
    [getLocalePath, t]
  );

  const titleSEO = useMemo(() => {
    return `${t("app.name")} - ${t("uploads_detail.title")}`;
  }, [t]);

  const descriptionSEO = useMemo(() => {
    return t("uploads_detail.description");
  }, [t]);

  const urlSEO = useMemo(() => {
    return `${process.env.REACT_APP_PUBLIC_URL}${getNestedRoute(
      t("routes.private.uploads.detail")
    )}`;
  }, [getNestedRoute, t]);

  const altLinksSEO = useMemo(() => {
    const publicURL = process.env.REACT_APP_PUBLIC_URL;
    const result = i18n.languages.map((lng) => {
      return {
        lng: lng,
        url: `${publicURL}/${lng}/${t("routes.private.uploads.index", {
          lng: lng,
        })}/${t("routes.private.uploads.detail", {
          lng: lng,
        })}`,
      };
    });

    const defaultLng = result.find((url) => url.lng === "en");
    result.push({
      lng: "x-default",
      url: defaultLng.url,
    });

    return result;
  }, [i18n.languages, t]);

  const goToUploads = useCallback(
    (evt) => {
      dispatch(clearItems());
      dispatch(clearPagination());
      dispatch(clearSelectedItem());
      dispatch(clearSelectedSubitem());
      dispatch(setUserInit(true));
      dispatch(setLoading(true));
      dispatch(setTransferInit(true));
    },
    [dispatch]
  );

  useEffect(() => {
    if (!user.logged_in) {
      navigate(getLocalePath(t("routes.common.home")));
    }
  });

  useEffect(() => {
    logDebug("TransferDetail", "setTransfer", { data: transfer });

    if (!isEmptyObject(transfer)) {
      navigate(null, { replace: true, state: { ...transfer } });
    }
  }, [navigate, transfer]);

  return (
    <>
      <SEO
        title={titleSEO}
        description={descriptionSEO}
        url={urlSEO}
        canonical={urlSEO}
        altLinks={altLinksSEO}
      />
      <div className="uploads-detail-page__container">
        <div className="uploads-detail-page__content">
          <div className="uploads-detail-page__title">
            <Link
              to={t("routes.common.go_back")}
              onClick={(evt) => goToUploads(evt)}
            >
              <div className="link-container">
                <span className="icon icon-nav-arrow-left"></span>
                <span className="label small-title">
                  {t("uploads_detail.header.title")}
                </span>
              </div>
            </Link>
            <Divider />
          </div>
          <div className="uploads-detail__container">
            <div className="uploads-detail__content">
              <TransferDetailInfo
                mode={mode}
                transfer={transfer}
                setTransfer={setTransfer}
              />
              <TransferDetailTable
                mode={mode}
                transfer={transfer}
                setTransfer={setTransfer}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TransferDetail;
