import { useCallback, useMemo, useState } from "react";

const useGetUserInfo = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const url = useMemo(() => {
    return `https://${process.env.REACT_APP_API}/user/`;
  }, []);

  const config = useMemo(() => {
    return {
      method: "GET",
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
  }, []);

  const getUserInfo = useCallback(() => {
    try {
      setLoading(true);
      fetch(url, config)
        .then((response) => response.json())
        .then((data) => {
          setError(data.error);
          delete data.error;
          setData(data);
        })
        .catch((error) => setError(error))
        .finally(() => setLoading(false));
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  }, [config, url]);

  return { loading, data, error, getUserInfo };
};

export default useGetUserInfo;
