import SpeechBallonSection from "./speech-ballon-section";
import HowItWorkSection from "./how-it-work-section";
import PlansSection from "./plans-section";
import WhyYdraySection from "./why-ydray-section";
import SecurityTipsSection from "./security-tips-section";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { isEmpty } from "../../../utils/validators";

function LandingBody({ params }) {
  const location = useLocation();

  useEffect(() => {
    const targetId = location?.state?.targetId;
    const el = document.getElementById(targetId);
    if (!isEmpty(el)) {
      el.scrollIntoView({
        block: "start",
        inline: "start",
        behavior: "smooth",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="landing__body">
      <SpeechBallonSection />
      <HowItWorkSection />
      <PlansSection />
      <WhyYdraySection />
      <SecurityTipsSection />
    </div>
  );
}

export default LandingBody;
