import { useTranslation } from "react-i18next";
import SEO from "../components/seo";
import LandingFooter from "../components/landing/landing-footer";
import { useEffect, useMemo, useRef } from "react";
import useGetLocalePath from "../hooks/useGetLocalePath";

function ShareFiles() {
  const { t, i18n } = useTranslation();

  const { getLocalePath } = useGetLocalePath();

  const shareFilesRef = useRef(null);
  useEffect(() => {
    shareFilesRef?.current?.scrollIntoView({
      block: "start",
      inline: "start",
      behavior: "smooth",
    });
  }, []);

  const titleSEO = useMemo(() => {
    return `${t("app.name")} - ${t("share_files.title")}`;
  }, [t]);

  const descriptionSEO = useMemo(() => {
    return t("share_files.description");
  }, [t]);

  const urlSEO = useMemo(() => {
    return `${process.env.REACT_APP_PUBLIC_URL}${getLocalePath(
      t("routes.public.share_files.index")
    )}`;
  }, [getLocalePath, t]);

  const altLinksSEO = useMemo(() => {
    const publicURL = process.env.REACT_APP_PUBLIC_URL;
    const result = i18n.languages.map((lng) => {
      return {
        lng: lng,
        url: `${publicURL}/${lng}/${t("routes.public.share_files.index", {
          lng: lng,
        })}`,
      };
    });

    const defaultLng = result.find((url) => url.lng === "en");
    result.push({
      lng: "x-default",
      url: defaultLng.url,
    });

    return result;
  }, [i18n.languages, t]);

  return (
    <div>
      <SEO
        title={titleSEO}
        description={descriptionSEO}
        url={urlSEO}
        canonical={urlSEO}
        altLinks={altLinksSEO}
      />
      <div ref={shareFilesRef}>
        <div className="share-files-page__header"></div>
        <div className="share-files-page__body">
          <div
            className="share-files-page__content"
            dangerouslySetInnerHTML={{
              __html: t("share_files.content", {
                interpolation: { escapeValue: true },
              }),
            }}
          ></div>
        </div>
        <div className="share-files-page__footer">
          <LandingFooter />
        </div>
      </div>
    </div>
  );
}

export default ShareFiles;
