import { toast } from "react-toastify";
import { logInfo, logWarn, logError } from "../../utils/logger";

const config = {
  position: "bottom-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

export const infoToast = (evt, payload, msg) => {
  logInfo("infoToast", { evt: evt, payload: payload, msg: msg });
  toast.info(msg, config);
};

export const successToast = (evt, payload, msg) => {
  logInfo("successToast", { evt: evt, payload: payload, msg: msg });
  toast.success(msg, config);
};

export const warnToast = (evt, payload, msg) => {
  logWarn("warnToast", { evt: evt, payload: payload, msg: msg });
  toast.warn(msg, config);
};

export const errorToast = (evt, payload, msg) => {
  logError("errorToast", { evt: evt, payload: payload, msg: msg });
  toast.error(msg, config);
};
