import { RadialProgress } from "react-radial-progress-indicator";

function RadialProgressIndicator(props) {
  const {
    value,
    total,
    duration,
    width,
    height,
    fontRatio,
    ringBgColor,
    ringFgColor,
    ringThickness,
    textHandler,
  } = props;

  return (
    <div className="radial-progressbar__container">
      <RadialProgress
        duration={duration}
        width={width}
        height={height}
        backgroundTransparent
        ringBgColour={ringBgColor}
        ringFgColour={ringFgColor}
        ringIntermediateColour={ringFgColor}
        ringThickness={ringThickness}
        fontRatio={fontRatio}
        segmented={false}
        showIntermediateProgress
        startStep={0}
        step={value}
        steps={total}
        text={textHandler}
      />
    </div>
  );
}

export default RadialProgressIndicator;
