import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  setBlur,
  setShowContactDialog,
} from "../../../../redux/reducers/uiReducer.js";
import Button from "../../../button";

function HowItWorkSection() {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { ui } = useSelector((state) => state);

  const openContactDialog = () => {
    dispatch(setShowContactDialog(!ui.show_contact_dialog));
    dispatch(setBlur(!ui.is_blur));
  };

  return (
    <div className="hiw__section">
      <div className="hiw__section__header">
        <div className="hiw__section__header__content">
          <span className="hiw__section__header__subtitle">
            {t("landing.body.how_section.subtitle")}
          </span>
          <h2 className="hiw__section__header__title">
            {t("landing.body.how_section.title")}
          </h2>
        </div>
      </div>

      <div className="hiw__section__body">
        <div className="hiw__section__body__content">
          <div className="hiw__card__container">
            <div className="hiw__card step1">
              <div className="hiw__card__step">{"1"}</div>
              <div className="hiw__card__title">
                {t("landing.body.how_section.step1.title")}
              </div>
              <div className="hiw__card__subtitle">
                {t("landing.body.how_section.step1.subtitle")}
              </div>
            </div>
            <img
              className="hiw__card__next"
              src="../../../assets/images/next_step.svg"
              alt="next step"
              title="next step"
              loading="lazy"
              width="auto"
              height="auto"
            />
            <div className="hiw__card step2">
              <div className="hiw__card__step">{"2"}</div>
              <div className="hiw__card__title">
                {t("landing.body.how_section.step2.title")}
              </div>
              <div className="hiw__card__subtitle">
                {t("landing.body.how_section.step2.subtitle")}
              </div>
            </div>
            <img
              className="hiw__card__next"
              src="../../../assets/images/next_step.svg"
              alt="next step"
              title="next step"
              loading="lazy"
              width="auto"
              height="auto"
            />
            <div className="hiw__card step3">
              <div className="hiw__card__step">{"3"}</div>
              <div className="hiw__card__title">
                {t("landing.body.how_section.step3.title")}
              </div>
              <div className="hiw__card__subtitle">
                {t("landing.body.how_section.step3.subtitle")}
              </div>
            </div>
          </div>
          <div className="hiw__help__container">
            <div className="hiw__card help">
              <div className="hiw__card__step">{"?"}</div>
              <div className="hiw__card__title">
                {t("landing.body.how_section.help.title")}
              </div>
              <div className="hiw__card__subtitle">
                {t("landing.body.how_section.help.subtitle")}
              </div>
              <Button primary text onClick={(evt) => openContactDialog()}>
                <span className="label">{t("button.contact")}</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HowItWorkSection;
