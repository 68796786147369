import { useCallback, useMemo } from "react";

import classNames from "classnames";

import {
  PayPalButtons,
  PayPalScriptProvider,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";

import Loading from "../../loading";

function SubPaypalButton(props) {
  const {
    plan_id,
    custom_id,
    className,
    disabled = false,
    showSpinner = false,
    onInit,
    onClick,
    onApprove,
    onCancel,
    onError,
  } = props;

  const style = useMemo(() => {
    return {
      shape: "pill",
      color: "gold",
      layout: "horizontal",
      label: "subscribe",
      tagline: false,
      disableMaxWidth: true,
    };
  }, []);

  /**
   * Called on button click to set up a recurring payment. [createSubscription docs](https://developer.paypal.com/docs/business/javascript-sdk/javascript-sdk-reference/#createsubscription).
   */
  const createSubscriptionHandler = useCallback(
    (data, actions) =>
      actions.subscription.create({
        plan_id: plan_id,
        custom_id: custom_id,
      }),
    [plan_id, custom_id]
  );

  /**
   * Called when the buttons are initialized. The component is initialized after the iframe has successfully loaded.
   */
  const onInitHandler = useCallback(
    (data, actions) => onInit && onInit((data, actions)),
    [onInit]
  );

  /**
   * Called when the button is clicked. Often used for [validation](https://developer.paypal.com/docs/checkout/integration-features/validation/).
   */
  const onClickHandler = useCallback(
    (data, actions) => !disabled && onClick && onClick(data, actions),
    [disabled, onClick]
  );

  /**
   * Called when finalizing the transaction. Often used to inform the buyer that the transaction is complete. [onApprove docs](https://developer.paypal.com/docs/business/javascript-sdk/javascript-sdk-reference/#onapprove).
   */
  const onApproveHandler = useCallback(
    (data, details) => onApprove && onApprove(data, details),
    [onApprove]
  );

  /**
   * Called when the buyer cancels the transaction.
   * Often used to show the buyer a [cancellation page](https://developer.paypal.com/docs/business/checkout/add-capabilities/buyer-experience/#3-show-cancellation-page).
   */
  const onCancelHandler = useCallback(
    (data) => onCancel && onCancel(data),
    [onCancel]
  );

  /**
   * Catch all for errors preventing buyer checkout.
   * Often used to show the buyer an [error page](https://developer.paypal.com/docs/checkout/integration-features/handle-errors/).
   */
  const onErrorHandler = useCallback(
    (err) => onError && onError(err),
    [onError]
  );

  const ProviderWrapper = (props) => {
    const { children } = props;

    return (
      <PayPalScriptProvider
        options={{
          debug: process.env.REACT_APP_PAYPAL_DEBUG,
          "client-id": process.env.REACT_APP_PAYPAL_ID_CLIENT,
          components: "buttons",
          intent: "subscription",
          vault: true,
        }}
      >
        {children}
      </PayPalScriptProvider>
    );
  };

  const PaypalButtonsWrapper = (props) => {
    const { showSpinner } = props;

    const [{ isPending }] = usePayPalScriptReducer();

    const classButtonMemo = useMemo(() => {
      return {
        "subscription-button": true,
        ...className,
      };
    }, []);

    return (
      <div className="subscription-button__container">
        <div className="subscription-button__content">
          {showSpinner && isPending && (
            <div className="subscription-button__loader">
              <Loading />
            </div>
          )}
          <div className="subscription-button__button">
            <PayPalButtons
              disabled={disabled}
              className={classNames(classButtonMemo)}
              style={style}
              createSubscription={(data, actions) =>
                createSubscriptionHandler(data, actions)
              }
              onInit={(data, actions) => onInitHandler(data, actions)}
              onClick={(data, actions) => onClickHandler(data, actions)}
              onApprove={(data, details) => onApproveHandler(data, details)}
              onCancel={(err) => onCancelHandler(err)}
              onError={(err) => onErrorHandler(err)}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <ProviderWrapper>
      <PaypalButtonsWrapper showSpinner={showSpinner} />
    </ProviderWrapper>
  );
}

export default SubPaypalButton;
