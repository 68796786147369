import { useCallback, useMemo, useRef } from "react";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import classNames from "classnames";
import { isEmpty } from "../../utils/validators";

function Dialog(props) {
  const { showDialog, closeHandler, canCloseOutside, header, body, footer } =
    props;

  const dialogRef = useRef(null);
  useOnClickOutside(dialogRef, () => closeLoginDialog());

  const canCloseOutsideMemo = useMemo(() => {
    return !isEmpty(canCloseOutside) ? canCloseOutside : true;
  }, [canCloseOutside]);

  const closeLoginDialog = useCallback(() => {
    if (canCloseOutsideMemo) {
      closeHandler();
    }
  }, [canCloseOutsideMemo, closeHandler]);

  return (
    <>
      {showDialog && (
        <>
          <div className={classNames("wrapper", { blur: showDialog })}></div>
          <div ref={dialogRef} className="dialog__container">
            {header && <div className="dialog__header">{header}</div>}
            {body && <div className="dialog__body">{body}</div>}
            {footer && <div className="dialog__footer">{footer}</div>}
          </div>
        </>
      )}
    </>
  );
}

export default Dialog;
