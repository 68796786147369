import classNames from "classnames";

function Switch({ name, checked, disabled, required, onChange }) {
  return (
    <label className={classNames("switch", { disabled: disabled })}>
      <input
        id={name}
        className="checkbox"
        type="checkbox"
        name={name}
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        required={required}
      />
      <span className="slider"></span>
    </label>
  );
}

export default Switch;
