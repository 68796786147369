import { useCallback, useEffect, useMemo } from "react";
import { isEmpty } from "../../../utils/validators";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import getRegisterUserSchema from "../../../schemas/registerUserSchema";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TextField from "../../text-field";
import PasswordField from "../../password-field";
import { errorToast, successToast } from "../../toast";
import useUserRegister from "../../../hooks/useUserRegister";
import { removeUser } from "../../../redux/reducers/userReducer";
import {
  setBlur,
  setShowRegisterDialog,
} from "../../../redux/reducers/uiReducer";
import Button from "../../button";
import {
  loginUserMethods,
  trackSignUpUser,
} from "../../../utils/google-analytics/events/user";
import { logDebug } from "../../../utils/logger";

function RegisterForm({ recaptcha }) {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { ui } = useSelector((state) => state);
  const { settings } = useSelector((state) => state.settings);

  const token = useMemo(
    () =>
      !isEmpty(settings) && !isEmpty(settings.csrf_token)
        ? settings.csrf_token
        : "",
    [settings]
  );

  const registerUserSchema = getRegisterUserSchema(t);
  const {
    register,
    control,
    watch,
    setValue,
    getValues,
    handleSubmit,
    reset,
    formState: { isSubmitSuccessful, errors },
  } = useForm({
    defaultValues: {
      username: "",
      email: "",
      pass1: "",
      pass2: "",
      csrf_token: token,
      "g-recaptcha-response": "",
    },
    values: {
      username: "",
      email: "",
      pass1: "",
      pass2: "",
      csrf_token: token,
      "g-recaptcha-response": "",
    },
    mode: "all",
    criteriaMode: "all",
    resolver: yupResolver(registerUserSchema),
  });

  const changeRecaptcha = useCallback(
    (token) => {
      setValue("g-recaptcha-response", token);
      recaptcha.ref.reset();
    },
    [recaptcha, setValue]
  );

  const resetRecaptcha = useCallback(() => {
    setValue("g-recaptcha-response", "");
    recaptcha.ref.reset();
  }, [recaptcha, setValue]);

  const { loading, data, error, registerHandler } = useUserRegister();
  useEffect(() => {
    if (!loading) {
      if (error) {
        errorToast(
          "useUserRegister",
          error,
          t(`errors.${error}`, t("errors.default"))
        );
        dispatch(removeUser());
      } else if (!error && isSubmitSuccessful) {
        reset({
          username: "",
          email: "",
          pass1: "",
          pass2: "",
          csrf_token: token,
          "g-recaptcha-response": "",
        });
        resetRecaptcha();
        dispatch(setShowRegisterDialog(!ui.show_register_dialog));
        dispatch(setBlur(!ui.is_blur));
        successToast("useUserRegister", data, t("message.create_user_ok"));
        // GA4 Event Tracking
        trackSignUpUser(loginUserMethods.EMAIL);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, data, error]);

  const onSubmit = async (data, evt) => {
    if (recaptcha.loaded) {
      const token = !isEmpty(getValues("g-recaptcha-response"))
        ? getValues("g-recaptcha-response")
        : await recaptcha.ref.executeAsync();

      if (!isEmpty(token)) {
        recaptcha.ref.reset();
        setValue("g-recaptcha-response", token);
        logDebug("RegisterForm", "onSubmit", { data: data, evt: evt });
        registerHandler(getValues());
      }
    }
  };

  const onError = async (errors, evt) => {
    logDebug("RegisterForm", "onError", { errors: errors, evt: evt });
    recaptcha.ref.reset();
    errorToast("onError", errors, t("errors.error_form", t("errors.default")));
  };

  return (
    <form
      id="user_register_form"
      className="register__form"
      onSubmit={handleSubmit(onSubmit, onError)}
    >
      <TextField
        label={t("register_dialog.form.username")}
        autoComplete={"username"}
        value={watch("username")}
        register={register("username", {
          ...registerUserSchema.username,
          onChange: (evt) => setValue("username", evt.target.value),
          onBlur: (evt) => setValue("username", evt.target.value),
        })}
        error={errors?.username?.message}
        required
      />
      <TextField
        label={t("register_dialog.form.email")}
        autoComplete={"email"}
        value={watch("email")}
        register={register("email", {
          ...registerUserSchema.email,
          onChange: (evt) => setValue("email", evt.target.value),
          onBlur: (evt) => setValue("email", evt.target.value),
        })}
        error={errors?.email?.message}
        required
      />
      <PasswordField
        label={t("register_dialog.form.pass1")}
        autoComplete={"new-password"}
        value={watch("pass1")}
        register={register("pass1", {
          ...registerUserSchema.pass1,
          onChange: (evt) => setValue("pass1", evt.target.value),
          onBlur: (evt) => setValue("pass1", evt.target.value),
        })}
        error={errors?.pass1?.message}
        required
      />
      <PasswordField
        label={t("register_dialog.form.pass2")}
        autoComplete={"new-password"}
        value={watch("pass2")}
        register={register("pass2", {
          ...registerUserSchema.pass2,
          onChange: (evt) => setValue("pass2", evt.target.value),
          onBlur: (evt) => setValue("pass2", evt.target.value),
        })}
        error={errors?.pass2?.message}
        required
      />
      <Controller
        name="csrf_token"
        control={control}
        defaultValue={token}
        render={({ field }) => <input hidden {...field}></input>}
      />
      <Controller
        name="g-recaptcha-response"
        control={control}
        defaultValue=""
        onChange={(token) => changeRecaptcha("g-recaptcha-response", token)}
        onExpired={() => resetRecaptcha()}
        onError={() => resetRecaptcha()}
        render={({ field }) => (
          <input hidden value={getValues("g-recaptcha-response")} {...field} />
        )}
      />
      <div className="register__form__footer">
        <Button type="submit" primary text loading={loading}>
          <span className="label">{t("register_dialog.button.create")}</span>
        </Button>
      </div>
    </form>
  );
}

export default RegisterForm;
