import { useCallback, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import useCopyToClipboard from "../../../hooks/useCopyToClipboard.ts";

import Button from "../../button";
import { successToast } from "../../toast";
import ShareComponent from "../../share-component";

import { SHARE_TYPE } from "../utils/config";
import { FILE_STATES } from "@rpldy/uploady";
import { logDebug } from "../../../utils/logger.js";
import { equalsIgnoringCase, isEmptyList } from "../../../utils/validators";

function ShareFilesComponent({ loading, sharedLink }) {
  const { t } = useTranslation();

  const { uploader } = useSelector((state) => state.uploader);
  const [copiedText, copy] = useCopyToClipboard();

  const isGenerate = useMemo(
    () => equalsIgnoringCase(uploader.shareType, SHARE_TYPE.GENERATE),
    [uploader.shareType]
  );

  const isCopy = useMemo(
    () => equalsIgnoringCase(uploader.shareType, SHARE_TYPE.COPY),
    [uploader.shareType]
  );

  const disabledGenerateBtn = useMemo(() => {
    const files = Array.from(uploader.files);
    const isAnyUploading =
      !isEmptyList(files) &&
      files.some((item) =>
        equalsIgnoringCase(item.state, FILE_STATES.UPLOADING)
      );
    const isAnyFinished =
      !isEmptyList(files) &&
      files.some((item) =>
        equalsIgnoringCase(item.state, FILE_STATES.FINISHED)
      );
    if (isAnyUploading) {
      return false;
    } else if (isAnyFinished) {
      return false;
    } else {
      return true;
    }
  }, [uploader.files]);

  const onClick = useCallback(
    (evt) => {
      copy(sharedLink);
      logDebug("ShareFilesComponent", "copyLink", {
        evt: evt,
        sharedLink: sharedLink,
        copiedText: copiedText,
      });
      successToast("copyLink", sharedLink, t("message.clipboard"));
    },
    [sharedLink, copiedText, copy, t]
  );

  return (
    <div className="share-files-container">
      <div className="share-files__header">
        <span className="icon-title icon-share"></span>
        <span className="text-title small-title">
          {t("uploader.share_content.title")}
        </span>
      </div>
      <div className="share-files__body">
        {isGenerate && (
          <Button
            type="submit"
            share
            text
            loading={loading}
            disabled={disabledGenerateBtn}
          >
            <span className="label">
              {t("uploader.share_content.button.generate")}
            </span>
          </Button>
        )}
        {isCopy && (
          <div className="container">
            <Button share text onClick={(evt) => onClick(evt)}>
              <span className="icon icon-cloud-upload"></span>
              <span className="label">
                {t("uploader.share_content.button.copy")}
              </span>
            </Button>
            <ShareComponent sharedLink={sharedLink} />
          </div>
        )}
      </div>
    </div>
  );
}

export default ShareFilesComponent;
