import * as yup from "yup";

const getPasswordSchema = (t) => {
  return yup.object().shape({
    oldpass: yup
      .string()
      .required(t("validators.notEmpty"))
      .min(8, t("validators.minLength", { value: 8 }))
      .max(16, t("validators.maxLength", { value: 16 })),
    pass1: yup
      .string()
      .required(t("validators.notEmpty"))
      .min(8, t("validators.minLength", { value: 8 }))
      .max(16, t("validators.maxLength", { value: 16 })),
    pass2: yup
      .string()
      .required(t("validators.notEmpty"))
      .min(8, t("validators.minLength", { value: 8 }))
      .max(16, t("validators.maxLength", { value: 16 }))
      .oneOf([yup.ref("pass1"), null], t("validators.compareFields")),
  });
};

export default getPasswordSchema;
