import { useCallback, useEffect, useMemo, useState } from "react";

import classNames from "classnames";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useGetUserInfo from "../../../hooks/useGetUserInfo";
import useWindowSize from "../../../hooks/useWindowSize";
import useGetUserSubscriptionStatus from "../../../hooks/useGetUserSubscriptionStatus";

import { removeUser, setUser } from "../../../redux/reducers/userReducer";

import Divider from "../../divider";
import RadialProgressIndicator from "../../radial-progress-indicator";
import CountUp from "react-countup";
import { errorToast } from "../../toast";

import {
  getDecimalSeparator,
  humanFileSize,
  isEmpty,
} from "../../../utils/validators";

import { logDebug } from "../../../utils/logger";
import { setUserInit } from "../../../redux/reducers/uiReducer";

function UploaderPageHeader() {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const windowSize = useWindowSize();
  const { ui } = useSelector((state) => state);
  const { user } = useSelector((state) => state.user);
  const { isProUser } = useGetUserSubscriptionStatus();

  const [showNumFiles, setShowNumFiles] = useState(true);
  useEffect(() => {
    if (windowSize.width <= 1300) {
      setShowNumFiles(false);
      if (windowSize.width <= 576) {
        setShowNumFiles(true);
      }
    } else {
      setShowNumFiles(true);
    }
  }, [windowSize.width]);

  const [showNumUploads, setShowNumUploads] = useState(true);
  useEffect(() => {
    if (windowSize.width <= 1200) {
      setShowNumUploads(false);
      if (windowSize.width <= 576) {
        setShowNumUploads(true);
      }
    } else {
      setShowNumUploads(true);
    }
  }, [windowSize.width]);

  const [showStoragePercent, setShowStoragePercent] = useState(true);
  useEffect(() => {
    if (windowSize.width <= 1000) {
      setShowStoragePercent(false);
      if (windowSize.width <= 576) {
        setShowStoragePercent(true);
      }
    } else {
      setShowStoragePercent(true);
    }
  }, [windowSize.width]);

  const showStats = useMemo(() => {
    return [showStoragePercent, showNumUploads, showNumFiles].some(
      (item) => item
    );
  }, [showNumFiles, showNumUploads, showStoragePercent]);

  const username = useMemo(() => {
    return !isEmpty(user.username) ? user.username : user.email;
  }, [user.username, user.email]);

  const totalTransfers = useMemo(() => {
    return parseInt(user.numTransfers);
  }, [user.numTransfers]);

  const totalNumFiles = useMemo(() => {
    return parseInt(user.numFiles);
  }, [user.numFiles]);

  const usedStorage = useMemo(() => {
    return humanFileSize(user.spaceUsed, i18n.language, true, 2);
  }, [user.spaceUsed, i18n]);

  const totalStorage = useMemo(() => {
    return humanFileSize(user.space, i18n.language, true, 2);
  }, [user.space, i18n]);

  const usedStoragePercent = useMemo(() => {
    const spaceUsed = parseInt(user.spaceUsed);
    const space = parseInt(user.space);
    return (spaceUsed / space) * 100;
  }, [user.spaceUsed, user.space]);

  const ringFgColour = useMemo(() => {
    if (usedStoragePercent >= 0.0 && usedStoragePercent < 50.0) {
      return "#12b76a";
    } else if (usedStoragePercent >= 50.0 && usedStoragePercent < 80.0) {
      return "#f79009";
    } else {
      return "#f04438";
    }
  }, [usedStoragePercent]);

  const radialProgressTextRender = useCallback((step, proportion) => {
    const percent = (100 * proportion).toFixed(2);
    return `${percent}%`;
  }, []);

  const { loading, data, error, getUserInfo } = useGetUserInfo();
  useEffect(() => {
    if (ui.user_init) {
      getUserInfo();
    }
  }, [ui.user_init, t, getUserInfo]);
  useEffect(() => {
    if (!loading) {
      if (isEmpty(error)) {
        if (!isEmpty(data)) {
          dispatch(setUser(data));
        }
      } else {
        dispatch(removeUser());
        logDebug("UploaderPageHeader", "getUserInfo", { error: error });
        errorToast(
          "UploaderPageHeader - getUserInfo",
          error,
          t(`errors.${error}`, t("errors.default"))
        );
      }
      dispatch(setUserInit(false));
    }
  }, [loading, data, error, dispatch, t]);

  return (
    <div className="uploads__content__header">
      {windowSize.width >= 576 && (
        <div className="uploads__content__header__user">
          <div className="circle">
            <span className="icon icon-user"></span>
          </div>
          <div className="user-title__ellipsis">
            <span
              className="user-title header-3"
              title={t("uploads.header.title", { username: username })}
            >
              {t("uploads.header.title", { username: username })}
            </span>
          </div>
        </div>
      )}
      {showStats && (
        <div className="uploads__content__header__stats">
          {showStoragePercent && isProUser && (
            <>
              <div className="stat small-title storage-percent">
                {windowSize.width >= 576 ? (
                  <>
                    <div
                      className="column"
                      title={t("uploads.header.used_storage_ph", {
                        value: usedStoragePercent.toFixed(2),
                      })}
                    >
                      <RadialProgressIndicator
                        value={usedStoragePercent}
                        total={100}
                        duration={3500}
                        ringFgColor={ringFgColour}
                        ringBgColor={"#e5e7eb"}
                        ringThickness={0.275}
                        width={48}
                        height={48}
                        fontRatio={4.8}
                        textHandler={(step, proportion) =>
                          radialProgressTextRender(step, proportion)
                        }
                      />
                    </div>
                    <div className="column">
                      <span
                        className="smaller"
                        title={t("uploads.header.used_storage", {
                          value: usedStorage,
                        })}
                      >
                        {t("uploads.header.used_storage", {
                          value: usedStorage,
                        })}
                      </span>
                      <span
                        className="smaller"
                        title={t("uploads.header.total_storage", {
                          value: totalStorage,
                        })}
                      >
                        {t("uploads.header.total_storage", {
                          value: totalStorage,
                        })}
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    <span className="icon-database"></span>
                    <span>
                      <CountUp
                        start={0}
                        decimals={2}
                        decimal={getDecimalSeparator(i18n.language)}
                        end={usedStoragePercent.toFixed(2)}
                        delay={0.5}
                        duration={2.75}
                      />
                      %
                    </span>
                  </>
                )}
              </div>
            </>
          )}
          {showNumUploads && (
            <>
              <Divider vertical />
              <div
                className="stat small-title num-uploads"
                title={t("uploads.header.uploads_ph", {
                  count: totalTransfers,
                })}
              >
                {windowSize.width >= 576 ? (
                  <>
                    <span>
                      <CountUp
                        start={0.0}
                        end={totalTransfers}
                        delay={0.5}
                        duration={2.75}
                      />
                      <span className="icon-arrow-up"></span>
                    </span>
                    <span className="smaller">
                      {t("uploads.header.uploads")}
                    </span>
                  </>
                ) : (
                  <>
                    <span className="icon-cloud-upload"></span>
                    <CountUp
                      start={0}
                      end={totalTransfers}
                      delay={0.5}
                      duration={2.75}
                    />
                  </>
                )}
              </div>
            </>
          )}
          {showNumFiles && (
            <>
              <Divider vertical />
              <div
                className={classNames("stat", "small-title", "num-files", {
                  hide: showNumFiles,
                })}
                title={t("uploads.header.files_ph", { count: totalNumFiles })}
              >
                {windowSize.width >= 576 ? (
                  <>
                    <span>
                      <CountUp
                        start={0}
                        end={totalNumFiles}
                        delay={0.5}
                        duration={2.75}
                      />
                      <span className="icon-arrow-up"></span>
                    </span>
                    <span className="smaller">{t("uploads.header.files")}</span>
                  </>
                ) : (
                  <>
                    <span className="icon-empty-page"></span>
                    <CountUp
                      start={0}
                      end={totalNumFiles}
                      delay={0.5}
                      duration={2.75}
                    />
                  </>
                )}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default UploaderPageHeader;
