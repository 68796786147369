import { FILE_STATES } from "@rpldy/uploady";
import { useTranslation } from "react-i18next";
import Button from "../../button";
import Divider from "../../divider";
import FileItem from "../file-item";

function FileItemList({
  title,
  list,
  detail,
  showRetryAll,
  onRetryAll,
  onRetryItem,
  showAbortAll,
  onAbortAll,
  onAbortItem,
}) {
  const { t } = useTranslation();

  return (
    <div className="file-item-list-container">
      <div className="file-item-list__header">
        <span className="file-item-list__title">{title}</span>
        <div className="file-item-list__actions">
          {!detail && showRetryAll && (
            <Button secondary icon onClick={onRetryAll}>
              <span
                className="icon icon-refresh-double"
                title={t("button.reuploadAll")}
              ></span>
              <span className="label" title={t("button.reuploadAll")}>
                {t("button.reuploadAll")}
              </span>
            </Button>
          )}
          {!detail && showAbortAll && (
            <Button secondary icon onClick={onAbortAll}>
              <span
                className="icon icon-delete-circled-outline"
                title={t("button.cancelAll")}
              ></span>
              <span className="label" title={t("button.cancelAll")}>
                {t("button.cancelAll")}
              </span>
            </Button>
          )}
        </div>
      </div>
      <Divider />
      <div className="file-item-list__uploads">
        {list.map((file, idx) => (
          <FileItem
            key={idx}
            item={file}
            detail={detail}
            showRetry={
              !detail &&
              showRetryAll &&
              [
                FILE_STATES.ABORTED,
                FILE_STATES.CANCELLED,
                FILE_STATES.ERROR,
              ].includes(file.state)
            }
            onRetry={onRetryItem}
            showAbort={!detail && showAbortAll}
            onAbort={onAbortItem}
          />
        ))}
      </div>
    </div>
  );
}

export default FileItemList;
