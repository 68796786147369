import { useCallback, useEffect } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  setBlur,
  setHeaderMenuOpen,
  setShowContactDialog,
  setShowNewsletterRegisterDialog,
} from "../../../../redux/reducers/uiReducer.js";
import { equalsIgnoringCase, isEmpty } from "../../../../utils/validators.js";
import Button from "../../../button/index.jsx";
import PlanCard from "../../../plan-card/index.jsx";
import { useInView } from "react-intersection-observer";

function PlansSection() {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { ui } = useSelector((state) => state);

  const { ref: plansRef, inView: plansVisible } = useInView();
  useEffect(() => {
    if (plansVisible) {
      const headerAboutLink = document.getElementById("header-about-link");
      if (!isEmpty(headerAboutLink)) {
        headerAboutLink.classList.toggle("active", false);
      }
      const footerAboutLink = document.getElementById("footer-about-link");
      if (!isEmpty(footerAboutLink)) {
        footerAboutLink.classList.toggle("active", false);
      }
      const headerPlansLink = document.getElementById("header-plans-link");
      if (!isEmpty(headerPlansLink)) {
        headerPlansLink.classList.toggle("active", true);
      }
      const footerPlansLink = document.getElementById("footer-plans-link");
      if (!isEmpty(footerPlansLink)) {
        footerPlansLink.classList.toggle("active", true);
      }
    } else {
      const headerAboutLink = document.getElementById("header-about-link");
      if (!isEmpty(headerAboutLink)) {
        headerAboutLink.classList.toggle("active", false);
      }
      const footerAboutLink = document.getElementById("footer-about-link");
      if (!isEmpty(footerAboutLink)) {
        footerAboutLink.classList.toggle("active", false);
      }
      const headerPlansLink = document.getElementById("header-plans-link");
      if (!isEmpty(headerPlansLink)) {
        headerPlansLink.classList.toggle("active", false);
      }
      const footerPlansLink = document.getElementById("footer-plans-link");
      if (!isEmpty(footerPlansLink)) {
        footerPlansLink.classList.toggle("active", false);
      }
    }
  }, [plansVisible]);

  const planIcon = (plan) => {
    if (equalsIgnoringCase(plan, t("subscription.free"))) {
      return (
        <span
          className={classNames(
            "plan-card__container__header__icon",
            "icon-circle-alternate"
          )}
        ></span>
      );
    } else if (equalsIgnoringCase(plan, t("subscription.pro"))) {
      return (
        <span
          className={classNames(
            "plan-card__container__header__icon",
            "icon-up-round-arrow"
          )}
        >
          <span className="path1"></span>
          <span className="path2"></span>
        </span>
      );
    } else if (equalsIgnoringCase(plan, t("subscription.business"))) {
      return (
        <span
          className={classNames(
            "plan-card__container__header__icon",
            "icon-star-circled"
          )}
        ></span>
      );
    }
  };

  const planTitle = (plan) => {
    return (
      <span className="plan-card__container__header__title">
        {t(`plans.${plan}.title`)}
      </span>
    );
  };

  const FreePlanButton = () => {
    const openNewsletterDialog = useCallback(() => {
      dispatch(
        setShowNewsletterRegisterDialog(!ui.show_newsletter_register_dialog)
      );
      dispatch(setBlur(!ui.is_blur));
      dispatch(setHeaderMenuOpen(false));
    }, []);

    return (
      <Button secondary text onClick={(evt) => openNewsletterDialog()}>
        <span className="label">{t("button.waiting_list")}</span>
      </Button>
    );
  };

  const ProPlanButton = () => {
    const openNewsletterDialog = useCallback(() => {
      dispatch(
        setShowNewsletterRegisterDialog(!ui.show_newsletter_register_dialog)
      );
      dispatch(setBlur(!ui.is_blur));
      dispatch(setHeaderMenuOpen(false));
    }, []);

    return (
      <Button secondary text onClick={(evt) => openNewsletterDialog()}>
        <span className="label">{t("button.waiting_list")}</span>
      </Button>
    );
  };

  const BusinessPlanButton = () => {
    const openContactDialog = () => {
      dispatch(setShowContactDialog(!ui.show_contact_dialog));
      dispatch(setBlur(!ui.is_blur));
    };

    return (
      <Button secondary text onClick={(evt) => openContactDialog()}>
        <span className="label">{t("button.contact")}</span>
      </Button>
    );
  };

  const planContent = (plan) => {
    return (
      <div
        className="plan-card__container__body__list"
        dangerouslySetInnerHTML={{
          __html: t(`plans.${plan}.content`, {
            interpolation: { escapeValue: true },
          }),
        }}
      ></div>
    );
  };

  return (
    <div ref={plansRef} id="plans" className="plans__section">
      <div className="plans__section__header">
        <div className="plans__section__header__content">
          <div className="plans__section__header__subtitle">
            <span>{t("landing.body.plans_section.subtitle")}</span>
          </div>
          <div className="plans__section__header__title">
            <h2>{t("landing.body.plans_section.title")}</h2>
          </div>
          <div
            className="plans__section__header__description"
            dangerouslySetInnerHTML={{
              __html: t("landing.body.plans_section.description", {
                interpolation: { escapeValue: true },
              }),
            }}
          ></div>
        </div>
      </div>
      <div className="plans__section__body">
        <div className="plans__section__body__content">
          <PlanCard
            plan="free"
            icon={planIcon("free")}
            title={planTitle("free")}
            button={FreePlanButton()}
            content={planContent("free")}
          />
          <PlanCard
            plan="pro"
            icon={planIcon("pro")}
            title={planTitle("pro")}
            button={ProPlanButton()}
            content={planContent("pro")}
          />
          <PlanCard
            plan="business"
            icon={planIcon("business")}
            title={planTitle("business")}
            button={BusinessPlanButton()}
            content={planContent("business")}
          />
        </div>
      </div>
      <div className="plans__section__footer"></div>
    </div>
  );
}

export default PlansSection;
