import { UPLOADER_EVENTS } from "@rpldy/uploader";
import { FILE_STATES } from "@rpldy/uploady";
import store from "../../../redux/store";
import {
  addFileAction,
  setFooterMenuCollapseAction,
  setGlobalProgressAction,
  setGlobalStatusAction,
  setGlobalSizeAction,
  setUploadStatusAction,
  updateFileAction,
} from "./actions";
import { GLOBAL_STATUS, START_WITH_FILE, STATUS_UPLOADER } from "./config";
import { CHUNK_EVENTS } from "@rpldy/chunked-sender";
import { isEmpty, isEmptyList } from "../../../utils/validators";
import { logDebug } from "../../../utils/logger";

/**
 * Batch Abort State handler
 *
 * @param {Batch} batch https://react-uploady.org/docs/api/entities/#batch
 * @param {CreateOptions} options https://react-uploady.org/docs/api/types/#createoptions
 *
 * @see https://react-uploady.org/docs/api/events/#batchAbort
 */
const batchAbortHandler = (batch, options) => {
  logDebug("listeners", "batchAbortHandler", {
    batch: { ...batch },
    options: { ...options },
  });

  // Uploading global uploader state
  store.dispatch(setGlobalProgressAction(batch.completed));
};

/**
 * Batch Add State handler
 *
 * @param {Batch} batch https://react-uploady.org/docs/api/entities/#batch
 * @param {CreateOptions} options https://react-uploady.org/docs/api/types/#createoptions
 *
 * @see https://react-uploady.org/docs/api/events/#batchAdd
 */
const batchAddHandler = (batch, options) => {
  logDebug("listeners", "batchAddHandler", {
    batch: { ...batch },
    options: { ...options },
  });
  Array.from(batch.items).forEach((item) =>
    store.dispatch(addFileAction(item))
  );

  // Updating global uploader state
  store.dispatch(setGlobalProgressAction(batch.completed));
};

/**
 * Batch Cancel State handler
 *
 * @param {Batch} batch https://react-uploady.org/docs/api/entities/#batch
 * @param {CreateOptions} options https://react-uploady.org/docs/api/types/#createoptions
 *
 * @see https://react-uploady.org/docs/api/events/#batchCancel
 */
const batchCancelHandler = (batch, options) => {
  logDebug("listeners", "batchCancelHandler", {
    batch: { ...batch },
    options: { ...options },
  });

  // Updating global uploader state
  store.dispatch(setGlobalProgressAction(batch.completed));
};

/**
 * Batch Error State handler
 *
 * @param {Batch} batch https://react-uploady.org/docs/api/entities/#batch
 * @param {CreateOptions} options https://react-uploady.org/docs/api/types/#createoptions
 *
 * @see https://react-uploady.org/docs/api/events/#batchError
 */
const batchErrorHandler = (batch, options) => {
  logDebug("listeners", "batchErrorHandler", {
    batch: { ...batch },
    options: { ...options },
  });

  // Updating global uploader state
  store.dispatch(setGlobalProgressAction(batch.completed));
};

/**
 * Batch Finalize State handler
 *
 * @param {Batch} batch https://react-uploady.org/docs/api/entities/#batch
 * @param {CreateOptions} options https://react-uploady.org/docs/api/types/#createoptions
 *
 * @see https://react-uploady.org/docs/api/events/#batchFinalize
 */
const batchFinalizeHandler = (batch, options) => {
  logDebug("listeners", "batchFinalizeHandler", {
    batch: { ...batch },
    options: { ...options },
  });

  // Updating global uploader state
  const state = store.getState();
  const uploader = state.uploader.uploader;
  const files = Array.from(uploader.files);

  if (!isEmptyList(files)) {
    const isSomeUploading = files.some((file) =>
      [FILE_STATES.PENDING, FILE_STATES.ADDED, FILE_STATES.UPLOADING].includes(
        file.state
      )
    );
    const isSomeFinished = files.some((file) =>
      [FILE_STATES.FINISHED].includes(file.state)
    );
    const globalSize = files
      .filter((file) => [FILE_STATES.FINISHED].includes(file.state))
      .reduce((sum, file) => sum + file.file.size, 0);
    if (isSomeUploading) {
      store.dispatch(setGlobalProgressAction(batch.completed));
      store.dispatch(setGlobalStatusAction(GLOBAL_STATUS.UPLOADING));
      store.dispatch(setGlobalSizeAction(globalSize));
    } else if (isSomeFinished) {
      store.dispatch(setGlobalProgressAction(100));
      store.dispatch(setGlobalStatusAction(GLOBAL_STATUS.SUCCESS));
      store.dispatch(setGlobalSizeAction(globalSize));
    } else {
      store.dispatch(setGlobalProgressAction(0));
      store.dispatch(setGlobalStatusAction(GLOBAL_STATUS.INIT));
      store.dispatch(setGlobalSizeAction(globalSize));
    }
  } else {
    store.dispatch(setGlobalProgressAction(0));
    store.dispatch(setGlobalStatusAction(GLOBAL_STATUS.INIT));
    store.dispatch(setGlobalSizeAction(0));
  }
};

/**
 * Batch Finish State handler
 *
 * @param {Batch} batch https://react-uploady.org/docs/api/entities/#batch
 * @param {CreateOptions} options https://react-uploady.org/docs/api/types/#createoptions
 *
 * @see https://react-uploady.org/docs/api/events/#batchFinish
 */
const batchFinishHandler = (batch, options) => {
  logDebug("listeners", "batchFinishHandler", {
    batch: { ...batch },
    options: { ...options },
  });

  // Updating global uploader state
  store.dispatch(setGlobalProgressAction(batch.completed));
};

/**
 * Batch Progress State handler
 *
 * @param {Batch} batch https://react-uploady.org/docs/api/entities/#batch
 * @param {CreateOptions} options https://react-uploady.org/docs/api/types/#createoptions
 *
 * @see https://react-uploady.org/docs/api/events/#batchProgress
 */
const batchProgressHandler = (batch, options) => {
  logDebug("listeners", "batchProgressHandler", {
    batch: { ...batch },
    options: { ...options },
  });

  // Updating global uploader state
  store.dispatch(setGlobalProgressAction(batch.completed));
};

/**
 * Batch Start State handler
 *
 * @param {Batch} batch https://react-uploady.org/docs/api/entities/#batch
 * @param {CreateOptions} options https://react-uploady.org/docs/api/types/#createoptions
 *
 * @see https://react-uploady.org/docs/api/events/#batchStart
 */
const batchStartHandler = (batch, options) => {
  logDebug("listeners", "batchStartHandler", {
    batch: { ...batch },
    options: { ...options },
  });

  // Updating global uploader state
  const state = store.getState();
  const uploader = state.uploader.uploader;
  if (uploader.uploadStatus === STATUS_UPLOADER.DRAG) {
    store.dispatch(setUploadStatusAction(STATUS_UPLOADER.FORM));
    store.dispatch(setFooterMenuCollapseAction(true));
  }
  store.dispatch(setGlobalStatusAction(GLOBAL_STATUS.UPLOADING));
  store.dispatch(setGlobalProgressAction(batch.completed));
};

/**
 * ItemBatch Abort State Handler
 *
 * @param {BatchItem} item https://react-uploady.org/docs/api/entities/#batchitem
 *
 * @see https://react-uploady.org/docs/api/events/#itemAbort
 */
const itemAbortHandler = async (item) => {
  logDebug("listeners", "itemAbortHandler", item);

  // Updating item state
  store.dispatch(updateFileAction(item));
};

/**
 * ItemBatch Cancel State Handler
 *
 * @param {BatchItem} item https://react-uploady.org/docs/api/entities/#batchitem
 *
 * @see https://react-uploady.org/docs/api/events/#itemCancel
 */
const itemCancelHandler = (item) => {
  logDebug("listeners", "itemCancelHandler", item);

  // Updating item state
  store.dispatch(updateFileAction(item));
};

/**
 * ItemBatch Error State Handler
 *
 * @param {BatchItem} item https://react-uploady.org/docs/api/entities/#batchitem
 *
 * @see https://react-uploady.org/docs/api/events/#itemError
 */
const itemErrorHandler = (item) => {
  logDebug("listeners", "itemErrorHandler", item);

  // Updating item state
  store.dispatch(updateFileAction(item));
};

/**
 * ItemBatch Finalize State Handler
 *d
 * @param {BatchItem} item https://react-uploady.org/docs/api/entities/#batchitem
 *
 * @see https://react-uploady.org/docs/api/events/#itemFinalize
 */
const itemFinalizeHandler = (item) => {
  logDebug("listeners", "itemFinalizeHandler", item);

  // Updating item state
  store.dispatch(updateFileAction(item));
};

/**
 * ItemBatch Finish State Handler
 *
 * @param {BatchItem} item https://react-uploady.org/docs/api/entities/#batchitem
 *
 * @see https://react-uploady.org/docs/api/events/#itemFinish
 */
const itemFinishHandler = (item) => {
  logDebug("listeners", "itemFinishHandler", item);
  if (
    !isEmpty(item.uploadResponse) &&
    !isEmptyList(item.uploadResponse.results)
  ) {
    const length = item.uploadResponse.results.length;
    const firstResult = item.uploadResponse.results[length - 1];
    const data = JSON.parse(firstResult.data);
    item.file.serverName = !data.files[0].name.startsWith(START_WITH_FILE)
      ? `${START_WITH_FILE}${data.files[0].name}`
      : data.files[0].name;
  }

  // Updating item state
  store.dispatch(updateFileAction(item));

  // Updating global uploader state
  const state = store.getState();
  const uploader = state.uploader.uploader;
  store.dispatch(setGlobalSizeAction(uploader.globalSize + item.file.size));
};

/**
 * ItemBatch Finish State Handler
 *
 * @param {BatchItem} item https://react-uploady.org/docs/api/entities/#batchitem
 *
 * @see https://react-uploady.org/docs/api/events/#itemProgress
 */
const itemProgressHandler = (item) => {
  logDebug("listeners", "itemProgressHandler", item);

  // Updating item state
  store.dispatch(updateFileAction(item));
};

/**
 * ItemBatch Finish State Handler
 *
 * @param {BatchItem} item https://react-uploady.org/docs/api/entities/#batchitem
 *
 * @see https://react-uploady.org/docs/api/events/#itemStart
 */
const itemStartHandler = async (item) => {
  logDebug("listeners", "itemStartHandler", item);

  // Updating item state
  // store.dispatch(updateFileAction(item));
};

/**
 * Chunk Start Event Handler
 *
 * @param {ChunkStartEventData} chunkData
 * @see https://react-uploady.org/docs/api/events/#chunkStart
 */
const chunkStartHandler = (chunkData) => {
  logDebug("listeners", "chunkStartHandler", chunkData);
};

/**
 * Chunk Finish Event Handler
 *
 * @param {ChunkFinishEventData} chunkData
 * @see https://react-uploady.org/docs/api/events/#chunkFinish
 */
const chunkFinishHandler = (chunkData) => {
  logDebug("listeners", "chunkFinishHandler", chunkData);
};

/**
 * Request Pre Send Handler
 *
 * @param {BatchItem[]} items https://react-uploady.org/docs/api/entities/#batchitem
 * @param {CreateOptions} options https://react-uploady.org/docs/api/types/#createoptions
 *
 * @see https://react-uploady.org/docs/api/events/#requestPreSend
 */
const requestPreSendHandler = (items, options) => {
  logDebug("listeners", "requestPreSendHandler", {
    items: { ...items },
    options: { ...options },
  });
};

/**
 * All Abort State Handler
 *
 * @see https://react-uploady.org/docs/api/events/#allAbort
 */
const allAbortHandler = () => {
  logDebug("listeners", "allAbortHandler");
};

/**
 * Retry Event Handler
 *
 * @param {BatchItem[]} uploads https://react-uploady.org/docs/api/entities/#batchitem
 * @param {UploadOptions} options https://react-uploady.org/docs/api/hooks/useUploadOptions/
 * @see https://react-uploady.org/docs/api/events/#retryEvent
 */
const retryEventHandler = (uploads, options) => {
  logDebug("listeners", "retryEventHandler", {
    uploads: { ...uploads },
    options: { ...options },
  });
};

const listenerHandlers = {
  // Batch Event Listeners
  [UPLOADER_EVENTS.BATCH_ABORT]: batchAbortHandler,
  [UPLOADER_EVENTS.BATCH_ADD]: batchAddHandler,
  [UPLOADER_EVENTS.BATCH_CANCEL]: batchCancelHandler,
  [UPLOADER_EVENTS.BATCH_ERROR]: batchErrorHandler,
  [UPLOADER_EVENTS.BATCH_FINALIZE]: batchFinalizeHandler,
  [UPLOADER_EVENTS.BATCH_FINISH]: batchFinishHandler,
  [UPLOADER_EVENTS.BATCH_PROGRESS]: batchProgressHandler,
  [UPLOADER_EVENTS.BATCH_START]: batchStartHandler,
  // Item Event Listeners
  [UPLOADER_EVENTS.ITEM_ABORT]: itemAbortHandler,
  [UPLOADER_EVENTS.ITEM_CANCEL]: itemCancelHandler,
  [UPLOADER_EVENTS.ITEM_ERROR]: itemErrorHandler,
  [UPLOADER_EVENTS.ITEM_FINALIZE]: itemFinalizeHandler,
  [UPLOADER_EVENTS.ITEM_FINISH]: itemFinishHandler,
  [UPLOADER_EVENTS.ITEM_PROGRESS]: itemProgressHandler,
  [UPLOADER_EVENTS.ITEM_START]: itemStartHandler,
  // Other Event Listeners
  [UPLOADER_EVENTS.REQUEST_PRE_SEND]: requestPreSendHandler,
  [UPLOADER_EVENTS.ALL_ABORT]: allAbortHandler,
  [UPLOADER_EVENTS.RETRY_EVENT]: retryEventHandler,
  // Chunk Event Listeners
  [CHUNK_EVENTS.CHUNK_START]: chunkStartHandler,
  [CHUNK_EVENTS.CHUNK_FINISH]: chunkFinishHandler,
};

export default listenerHandlers;
