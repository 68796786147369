import { useEffect, useMemo } from "react";

import { useSelector } from "react-redux";

import { withOneTabEnforcer } from "react-one-tab-enforcer";

import Loading from "../loading";
import BlockUpload from "../block-upload";
import FormComponent from "./form-component";
import DetailComponent from "./detail-component";
import ErrorComponent from "./error-component";
import MaintenanceMode from "../maintenance-mode";
import AddFilesComponent from "./add-files-component";

import { STATUS_UPLOADER } from "./utils/config";
import { SERVER_STATUS } from "../../hooks/useInit";
import { equalsIgnoringCase, isEmpty } from "../../utils/validators";

function UploaderComponent() {
  const { uploader } = useSelector((state) => state.uploader);
  const { settings } = useSelector((state) => state.settings);

  useEffect(() => {
    const headerAboutLink = document.getElementById("header-about-link");
    if (!isEmpty(headerAboutLink)) {
      headerAboutLink.classList.toggle("active", false);
    }
    const footerAboutLink = document.getElementById("footer-about-link");
    if (!isEmpty(footerAboutLink)) {
      footerAboutLink.classList.toggle("active", false);
    }
    const headerPlansLink = document.getElementById("header-plans-link");
    if (!isEmpty(headerPlansLink)) {
      headerPlansLink.classList.toggle("active", false);
    }
    const footerPlansLink = document.getElementById("footer-plans-link");
    if (!isEmpty(footerPlansLink)) {
      footerPlansLink.classList.toggle("active", false);
    }
  }, []);

  const isOnlineMode = useMemo(
    () =>
      !isEmpty(settings) && !isEmpty(settings.status)
        ? equalsIgnoringCase(settings.status, SERVER_STATUS.ONLINE)
        : true,
    [settings]
  );

  const isDragStatus = useMemo(
    () =>
      !isEmpty(uploader) && !isEmpty(uploader.uploadStatus)
        ? equalsIgnoringCase(uploader.uploadStatus, STATUS_UPLOADER.DRAG)
        : false,
    [uploader]
  );

  const isFormStatus = useMemo(
    () =>
      !isEmpty(uploader) && !isEmpty(uploader.uploadStatus)
        ? equalsIgnoringCase(uploader.uploadStatus, STATUS_UPLOADER.FORM)
        : false,
    [uploader]
  );

  const isDetailStatus = useMemo(
    () =>
      !isEmpty(uploader) && !isEmpty(uploader.uploadStatus)
        ? equalsIgnoringCase(uploader.uploadStatus, STATUS_UPLOADER.DETAIL)
        : false,
    [uploader]
  );

  const isErrorStatus = useMemo(
    () =>
      !isEmpty(uploader) && !isEmpty(uploader.uploadStatus)
        ? equalsIgnoringCase(uploader.uploadStatus, STATUS_UPLOADER.ERROR)
        : false,
    [uploader]
  );

  return (
    <div className="uploader-component">
      {isOnlineMode ? (
        <>
          {uploader.loading ? (
            <Loading />
          ) : (
            <>
              <div
                id="add-files-upload-component"
                style={{
                  display: isDragStatus ? "flex" : "none",
                }}
              >
                <AddFilesComponent />
              </div>
              <div
                id="form-upload-component"
                style={{
                  display: isFormStatus ? "flex" : "none",
                }}
              >
                <FormComponent />
              </div>
              <div
                id="detail-upload-component"
                style={{
                  display: isDetailStatus ? "flex" : "none",
                }}
              >
                <DetailComponent />
              </div>
              <div
                id="error-upload-component"
                style={{
                  display: isErrorStatus ? "flex" : "none",
                }}
              >
                <ErrorComponent />
              </div>
            </>
          )}
        </>
      ) : (
        <MaintenanceMode />
      )}
    </div>
  );
}

const config = {
  appName: "ydray-one-tab",
  OnlyOneTabComponent: BlockUpload,
};

export default withOneTabEnforcer(config)(UploaderComponent);
