import { useCallback, useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import useUpdateUser from "../hooks/useUpdateUser";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import useGetLocalePath from "../hooks/useGetLocalePath";

import { setUser } from "../redux/reducers/userReducer";

import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import getUpdateUserSchema from "../schemas/updateUserSchema";

import SEO from "../components/seo";
import Button from "../components/button";
import Divider from "../components/divider";
import TextField from "../components/text-field";
import PhoneField from "../components/phone-field";
import { SelectCityField, cities } from "../components/select-city-field";
import { SelectStateField, states } from "../components/select-state-field";
import {
  SelectCountryField,
  countries,
} from "../components/select-country-field";
import { errorToast, successToast } from "../components/toast";

import { logDebug, logError } from "../utils/logger";
import { equalsIgnoringCase, isEmpty, isEmptyList } from "../utils/validators";

function Personal() {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const { getLocalePath } = useGetLocalePath();

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { settings } = useSelector((state) => state.settings);

  const emptyOption = useMemo(() => {
    return {
      id: -1,
      name: "",
      label: "",
      value: "",
    };
  }, []);
  const [country, setCountry] = useState(emptyOption);
  const [state, setState] = useState(emptyOption);
  const [city, setCity] = useState(emptyOption);
  const updateUserSchema = getUpdateUserSchema(t);

  const {
    loading: loadingUpdateUser,
    data: dataUpdateUser,
    error: errorUpdateUser,
    updateUserHandler,
  } = useUpdateUser();

  const titleSEO = useMemo(() => {
    return `${t("app.name")} - ${t("personal.title")}`;
  }, [t]);

  const descriptionSEO = useMemo(() => {
    return t("personal.description");
  }, [t]);

  const urlSEO = useMemo(() => {
    const publicURL = process.env.REACT_APP_PUBLIC_URL;
    const prefsPath = getLocalePath(t("routes.private.prefs.index"));
    const personalPath = t("routes.private.prefs.personal");
    return `${publicURL}/${prefsPath}/${personalPath}`;
  }, [getLocalePath, t]);

  const altLinksSEO = useMemo(() => {
    const publicURL = process.env.REACT_APP_PUBLIC_URL;
    const result = i18n.languages.map((lng) => {
      return {
        lng: lng,
        url: `${publicURL}/${lng}/${t("routes.private.prefs.index", {
          lng: lng,
        })}/${t("routes.private.prefs.personal", {
          lng: lng,
        })}`,
      };
    });

    const defaultLng = result.find((url) => url.lng === "en");
    result.push({
      lng: "x-default",
      url: defaultLng.url,
    });

    return result;
  }, [i18n.languages, t]);

  const defaultCountry = useMemo(() => {
    if (
      isEmpty(country) &&
      (!isEmpty(user.country) || !isEmpty(settings.country))
    ) {
      if (!isEmptyList(countries)) {
        return countries
          .filter(
            (country) =>
              equalsIgnoringCase(user.country, country.name) ||
              equalsIgnoringCase(settings.country, country.iso2)
          )
          .map((country) => {
            const id = country.id;
            const name = country.name;
            const label =
              !isEmpty(country.translations) &&
              !isEmpty(country.translations[i18n.language])
                ? country.translations[i18n.language]
                : country.name;
            const value = country.iso2;
            return {
              id: id,
              name: name,
              label: label,
              value: value,
            };
          })[0];
      }
    }
    return null;
  }, [country, i18n.language, user.country, settings.country]);

  const defaultState = useMemo(() => {
    if (isEmpty(state) && !isEmpty(country) && !isEmpty(user.state)) {
      if (!isEmptyList(states)) {
        return states
          .filter(
            (state) =>
              state.country_id === country.id &&
              equalsIgnoringCase(state.name, user.state)
          )
          .map((state) => {
            const id = state.id;
            const name = state.name;
            const label = state.name;
            const value = state.state_code;
            return {
              id: id,
              name: name,
              label: label,
              value: value,
            };
          })[0];
      }
    }
    return null;
  }, [country, state, user.state]);

  const defaultCity = useMemo(() => {
    if (
      isEmpty(city) &&
      !isEmpty(country) &&
      !isEmpty(state) &&
      !isEmpty(user.state)
    ) {
      if (!isEmptyList(cities)) {
        return cities
          .filter(
            (city) =>
              city.country_id === country.id &&
              city.state_id === state.id &&
              equalsIgnoringCase(city.name, user.city)
          )
          .map((city) => {
            const id = city.id;
            const name = city.name;
            const label = city.name;
            const value = city.id;
            return {
              id: id,
              name: name,
              label: label,
              value: value,
            };
          })[0];
      }
    }
    return null;
  }, [country, state, city, user.state, user.city]);

  const defaultValues = useMemo(() => {
    return {
      // Account
      name: !isEmpty(user.name) ? user.name : "",
      surname: !isEmpty(user.surname) ? user.surname : "",
      username: !isEmpty(user.username) ? user.username : "",
      // Billing
      billing_name: !isEmpty(user.billing_name) ? user.billing_name : "",
      vat: !isEmpty(user.vat) ? user.vat : "",
      address: !isEmpty(user.address) ? user.address : "",
      zip_code: !isEmpty(user.zip_code) ? user.zip_code : "",
      city: defaultCity,
      state: defaultState,
      country: defaultCountry,
      phone: !isEmpty(user.phone) ? user.phone : "",
    };
  }, [user, defaultCountry, defaultState, defaultCity]);

  const {
    register,
    control,
    watch,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValues,
    mode: "all",
    criteriaMode: "all",
    resolver: yupResolver(updateUserSchema),
  });

  const onChangeCountryHandler = useCallback(
    (country, onChange) => {
      onChange(country);
      setValue("country", country);
      setCountry(country);
      setValue("state", emptyOption);
      setState(emptyOption);
      setValue("city", emptyOption);
      setCity(emptyOption);
    },
    [emptyOption, setValue]
  );

  const onChangeStateHandler = useCallback(
    (state, onChange) => {
      onChange(state);
      setValue("state", state);
      setState(state);
      setValue("city", emptyOption);
      setCity(emptyOption);
    },
    [emptyOption, setValue]
  );

  const onChangeCityHandler = useCallback(
    (city, onChange) => {
      onChange(city);
      setCity(city);
      setValue("city", city);
    },
    [setValue]
  );

  const onSubmit = useCallback(
    (data, evt) => {
      logDebug("Personal", "onSubmit", { data: data, evt: evt });
      updateUserHandler({
        // Account
        name: getValues("name"),
        surname: getValues("surname"),
        username: getValues("username"),
        // Billing
        billing_name: getValues("billing_name"),
        vat: getValues("vat"),
        address: getValues("address"),
        zip_code: getValues("zip_code"),
        country: getValues("country.name"),
        state: getValues("state.name"),
        city: getValues("city.name"),
        phone: getValues("phone"),
      });
    },
    [getValues, updateUserHandler]
  );

  const onError = useCallback(
    (errors, evt) => {
      logDebug("Personal", "onError", { errors: errors, evt: evt });
      errorToast(
        "onError",
        errors,
        t("errors.error_form", t("errors.default"))
      );
    },
    [t]
  );

  useEffect(() => {
    if (!user.logged_in) {
      navigate(getLocalePath(t("routes.common.home")));
    }
  });

  useEffect(() => {
    if (!isEmpty(defaultCountry) && defaultCountry.id > 0) {
      setCountry(defaultCountry);
      setValue("country", defaultCountry);
    }
    if (!isEmpty(defaultState) && defaultState.id > 0) {
      setState(defaultState);
      setValue("state", defaultState);
    }
    if (!isEmpty(defaultCity) && defaultCity.id > 0) {
      setCity(defaultCity);
      setValue("city", defaultCity);
    }
  }, [defaultCountry, defaultState, defaultCity, setValue]);

  useEffect(() => {
    if (!loadingUpdateUser) {
      if (isEmpty(errorUpdateUser)) {
        if (!isEmpty(dataUpdateUser)) {
          logDebug("Personal", "useUpdateUser", {
            data: dataUpdateUser,
          });
          successToast("Personal", "useUpdateUser", { data: dataUpdateUser });
          dispatch(setUser(dataUpdateUser));
        }
      } else {
        logError("Personal", "useUpdateUser", {
          error: errorUpdateUser,
          msg: t(`errors.${errorUpdateUser}`, t("errors.default")),
        });
        errorToast(
          "Personal",
          errorUpdateUser,
          t(`errors.${errorUpdateUser}`, t("errors.default"))
        );
      }
    }
  }, [loadingUpdateUser, dataUpdateUser, errorUpdateUser, dispatch, t]);

  return (
    <>
      <SEO
        title={titleSEO}
        description={descriptionSEO}
        url={urlSEO}
        canonical={urlSEO}
        altLinks={altLinksSEO}
      />
      <div className="personal-page__container">
        <div className="personal-page__content">
          <div className="personal-page__title">
            <Link to={t("routes.common.go_back")}>
              <div className="link-container">
                <span className="icon icon-nav-arrow-left"></span>
                <span className="label small-title">{t("personal.title")}</span>
              </div>
            </Link>
            <Divider />
          </div>
          <div className="personal-container">
            <div className="personal-content">
              <form
                id="update_personal_form"
                className="personal-form"
                onSubmit={handleSubmit(onSubmit, onError)}
              >
                <div className="personal-block user">
                  <div className="personal-block-title">
                    {t("personal.form.user_title")}
                  </div>
                  <div className="personal-block-content">
                    <TextField
                      label={t("personal.form.name")}
                      value={watch("name")}
                      register={register("name", {
                        ...updateUserSchema.name,
                        onChange: (evt) => setValue("name", evt.target.value),
                        onBlur: (evt) => setValue("name", evt.target.value),
                      })}
                      disabled={loadingUpdateUser}
                      error={errors?.name?.message}
                      required
                    />
                    <TextField
                      label={t("personal.form.surname")}
                      value={watch("surname")}
                      register={register("surname", {
                        ...updateUserSchema.surname,
                        onChange: (evt) =>
                          setValue("surname", evt.target.value),
                        onBlur: (evt) => setValue("surname", evt.target.value),
                      })}
                      disabled={loadingUpdateUser}
                      error={errors?.surname?.message}
                      required
                    />
                    <TextField
                      label={t("personal.form.username")}
                      value={watch("username")}
                      register={register("username", {
                        ...updateUserSchema.username,
                        onChange: (evt) =>
                          setValue("username", evt.target.value),
                        onBlur: (evt) => setValue("username", evt.target.value),
                      })}
                      disabled={loadingUpdateUser}
                      error={errors?.username?.message}
                    />
                  </div>
                </div>
                <Divider />
                <div className="personal-block address">
                  <div className="personal-block-title">
                    {t("personal.form.address_title")}
                  </div>
                  <div className="personal-block-content">
                    <div className="field billing-name">
                      <TextField
                        label={t("personal.form.billing_name")}
                        value={watch("billing_name")}
                        register={register("billing_name", {
                          ...updateUserSchema.address,
                          onChange: (evt) =>
                            setValue("billing_name", evt.target.value),
                          onBlur: (evt) =>
                            setValue("billing_name", evt.target.value),
                        })}
                        error={errors?.billing_name?.message}
                      />
                    </div>
                    <div className="field vat">
                      <TextField
                        label={t("personal.form.vat")}
                        value={watch("vat")}
                        register={register("vat", {
                          ...updateUserSchema.vat,
                          onChange: (evt) => {
                            setValue("vat", evt.target.value);
                          },
                          onBlur: (evt) => {
                            setValue("vat", evt.target.value);
                          },
                        })}
                        error={errors?.vat?.message}
                      />
                    </div>
                    <div className="field address">
                      <TextField
                        label={t("personal.form.address")}
                        value={watch("address")}
                        register={register("address", {
                          ...updateUserSchema.address,
                          onChange: (evt) =>
                            setValue("address", evt.target.value),
                          onBlur: (evt) =>
                            setValue("address", evt.target.value),
                        })}
                        disabled={loadingUpdateUser}
                        error={errors?.address?.message}
                        required
                      />
                    </div>
                    <div className="field zipcode">
                      <TextField
                        label={t("personal.form.zip_code")}
                        value={watch("zip_code")}
                        register={register("zip_code", {
                          ...updateUserSchema.zip_code,
                          onChange: (evt) =>
                            setValue("zip_code", evt.target.value),
                          onBlur: (evt) =>
                            setValue("zip_code", evt.target.value),
                        })}
                        disabled={loadingUpdateUser}
                        error={errors?.zip_code?.message}
                        required
                      />
                    </div>
                    <div className="field country">
                      <Controller
                        name="country"
                        control={control}
                        render={({ field: { onChange, ref } }) => (
                          <SelectCountryField
                            placeHolder={t("personal.form.country")}
                            value={watch("country")}
                            onChange={(country) =>
                              onChangeCountryHandler(country, onChange)
                            }
                            disabled={loadingUpdateUser}
                            error={errors?.country?.message}
                            required
                          />
                        )}
                      />
                    </div>
                    <div className="field state">
                      <Controller
                        name="state"
                        control={control}
                        render={({ field: { onChange, ref } }) => (
                          <SelectStateField
                            placeHolder={t("personal.form.state")}
                            country_id={country?.id}
                            value={watch("state")}
                            disabled={loadingUpdateUser}
                            onChange={(state) =>
                              onChangeStateHandler(state, onChange)
                            }
                            required
                            error={errors?.state?.message}
                          />
                        )}
                      />
                    </div>
                    <div className="field city">
                      <Controller
                        name="city"
                        control={control}
                        render={({ field: { onChange, ref } }) => (
                          <SelectCityField
                            placeHolder={t("personal.form.city")}
                            country_id={country?.id}
                            state_id={state?.id}
                            value={watch("city")}
                            onChange={(city) =>
                              onChangeCityHandler(city, onChange)
                            }
                            disabled={loadingUpdateUser}
                            error={errors?.city?.message}
                            required
                          />
                        )}
                      />
                    </div>
                    <div className="field phone">
                      <Controller
                        name="phone"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <PhoneField
                            label={t("personal.form.phone")}
                            value={watch("phone")}
                            disabled={loadingUpdateUser}
                            error={errors?.phone?.message}
                            onChangeHandler={(phone) => {
                              setValue("phone", phone);
                            }}
                            {...field}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="personal-block-button">
                  <Button
                    type="submit"
                    primary
                    text
                    loading={loadingUpdateUser}
                  >
                    <span className="label">{t("button.save")}</span>
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Personal;
