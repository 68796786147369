import { useCallback, useMemo, useState } from "react";

const useUserLogout = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const url = useMemo(() => {
    return `https://${process.env.REACT_APP_API}/user/logout/`;
  }, []);

  const logoutHandler = useCallback(() => {
    try {
      fetch(url, {
        method: "POST",
        mode: "cors",
        credentials: "include",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setError(data.error);
          delete data.error;
          setData(data);
        })
        .catch((error) => setError(error))
        .finally(() => setLoading(false));
    } catch (error) {
      setError(error);
    }
  }, [url]);

  return { loading, data, error, logoutHandler };
};

export default useUserLogout;
