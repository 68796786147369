import { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import {
  setBlur,
  setShowNewsletterRegisterDialog,
  setShowNewsletterUnregisterDialog,
} from "../../redux/reducers/uiReducer";
import NewsletterRegForm from "./newsletter-reg-form";
import NewsletterUnregForm from "./newsletter-unreg-form";
import ReCAPTCHA from "react-google-recaptcha";
import { useLocation, useNavigate } from "react-router-dom";
import { equalsIgnoringCase } from "../../utils/validators";
import useGetLocalePath from "../../hooks/useGetLocalePath";

function NewsletterDialog() {
  const { t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();
  const { getLocalePath } = useGetLocalePath();

  const dispatch = useDispatch();

  const { ui } = useSelector((state) => state);

  const recaptchaRef = useRef(null);
  const [loaded, setLoaded] = useState(false);
  const asyncScriptOnLoad = useCallback((data) => {
    setLoaded(data.loaded);
  }, []);

  const dialogNewsletterRef = useRef(null);
  useOnClickOutside(dialogNewsletterRef, () => closeNewsletterDialog());

  const title = useMemo(() => {
    return ui.show_newsletter_register_dialog
      ? t("reg_newsletter_dialog.title")
      : t("unreg_newsletter_dialog.title");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const subtitle = useMemo(() => {
    return ui.show_newsletter_register_dialog
      ? t("reg_newsletter_dialog.subtitle")
      : t("unreg_newsletter_dialog.subtitle");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeNewsletterDialog = () => {
    if (ui.show_newsletter_register_dialog) {
      dispatch(
        setShowNewsletterRegisterDialog(!ui.show_newsletter_register_dialog)
      );
    } else {
      dispatch(
        setShowNewsletterUnregisterDialog(!ui.show_newsletter_unregister_dialog)
      );
    }
    dispatch(setBlur(!ui.is_blur));
    if (
      equalsIgnoringCase(
        location.pathname,
        getLocalePath(t("routes.public.newsletter_reg"))
      ) ||
      equalsIgnoringCase(
        location.pathname,
        getLocalePath(t("routes.public.newsletter_unreg"))
      )
    ) {
      navigate(getLocalePath(t("routes.common.home")));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  return (
    <div>
      <div className="wrapper"></div>
      <div ref={dialogNewsletterRef} className="newsletter__dialog">
        <div className="newsletter__dialog__header">
          <span className="newsletter__dialog__header__logo"></span>
          <div className="newsletter__dialog__header__title header-3">
            {title}
          </div>
          <div className="newsletter__dialog__header__subtitle text-body">
            {subtitle}
          </div>
        </div>
        <div className="newsletter__dialog__body">
          {ui.show_newsletter_register_dialog && (
            <NewsletterRegForm
              recaptcha={{ ref: recaptchaRef.current, loaded: loaded }}
            />
          )}
          {!ui.show_newsletter_register_dialog && (
            <NewsletterUnregForm
              recaptcha={{ ref: recaptchaRef.current, loaded: loaded }}
            />
          )}
        </div>
        <div className="newsletter__dialog__footer"></div>
      </div>
      <ReCAPTCHA
        ref={recaptchaRef}
        className="newsletter-reCAPTCHA"
        sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
        badge="bottomright"
        size="invisible"
        asyncScriptOnLoad={(data) => asyncScriptOnLoad(data)}
      />
    </div>
  );
}

export default NewsletterDialog;
