import classNames from "classnames";

function SpeechBallon({ msg, width, height, pointerDirection = "bottom" }) {
  return (
    <div
      className={classNames("speech-ballon__container", {
        [pointerDirection]: pointerDirection,
      })}
      style={{ width: width, height: height }}
    >
      <div
        className="speech-ballon__content"
        dangerouslySetInnerHTML={{
          __html: msg,
        }}
      ></div>
    </div>
  );
}

export default SpeechBallon;
