import { useCallback, useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import useGetTransfer from "../../hooks/useGetTransfer";
import useGetTransferPwd from "../../hooks/useGetTransferPwd";

import SEO from "../seo";
import Loading from "../loading";
import DownloaderShareForm from "./downloader-form";
import DownloaderShareList from "./downloader-list";
import DownloaderExpired from "./downloader-expired";
import { errorToast, warnToast } from "../toast";
import Divider from "../divider";
import AdsComponent from "../ads-component";

import { logDebug } from "../../utils/logger";
import {
  equalsIgnoringCase,
  isEmpty,
  isEmptyList,
} from "../../utils/validators";

function DownloaderComponent(props) {
  const { transferId } = props;

  const { t } = useTranslation();

  const [files, setFiles] = useState([]);
  const [password, setPassword] = useState("");
  const [isExpired, setIsExpired] = useState(false);
  const [isProtected, setIsProtected] = useState(false);

  const titleSEO = useMemo(() => {
    return !isExpired
      ? `${t("app.name")} - ${t("downloader.title")}`
      : `${t("app.name")} - ${t("downloader_expired.title")}`;
  }, [isExpired, t]);

  const descriptionSEO = useMemo(() => {
    return !isExpired
      ? `${t("app.name")} - ${t("downloader.title")}`
      : `${t("app.name")} - ${t("downloader_expired.message")}`;
  }, [isExpired, t]);

  const titleHeader = useMemo(() => {
    if (isProtected) {
      return t("downloader.title");
    } else if (!isProtected && !isEmptyList(files)) {
      return !isEmpty(files[0].title) ? files[0].title : t("downloader.title");
    }
  }, [isProtected, files, t]);

  const subtitleHeader = useMemo(() => {
    if (isProtected) {
      return t("downloader.subtitle");
    } else if (!isProtected && !isEmptyList(files)) {
      return !isEmpty(files[0].message) ? files[0].message : "";
    }
  }, [isProtected, files, t]);

  const {
    loading: loadingGetTransfer,
    data: dataGetTransfer,
    error: errorGetTransfer,
    getTransferHandler,
  } = useGetTransfer();

  const {
    loading: loadingGetTransferPwd,
    data: dataGetTransferPwd,
    error: errorGetTransferPwd,
    getTransferPwdHandler,
  } = useGetTransferPwd();

  useEffect(() => {
    if (!loadingGetTransfer) {
      if (isEmpty(errorGetTransfer)) {
        if (!isEmpty(dataGetTransfer)) {
          setFiles(Array.from(dataGetTransfer));
        }
      } else if (equalsIgnoringCase("password_protected", errorGetTransfer)) {
        setIsProtected(true);
        setFiles([]);
        logDebug("DownloaderComponent", "useGetTransfer", {
          error: errorGetTransfer,
        });
        warnToast(
          "DownloaderComponent.useGetTransfer",
          "password_protected",
          t(`errors.password_protected`, t("errors.default"))
        );
      } else {
        setIsExpired(true);
        setFiles([]);
        logDebug("DownloaderComponent", "useGetTransfer", {
          error: errorGetTransfer,
        });
      }
    }
  }, [loadingGetTransfer, dataGetTransfer, errorGetTransfer, t]);

  useEffect(() => {
    if (!loadingGetTransferPwd) {
      if (isEmpty(errorGetTransferPwd)) {
        if (!isEmpty(dataGetTransferPwd)) {
          setIsProtected(false);
          setIsExpired(false);
          setFiles(Array.from(dataGetTransferPwd));
        }
      } else {
        if (equalsIgnoringCase(errorGetTransferPwd, "expired")) {
          setIsExpired(true);
        } else if (equalsIgnoringCase(errorGetTransferPwd, "wrong_pass")) {
          setIsExpired(false);
        } else {
          setIsExpired(false);
        }
        setFiles([]);
        logDebug("DownloaderComponent", "getTransferPwdHandler", {
          data: dataGetTransferPwd,
          error: errorGetTransferPwd,
        });
        errorToast(
          "DownloaderComponent.getTransferPwdHandler",
          errorGetTransferPwd,
          t(`errors.${errorGetTransferPwd}`, t("errors.default"))
        );
      }
    }
  }, [loadingGetTransferPwd, dataGetTransferPwd, errorGetTransferPwd, t]);

  const onChangePasswordHandler = useCallback((evt) => {
    logDebug("DownloaderComponent.DownloaderBody", "onChangePasswordHandler", {
      evt: evt,
    });
    setPassword(evt.target.value);
  }, []);

  const onSubmitHandler = useCallback(
    (evt) => {
      logDebug("DownloaderComponent.DownloaderBody", "onSubmitHandler", {
        data: {
          password: password,
          transferid: transferId,
        },
        evt: evt,
      });
      if (!isEmpty(password) && !isEmpty(transferId)) {
        getTransferPwdHandler({
          password: password,
          transferid: transferId,
        });
      }
    },
    [password, transferId, getTransferPwdHandler]
  );

  useEffect(() => {
    if (!isEmpty(transferId)) {
      getTransferHandler(transferId);
    }
  }, [transferId, getTransferHandler]);

  return (
    <>
      <SEO title={titleSEO} description={descriptionSEO} />
      <div className="downloader-component__container">
        {loadingGetTransfer ? (
          <Loading />
        ) : (
          <>
            {isExpired ? (
              <DownloaderExpired />
            ) : (
              <div className="downloader-component">
                <div className="downloader-component__header">
                  <span className="icon-title icon-cloud-download"></span>
                  <div className="title-container">
                    <span className="text-title" title={titleHeader}>
                      {titleHeader}
                    </span>
                  </div>
                  {subtitleHeader && (
                    <span
                      className="text-subtitle"
                      dangerouslySetInnerHTML={{ __html: subtitleHeader }}
                    />
                  )}
                </div>
                <div className="downloader-component__body">
                  {isProtected ? (
                    <DownloaderShareForm
                      loading={loadingGetTransferPwd}
                      transferId={transferId}
                      password={password}
                      onChangePassword={(evt) => onChangePasswordHandler(evt)}
                      onSubmit={(evt) => onSubmitHandler(evt)}
                    />
                  ) : (
                    <DownloaderShareList
                      loading={loadingGetTransfer}
                      files={files}
                    />
                  )}
                </div>
                <div className="downloader-component__footer">
                  <Divider />
                  <AdsComponent
                    dataAdSlot={process.env.REACT_APP_G_AD_SLOT_DOWNLOADER}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default DownloaderComponent;
