import classNames from "classnames";

function ProgressBar({ type = "info", max = 100, value = 0 }) {
  return (
    <progress
      className={classNames("progress-bar", {
        info: type === "info",
        warning: type === "warning",
        success: type === "success",
        error: type === "error",
      })}
      max={max}
      value={value}
    ></progress>
  );
}

export default ProgressBar;
