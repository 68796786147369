import classNames from "classnames";
import { useMemo } from "react";
import { isEmpty } from "../../utils/validators";
import { useTranslation } from "react-i18next";

function SearchInput({
  name,
  placeholder,
  value,
  hidden,
  disabled,
  required,
  clearable,
  onBlur,
  onChange,
  onClear,
}) {
  const { t } = useTranslation();

  const pHolder = useMemo(() => {
    return !isEmpty(placeholder) ? placeholder : t("placeholder.search");
  }, [placeholder, t]);

  const title = useMemo(() => {
    return !isEmpty(value) ? value : pHolder;
  }, [pHolder, value]);

  const showClearable = useMemo(() => {
    return clearable && !isEmpty(value);
  }, [clearable, value]);

  return (
    <div
      className={classNames("search-input__container", {
        disabled: disabled,
        hidden: hidden,
      })}
    >
      <span className="search-input__icon icon-search"></span>
      <input
        id={name}
        type="text"
        role="search"
        className="search-input__input"
        name={name}
        placeholder={pHolder}
        value={value}
        title={title}
        disabled={disabled}
        hidden={hidden}
        required={required}
        onBlur={onBlur && ((evt) => onBlur(evt.target.value))}
        onChange={onChange && ((evt) => onChange(evt.target.value))}
      ></input>
      {showClearable && (
        <span
          className="search-input__icon icon-delete-circled-outline"
          onClick={onClear && (() => onClear(""))}
        ></span>
      )}
    </div>
  );
}

export default SearchInput;
