import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import InfiniteScroll from "react-infinite-scroll-component";
import LoadingPage from "../../../loading-page";
import Button from "../../../button";
import Table from "../../../table";

function BillingTable(props) {
  const { items, total, more, nextHandler } = props;

  const { t } = useTranslation();

  const RowActions = (props) => {
    return (
      <div className="actions">
        <Button secondary text onClick={(evt) => evt.preventDefault()}>
          <span className="small-title">{t("button.download")}</span>
        </Button>
      </div>
    );
  };

  const data = useMemo(() => items, [items]);

  const columns = useMemo(
    () => [
      {
        id: "date",
        accessor: "date",
        Header: () => t("bills.table.columns.date"),
        Cell: ({ value, row }) => (
          <span class="cell" title={value}>
            {value}
          </span>
        ),
        Footer: () => {},
      },
      {
        id: "plan",
        accessor: "plan",
        Header: () => t("bills.table.columns.plan"),
        Cell: ({ value, row }) => (
          <span class="cell" title={value}>
            {value}
          </span>
        ),
        Footer: () => {},
      },
      {
        id: "amount",
        accessor: "amount",
        Header: () => t("bills.table.columns.amount"),
        Cell: ({ value, row }) => (
          <span class="cell" title={value}>
            {value}
          </span>
        ),
        Footer: () => {},
      },
      {
        id: "status",
        accessor: "status",
        Header: () => t("bills.table.columns.status"),
        Cell: ({ value, row }) => (
          <span class="cell" title={value}>
            {value}
          </span>
        ),
        Footer: () => {},
      },
      {
        id: "actions",
        accessor: "actions",
        Header: () => t("bills.table.columns.actions"),
        Cell: ({ row }) => <RowActions row={row} />,
        Footer: () => {},
      },
    ],
    [t]
  );

  const dataLength = useMemo(() => {
    return data ? data.length : 0;
  }, [data]);

  const hasMore = useMemo(() => {
    return more && (data ? data.length < total : false);
  }, [more, data, total]);

  return (
    <InfiniteScroll
      scrollableTarget="scrollable-billing-table"
      dataLength={dataLength}
      hasMore={hasMore}
      next={nextHandler}
      loader={<LoadingPage />}
    >
      <Table data={data} columns={columns} withHeaders={true} />
    </InfiniteScroll>
  );
}

export default BillingTable;
