import classNames from "classnames";
import DropDown from "../dropdown";

function DropdownField(props) {
  const {
    name,
    label,
    placeHolder,
    options,
    value,
    onChange,
    loading,
    required,
    error,
    register,
  } = props;

  return (
    <div className="dropdown-field__container">
      <div className="dropdown-field__content">
        {label && (
          <label htmlFor={name} className="dropdown-field__label smaller">
            {`${label}${required ? " *" : ""}`}
          </label>
        )}
        <DropDown
          value={value}
          placeHolder={placeHolder}
          options={options}
          onChange={onChange}
          disabled={loading}
          required={required}
          register={register}
        />
      </div>
      <p className={classNames("dropdown-field__error", { show: error })}>
        {error}
      </p>
    </div>
  );
}

export default DropdownField;
