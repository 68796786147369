import { useTable, useExpanded } from "react-table";

import { t } from "i18next";

import { isEmptyList } from "../../utils/validators";

import Loading from "../loading";

function Table(props) {
  const { loading, data, columns, withHeaders, renderRowSubComponent } = props;

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        data,
        columns,
      },
      useExpanded
    );

  return (
    <>
      {loading ? (
        <div className="table__loading">
          <Loading />
        </div>
      ) : (
        <table className="table" {...getTableProps()}>
          {withHeaders && (
            <thead className="table__thead">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
          )}
          {!isEmptyList(rows) ? (
            <tbody className="table__tbody" {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <>
                    <tr className="table__tbody__tr" {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td
                            className="table__tbody__td"
                            {...cell.getCellProps()}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                    {row.isExpanded ? renderRowSubComponent({ row }) : null}
                  </>
                );
              })}
            </tbody>
          ) : (
            <span className="table__empty">{t("placeholder.empty_table")}</span>
          )}
        </table>
      )}
    </>
  );
}

export default Table;
