import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { TRANSLATIONS as es } from "./es";
import { TRANSLATIONS as en } from "./en";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: "PRODUCTION" !== process.env.REACT_APP_ENV,
    resources: {
      es: { translation: es },
      en: { translation: en },
    },
    lng: "en",
    fallbackLng: ["es", "en"],
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ["localStorage"],
      lookupLocalStorage: "lng",
    },
    returnObjects: true,
  });

export default i18n;
