import { useCallback, useMemo } from "react";

import classNames from "classnames";

import { useTranslation } from "react-i18next";

import Select from "react-select";

import countries from "./countries.json";
import {
  equalsIgnoringCase,
  isEmpty,
  isEmptyList,
} from "../../utils/validators";

function SelectCountryField(props) {
  const {
    name,
    label,
    placeHolder,
    loading,
    required,
    disabled,
    clearable,
    searchable,
    value,
    error,
    onChange,
    register,
  } = props;

  const { t, i18n } = useTranslation();

  const disabledMemo = useMemo(() => {
    return disabled;
  }, [disabled]);

  const options = useMemo(() => {
    let result = [];
    if (!isEmptyList(countries)) {
      result = countries
        .map((country) => {
          return {
            id: country.id,
            name: country.name,
            label:
              !isEmpty(country.translations) &&
              !isEmpty(country.translations[i18n.language])
                ? country.translations[i18n.language]
                : country.name,
            value: country.iso2,
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label));
    }
    return result;
  }, [i18n.language]);

  const onChangeHandler = useCallback(
    (evt) => {
      !disabledMemo && onChange && onChange(evt);
    },
    [disabledMemo, onChange]
  );

  const selectedOption = useMemo(() => {
    if (!isEmpty(value) && value.id > 0) {
      const idx = options.findIndex((c) =>
        equalsIgnoringCase(value.value, c.value)
      );
      return options[idx];
    }
    return null;
  }, [options, value]);

  return (
    <div className="select-country-field__container">
      <div className="select-country-field__content">
        {label && (
          <label htmlFor={name} className="select-country-field__label smaller">
            {`${label}${required ? " *" : ""}`}
          </label>
        )}
        <Select
          {...register}
          id={name}
          name={name}
          placeholder={placeHolder}
          noOptionsMessage={() => t("placeholder.empty_list")}
          className="select-country-field__input"
          classNamePrefix="select-country-field"
          options={options}
          value={selectedOption}
          isLoading={loading}
          isDisabled={disabledMemo}
          isClearable={clearable}
          isSearchable={searchable}
          maxMenuHeight={175}
          menuPlacement="auto"
          menuShouldScrollIntoView={true}
          onChange={(evt) => onChangeHandler(evt)}
        />
        <p
          className={classNames("select-country-field__error", { show: error })}
        >
          {error}
        </p>
      </div>
    </div>
  );
}

export default SelectCountryField;
export { SelectCountryField, countries };
