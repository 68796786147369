import { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import SEO from "../components/seo";
import LandingFooter from "../components/landing/landing-footer";
import useGetLocalePath from "../hooks/useGetLocalePath";

function Press() {
  const { t, i18n } = useTranslation();
  const { getLocalePath } = useGetLocalePath();

  const press_container_ref = useRef(null);

  useEffect(() => {
    press_container_ref?.current?.scrollIntoView({
      block: "start",
      inline: "start",
      behavior: "smooth",
    });
  }, []);

  const titleSEO = useMemo(() => {
    return `${t("app.name")} - ${t("press.title")}`;
  }, [t]);

  const descriptionSEO = useMemo(() => {
    return t("press.description");
  }, [t]);

  const urlSEO = useMemo(() => {
    return `${process.env.REACT_APP_PUBLIC_URL}${getLocalePath(
      t("routes.public.press")
    )}`;
  }, [getLocalePath, t]);

  const altLinksSEO = useMemo(() => {
    const publicURL = process.env.REACT_APP_PUBLIC_URL;
    const result = i18n.languages.map((lng) => {
      return {
        lng: lng,
        url: `${publicURL}/${lng}/${t("routes.public.press", { lng: lng })}`,
      };
    });

    const defaultLng = result.find((url) => url.lng === "en");
    result.push({
      lng: "x-default",
      url: defaultLng.url,
    });

    return result;
  }, [i18n.languages, t]);

  return (
    <div>
      <SEO
        title={titleSEO}
        description={descriptionSEO}
        url={urlSEO}
        canonical={urlSEO}
        altLinks={altLinksSEO}
      />
      <div ref={press_container_ref}>
        <div className="press__header"></div>
        <div className="press__body">
          <div
            className="press__content"
            dangerouslySetInnerHTML={{
              __html: t("press.content", {
                interpolation: { escapeValue: true },
              }),
            }}
          ></div>
        </div>
        <div className="press__footer">
          <LandingFooter />
        </div>
      </div>
    </div>
  );
}

export default Press;
