import { useMemo } from "react";
import classNames from "classnames";
import { isEmpty } from "../../utils/validators";

function TextAreaField(props) {
  const {
    label,
    value,
    placeholder,
    autoComplete,
    style,
    maxChars,
    disabled,
    hidden,
    required,
    error,
    register,
  } = props;
  const { name, ref } = register;

  const numChar = useMemo(() => {
    return !isEmpty(value) ? value.length : 0;
  }, [value]);

  const counterChar = useMemo(() => {
    return `${numChar}/${maxChars}`;
  }, [maxChars, numChar]);

  const titleMemo = useMemo(() => {
    return !isEmpty(placeholder) ? `${placeholder}` : "";
  }, [placeholder]);

  return (
    <div
      className={classNames("textarea-field__container", {
        disabled: disabled,
      })}
      title={titleMemo}
    >
      <div
        className={classNames("textarea-field__content", {
          disabled: disabled,
        })}
      >
        {label && (
          <label
            htmlFor={name}
            className={classNames("textarea-field__label", "smaller")}
          >
            {`${label}${required ? " *" : ""}`}
          </label>
        )}
        <textarea
          id={name}
          name={name}
          ref={ref}
          className={classNames("textarea-field__input", {
            disabled: disabled,
          })}
          style={style}
          hidden={hidden}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          autoComplete={autoComplete}
          {...register}
        />
        {!disabled && maxChars && (
          <p
            className={classNames("textarea-field__counter", {
              disabled: disabled,
            })}
          >
            {counterChar}
          </p>
        )}
      </div>
      <p className={classNames("textarea-field__error", { show: error })}>
        {error}
      </p>
    </div>
  );
}

export default TextAreaField;
