import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { errorToast, successToast } from "../../toast";
import {
  setBlur,
  setShowNewsletterUnregisterDialog,
} from "../../../redux/reducers/uiReducer";
import useUnregisterNewsletter from "../../../hooks/useUnregNewsletter";
import { equalsIgnoringCase, isEmpty } from "../../../utils/validators";
import { getNewsletterUnregSchema } from "../../../schemas/newsletterSchema";
import TextField from "../../text-field";
import Button from "../../button";
import { logDebug } from "../../../utils/logger";
import { useLocation, useNavigate } from "react-router-dom";
import useGetLocalePath from "../../../hooks/useGetLocalePath";

function NewsletterForm({ regNewsletter, recaptcha }) {
  const { t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();
  const { getLocalePath } = useGetLocalePath();

  const dispatch = useDispatch();

  const { ui } = useSelector((state) => state);
  const { settings } = useSelector((state) => state.settings);

  const token = useMemo(
    () =>
      !isEmpty(settings) && !isEmpty(settings.csrf_token)
        ? settings.csrf_token
        : "",
    [settings]
  );

  const newsletterUnregSchema = getNewsletterUnregSchema(t);
  const {
    register,
    control,
    watch,
    setValue,
    getValues,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      csrf_token: token,
      "g-recaptcha-response": "",
    },
    values: {
      email: "",
      csrf_token: token,
      "g-recaptcha-response": "",
    },
    mode: "all",
    criteriaMode: "all",
    resolver: yupResolver(newsletterUnregSchema),
  });

  const changeRecaptcha = useCallback(
    (token) => {
      setValue("g-recaptcha-response", token);
      recaptcha.ref.reset();
    },
    [recaptcha, setValue]
  );

  const resetRecaptcha = useCallback(() => {
    setValue("g-recaptcha-response", "");
    recaptcha.ref.reset();
  }, [recaptcha, setValue]);

  const { loading, data, error, newsletterUnregHandler } =
    useUnregisterNewsletter();
  useEffect(() => {
    if (!loading) {
      if (error) {
        errorToast(
          "useNewsletter",
          error,
          t(`errors.${error}`, t("errors.default"))
        );
      } else if (data) {
        reset({
          email: "",
          csrf_token: token,
          "g-recaptcha-response": "",
        });
        resetRecaptcha();
        successToast(
          "useNewsletter",
          data.result,
          t(`message.${data.result}`, t("message.default"))
        );
        dispatch(setBlur(!ui.is_blur));
        dispatch(
          setShowNewsletterUnregisterDialog(
            !ui.show_newsletter_unregister_dialog
          )
        );
        if (
          equalsIgnoringCase(
            location.pathname,
            getLocalePath(t("routes.public.newsletter_unreg"))
          )
        ) {
          navigate(getLocalePath(t("routes.common.home")));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, data, error]);

  const onSubmit = async (data, evt) => {
    if (recaptcha.loaded) {
      const token = !isEmpty(getValues("g-recaptcha-response"))
        ? getValues("g-recaptcha-response")
        : await recaptcha.ref.executeAsync();

      if (!isEmpty(token)) {
        recaptcha.ref.reset();
        setValue("g-recaptcha-response", token);
        logDebug("NewsletterForm", "onSubmit", { data: data, evt: evt });
        newsletterUnregHandler(getValues());
      }
    }
  };

  const onError = async (errors, evt) => {
    logDebug("NewsletterForm", "onError", { errors: errors, evt: evt });
    recaptcha.ref.reset();
    errorToast("onError", errors, t("errors.error_form", t("errors.default")));
  };

  return (
    <form
      id="newsletter_unregister_form"
      className="newsletter__form"
      onSubmit={handleSubmit(onSubmit, onError)}
    >
      <TextField
        label={t("unreg_newsletter_dialog.form.email")}
        autoComplete={"email"}
        value={watch("email")}
        register={register("email", {
          ...newsletterUnregSchema.email,
          onChange: (evt) => setValue("email", evt.target.value),
          onBlur: (evt) => setValue("email", evt.target.value),
        })}
        error={errors?.email?.message}
        disabled={loading}
        required
      />
      <Controller
        name="csrf_token"
        control={control}
        defaultValue={token}
        render={({ field }) => <input hidden {...field}></input>}
      />
      <Controller
        name="g-recaptcha-response"
        control={control}
        defaultValue=""
        onChange={(token) => changeRecaptcha(token)}
        onExpired={() => resetRecaptcha()}
        onError={() => resetRecaptcha()}
        render={({ field }) => {
          <input hidden value={getValues("g-recaptcha-response")} {...field} />;
        }}
      />
      <div className="login__form__footer">
        <Button type="submit" primary text loading={loading}>
          <span className="label">{t("button.send")}</span>
        </Button>
      </div>
    </form>
  );
}

export default NewsletterForm;
