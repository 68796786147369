import { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  setBlur,
  setContactReason,
  setShowContactDialog,
} from "../../redux/reducers/uiReducer";
import ContactForm from "../contact-form";
import ReCAPTCHA from "react-google-recaptcha";
import { useLocation, useNavigate } from "react-router-dom";
import { equalsIgnoringCase } from "../../utils/validators";
import useGetLocalePath from "../../hooks/useGetLocalePath";

function ContactDialog() {
  const { t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();
  const { getLocalePath } = useGetLocalePath();

  const dispatch = useDispatch();
  const { ui } = useSelector((state) => state);

  const recaptchaRef = useRef(null);
  const [loaded, setLoaded] = useState(false);
  const asyncScriptOnLoad = useCallback((data) => {
    setLoaded(data.loaded);
  }, []);

  const closeContactDialog = () => {
    dispatch(setBlur(!ui.is_blur));
    dispatch(setContactReason(""));
    dispatch(setShowContactDialog(!ui.show_contact_dialog));
    if (
      equalsIgnoringCase(
        location.pathname,
        getLocalePath(t("routes.public.contact"))
      )
    ) {
      navigate(getLocalePath(t("routes.common.home")));
    }
  };

  return (
    <>
      <div className="wrapper" onClick={(evt) => closeContactDialog()}></div>
      <div className="contact-form-dialog">
        <div className="contact-form-dialog__header">
          <span className="header-3">{t("contact.title")}</span>
          <span className="title">{t("contact.subtitle")}</span>
          <span
            className="icon-close"
            onClick={() => closeContactDialog()}
          ></span>
        </div>
        <div className="contact-form-dialog__body">
          <ContactForm
            recaptcha={{ ref: recaptchaRef.current, loaded: loaded }}
          />
        </div>
      </div>
      <ReCAPTCHA
        ref={recaptchaRef}
        className="contact-form-reCAPTCHA"
        sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
        badge="bottomright"
        size="invisible"
        asyncScriptOnLoad={(data) => asyncScriptOnLoad(data)}
      />
    </>
  );
}

export default ContactDialog;
