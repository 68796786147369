import classNames from "classnames";
import { useEffect, useMemo, useState } from "react";
import { isEmpty } from "../../utils/validators";

function TextArea(props) {
  const {
    style,
    label,
    placeholder,
    autoComplete,
    rows,
    disabled,
    hidden,
    required,
    value,
    error,
    onInput,
    register,
  } = props;
  const { name, ref } = register;

  const [isActive, setIsActive] = useState(false);

  const placeholderMemo = useMemo(() => {
    return !isEmpty(placeholder) ? placeholder : "";
  }, [placeholder]);

  const rowsMemo = useMemo(() => {
    return rows ? rows : 5;
  }, [rows]);

  useEffect(() => {
    setIsActive(!isEmpty(placeholderMemo) || !isEmpty(value));
  }, [placeholderMemo, value]);

  return (
    <div
      className={classNames("text-area__container", {
        unlabeled: isEmpty(label),
        hidden: hidden,
        disabled: disabled,
      })}
    >
      <div className="text-area__content">
        <textarea
          id={name}
          name={name}
          ref={ref}
          className={"text-area__input"}
          style={style}
          value={value}
          rows={rowsMemo}
          disabled={disabled}
          required={required}
          autoComplete={autoComplete}
          onInput={onInput}
          {...register}
        />
        {label && (
          <label
            htmlFor={name}
            className={classNames("text-area__label", "smaller", {
              active: isActive,
            })}
          >
            {`${label}${required ? " *" : ""}`}
          </label>
        )}
      </div>
      <p className={classNames("text-area__error", { show: error })}>{error}</p>
    </div>
  );
}

export default TextArea;
