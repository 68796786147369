import { useCallback, useEffect, useMemo } from "react";

import classNames from "classnames";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useGetLocalePath from "../hooks/useGetLocalePath";
import useGetUserSubscriptionStatus from "../hooks/useGetUserSubscriptionStatus";

import {
  setBlur,
  setContactReason,
  setShowContactDialog,
  setShowSubscriptionDialog,
  setShowCancelSubscriptionDialog,
} from "../redux/reducers/uiReducer";

import { Link } from "react-router-dom";
import { equalsIgnoringCase } from "../utils/validators";

import SEO from "../components/seo";
import Button from "../components/button";
import Divider from "../components/divider";
import PlanCard from "../components/plan-card";

function Plans() {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const { getLocalePath } = useGetLocalePath();

  const dispatch = useDispatch();
  const { ui } = useSelector((state) => state);
  const { user } = useSelector((state) => state.user);
  const { isFreeUser, isProUser } = useGetUserSubscriptionStatus();

  useEffect(() => {
    if (!user.logged_in) {
      navigate(getLocalePath(t("routes.common.home")));
    }
  });

  const getNestedRoute = useCallback(
    (nestedRoute) => {
      return getLocalePath(`${t("routes.private.prefs.index")}/${nestedRoute}`);
    },
    [getLocalePath, t]
  );

  const titleSEO = useMemo(() => {
    return `${t("app.name")} - ${t("plans.title")}`;
  }, [t]);

  const descriptionSEO = useMemo(() => {
    return t("plans.description");
  }, [t]);

  const urlSEO = useMemo(() => {
    return `${process.env.REACT_APP_PUBLIC_URL}${getNestedRoute(
      t("routes.private.prefs.plans")
    )}`;
  }, [getNestedRoute, t]);

  const altLinksSEO = useMemo(() => {
    const publicURL = process.env.REACT_APP_PUBLIC_URL;
    const result = i18n.languages.map((lng) => {
      return {
        lng: lng,
        url: `${publicURL}/${lng}/${t("routes.private.prefs.index", {
          lng: lng,
        })}/${t("routes.private.prefs.plans", {
          lng: lng,
        })}`,
      };
    });

    const defaultLng = result.find((url) => url.lng === "en");
    result.push({
      lng: "x-default",
      url: defaultLng.url,
    });

    return result;
  }, [i18n.languages, t]);

  const isFreePlan = useCallback(
    (plan) => equalsIgnoringCase(plan, t("subscription.free")),
    [t]
  );

  const isProPlan = useCallback(
    (plan) => equalsIgnoringCase(plan, t("subscription.pro")),
    [t]
  );

  const isBusinessPlan = useCallback(
    (plan) => equalsIgnoringCase(plan, t("subscription.business")),
    [t]
  );

  const PlanIcon = (props) => {
    const { plan } = props;

    const FreePlanIcon = (props) => {
      return (
        <span
          className={classNames(
            "plan-card__container__header__icon",
            "icon-circle-alternate"
          )}
        ></span>
      );
    };

    const ProPlanIcon = (props) => {
      return (
        <span
          className={classNames(
            "plan-card__container__header__icon",
            "icon-up-round-arrow"
          )}
        >
          <span className="path1"></span>
          <span className="path2"></span>
        </span>
      );
    };

    const BusinessPlanIcon = (props) => {
      return (
        <span
          className={classNames(
            "plan-card__container__header__icon",
            "icon-star-circled"
          )}
        ></span>
      );
    };

    if (isFreePlan(plan)) {
      return <FreePlanIcon />;
    } else if (isProPlan(plan)) {
      return <ProPlanIcon />;
    } else if (isBusinessPlan(plan)) {
      return <BusinessPlanIcon />;
    }
  };

  const PlanTitle = (props) => {
    const { plan } = props;

    return (
      <span className="plan-card__container__header__title">
        {t(`plans.${plan}.title`)}
      </span>
    );
  };

  const PlanButton = (props) => {
    const { plan } = props;

    const FreePlanButton = (props) => {
      const onClick = useCallback((evt) => {
        if (!isFreeUser) {
          dispatch(setBlur(!ui.is_blur));
          dispatch(setShowContactDialog(!ui.show_contact_dialog));
          dispatch(setContactReason(t("contact.form.reasons.1")));
        }
      }, []);

      return (
        <Button
          secondary
          text
          disabled={isFreeUser}
          onClick={(evt) => onClick(evt)}
        >
          {!isFreeUser ? (
            <span className="label">{t("button.contact")}</span>
          ) : (
            <span className="label">{t("button.actual_plan")}</span>
          )}
        </Button>
      );
    };

    const ProPlanButton = (props) => {
      const subsClickHandler = useCallback((evt) => {
        dispatch(setShowSubscriptionDialog(!ui.show_subscription_dialog));
        dispatch(setBlur(!ui.is_blur));
      }, []);

      const cancelClickHandler = useCallback((evt) => {
        dispatch(
          setShowCancelSubscriptionDialog(!ui.show_cancel_subscription_dialog)
        );
        dispatch(setBlur(!ui.is_blur));
      }, []);

      return (
        <>
          {isFreeUser && (
            <Button secondary text onClick={(evt) => subsClickHandler(evt)}>
              <span className="label">{t("button.subscription")}</span>
            </Button>
          )}
          {isProUser && (
            <Button secondary text onClick={(evt) => cancelClickHandler(evt)}>
              <span className="label">{t("button.cancel")}</span>
            </Button>
          )}
        </>
      );
    };

    const BusinessPlanButton = (props) => {
      const openContactDialogHandler = useCallback((evt) => {
        dispatch(setBlur(!ui.is_blur));
        dispatch(setShowContactDialog(!ui.show_contact_dialog));
        dispatch(setContactReason(t("contact.form.reasons.1")));
      }, []);

      return (
        <Button secondary text onClick={(evt) => openContactDialogHandler(evt)}>
          <span className="label">{t("button.contact")}</span>
        </Button>
      );
    };

    if (isFreePlan(plan)) {
      return <FreePlanButton />;
    } else if (isProPlan(plan)) {
      return <ProPlanButton />;
    } else if (isBusinessPlan(plan)) {
      return <BusinessPlanButton />;
    }
  };

  const PlanContent = (props) => {
    const { plan } = props;
    const planMemo = useMemo(() => plan.toLowerCase(), [plan]);

    return (
      <div
        className="plan-card__container__body__list"
        dangerouslySetInnerHTML={{
          __html: t(`plans.${planMemo}.content`, {
            interpolation: { escapeValue: true },
          }),
        }}
      ></div>
    );
  };

  return (
    <>
      <SEO
        title={titleSEO}
        description={descriptionSEO}
        url={urlSEO}
        canonical={urlSEO}
        altLinks={altLinksSEO}
      />
      <div className="plans-page__container">
        <div className="plans-page__content">
          <div className="plans-page__title">
            <Link to={t("routes.common.go_back")}>
              <div className="link-container">
                <span className="icon icon-nav-arrow-left"></span>
                <span className="label small-title">{t("plans.title")}</span>
              </div>
            </Link>
            <Divider />
          </div>
          <div className="plans-container">
            <div className="plans-content">
              <div className="plans-table-prices">
                {[
                  t("subscription.free").toLowerCase(),
                  t("subscription.pro").toLowerCase(),
                  t("subscription.business").toLowerCase(),
                ].map((plan) => {
                  return (
                    <PlanCard
                      plan={plan}
                      icon={<PlanIcon plan={plan} />}
                      title={<PlanTitle plan={plan} />}
                      button={<PlanButton plan={plan} />}
                      content={<PlanContent plan={plan} />}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Plans;
