function Avatar({ src, alt, title, size, loading }) {
  return (
    <div className="avatar-container">
      <img
        className="avatar"
        src={src}
        alt={alt}
        title={title}
        width={size}
        height={size}
        loading={loading}
      ></img>
    </div>
  );
}

export default Avatar;
