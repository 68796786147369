import { createSlice } from "@reduxjs/toolkit";
import { logDebug } from "../../utils/logger";

const migrations = {
  0: (state) => {
    return {
      ...state,
      consentPrivacy: true,
    };
  },
};
const currentVersion = 0;

const slice = createSlice({
  name: "consentPrivacy",
  initialState: {
    consentPrivacy: true,
  },
  reducers: {
    setConsentPrivacy: (state, action) => {
      state.consentPrivacy = action.payload;
      logDebug("consentPrivacyReducer", "setConsentPrivacy", {
        consentPrivacy: state.consentPrivacy,
      });
    },
  },
});

export const { setConsentPrivacy } = slice.actions;
export { migrations, currentVersion };
export default slice.reducer;
