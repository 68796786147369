import { useTranslation } from "react-i18next";

import Divider from "../../divider";
import AdsComponent from "../../ads-component";

function ErrorComponent() {
  const { t } = useTranslation();

  return (
    <div className="error__container">
      <div className="error__card">
        <div className="error__header">
          <span className="error__logo"></span>
        </div>
        <div className="error__body">
          <span className="error__title">{t("error_upload.title")}</span>
          <span className="error__message">{t("error_upload.message")}</span>
        </div>
        <div className="error__footer">
          <Divider />
          <AdsComponent
            dataAdSlot={process.env.REACT_APP_G_AD_SLOT_DOWNLOADER}
          />
        </div>
      </div>
    </div>
  );
}

export default ErrorComponent;
