import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useGetLocalePath from "../../hooks/useGetLocalePath";

function TopBanner() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { getLocalePath } = useGetLocalePath();

  const getNestedRoute = useCallback(
    (nestedRoute) => {
      return getLocalePath(`${t("routes.private.prefs.index")}/${nestedRoute}`);
    },
    [getLocalePath, t]
  );

  const moreInfoHandler = useCallback(
    (evt) => {
      navigate(getNestedRoute(t("routes.private.prefs.plans")));
    },
    [getNestedRoute, navigate, t]
  );

  return (
    <div className="top-banner__container">
      <div className="top-banner__content">
        <span className="message">{t("banner.pro_plan.message")}</span>
        <span className="link" onClick={(evt) => moreInfoHandler(evt)}>
          {t("button.more_info")}
        </span>
      </div>
    </div>
  );
}

export default TopBanner;
