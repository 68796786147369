import { useCallback, useEffect, useMemo, useState } from "react";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useGetLocalePath from "../hooks/useGetLocalePath";
import useWindowSize from "../hooks/useWindowSize";
import useGetUserSubscriptionStatus from "../hooks/useGetUserSubscriptionStatus";

import SEO from "../components/seo";
import Divider from "../components/divider";
import UploaderPageHeader from "../components/uploader-page/header";
import UploaderPageBody from "../components/uploader-page/body";
import TopBanner from "../components/top-banner";
import FloatingMsg from "../components/floating-message";

function Uploads() {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const { getLocalePath } = useGetLocalePath();

  const windowSize = useWindowSize();
  const { user } = useSelector((state) => state.user);
  const { isUnregisteredUser, isFreeUser } = useGetUserSubscriptionStatus();

  useEffect(() => {
    if (!user.logged_in) {
      navigate(getLocalePath(t("routes.common.home")));
    }
  });

  const titleSEO = useMemo(() => {
    return `${t("app.name")} - ${t("uploads.title")}`;
  }, [t]);

  const descriptionSEO = useMemo(() => {
    return t("uploads.description");
  }, [t]);

  const urlSEO = useMemo(() => {
    return `${process.env.REACT_APP_PUBLIC_URL}${getLocalePath(
      t("routes.private.uploads.index")
    )}`;
  }, [getLocalePath, t]);

  const altLinksSEO = useMemo(() => {
    const publicURL = process.env.REACT_APP_PUBLIC_URL;
    const result = i18n.languages.map((lng) => {
      return {
        lng: lng,
        url: `${publicURL}/${lng}/${t("routes.private.uploads.index", {
          lng: lng,
        })}`,
      };
    });

    const defaultLng = result.find((url) => url.lng === "en");
    result.push({
      lng: "x-default",
      url: defaultLng.url,
    });

    return result;
  }, [i18n.languages, t]);

  const [show, setShow] = useState(true);
  const showProAdsDesktop = useMemo(() => {
    return (
      (isUnregisteredUser || isFreeUser) && windowSize.width >= 576 && show
    );
  }, [isUnregisteredUser, isFreeUser, windowSize.width, show]);
  const showProAdsMobile = useMemo(() => {
    return (isUnregisteredUser || isFreeUser) && windowSize.width < 576 && show;
  }, [isUnregisteredUser, isFreeUser, windowSize.width, show]);

  useEffect(() => {
    if (showProAdsMobile) {
      const page1 = document.getElementsByClassName("page1__container")[0];
      if (page1) {
        page1.classList.toggle("free");
      }
    }
  }, [showProAdsMobile]);

  const MessageContent = (props) => {
    const messageStyle = useMemo(() => {
      return {
        textAlign: "center",
      };
    }, []);

    const buttonStyle = useMemo(() => {
      return {
        padding: "4px 12px",
        marginTop: "16px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "99px",
        color: "#6c2bd9",
        background:
          "linear-gradient(0deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 1) 100%)",
        boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.16)",
        cursor: "pointer",
      };
    }, []);

    const getNestedRoute = useCallback((nestedRoute) => {
      return getLocalePath(`${t("routes.private.prefs.index")}/${nestedRoute}`);
    }, []);

    const moreInfoHandler = useCallback(
      (evt) => {
        navigate(getNestedRoute(t("routes.private.prefs.plans")));
      },
      [getNestedRoute]
    );

    return (
      <>
        <span className="message" style={messageStyle}>
          {t("banner.pro_plan.message")}
        </span>
        <div
          role="button"
          className="button"
          style={buttonStyle}
          title={t("button.more_info")}
          onClick={(evt) => moreInfoHandler(evt)}
        >
          <span className="label">{t("button.more_info")}</span>
        </div>
      </>
    );
  };

  return (
    <>
      <SEO
        title={titleSEO}
        description={descriptionSEO}
        url={urlSEO}
        canonical={urlSEO}
        altLinks={altLinksSEO}
      />

      <div className="uploads-page__container">
        <div className="uploads-page__content">
          <div className="uploads-page__title">
            <UploaderPageHeader />
            <Divider />
          </div>
          <div className="uploads__container">
            <div className="uploads__content">
              {showProAdsMobile && <TopBanner />}
              {showProAdsDesktop && (
                <FloatingMsg closeHandler={setShow}>
                  {<MessageContent />}
                </FloatingMsg>
              )}
              <UploaderPageBody />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Uploads;
