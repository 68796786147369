import { useCallback, useEffect, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import useSearchTransfers from "../../../hooks/useSearchTransfers";

import {
  equalsIgnoringCase,
  isEmpty,
  isEmptyList,
} from "../../../utils/validators";
import { setTransferInit } from "../../../redux/reducers/uiReducer";

import TransferTable from "./transfer-table";
import TransferTableFilter from "./transfer-table-filter";
import { errorToast, warnToast } from "../../toast";

import { logDebug } from "../../../utils/logger";
import {
  setItems,
  clearItems,
  setFilters,
  setLoading,
  setPagination,
  clearPagination,
  setOrderBy,
  clearOrderBy,
} from "../../../redux/reducers/transfersTableReducer";

function UploaderPageBody() {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { ui } = useSelector((state) => state);
  const { user } = useSelector((state) => state.user);
  const { transfersTable } = useSelector((state) => state);

  const paginationMemo = useMemo(() => {
    return JSON.parse(transfersTable.pagination);
  }, [transfersTable.pagination]);

  const filtersMemo = useMemo(() => {
    return JSON.parse(transfersTable.filters);
  }, [transfersTable.filters]);

  const orderByMemo = useMemo(() => {
    return transfersTable.orderBy;
  }, [transfersTable.orderBy]);

  const gapPagination = useMemo(() => {
    return 20;
  }, []);

  const calcPagination = useCallback(
    (num_rows) => {
      let total = 0;
      if (isEmpty(filtersMemo.search)) {
        total =
          parseInt(num_rows) >= parseInt(user.numTransfers)
            ? parseInt(num_rows)
            : parseInt(user.numTransfers);
      } else {
        total = parseInt(num_rows);
      }
      const limit =
        paginationMemo.limit + gapPagination > total
          ? total
          : paginationMemo.limit + gapPagination;
      const start =
        paginationMemo.start + gapPagination < limit
          ? paginationMemo.start + gapPagination
          : paginationMemo.start;
      const more = paginationMemo.limit < total;
      const pagination = {
        start: start,
        limit: limit,
        total: total,
        more: more,
      };
      logDebug("UploaderPageBody", "calcPagination", {
        pagination: pagination,
      });
      return pagination;
    },
    [
      filtersMemo.search,
      paginationMemo.limit,
      paginationMemo.start,
      gapPagination,
      user.numTransfers,
    ]
  );

  const { loading, data, error, handler } = useSearchTransfers();
  useEffect(() => {
    if (!loading) {
      if (isEmpty(error)) {
        if (!isEmptyList(data)) {
          dispatch(setItems([...transfersTable.items, ...data.result]));
          dispatch(setPagination(calcPagination(data.num_rows)));
          dispatch(setLoading(false));
        }
      } else if (equalsIgnoringCase(error, "empty_list")) {
        warnToast(
          "useSearchTransfers",
          error,
          t(`message.not_found`, t("message.default"))
        );
        dispatch(clearPagination());
        dispatch(clearItems());
        dispatch(setLoading(false));
      } else {
        errorToast(
          "useSearchTransfers",
          error,
          t(`errors.${error}`, t("errors.default"))
        );
        dispatch(clearPagination());
        dispatch(clearItems());
        dispatch(setLoading(false));
      }
      dispatch(setTransferInit(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const searchHandler = useCallback(() => {
    const params = {
      start: paginationMemo.start,
      limit: paginationMemo.limit,
      search: filtersMemo.search,
      orderBy: orderByMemo,
    };
    handler({ ...params });
    logDebug("UploaderPageBody", "searchHandler", { ...params });
  }, [
    paginationMemo.start,
    paginationMemo.limit,
    filtersMemo.search,
    orderByMemo,
    handler,
  ]);

  useEffect(() => {
    if (ui.transfer_init) {
      dispatch(clearPagination());
      dispatch(setLoading(true));
      handler({
        start: paginationMemo.start,
        limit: paginationMemo.limit,
        search: filtersMemo.search,
        orderBy: orderByMemo,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ui.transfer_init]);

  const onSearchHandler = useCallback(
    (value) => {
      dispatch(clearItems());
      dispatch(clearOrderBy());
      dispatch(clearPagination());
      dispatch(setFilters({ ...value }));
      dispatch(setLoading(true));
      dispatch(setTransferInit(true));
    },
    [dispatch]
  );

  const onSearchClearHandler = useCallback(
    (value) => {
      dispatch(clearItems());
      dispatch(clearOrderBy());
      dispatch(clearPagination());
      dispatch(setFilters({ ...value }));
      dispatch(setLoading(true));
      dispatch(setTransferInit(true));
    },
    [dispatch]
  );

  const onOrderHandler = useCallback(
    (value) => {
      dispatch(clearItems());
      dispatch(clearOrderBy());
      dispatch(clearPagination());
      dispatch(setOrderBy(value));
      dispatch(setLoading(true));
      dispatch(setTransferInit(true));
    },
    [dispatch]
  );

  return (
    <div className="uploads__content__body">
      <div className="uploads__content__body__filter">
        <TransferTableFilter
          loading={transfersTable.loading || loading}
          filters={filtersMemo}
          orderBy={orderByMemo}
          total={paginationMemo.total}
          onSearch={(value) => onSearchHandler(value)}
          onSearchClear={(value) => onSearchClearHandler(value)}
          onOrder={(value) => onOrderHandler(value)}
        />
      </div>
      <div
        id="scrollable-transfer-table"
        className="uploads__content__body__table"
      >
        <TransferTable
          loading={transfersTable.loading}
          items={transfersTable.items}
          total={paginationMemo.total}
          more={paginationMemo.more}
          nextHandler={searchHandler}
        />
      </div>
    </div>
  );
}

export default UploaderPageBody;
