export const TRANSLATIONS = {
  app: {
    name: "YDRAY",
  },

  routes: {
    // Common routes
    common: {
      home: "/",
      go_back: "..",
    },
    // Public routes
    public: {
      about: "about-us",
      plans: "plans",
      press: "press",
      newsletter_reg: "newsletter-register",
      newsletter_unreg: "newsletter-unregister",
      contact: "contact",
      faq: "faq",
      cookies: "cookies-policy",
      privacy: "privacy-policy",
      legal: "legal-information",
      send_files: {
        index: "send-files",
        big: "big",
        free: "free",
      },
      share_files: {
        index: "share-files",
        big: "big",
        free: "free",
      },
      uploader: "uploader",
    },
    // Private routes
    private: {
      uploads: {
        index: "uploads",
        detail: "transfer-detail",
      },
      prefs: {
        index: "preferences",
        plans: "plans",
        bills: "billing",
        pass: "change-password",
        contact: "contact",
        custom: "customize-uploads",
        personal: "change-user-data",
      },
    },
  },

  langs: {
    es: "Castellano",
    en: "English",
  },

  button: {
    accept: "Accept",
    add: "Add",
    save: "Save",
    search: "Search",
    close: "Close",
    menu: "Menu",
    send: "Send",
    upload: "Upload",
    download: "Download",
    more_info: "More Info",
    try: "Try",
    cancel: "Cancel",
    cancelAll: "Cancel All",
    reupload: "Reupload",
    reuploadAll: "Reupload All",
    cancel_plan: "Cancel Plan",
    contact: "Contact",
    waiting_list: "Waitlist",
    subscription: "Subscription",
    actual_plan: "Actual Plan",
  },

  dialog: {
    types: {
      info: "Information",
      warn: "Warning",
      error: "Error",
      success: "Success",
      share: "Share",
    },
  },

  placeholder: {
    search: "Search...",
    empty: "(empty field)",
    empty_list: "Empty list",
    empty_table: "No data found",
  },

  social_media: {
    mail: "Email",
    gmail: "Gmail",
    youtube: "Youtube",
    twitter: "X (Twitter)",
    facebook: "Facebook",
    whatsapp: "WhatsApp",
    linkedin: "LinkedIn",
    instagram: "Instagram",
    share_ph: "Share...",
    share_msg: "I send you these files by YDRAY",
    links: {
      youtube: "https://www.youtube.com/channel/UCUEyel0jLWYlOxr8HuXUEqA",
      twitter: "https://twitter.com/Ydray_",
      facebook: "https://www.facebook.com/Ydray",
      instagram: "https://www.instagram.com/ydray.com_",
      linkedin: "https://www.linkedin.com/company/ydray",
    },
  },

  subscription: {
    free: "FREE",
    pro: "PRO",
    business: "BUSINESS",
  },

  errors: {
    default: "An error has occurred, please try again later",
    empty: "{{field}} field cannot be empty",
    already_registered: "There is a registered user yet",
    invalid_email: "Email is not valid",
    field_too_long: "{{field}} field is too long",
    field_too_short: "{{field}} field is too short",
    invalid_email_or_pass: "Email or password are not valid",
    pass_not_equal: "Password fields do not match",
    pass_too_long: "Password field is too long",
    pass_too_short: "Password field is too short",
    msg_too_long: "Comment field is too long",
    title_too_long: "Title field is too long",
    invalid_old_pass: "Old password is not valid",
    new_pass_not_equal: "New password does not match",
    existed_file: "A file already exists with same name",
    file_too_big: "File size is too big",
    max_upload_size:
      "The total size allowed per upload has been exceeded (max {{size}})",
    fileType: "File type is not valid",
    exceed_uploading_size: "Total upload size exceeded",
    exceed_uploaded_size: "Storage limit exceeded",
    error_db: "A database error has occurred, please try again later",
    bbdd: "A database error has occurred, please try again later",
    unauthenticated: "You don't have permissions to upload files",
    forbidden: "You do not have permissions to perform the action",
    expired: "The download link has expired",
    password_protected: "The download link is password protected",
    wrong_pass: "Wrong password",
    not_confirmed: "The email provided has not been confirmed",
    maintenance: "The service is under maintenance. Please try again later",
    incorrect_captcha: "Captcha is wrong",
    incorrect_email: "Email is not valid",
    missing_message: "Message is not valid",
    missing_name: "Name is not valid",
    conditions_not_accepted: "Conditions must be accepted",
    invalid_url: "🤔 Ups! It seems that there is nothing here...",
    error_form: "Please, you must to resolve the form problems",
    max_file_per_upload:
      "The maximum number of files per upload is {{count}} files",
    server_maintenance: "The server is under maintenance",
    search_chars: "Enter at least {{count}} characters to search transfers",
    invalid_opt: "The selected option is uknown",
    expired_date: "Expired",
    exp_transfer_date: "Expired transfer",
    files: "A cookie error has occurred, please try again later",
    cors_server: "A CORS server error has occurred, please try again later",
    adblock_detected: "AdBlock detected...",
    iab_cookie_not_detected: "The TC string is not detected",
    iab_cookie_expired:
      "The TC string last updated date was more than 13 months ago",
    not_correct_user: "The user's session does not match the subscription",
    user_is_pro: "The user already has a PRO subscription",
    not_logged_user: "User session is invalid",
    not_found_subscription: "Subscription not found",
    not_id_sent: "The user's subscription ID has not been sent",
    not_confirmed_subscription: "Subscription status could not be confirmed",
    not_cancelled_subscription: "Unable to unsubscribe user",
    error_subscription: "An error occurred while subscribing",
    cancel_subcription_process:
      "Subscription not completed. Payment popup has been closed too soon",
  },

  message: {
    default: "The operation was successful",
    language: "Your language was changed successfully! 🌎",
    clipboard: "Copied to clipboard! 📋",
    have_not_uploaded_files: "You have unshared files",
    reload_uploading_files:
      "There is an active upload, do you want to leave the page?",
    mail_check: "We have sent you an email to confirm the change",
    create_user_ok: "An email has been sent to validate the new user",
    not_found: "There are no results for the requested search",
    prevent_reload:
      "There are unsaved changes on the page. If you continue reloading the page, this changes will be lost. Do you want to continue?",
    adblock_not_detected: "AdBlock not detected",
    iab_cookie_not_expired:
      "The TC string last updated date was less than 13 months ago",
    ads_not_allowed: "Ads not allowed",
    allow_ads: "This ads are safe and informative, please disable ad blocker",
    refresh_ads: "Refreshing ads",
    user_changed_to_pro: "Subscription was created successfully",
  },

  validators: {
    integer: "The field has to be an integer value",
    positive: "The field has to be a positive value",
    negative: "The field has to be a negative value",
    phone: "The field is not a valid phone number",
    notEmpty: "The field cannot be empty",
    email: "The field is not a valid email address",
    compareFields: "The field value does not match the previous one",
    lessThan: "The field cannot be less than {{value}}",
    moreThan: "The field cannot be greater than {{value}}",
    minLength: "The field length cannot be less than {{value}} chars",
    maxLength: "The field length cannot be greater than {{value}} chars",
    checkTrue: "The field has to be checked",
  },

  maintenance: {
    title: "We are under maintenance",
    message:
      "The server is under maintenance. Please, come back in a few minutes to share and send your files.",
  },

  block_upload: {
    title: "YDRAY is open in another window",
    message: "Keep sending and sharing files with YDRAY in the other window",
  },

  error_upload: {
    title: "An error has occurred at YDRAY",
    message: "An error has occurred, please try again later",
  },

  downloader_expired: {
    title: "Your download has expired",
    message: "The download link has expired",
  },

  banner: {
    pro_plan: {
      message:
        "Remember that you can subscribe to the PRO plan to have more storage capacity and unlimited uploads",
    },
  },

  tooltip: {
    feature_pro_message:
      "Subscribe to the PRO plan to have access to this section",
  },

  logo: {
    title: "YDRAY's logo",
    alt: "YDRAY's logo",
  },

  wordmark: {
    title: "YDRAY",
    alt: "YDRAY",
  },

  header_menu: {
    about_us: "About us",
    plans: "Plans",
    account: "Account",
    uploads: "Uploads",
  },

  footer_menu: {
    about_us: "About us",
    plans: "Plans",
    account: "Account",
    login: "Login",
    uploads: "Uploads",
    preferences: "Preferences",
  },

  login_dialog: {
    title: "Login",
    subtitle: "",
    form: {
      email: "Email",
      loginpass: "Password",
      create_account: "Don't you have an account yet?",
      logIn: "Register",
      reset_password: "¿Have you forgot the password?",
      remind_me: "Remind me",
    },
    button: {
      login: "Login",
    },
  },

  register_dialog: {
    title: "Create an Account",
    subtitle: "You can choose a plan later",
    form: {
      username: "User name",
      email: "Email",
      pass1: "Password",
      pass2: "Repeat password",
      login_account: "Do you have an account?",
      logIn: "Login",
      reset_password: "¿Have you forgot the password?",
      remind_me: "Remind me",
    },
    button: {
      create: "Create an Account",
    },
  },

  reset_password_dialog: {
    title: "Forgot your password?",
    subtitle: "Don't worry, we'll help you!",
    form: {
      email: "Email",
      login_account: "Do you have an account?",
      logIn: "Login",
      create_account: "Don't you have an account yet?",
      register: "Register",
    },
    button: {
      remind_me: "Remind me",
    },
  },

  reg_newsletter_dialog: {
    title: "Newsletter",
    subtitle:
      "Sign in to our newsletter! We'll send you an email when register was available",
    form: {
      email1: "Email",
      email2: "Repeat email",
      conditions: `I read, understand and accept our <a class="link" href="/en/privacy-policy" title="privacy policy">privacy policy</a>.`,
    },
  },

  unreg_newsletter_dialog: {
    title: "Newsletter",
    subtitle:
      "To unsubscribe from our newsletter, please enter the email you signed up with",
    form: {
      email: "Email",
    },
  },

  consent_dialog: {
    title: "Wellcome to ydray.com",
    subtitle:
      "ydray.com requests your consent to use your personal data for these purposes:",
    ad: "Personalized ads and content, ad and content measurement, audience insights, and product development",
    storage: "Store or access information on a device",
    moreinfo: "More Info",
    moreinfo_p1:
      "Your personal data will be processed and your device information (cookies, unique identifiers and other device data) may be stored, consulted and shared with external providers or used specifically by this website or application.",
    moreinfo_p2:
      "Some providers may process your personal data based on a legitimate interest, which you can object to by managing your options below. Look for a link at the bottom of this page or in our privacy policy to revoke consent.",
    button: {
      management_ops: "Options",
      consent: "Accept",
    },
  },

  subscription_dialog: {
    title: "Thank you for choosing the PRO plan",
    subtitle: "To make the payment, connect to your account",
  },

  cancel_subscription_dialog: {
    title: "Cancel Plan",
    subtitle: "To cancel the plan, click on the button",
  },

  dropdown: {
    placeholder: "Choose...",
    order: "Order by",
    day_one: "day",
    day_other: "days",
    dayWithCount_one: "{{count}} day",
    dayWithCount_other: "{{count}} days",
    undefined: "Undefined",
  },

  uploader: {
    add_files_content: {
      title: "Add your files to share",
      subtitle:
        "Click on add button or drop your files here to start uploading",
      list_items: `
        <li class="list-item">
          <span class="item-icon smaller icon-check"></span>
          <span class="item-text smaller">Send and share large and big files for free up to 10 GB</span>
        </li>
        <li class="list-item">
          <span class="item-icon smaller icon-check"></span>
          <span class="item-text smaller">Share and send large and big files without registration</span>
        </li>
        <li class="list-item">
          <span class="item-icon smaller icon-check"></span>
          <span class="item-text smaller">Maximum privacy when sharing and sending files</span>
        </li>
      `,
      privacy_consent: `
        To send and share your files, please accept our <a class="link" href='/en/privacy-policy/' title="privacy policy">privacy policy</a>,
        <a class="link" href="/en/legal-information" title="terms of service">terms of service</a> y <a class="link" href='/en/cookies-policy/' title="cookies policy">cookies policy</a>.
        We use cookies for functional and statistical purposes, and third-party cookies for advertising purposes.`,
    },
    share_content: {
      title: "Ready to share!",
      button: {
        generate: "Generate link",
        copy: "Copy link",
        share: "Share",
        new: "New upload",
      },
    },
    upload_files_content: {
      add_files: "Add files",
      file_one: "file",
      file_other: "files",
      fileWithCount_one: "{{count}} file",
      fileWithCount_other: "{{count}} files",
      uploading_file_one: "Uploading file",
      uploading_file_other: "Uploading files",
      uploading_fileWithCount_one: "Uploading {{count}} file",
      uploading_fileWithCount_other: "Uploading {{count}} files",
      uploaded_file_one: "Uploaded file",
      uploaded_file_other: "Uploaded files",
      uploaded_fileWithCount_one: "{{count}} uploaded file",
      uploaded_fileWithCount_other: "{{count}} uploaded files",
      title: "Title",
      comment: "Comment",
      deleteIn: "Delete in",
      password: "Password",
      background: "Background",
    },
    upload_detail_content: {
      add_files: "Add files",
      back: "Back",
      title_error_one: "error",
      title_error_other: "errors",
      title_errorWithCount_one: "{{count}} error",
      title_errorWithCount_other: "{{count}} errors",
      title_uploads_one: "file",
      title_uploads_other: "files",
      title_uploadsWithCount_one: "{{count}} file",
      title_uploadsWithCount_other: "{{count}} files",
    },
    pro_ad: {
      title: "PRO Version",
      subtitle:
        "Remember that you can subscribe to have more storage capacity and unlimited uploads",
    },
  },

  downloader: {
    title: "Ready to download!",
    subtitle: "First enter your password",
    file_one: "file",
    file_other: "files",
    fileWithCount_one: "{{count}} file",
    fileWithCount_other: "{{count}} files",
    list: {
      file_one: "file",
      file_other: "files",
      fileWithCount_one: "{{count}} file",
      fileWithCount_other: "{{count}} files",
    },
  },

  preferences: {
    title: "Preferences",
    description:
      "Page where you can change your preferences in your YDRAY account",
    subtitle: {
      username: "Hi, {{username}}",
      billing: "Billing",
      account: "Account",
    },
    items: {
      plans: {
        title: "Plans",
        subtitle: "You are {{subscription}}",
      },
      bills: {
        title: "Bills",
        subtitle: "State, documents, etc",
      },
      personal: {
        title: "Personal Data",
        subtitle: "Name, username, address, etc",
      },
      password: {
        title: "Password",
        subtitle: "Edit password",
      },
      customize: {
        title: "Customize",
        subtitle: "User image, background, URLs, etc",
      },
      contact: {
        title: "Contact",
        subtitle: "How can we help?",
      },
      logout: {
        title: "Log out",
      },
    },
  },

  plans: {
    title: "Plans",
    description:
      "Page where you can change your contracted plan in your YDRAY account",
    free: {
      title: "YDRAY free",
      content: `
      <ul class="list">
        <li class="list-item">
          <span class="icon-check"></span>
          <span class="small">Free Service</span>
        </li>
        <li class="list-item">
          <span class="icon-check"></span>
          <span class="small">Send and share big and large files up to 10 GB</span>
        </li>
        <li class="list-item">
          <span class="icon-check"></span>
          <span class="small">Up to 50 files per transfer</span>
        </li>
        <li class="list-item">
          <span class="icon-check"></span>
          <span class="small">20 recipients maximum</span>
        </li>
        <li class="list-item">
          <span class="icon-check"></span>
          <span class="small">Unlimited file Downloads</span>
        </li>
      </ul>`,
    },
    pro: {
      title: "YDRAY pro",
      content: `
      <ul class="list">
        <li class="list-item">
          <span class="icon-check"></span>
          <span class="small">128 GB file storage</span>
          </li>
        <li class="list-item">
          <span class="icon-check"></span>
          <span class="small">Send and Share files up to 20 GB</span>
          </li>
        <li class="list-item">
          <span class="icon-check"></span>
          <span class="small">Unlimited files per transfer</span>
          </li>
        <li class="list-item">
          <span class="icon-check"></span>
          <span class="small">Unlimited Recipients for your uploads</span>
          </li>
        <li class="list-item">
          <span class="icon-check"></span>
          <span class="small">Shared files do not expire</span>
          </li>
        <li class="list-item">
          <span class="icon-check"></span>
          <span class="small">Unlimited file Downloads</span>
          </li>
      </ul>`,
    },
    business: {
      title: "YDRAY business",
      content: `
      <ul class="list">
        <li class="list-item">
          <span class="icon-check"></span>
          <span class="small">Exclusive server for your company</span>
          </li>
        <li class="list-item">
          <span class="icon-check"></span>
          <span class="small">Customizable Domain</span>
          </li>
        <li class="list-item">
          <span class="icon-check"></span>
          <span class="small">Corporate Image</span>
          </li>
        <li class="list-item">
          <span class="icon-check"></span>
          <span class="small">Send and share large and big files</span>
          </li>
        <li class="list-item">
          <span class="icon-check"></span>
          <span class="small">Full control of your files</span>
          </li>
        <li class="list-item">
          <span class="icon-check"></span>
          <span class="small">Advanced security options</span>
          </li>
        <li class="list-item">
          <span class="icon-check"></span>
          <span class="small">Allow your customers to upload files</span>
          </li>
      </ul>`,
    },
  },

  bills: {
    title: "Bills",
    description: "Summary of user invoices",
    table: {
      columns: {
        date: "Date",
        plan: "Plan",
        amount: "Amount",
        status: "Status",
      },
    },
  },

  personal: {
    title: "Personal Data",
    description:
      "Page where you can change your personal data in your YDRAY account",
    form: {
      user_title: "Account Information",
      name: "Name",
      surname: "Surname",
      username: "Username",
      address_title: "Billing Information",
      billing_name: "Company name",
      address: "Address",
      vat: "VAT",
      zip_code: "Zip code",
      country: "Country",
      state: "State/Region",
      city: "City",
      phone: "Phone",
    },
  },

  password: {
    title: "Password",
    description: "Page to change your password in your YDRAY account",
    form: {
      oldpass: "Old Password",
      pass1: "New Password",
      pass2: "Repeat new password",
    },
  },

  custom: {
    title: "Customize",
    description: "Page to customize your YDRAY account",
  },

  contact: {
    title: "Contact",
    description: "YDRAY contact page",
    subtitle: "How can we help?",
    emailLb: "Email",
    email: "info@ydray.com",
    form: {
      name: "Name / Company",
      email: "Email",
      email2: "Repeat email",
      phone: "Phone",
      reason: "Reason",
      reasonph: "Choose a reason...",
      reasons: {
        1: "I would like to know more about the plans available",
        2: "I need help to upload and transfer files",
        3: "I need help to download files",
        4: "I need help, I don't know how to use YDRAY",
        5: "Other",
      },
      message: "Message",
      consentLb: `I have read, understand and accept the <a class="link" href="/en/privacy-policy" title="privacy policy">privacy policy</a>`,
    },
  },

  uploads: {
    title: "Uploads",
    description: "Summary of file uploads from your YDRAY account",
    header: {
      title: "Hi, {{username}}",
      used_storage: "{{value}} used",
      total_storage: "{{value}} total",
      used_storage_ph: "{{value}}% of used storage",
      uploads: "uploads",
      uploads_ph: "{{count}} uploads",
      files: "files",
      files_ph: "{{count}} files",
    },
    body: {
      results: "{{count}} results",
    },
    table: {
      columns: {
        idTransfer: "ID",
        server: "Server",
        password: "Pass",
        title: "Title",
        message: "Comment",
        dispatched: "Creation Date",
        newest: "Newest first",
        oldest: "Oldest first",
        until: "Expiration",
        num_files: "Files",
        size: "Size",
      },
      rows: {
        file_one: "file",
        file_other: "files",
        fileWithCount_one: "{{count}} file",
        fileWithCount_other: "{{count}} files",
      },
      actions: {
        copy: "Copy",
        share: "Share",
        delete: "Delete",
        expand: "Expand",
        detail: "Detail",
        download: "Download",
        menu: {
          copy: "Copy",
          share: "Share",
          delete: "Delete",
        },
      },
    },
    message: {
      delete_transfer:
        "By deleting the transfer, all associated files will be deleted too, do you want to continue?",
      delete_file:
        "The file associated to the transfer will be deleted, do you want to continue?",
      delete_transfer_file:
        "By deleting the single associated file will also delete the transfer., do you want to continue?",
    },
  },

  uploads_detail: {
    title: "Transfer Detail",
    description: "Detail of a file transfer in YDRAY",
    header: {
      title: "Transfer Detail",
    },
    body: {
      transfer: {
        title: "Transfer of {{size}}",
        menu: {
          edit: "Edit",
          undo: "Undo",
          save: "Save",
          delete: "Delete",
          copy: "Copy",
          share: "Share",
        },
        form: {
          title: "Title",
          titleph: "Transfer title",
          message: "Message",
          messageph: "Transfer message",
          size: "Size",
          shareLink: "Link",
          shareLinkph: "Transfer link",
          dispatched: "Creation date",
          dispatchedph: "Transfer creation date",
          until: "Expiration date",
          untilph: "Transfer expiration date",
        },
      },
      files: {
        title_one: "File",
        title_other: "Files",
        titleWithCount_one: "{{count}} file",
        titleWithCount_other: "{{count}} files",
        table: {
          columns: {
            idFile: "File Id",
            idTransfer: "Transfer Id",
            fileName: "Name",
            fileSize: "Size",
            countDownload: "Downloads",
            actions: "Acctions",
            deleted: "Deleted",
          },
          rows: {
            download_one: "download",
            download_other: "downloads",
            downloadWithCount_one: "{{count}} download",
            downloadWithCount_other: "{{count}} downloads",
          },
        },
      },
    },
  },

  landing: {
    title: "Send and share large and big files up to 10 GB for free",
    description: `
      YDRAY is the fastest and easiest platform to send, transfer and share your large and heavy files with maximum security and privacy up to 10 GB for free
    `,
    hero: {
      title: "YDRAY allows you to share and send files easily and privately",
      subtitle:
        "With YDRAY you can safely send and share large files up to 10 GB for free, without registration and with unlimited downloads",
      button: {
        files: {
          title: "Files",
          subtitle: "Start to Sharing",
        },
        privacy: {
          title: "Privacy",
          subtitle: "More Info",
        },
      },
      img: {
        title:
          "YDRAY allows you to send and share your large and big files easily, quickly and privately",
        alt: "YDRAY allows you to send and share your large and big files easily, quickly and privately",
      },
    },
    body: {
      about_section: {
        title: "About us",
        description:
          "More than 25,000 people trust YDRAY to send and share your files daily.",
      },
      speech_section: {
        title:
          "More than 25,000 people trust YDRAY to send and share your files daily.",
        msg1: `
        <span class="msg">
          "The speed for sending and sharing big and large files on YDRAY is impressive."
        </span>
        <span class="firm">Diego S</span>
      `,
        msg2: `
        <span class="msg">
          "I love YDRAY to share and send big and large files without worrying."
        </span>
        <span class="firm">Sara M</span>
      `,
        msg3: `
        <span class="msg">
          "I like that you can share the files without registering in YDRAY."
        </span>
        <span class="firm">Nadia B.</span>
      `,
        msg4: `
        <span class="msg">
          "I recommend YDRAY to send and share large and big files quickly and securely."
        </span>
        <span class="firm">Jaime Q</span>
        `,
        msg5: `
        <span class="msg">
          "I've used YDRAY to send and share large and big files and I never had a problem."
        </span>
        <span class="firm">José S.</span>
        `,
      },
      how_section: {
        subtitle: "How it works YDRAY",
        title: "Easy and simple",
        step1: {
          title: "Upload your files",
          subtitle:
            "Accept the legal terms and upload your big and large files to send and share.",
        },
        step2: {
          title: "Customize your upload",
          subtitle: "Name, description, password and much more.",
        },
        step3: {
          title: "Share your link",
          subtitle: "With whoever you want and how you want.",
        },
        help: {
          title: "Any doubts?",
          subtitle: "Contact us and we will help you!",
        },
      },
      plans_section: {
        subtitle: "YDRAY Plans",
        title: "A plan for you",
        description: `
          <p>
            YDRAY offers different payment plans that allow you
            enjoy additional features by sending and sharing your
            large and big files. With YDRAY's PRO plan, you can send
            big and large files up to 20 GB. Plus, you get more control
            over your download links and the ability to set dates
            download.
          </p>
          <p>
            If you need even more capacity and advanced features,
            you can opt for YDRAY's Business plan, which allows you
            send and share big and large files without limit of size and
            offers additional storage and collaboration options.
            With these payment plans, YDRAY guarantees an experience
            to send and share files more complete and versatile.
          </p>
          <p class="coming-soon">
            The following plans will be available soon. Meanwhile,
            please note that you can use YDRAY for free in order to send and
            transfer files without creating any account. Just use the console
            <span class="left-side">on the left side of the screen </span>to
            upload, share the download link and download files.
          </p>
        `,
      },
      why_section: {
        subtitle: "Why choose YDRAY?",
        title: "Send and share big and large files easier than ever",
        description: `
        <p>
          YDRAY offers a solid alternative to other file transfer platforms by providing a seamless experience.
          exceptional at sending, transferring and sharing big and large files quickly, securely and with maximum privacy.
        </p>
        `,
      },
      secure_section: {
        subtitle: "Is YDRAY safe?",
        title: "Send and share files securely",
        description: `
        <p>
          With YDRAY, you can enjoy a user-friendly and secure platform that allows you to send large files
          size. YDRAY prioritizes security and privacy, offering end-to-end encryption for all
          file transfers. This means that your big and large files are protected against unauthorized access and
          interception during transmission.
        </p>
        `,
      },
      privacy_section: {
        subtitle: "How does YDRAY guarantee maximum privacy?",
        title: "Send and share files with maximum privacy",
        description: `
          <p>
            YDRAY guarantees maximum privacy when sending, sharing and transferring files by applying the policies
            stricter in terms of data protection such as the European General Data Protection Law (RGPD)
            Regulation (EU) 2016/679.
          </p>
          <p>
            YDRAY has a clear and transparent privacy policy that describes how data is collected,
            use and protect user data. This policy complies with data protection laws and regulations
            applicable and demonstrates YDRAY's commitment to the privacy of its users when sending and sharing their files.
          </p>
        `,
      },
      tips_section: {
        subtitle: "Tips to protect yourself",
        title: "Tips to protect your privacy on the Internet",
        tip1: {
          icon: "system",
          title: "Update the operating system",
          description: `
            <p>
              Keeping the operating system of your computer or mobile device updated will protect you against a multitude of cyber threats.
            </p>
          `,
        },
        tip2: {
          icon: "password",
          title: "Use strong passwords",
          description: `
            <p>
              Use strong passwords of at least 10 characters and a combination of letters, numbers and symbols.
            </p>
          `,
        },
        tip3: {
          icon: "auth",
          title: "Use 2FA authentication",
          description: `
            <p>
              The best way to protect your account is by activating double authentication, through a code or the use of code generation applications.
            </p>
          `,
        },
        tip4: {
          icon: "email",
          title: "Keep an eye on unknown emails",
          description: `
            <p>
              Be very careful with emails from unknown sources, avoid links or download attachments.
            </p>
          `,
        },
        tip5: {
          icon: "wifi",
          title: "Beware of public WiFi",
          description: `
            <p>
              When using public WIFI networks, avoid making purchases and/or accessing services that require entering a password.
            </p>
          `,
        },
        tip6: {
          icon: "social",
          title: "Limit social media",
          description: `
            <p>
              Do not post more information than necessary! Check who can access your information and publications.
            </p>
          `,
        },
        tip7: {
          icon: "store",
          title: "Apps only from official sites",
          description: `
            <p>
              Install applications from trusted official sites (official developer website, Play Store or Apple Store).
            </p>
          `,
        },
        tip8: {
          icon: "antivirus",
          title: "Use an Antivirus",
          description: `
            <p>
              Make sure that all your devices have high-quality security software protecting them at all times.
            </p>
          `,
        },
        tip9: {
          icon: "cloud",
          title: "Back Up",
          description: `
            <p>
              It is best to always have a backup copy of the information on your mobile and your computer in the cloud.
            </p>
          `,
        },
      },
    },
    footer: {
      press: "press",
      contact: "contact",
      newsletter: "newsletter",
      faq: "FAQs",
      privacy_policy: "privacy policy",
      cookie_policy: "cookies policy",
      legal_warn: "legal information",
      consent_cookies: "Cookie settings",
      send_files: "Send files",
      share_files: "Share files",
    },
  },

  uploader_page: {
    title: "Send and share large and big files up to 10 GB for free",
    description: `
      YDRAY is the fastest and easiest platform to send, transfer and share your large and heavy files with maximum security and privacy up to 10 GB for free
    `,
  },

  press: {
    title: "Press",
    description: "Page where to find the links to the news where to find YDRAY",
    content: `
      <h1>YDRAY in the international press</h1>
      <h2>News</h2>
      <h3>2023</h3>
      <div class="news-item">
        <h4>
          <a href="https://www.tvn-2.com/casi-las-cinco/video-ydray-aplicacion-permite-descargar-10g_8_2058955.html"
            class="news-item-link link"
            target="_blank"
            rel="noopener noreferrer"
            title="YDRAY es una aplicación que te permite descargar hasta 10G"
          >
            YDRAY es una aplicación que te permite descargar hasta 10G - (14/06/2023)
          </a>
        </h4>
        <p class="news-item-desc" title="Vídeo | YDRAY es una aplicación que te permite descargar hasta 10G - Casi Las Cinco | TVN Panamá">
          Vídeo | YDRAY es una aplicación que te permite descargar hasta 10G - Casi Las Cinco | TVN Panamá.
        </p>
      </div>
      <div class="news-item">
        <h4>
          <a href="https://www.adslzone.net/como-se-hace/gmail/enviar-adjuntos-superar-limite/"
            class="news-item-link link"
            target="_blank"
            rel="noopener noreferrer"
            title="Cómo enviar archivos adjuntos de más de 25 MB en Gmail"
          >
            Cómo enviar archivos adjuntos de más de 25 MB en Gmail - (13/03/2023)
          </a>
        </h4>
        <p class="news-item-desc" title="Truco para enviar archivos adjuntos de más de 50 MB en mensajes de correo electrónico de Gmail usando un servicio paralelo.">
        Truco para enviar archivos adjuntos de más de 50 MB en mensajes de correo electrónico de Gmail usando un servicio paralelo.
        </p>
      </div>
      <div class="news-item">
        <h4>
          <a href="https://www.adslzone.net/listas/mejores-webs/alternativas-wetransfer/"
            class="news-item-link link"
            target="_blank"
            rel="noopener noreferrer"
            title="Envía archivos grandes gratis con estas alternativas a WeTransfer"
          >
            Envía archivos grandes gratis con estas alternativas a WeTransfer - (07/03/2023)
          </a>
        </h4>
        <p class="news-item-desc" title="Las mejores alternativas a WeTransfer para enviar archivos grandes gratis por correo electrónico o por enlace y sin registro.">
        Las mejores alternativas a WeTransfer para enviar archivos grandes gratis por correo electrónico o por enlace y sin registro.
        </p>
      </div>
      <h3>2022</h3>
      <div class="news-item">
        <h4>
          <a href="https://www.semana.com/tecnologia/articulo/google-drive-asi-puede-liberar-espacio-en-su-cuenta/202231/"
            class="news-item-link link"
            target="_blank"
            rel="noopener noreferrer"
            title="Google Drive: así puede liberar espacio en su cuenta"
          >
            Google Drive: así puede liberar espacio en su cuenta - (01/11/2022)
          </a>
        </h4>
        <p class="news-item-desc" title="Google Drive es utilizado para guardar archivos multimedia como fotos, videos y audios, entre otros documentos. ">
        Google Drive es utilizado para guardar archivos multimedia como fotos, videos y audios, entre otros documentos. 
        </p>
      </div>
      <div class="news-item">
        <h4>
          <a href="https://www.adslzone.net/noticias/internet/mejores-webs-enviar-archivos-grandes-sin-limitacion-tamano/"
            class="news-item-link link"
            target="_blank"
            rel="noopener noreferrer"
            title="El tamaño no importa: las mejores webs para enviar archivos grandes por Internet"
          >
          El tamaño no importa: las mejores webs para enviar archivos grandes por Internet (26/09/2022)
          </a>
        </h4>
        <p class="news-item-desc" title="Enviar archivos por Internet es una tarea de lo más sencilla y con multitud de posibilidades. Sin embargo, a veces hay limitaciones por tamaño.">
        Enviar archivos por Internet es una tarea de lo más sencilla y con multitud de posibilidades. Sin embargo, a veces hay limitaciones por tamaño.
        </p>
      </div>
      <div class="news-item">
        <h4>
          <a href="https://www.elsuplemento.es/premiado/ydray-premio-nacional-de-tecnologia-2022-en-la-categoria-soluciones-tecnologicas"
            class="news-item-link link"
            target="_blank"
            rel="noopener noreferrer"
            title="YDRAY, Premio Nacional de Tecnología 2022 en la categoría Soluciones Tecnológicas | El Suplemento"
          >
            YDRAY, Premio Nacional de Tecnología 2022 en la categoría Soluciones Tecnológicas - (25/05/2022)
          </a>
        </h4>
        <p class="news-item-desc" title="YDRAY es la plataforma de gestión y envío de archivos online de referencia en España y América">
        YDRAY es la plataforma de gestión y envío de archivos online de referencia en España y América
        </p>
      </div>
      <div class="news-item">
        <h4>
          <a href="https://computerhoy.com/tutoriales/tecnologia/como-mandar-emails-adjuntos-25-mb-gmail-970223"
            class="news-item-link link"
            target="_blank"
            rel="noopener noreferrer"
            title="Cómo mandar emails adjuntos de más de 25 MB en Gmail"
          >
            Cómo mandar emails adjuntos de más de 25 MB en Gmail - (27/01/2022)
          </a>
        </h4>
        <p class="news-item-desc" title="Te contamos como puedes mandar un archivo adjunto de más de 35 GB en un correo electrónico de Gmail. Es mucho más sencillo de lo puedes imaginar.">
        Te contamos como puedes mandar un archivo adjunto de más de 35 GB en un correo electrónico de Gmail. Es mucho más sencillo de lo puedes imaginar.
        </p>
      </div>
      <div class="news-item">
        <h4 class="news-item-title">
          <a href="https://www.emprendedores.es/ayudas/estudio-de-ydray-com-sobre-uso-de-la-nube/"
            class="news-item-link link"
            target="_blank"
            title="Ydray.com estudio sobre el uso de la nube en 2021"
            rel="noopener noreferrer">
            Ydray.com estudio sobre el uso de la nube en 2021 - (02/12/2021)
          </a>
        </h4>
        <p
        class="news-item-desc"
        title="Ydray.com, la empresa de transferencia de archivos, presenta los resultados de su estudio sobre hábitos y usos de la nube. Los usuarios de este tipo de plataformas la emplean para enviar archivos de entre 100 y 500 megas, tanto para fines profesionales como particulares."
        >
        Ydray.com, la empresa de transferencia de archivos, presenta los resultados de su estudio sobre hábitos y usos de la nube.
        Los usuarios de este tipo de plataformas la emplean para enviar archivos de entre 100 y 500 megas, tanto para fines profesionales como particulares.
        </p>
      </div>
      <div class="news-item">
        <h4>
          <a href="https://vistarmagazine.com/como-enviar-archivos-pesados-desde-cuba-sin-usar-wetransfer/"
            class="news-item-link link"
            target="_blank"
            rel="noopener noreferrer"
            title="¿Cómo enviar archivos pesados desde Cuba sin usar WeTransfer?"
          >
            ¿Cómo enviar archivos pesados desde Cuba sin usar WeTransfer? (09/07/2021)
          </a>
        </h4>
        <p class="news-item-desc" title="Si necesitas enviar archivos pesados desde Cuba hay más de una manera de hacerlo. Descubre aquí otras opciones además de la popular WeTransfer">
        Si necesitas enviar archivos pesados desde Cuba hay más de una manera de hacerlo. Descubre aquí otras opciones además de la popular WeTransfer
        </p>
      </div>
      <div class="news-item">
        <h4>
          <a href="https://miracomohacerlo.com/enviar-videos-pesados-imgenes-audios-archivos-whatsapp/"
            class="news-item-link link"
            target="_blank"
            rel="noopener noreferrer"
            title="Cómo Enviar Vídeos Pesados, Imágenes, Audios y Archivos de más de 100 MB por WhatsApp | Mira Cómo Hacerlo"
          >
            Cómo Enviar Vídeos Pesados, Imágenes, Audios y Archivos de más de 100 MB por WhatsApp - (31/03/2021)
          </a>
        </h4>
        <p class="news-item-desc" title="¡No es imposible! Hay distintas formas de compartir archivos, fotos y videos de más de 100 MB por WhatsApp y aquí te contamos cómo ?">
        ¡No es imposible! Hay distintas formas de compartir archivos, fotos y videos de más de 100 MB por WhatsApp y aquí te contamos cómo ?
        </p>
      </div>
      <div class="news-item">
        <h4>
          <a href="https://miracomohacerlo.com/que-es-wetransfer-y-como-funciona/"
            class="news-item-link link"
            target="_blank"
            rel="noopener noreferrer"
            title="WeTransfer: ¿Qué es y como funciona? Aprende cómo Enviar y Recibir Archivos Pesados | Mira Cómo Hacerlo"
          >
            WeTransfer: ¿Qué es y como funciona? Aprende cómo Enviar y Recibir Archivos Pesados - (28/02/2021)
          </a>
        </h4>
        <p class="news-item-desc" title="¿Cuáles son los pasos para poder enviar archivos pesados a través de WeTransfer ? Conoce: ¿Qué es y como funciona?">
        ¿Cuáles son los pasos para poder enviar archivos pesados a través de WeTransfer ? Conoce: ¿Qué es y como funciona?
        </p>
      </div>
      <h3>2020</h3>
      <div class="news-item">
        <h4>
          <a href="https://www.tekcrispy.com/2020/11/11/ydray-transferir-archivos/"
            class="news-item-link link"
            target="_blank"
            rel="noopener noreferrer"
            title="YDRAY, un servicio para transferir archivos de hasta 10GB gratis"
          >
            YDRAY, un servicio para transferir archivos de hasta 10GB gratis - (11/11/2020)
          </a>
        </h4>
        <p class="news-item-desc" title="YDRAY es un sitio web en donde podrás transferir archivos de hasta 10GB de forma segura y completamente gratuita.">
        YDRAY es un sitio web en donde podrás transferir archivos de hasta 10GB de forma segura y completamente gratuita.
        </p>
      </div>
      <div class="news-item">
        <h4>
          <a href="https://intereconomia.com/noticia/ydray-amplia-su-servicio-gratuito-de-envio-de-archivos-online-hasta-los-10-gb-20201026-1300/"
            class="news-item-link link"
            target="_blank"
            rel="noopener noreferrer"
            title="YDRAY amplía su servicio gratuito de envío de archivos online hasta los 10 Gb"
          >
            YDRAY amplía su servicio gratuito de envío de archivos online hasta los 10 Gb - (20/10/2020)
          </a>
          </h4>
        <p class="news-item-desc" title="El nuevo contexto generado por la actual crisis del Covid 19 ha cambiado la vida y los hábitos de todo el mundo, hecho ante el que YDRAY no ha podido permanecer impasible, lo que le ha llevado a aplicar una serie de medidas en su versión gratuita de la plataforma Ydray.com">
        El nuevo contexto generado por la actual crisis del Covid 19 ha cambiado la vida y los hábitos de todo el mundo, hecho ante el que YDRAY no ha podido permanecer impasible, lo que le ha llevado a aplicar una serie de medidas en su versión gratuita de la plataforma Ydray.com
        </p>
      </div>
      <div class="news-item">
        <h4>
          <a href="https://www.xataka.com/servicios/21-servicios-gratis-para-enviar-archivos-grandes#:~:text=Ydray,a%20un%20m%C3%A1ximo%20de%2020."
            class="news-item-link link"
            target="_blank"
            rel="noopener noreferrer"
            title="21 servicios gratis para enviar archivos grandes"
          >
            21 servicios gratis para enviar archivos grandes - (29/09/2020)
          </a>
        </h4>
        <p class="news-item-desc" title="Tener que compartir grandes cantidades de información es una necesidad bastante habitual en algunos trabajos y también en el ámbito del ocio. Una sesión de...">
        Tener que compartir grandes cantidades de información es una necesidad bastante habitual en algunos trabajos y también en el ámbito del ocio. Una sesión de...
        </p>
      </div>
      <div class="news-item">
        <h4>
          <a href="https://www.europapress.es/portaltic/internet/noticia-servicios-gratuitos-permiten-enviar-archivos-pesados-20200927130036.html"
            class="news-item-link link"
            target="_blank"
            rel="noopener noreferrer"
            title="Servicios gratuitos que permiten enviar archivos pesados"
            >
            Servicios gratuitos que permiten enviar archivos pesados - (27/09/2020)
          </a>
        </h4>
        <p class="news-item-desc" title="Hoy en día son muchas las personas que necesitan compartir archivos de gran peso de forma...">
        Hoy en día son muchas las personas que necesitan compartir archivos de gran peso de forma...
        </p>
      </div>
      <div class="news-item">
        <h4>
          <a href="https://geekinsider.com/ydray-an-emerging-yet-robust-file-sharing-solution/"
            class="news-item-link link"
            target="_blank"
            rel="noopener noreferrer"
            title="YDRAY: An Emerging Yet Robust File Sharing Solution"
          >
            YDRAY: An Emerging Yet Robust File Sharing Solution - (02/10/2020)
          </a>
        </h4>
        <p class="news-item-desc" title="Based out of Europe, YDRAY is a file transfer service that allows you to share large files of up to 10 GB each. The easy-to-use solution allows you to directly share these files with designated">
        Based out of Europe, YDRAY is a file transfer service that allows you to share large files of up to 10 GB each. The easy-to-use solution allows you to directly share these files with designated
        </p>
      </div>
      <div class="news-item">
        <h4>
          <a href="https://roastbrief.com.mx/2020/10/ydray-com-amplia-su-servicio-gratuito-con-motivo-de-la-situacion-excepcional/"
            class="news-item-link link"
            target="_blank"
            rel="noopener noreferrer"
            title="Ydray.com amplía su servicio gratuito con motivo de la situación excepcional"
          >
            Ydray.com amplía su servicio gratuito con motivo de la situación excepcional - (01/10/2020)
          </a>
        </h4>
        <p class="news-item-desc" title="YDRAY es una plataforma para el envío gratuito online de archivos de hasta 5 Gb (actualmente 10Gb) de forma sencilla">
        YDRAY es una plataforma para el envío gratuito online de archivos de hasta 5 Gb (actualmente 10Gb) de forma sencilla
        </p>
      </div>
      <div class="news-item">
        <h4>
          <a href="https://www.eluniversal.com.mx/techbit/cinco-herramientas-para-enviar-archivos-pesados/"
            class="news-item-link link"
            target="_blank"
            rel="noopener noreferrer"
            title="Cinco herramientas para enviar archivos pesados"
          >
            Cinco herramientas para enviar archivos pesados - (03/05/2020)
          </a>
        </h4>
        <p class="news-item-desc" title="Con estos servicios podrás enviar archivos grandes sin preocuparte de restricciones de tamaño">
        Con estos servicios podrás enviar archivos grandes sin preocuparte de restricciones de tamaño
        </p>
      </div>
      <div class="news-item">
        <h4>
          <a href="https://www.dineroenimagen.com/management/lista-de-apps-y-sitios-premium-que-ahora-son-gratis/121197"
            class="news-item-link link"
            target="_blank"
            rel="noopener noreferrer"
            title="Lista de apps y sitios premium que ahora son gratis"
          >
            Lista de apps y sitios premium que ahora son gratis - (02/04/2020)
          </a>
        </h4>
        <p class="news-item-desc" title="Muchas, en realidad, muchas compañías han estado apoyando la situación actual poniendo sus servicios de forma gratuita para todas las pers">
        Muchas, en realidad, muchas compañías han estado apoyando la situación actual poniendo sus servicios de forma gratuita para todas las pers
        </p>
      </div>
      <div class="news-item">
        <h4>
          <a href="https://www.emprendedores.es/ayudas/ayuda-crear-empresa-negocio/recursos-gratuitos-emprendedores/"
            class="news-item-link link"
            target="_blank"
            rel="noopener noreferrer"
            title="Recursos gratuitos a disposición de los emprendedores en tiempos de crisis - Emprendedores"
          >
            Recursos gratuitos a disposición de los emprendedores en tiempos de crisis - Emprendedores - (19/03/2020)
          </a>
        </h4>
        <p class="news-item-desc" title="La mayoría de estos recursos han sido dispuestos de forma gratuita por otros emprendedores para ayudar a sus colegas en distintas áreas de negocio ante la situación actual. Estos son algunos de los que se ofrecen.">
        La mayoría de estos recursos han sido dispuestos de forma gratuita por otros emprendedores para ayudar
        a sus colegas en distintas áreas de negocio ante la situación actual. Estos son algunos de los que se ofrecen.
        </p>
      </div>
      <h3>2019</h3>
      <div class="news-item">
        <h4>
          <a href="https://www.businessinsider.es/7-plataformas-servicios-compartir-gratis-archivos-5gb-501917"
            class="news-item-link link"
            target="_blank"
            rel="noopener noreferrer"
            title="Estas 7 plataformas y servicios te permitirán compartir archivos de más de 5GB online y gratis"
          >
            Estas 7 plataformas y servicios te permitirán compartir archivos de más de 5GB online y gratis - (26/10/2019)
          </a>
        </h4>
        <p class="news-item-desc" title="Enviar archivos de gran tamaño a otras personas es posible gracias a distintas plataformas gratuitas, te contamos cuáles son algunas de las más fiables y cómodas.">
        Enviar archivos de gran tamaño a otras personas es posible gracias a distintas plataformas gratuitas, te contamos cuáles son algunas de las más fiables y cómodas.
        </p>
      </div>
      <div class="news-item">
        <h4>
          <a href="https://www.businessinsider.es/7-plataformas-servicios-compartir-gratis-archivos-5gb-501917"
            class="news-item-link link"
            target="_blank"
            rel="noopener noreferrer"
            title="7 plataformas y servicios para compartir gratis archivos de más de 5GB"
          >
            7 plataformas y servicios para compartir gratis archivos de más de 5GB - (26-10-2019)
          </a>
        </h4>
        <p class="news-item-desc" title="Enviar archivos de gran tamaño a otras personas es posible gracias a distintas plataformas gratuitas, te contamos cuáles son algunas de las más fiables y cómodas.">
        Enviar archivos de gran tamaño a otras personas es posible gracias a distintas plataformas gratuitas, te contamos cuáles son algunas de las más fiables y cómodas.
        </p>
      </div>
      <div class="news-item">
        <h4>
          <a href="https://www.tusequipos.com/2019/07/06/10-recursos-gratuitos-para-compartir-archivos-grandes/"
            class="news-item-link link"
            target="_blank"
            rel="noopener noreferrer"
            title="10 recursos gratuitos para compartir archivos grandes"
          >
            10 recursos gratuitos para compartir archivos grandes - (06/07/2019)
          </a>
        </h4>
        <p class="news-item-desc" title="¿Estas buscando opciones para compartir archivos grandes con tus contactos?">
        ¿Estas buscando opciones para compartir archivos grandes con tus contactos?
        </p>
      </div>
      <div class="news-item">
        <h4>
          <a href="https://www.interempresas.net/TIC/Articulos/245230-Guardar-archivos-en-la-nube-Que-tener-en-cuenta.html"
            class="news-item-link link"
            target="_blank"
            rel="noopener noreferrer"
            title="¿Qué tener en cuenta para guardar archivos en la nube?"
          >
          ¿Qué tener en cuenta para guardar archivos en la nube? - (09/05/2019)
          </a>
        </h4>
        <p class="news-item-desc" title="El almacenamiento de archivos ha evolucionado espectacularmente en los últimos 30/40 años. Empezamos por guardar nuestros datos y archivos informáticos en voluminosos equipos de hardware. Después, se pasó a los discos duros portátiles">
        El almacenamiento de archivos ha evolucionado espectacularmente en los últimos 30/40 años. Empezamos por guardar nuestros datos
        y archivos informáticos en voluminosos equipos de hardware. Después, se pasó a los discos duros portátiles
        </p>
      </div>
      <div class="news-item">
        <h4>
          <a href="https://www.muycomputer.com/2019/04/08/como-enviar-archivos-pesados-gratis/"
            class="news-item-link link"
            target="_blank"
            rel="noopener noreferrer"
            title="9 herramientas para enviar archivos pesados gratis por Internet"
          >
            9 herramientas para enviar archivos pesados gratis por Internet - (08/04/2019)
          </a>
        </h4>
        <p class="news-item-desc" title="Aunque el correo electrónico o la mensajería instantánea son fundamentales en nuestro día a día, también tienen limitaciones. Una de las más importantes es el tamaño máximo de los archivos que podemos enviar adjuntos y, para ayudaros a solventarla, en este práctico recopilamos nuestras nueve soluciones favoritas para solucionar este problema.">  
        Aunque el correo electrónico o la mensajería instantánea son fundamentales en nuestro día a día, también tienen limitaciones.
        Una de las más importantes es el tamaño máximo de los archivos que podemos enviar adjuntos y, para ayudaros a solventarla,
        en este práctico recopilamos nuestras nueve soluciones favoritas para solucionar este problema.
        </p>
      </div>
      <div class="news-item">
        <h4>
          <a href="https://applicantes.com/ydray-renueva-competir-dropbox-wetransfer/"
            class="news-item-link link"
            target="_blank"
            rel="noopener noreferrer"
            title="YDRAY se renueva para competir con Dropbox y Wetransfer"
          >
            YDRAY se renueva para competir con Dropbox y Wetransfer - (04/01/2019)
          </a>
        </h4>
        <p class="news-item-desc" title="YDRAY, el servicio para enviar archivos online de gran tamaño de origen español, ha iniciado 2019 con una renovación de su plataforma. Ydray.com ha añadido tres grandes novedades. En primer lugar, sus usuarios de la versión gratuita ya tienen la posibilidad de proteger los archivos con una c">
        YDRAY, el servicio para enviar archivos online de gran tamaño de origen español, ha iniciado 2019 con una renovación de su plataforma. Ydray.com ha añadido tres grandes novedades. En primer lugar, sus usuarios de la versión gratuita ya tienen la posibilidad de proteger los archivos con una c
        </p>
      </div>
      <h3>2018</h3>
      <div class="news-item">
        <h4>
          <a href="https://tools.robingood.com/24188/send-and-share-files-as-large-as-5gb-without-using-email-ydray"
            class="news-item-link link"
            target="_blank"
            rel="noopener noreferrer"
            title="Send and share files as large as 5GB without using email: YDRAY"
          >
            Send and share files as large as 5GB without using email: YDRAY - (15/01/2018)
          </a>
        </h4>
        <p class="news-item-desc" title="Free web app that allows to send files as large as 5GB to anyone without registration or login. Free version allows to distribute files to up to 20 recipients and to include as many as 50 files in one-go. Files sent are stored and made available for 7 days">
        Free web app that allows to send files as large as 5GB to anyone without registration or login. Free version allows to distribute files to up to 20 recipients and to include as many as 50 files in one-go. Files sent are stored and made available for 7 days
        </p>
      </div>
    `,
  },

  faq: {
    title: "FAQ",
    description: "YDRAY FAQ Page",
    content: `
    <h1>FAQ</h1>
    <h2>How do I send a file using Ydray.com?</h2>
    <p>To send a file using Ydray.com, simply visit the website and click on the <b>Add your files</b>" button.</p>
    <p>Then, select or drag and drop the file(s) you want to send from your computer.</p>
    <p>You can add a message or recipient email addresses, and then click on the <b>Transfer</b> button to start the upload.</p>
    <p>If you sing up to Ydray.com, in addition you can choose when the link will be deleted and set up a password.</p>
    <h2>How do I receive a file using Ydray.com?</h2>
    <p>When someone sends you a file using Ydray.com you will receive an email notification with a download link.</p>
    <p>Simply click on the link to download the file.</p>
    <h2>Is there a limit on the file size I can send with Ydray.com?</h2>
    <p>Yes, Ydray.com has a limit of 10 GB per transfer.</p>
    <p>If you need to send larger files, you can use their paid service, YDRAY Pro.</p>
    <h2>Is it secure to use Ydray.com for file transfer?</h2>
    <p>Yes, Ydray.com is a secure platform that uses end-to-end encryption for all file transfers.</p>
    <p>This means that your files are protected from unauthorized access and interception during transmission.</p>
    <h2>Why should I use Ydray.com instead of other transfer platforms?</h2>
    <p>There are some key differences that may make Ydray.com a better choice for you:</p>
    <h3>Security</h3>
    <p>Ydray.com prioritizes security and privacy, offering end-to-end encryption for all file transfers. This means that your files are protected from unauthorized access and interception during transmission.</p>
    <h3>Unlimited file size</h3>
    <p>With Ydray.com, you can send files up to 10 GB per transfer, whereas others have a limit of 2GB per transfer.</p>
    <h3>No ads or distractions</h3>
    <p>Ydray.com does not show ads or any other distractions during the transfer process, whereas others show ads and promotional messages.</p>
    <h3>Faster uploads</h3>
    <p>Ydray.com uses a highly optimized and scalable upload infrastructure, allowing for faster file uploads compared to others services providers</p>
    <p>Overall, Ydray.com offers a more secure, customizable, and professional file transfer experience compared to other transfer files portals. </p>
    `,
  },

  privacy: {
    title: "Privacy Policy",
    description: "Explanation of the YDRAY privacy policy",
    content: `
    <h1>Privacy Policy</h1>
    <p>In accordance with the provisions of Regulation (EU) 2016/679, of April 27, regarding the protection of natural persons with regard to the processing of personal data and the free circulation of these data (hereinafter, GDPR).</p>
    <h2>Who is responsible for the processing of your data?</h2>
    <div class="table-container">
      <table>
        <tr>
          <td>Name</td>
          <td>RECICLAONLINE S.L.</td>
        </tr>
        <tr>
          <td>CIF</td>
          <td>B13538053</td>
        </tr>
        <tr>
          <td>Address</td>
          <td>Tr.ª Manzana 10, 13300, Valdepeñas, C. Real</td>
        </tr>
        <tr>
          <td>Email</td>
          <td><a class="link" href="mailto:privacidad@ydray.com" target="_blank" title="Send mail to privacidad@ydray.com">privacidad@ydray.com</a></td>
        </tr>
      </table>
    </div>
    <p>First of all, we want to thank you for using our service. The fact that you are reading this point right now indicates that privacy is as important to you as it is to us, CONGRATULATIONS!!!</p>
    <h2>How we differ from other services</h2>
    <p>One of our main added values is the harsh legislation that we have to comply with as a Spanish company.</p>
    <p>It is easy to see how large providers of "other" services refer to foreign laws or treaties (mainly California laws) and do not inform us of the physical location of their servers or the privacy measures adopted. This has a very significant impact on the privacy of Your Files, since normally, unconsciously we tend to think that when using these services Your Files will continue to be Your Files, which is not always the case. In our case, Your Files WILL continue to be Your Files and you will never assign us the rights to make them Our Files.</p>
    <h2>But, how is this possible?</h2>
    <p>We ask you to read the following paragraph carefully, as it is very explanatory regarding what concerns Privacy.</p>
    <p>It is quite common that with other service providers governed by California law, the rights to disclose, copy, reveal stored data, even be able to modify the property and content of the same, are assigned.</p>
    <p>However, our terms of use of the service state that:</p>
    <p>YDRAY does not have any right to trademarks, trade names, distinctive signs, designs and/or data stored on its servers. Likewise, it will not divulge, copy or reveal any stored data, so it will never access them unless there is a justified cause due to problems derived from judicial procedures or because they are requested by the state security forces and bodies. For this reason, YDRAY maintains an access log of the users' IP addresses.</p>
    <h2>For what purpose do we process your personal data?</h2>
    <p>Your personal data will only be used for the following purposes: registration, management and service for sending large-capacity files through our website to third parties. In the event that it is a client, accounting, fiscal and administrative management. Also, if you proceed to register, to send newsletters related to our services, news and communications.</p>
    <h2>What is the legitimation for the processing of your personal data?</h2>
    <p>Express consent of the user through the selection of the check box of the registry where his consent is verified in the case of sending files and newsletter. Pre-contractual measures in the case of budget requests or other information in relation to our services. If you are a client for the execution of a contract as a client. Compliance with legal obligations applicable to the activity, derived from commercial and tax legislation.</p>
    <h2>How long will we keep your personal data?</h2>
    <p>The data will be kept for one week from when you send it. If you are a customer, the personal data you provide us will be kept as long as our contractual or commercial relationship is maintained. However, from the date of termination of our contractual or commercial relationship, your data will be kept:</p>
    <ul>
      <li>ONE YEAR those derived from connections made over the internet with our website, email and voice telephone calls, instant messaging, SMS or MMS In the event that you are our client, your data will be kept.</li>
      <li>FOUR YEARS for tax purposes. The accounting books and other mandatory record books according to the applicable tax regulations (IRPF, VAT, IS, etc.) as well as the documentary supports that justify the entries recorded in the books.</li>
      <li>SIX YEARS the personal data included in the mandatory books, documentation and supporting documents concerning my professional activity will be kept in accordance with the Commercial Code.</li>
      <li>INDEFINITELY in the case of the newsletter, unless you revoke your consent. In each email you will receive an option to unsubscribe.</li>
    </ul>
    <h2>how did I obtain your personal data?</h2>
    <p>The data obtained has not been provided by you.</p>
    <h2>To wich recipients will your personal data be communicated?</h2>
    <p>There is no planned transfer of data, except legal obligation.</p>
    <h2>What are your rights when your provide me with your personal data?</h2>
    <p>Any interested party may request the exercise of the following rights:</p>
    <ul>
      <li>Right of access: The interested party will have the right to obtain confirmation of whether or not personal data concerning him or her is being processed.</li>
      <li>Right of rectification: The interested party will have the right to obtain the rectification of inaccurate or incomplete personal data that concerns him.</li>
      <li>Right of deletion: The interested party will have the right to obtain the deletion of personal data that concerns him when the personal data is no longer necessary in relation to the purposes for which it was collected or otherwise processed.</li>
      <li>Right of limitation: You may request the limitation of the processing of your personal data, in which case you would only keep them for the exercise or defense of claims.</li>
      <li>Right to the portability of your data: Therefore, you can request that your automated personal data be transferred or transferred to any other company that you indicate in a structured, intelligible and automated format.</li>
      <li>Right to withdraw consent: You will have the right to withdraw consent at any time, without affecting the legality of the treatment based on consent before its withdrawal.</li>
      <li>Right of opposition: The interested party will have the right to oppose the processing of their data.</li>
    </ul>
    <h2>How can you exercise these rights?</h2>
    <p>If requested, we provide you with the forms where you can exercise these rights. You can request us by email <a class="link" href="mailto:info@ydray.com" target="_blank" title="send email to info@ydray.com">info@ydray.com</a> indicating the right you want to exercise and we will send you the corresponding form. The exercise of rights must be carried out through communication addressed to the email <a class="link" href="mailto:privacidad@ydray.com" target="_blank" title="Send email to privacidad@ydray.com">privacidad@ydray.com</a> or at the postal address Travesía Manzana 10, 13300, Valdepeñas, Ciudad Real, Spain.</p>
    <h2>Who can exercise the rights?</h2>
    <p>The rights of the interested parties are very personal, therefore, they will be exercised by the owner of the data, duly proving their identity (for this, the DNI or equivalent will be requested).</p>
    <p>It may also be exercised through legal representation, in which case, in addition to the ID of the interested party or equivalent, an ID must be provided and an authentic document accrediting the representation of the third party.</p>
    <h2>What will be our obligation when you exercise any of the rights?</h2>
    <p>The person in charge of the treatment must answer the request that is addressed to him in any case, regardless of whether or not the personal data of the affected person or interested party in their treatments appear.</p>
    <p>In the event that the request does not meet the specified requirements, the person responsible for the file must request their rectification.</p>
    <p>The data controller will respond to requests within one month of receipt of the request. Said term may be extended by another two months if necessary, taking into account the complexity and number of requests. The person in charge will inform the interested party of any of said extensions within a month from the receipt of the request, indicating the reasons for the delay.</p>
    <h2>Right to complaint to the control authority</h2>
    <p>You may request the protection of rights that have not been duly attended to by the Spanish Data Protection Agency. Either through the electronic headquarters of its web portal (<a class="link" href="www.agpd.es" title="www.agpd.es">www.agpd.es</a>), or by writing to your postal address (C/ Jorge Juan, 6, 28001-Madrid).</p>
    <h2>What information do we collect?</h2>
    <p>In general, you can use the Website without providing any personal information. We have several sections on our website in which there are personal data collection forms that are necessary to be able to provide you with the services you request. When you send us any of these forms requesting information, the user guarantees the authenticity, accuracy and veracity of all the information you provide us, committing to keep the personal data you provide us updated so that they respond, at all times, to your actual situation. The User will be solely responsible for false or inaccurate statements and for the damages that they may cause.</p>
    <h2>What steps do we take to keep your information secure?</h2>
    <p>We apply reasonable technical and physical measures to protect the information we collect through the Website.</p>
    `,
  },

  cookies: {
    title: "Cookies Policy",
    description: "Explanation of the YDRAY cookie policy",
    content: `
    <h1>Cookies Policy</h1>
    <p>Please read this privacy statement carefully before using this Site, you will be aware of how we use cookies.</p>
    <p><b>The use of this site implies the acceptance of our cookie policy. Otherwise do not use this Site.</b></p>
    <h2>1. Use of cookies</h2>
    <h3>1.1. What is a cookie?</h3>
    <p>A cookie is a small file, usually made up of letters and numbers, sent by our web server to the cookie file of your browser. This allows our website to remember your presence by establishing a new connection between our server and your browser, as well as improve the quality of the service by analyzing your browsing habits.</p>
    <h3>1.2. What type of cookies does this Site use?</h3>
    <h4>Session cookies</h4>
    <p>these are temporary cookies that remain in the cookie file of your browser while you are on our website. These cookies are necessary for certain applications or functions and allow this Site to function properly.</p>
    <h4>Analysis cookies</h4>
    <p>These are those that allow the person responsible for them to monitor and analyze the behavior of the users of the websites to which they are linked. The information collected through this type of cookies is used to measure the activity of the websites and to create browsing profiles of the users of said sites in order to introduce improvements based on their analysis of the usage data. what service users do.</p>
    <div class="table-container">
      <table>
        <thead>
          <tr>
            <td>Cookie</td>
            <td>Type</td>
            <td>Function</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>YDRAY_COM</td>
            <td>Session</td>
            <td>Correct operation of the page</td>
          </tr>
          <tr>
            <td>ydray</td>
            <td>Session</td>
            <td>Correct operation of the page</td>
          </tr>
          <tr>
            <td>st_ydy</td>
            <td>Session</td>
            <td>Correct operation of the page</td>
          </tr>
          <tr>
            <td>st_hmac</td>
            <td>Session</td>
            <td>Correct operation of the page</td>
          </tr>
          <tr>
            <td>csrf_cookie</td>
            <td>Session</td>
            <td>Correct operation of the page</td>
          </tr>
          <tr>
            <td>AcceptCookies</td>
            <td>Session</td>
            <td>Correct operation of the page</td>
          </tr>
          <tr>
            <td>_ga</td>
            <td>Analytics</td>
            <td>Obtain navigation information, measure and analyze the audience</td>
          </tr>
          <tr>
            <td>_gat</td>
            <td>Analytics</td>
            <td>Obtain navigation information, measure and analyze the audience</td>
          </tr>
          <tr>
            <td>_gid</td>
            <td>Analytics</td>
            <td>Obtain navigation information, measure and analyze the audience</td>
          </tr>
          <tr>
            <td>_asc</td>
            <td>Analytics</td>
            <td>Obtain navigation information, measure and analyze the audience</td>
          </tr>
          <tr>
            <td>_auc</td>
            <td>Analytics</td>
            <td>Obtain navigation information, measure and analyze the audience</td>
          </tr>
        </tbody>
      </table>
    </div>
    <p>On other YDRAY sites, third-party cookies are installed for all its visitors, even if they are not registered users on the corresponding platforms:</p>
    <h4>1.2.3. Google Analytics</h4>
    <p>Its purposes are described in the <a class="link" href="http://www.google.com/intl/en/policies/" target="_blank" title="Google Policies Page">Google Policies Page</a>.</p>
    <h4>1.2.4 Facebook</h4>
    <p>Its purposes are described in the <a class="link" href="https://www.facebook.com/help/cookies" target="_blank" title="Facebook Cookies Page">Facebook Cookies Page</a>.</p>
    <h4>1.2.5 X (Twitter)</h4>
    <p>Its purposes are described in the <a class="link" href="https://twitter.com/privacy" target="_blank" title="X (Twitter) Privacy Page">X (Twitter) Privacy Page</a>.</p>
    <h4>1.2.6 YouTube</h4>
    <p>Its purposes are described in the <a class="link" href="http://www.youtube.com/yt/policyandsafety/es/policy.html" target="_blank" title="Youtube Privacy Page">Youtube Privacy Page</a>.</p>
    <h2>2. Enabling and disabling cookies and similar resources</h2>
    <h3>2.1. Internet Explorer</h3>
    <p>Tools → Internet Options → Privacy → Settings.</p>
    <p>For more information, you can consult <a class="link" href="http://windows.microsoft.com/en-US/windows/support" target="_blank" title="Microsoft support">Microsoft support</a> or the browser's Help.</p>
    <h3>2.2. Firefox</h3>
    <p>Tools → Options → Privacy → History → Custom Settings.</p>
    <p>For more information, you can consult <a class="link" href="http://support.mozilla.org/en/home" target="_blank" title="Mozilla support">Mozilla support</a> or the browser's Help.</p>
    <h3>2.3. Chrome</h3
    <p>Settings → Show advanced options → Privacy → Content settings.</p>
    <p>For more information, you can consult the <a class="link" href="http://support.google.com/chrome/?hl=en" target="_blank" title="Google support">Google support</a> or the browser Help.</p>
    <h3>2.4. Safari</h3
    <p>Preferences → Security.</p>
    <p>For more information, you can refer to <a class="link" href="http://www.apple.com/en/support/safari/" target="_blank" title="Apple Support">Apple Support</a> or your browser's Help.</p>
    `,
  },

  legal_warn: {
    title: "Legal Information",
    description: "YDRAY Legal Information",
    content: `
    <h1>Legal Information</h1>
    <p>Thank you for trusting Ydray.com, at this point we explain the objective of the web portal and compliance with Law 34/2002.</p>
    <h2>Objective of the web portal</h2>    
    <p>This web portal aims to be able to send, share and store large files between users, companies and contacts easily and safely.</p>
    <p>Our servers are located in Europe, all of which are dedicated servers. For us, the privacy of your files comes first, for this reason, we flee from the famous cloud, a much-discussed system in terms of privacy and legal coverage, and which is used by all of our competition.</p>
    <h2>Information on compliance with Law 34/2002</h2>
    <p>In compliance with Law 34/2002, of July 11, on Services of the Information Society and Electronic Commerce, we inform you that RECICLAONLINE SL, domiciled at Travesía Manzana 10, 13300, Valdepeñas, Ciudad Real, Spain with CIF no. B13538053 and registered in the CIUDAD REAL Registry, Volume 550, Book 0, page 206, page CR-21866, 1st entry, is the owner of the page <a class="link" href="www.ydray.com" title="www.ydray.com">www.ydray.com</a>.</p>
    `,
  },

  send_files: {
    title: "Send large files up to 10 GB for free",
    description: `
      YDRAY is the easiest and most efficient way to send and transfer large files quickly with maximum
      privacy and security up to 10 GB for free
    `,
    content: `
      <h1>Send files with YDRAY</h1>
      <p>
        YDRAY is a <strong>versatile</strong> and <strong>practical</strong> tool that can provide you
        multiple benefits both in <strong>your work</strong> and in your <strong>personal life</strong>.
        With its ability to <a class="link" href="/en/send-files/big" title="Send big files with YDRAY">
        send big files</a> quickly and easily, YDRAY has become a <strong>reliable</strong> and
        <strong>safe</strong> solution to manage your digital files. Below we will explore how YDRAY can
        help you on both fronts.
      </p>
      <h2>YDRAY at work</h2>
      <img
        src="../../../assets/images/send-files-working.png"
        alt="sending files at work"
        title="sending files at work"
        height="auto"
        width="auto"
        loading="eager"
      />
      <p>
        YDRAY is a <strong>must-have</strong> tool for sending and sharing files with
        colleagues, collaborators and clients. You can easily send presentations, documents,
        images, videos and other files related to your work. Whether you need to send a
        file to a colleague in the same or different office, collaborators from other cities or to a
        customer abroad, YDRAY allows you to do it <strong>efficiently</strong>,
        <strong>fast</strong> and <strong>without complications</strong>. You can send and share files
        without worrying about email size limitations or
        traditional messaging systems.
      </p>
      <p>
        In addition to its options when it comes to sending and sharing files, YDRAY also allows you to
        collaborate effectively on team projects. You can share and send entire folders
        with your workforce, which facilitates <strong>collaboration</strong> and <strong>the exchange of ideas</strong>.
        Team members can add and download files, stay up to date with the latest
        versions and work together. This functionality promotes <strong>productivity</strong>
        and <strong>efficiency</strong> in the work environment, since it eliminates the need to send multiple
        emails or use external storage devices.
      </p>
      <h2>YDRAY in your personal life</h2>
      <img
        src="../../../assets/images/send-files-friends.png"
        title="Sending files to friends"
        alt="Sending files to friends"
        height="auto"
        width="auto"
        loading="lazy"
      />
      <p>
        YDRAY is also useful for sending files to <strong>friends</strong> and <strong>family</strong>.
        You can share photos and videos from your last vacation, wedding or birthday celebrations, as well
        important documents or any other files that you want to send or share with your loved ones.
        YDRAY <strong>makes</strong> it easy to send these files, even if they are <strong>large</strong>
        size</strong>, allowing you to keep dear ones up to date and share significant moments.
      </p>
      <p>
        YDRAY is a tool that gives you <strong>peace of mind</strong> in terms of <strong>privacy</strong> and
        <strong>security</strong>. Use end-to-end encryption to protect your files during transfer,
        which means that only you and authorized recipients can access them.
        This is especially important when it comes to sending confidential or sensitive files.
        You can be <strong>confident</strong> that your files are <strong>protected</strong> and shared safely
        through YDRAY. It also offers the possibility of sending and sharing your files by assigning a
        password when sending them, giving an extra layer of <strong>protection</strong>.
      </p>
      <h2>Tips for sending files</h2>
      <img
        src="../../../assets/images/send-files-doubts.png"
        title="Tips when sending files"
        alt="Tips when sending files"
        height="auto"
        width="auto"
        loading="lazy"
      />
      <h3>How can I send the files?</h3>
      <p>
        With YDRAY, you can send files <strong>in an easy way</strong>. Just visit ydray.com,
        select the files you want to share and <strong>generate a link</strong> to share
        by email, Facebook, Instagram, X (Twitter), Whatsapp or Gmail. Click on generate link and YDRAY
        will take care of sharing your files safely and with maximum privacy. That easy!
      </p>
      <h3>What is the best file sharing platform?</h3>
      <p>
        The best platform for sending and sharing files is ydray.com. with its interface
        <strong>intuitive</strong> and <strong>easy to use</strong>, you can send large files
        size quickly and safely. YDRAY allows you to share files with friends, colleagues
        work, friends or clients in a simple way, making the experience of
        file sharing efficient and reliable.
        Discover YDRAY and enjoy a convenient way to send and share files online!
      </p>
      <h3>Where can I send large files for free?</h3>
      <p>
        YDRAY is the perfect solution for <a class="link" href="/en/send-files/free" title="Send big files
        for free with YDRAY">sending big files for free</a>.
        With YDRAY, you can send <strong>large files</strong> easily
        easily and quickly to anyone around the world. Just visit ydray.com,
        select the files you want to send and generate the link to share your files.
        Share files without limits and for free with YDRAY!
      </p>
      <h3>What is the name of the page to send files?</h3>
      <p>
        The popularly known page for sending and sharing files is called YDRAY. With
        YDRAY, you can easily send and share files in a <strong>secure</strong> and
        <strong>fast</strong>. Just visit ydray.com, select the files you want
        send and share them with your recipients. So you can send and share files
        efficiently with YDRAY!
      </p>
      <h2>In short, YDRAY</h2>
      <p>
        YDRAY is a <strong>valuable tool</strong> for sending and sharing files both
        both at work and in your personal life. <strong>Makes</strong> file transfer easy
        large, <strong>encourages</strong> team collaboration and offers security and
        privacy in your shipments. Either for work projects or to share special moments
        with your loved ones, YDRAY is a <strong>reliable</strong> and <strong>convenient</strong> option
        that will simplify your tasks of sending and sharing files.
      </p>
    `,
  },

  send_files_big: {
    title: `Send big files`,
    description: `Send big files`,
    content: `
    <h1>Send your large files with YDRAY</h1>
    <h2>Do you need to send your large files?</h2>
    <img
      src="../../../assets/images/send-big-files-doubts.png"
      alt="Send large files with YDRAY"
      title="Send large files with YDRAY"
      height="auto"
      width="auto"
      loading="eager"
    />
    <p>
      YDRAY is the most powerful platform that allows you to send large and heavy files, that is,
      <strong>large</strong> size, <strong>efficiently</strong> and <strong>securely</strong>. However,
      YDRAY stands out from its competitors, giving you a superior experience when it comes to
      <strong>sharing large files</strong>.
    </p>
    <p>
      One of the key benefits of YDRAY is its ability to
      <a class="link" href="/en/send-files" title="Send files with YDRAY">send files</a> for free.
      Unlike many other services, with YDRAY you can <strong>send large files up to 10GB</strong> without additional costs,
      that is, totally <strong>free</strong>. This is especially interesting when you need to <strong>send</strong> presentations,
      multimedia projects, 3D designs or large files that exceed the size limits of other services. YDRAY gives you the freedom to
      <strong>send large and heavy files</strong> without size restrictions and without spending extra money.
    </p>
    <h2>YDRAY's Features</h2>
    <img
      src="../../../assets/images/send-big-files-characteristics.png"
      alt="YDRAY's Features"
      title="YDRAY's Features"
      height="auto"
      width="auto"
      loading="eager"
    />
    <p>
      YDRAY excels in terms of <strong>security</strong> and <strong>privacy</strong>. Using <strong>end-to-end encryption</strong>
      for all large file transfers, ensuring your data is <strong>protected</strong> during transmission. YDRAY is committed to the
      <strong>confidentiality</strong> of your large files and gives you <strong>peace of mind</strong> knowing that your documents and
      multimedia content will be <strong>secure</strong> and only accessible to outsiders. authorized recipients. YDRAY is <strong>the best option</strong>
      to send large files in compliance with the <strong>GDPR (General Data Protection Regulation)</strong>.
    </p>
    <p>
      YDRAY has an <strong>intuitive interface</strong> and <strong>easy to use</strong>. YDRAY places the user at the center of the
      process, it has the best <strong>user experience</strong> when it comes to <strong>transferring files</strong>. The process when
      sending large files is simplified to the maximum, allowing you to <strong>save time</strong> and <strong>effort</strong>. With
      just a few clicks, you can upload your large files, select among the many free options and <strong>share the download link</strong>,
      either by copying the link, using your email, Gmail, Facebook, X (Twitter) and even by email. WhatsApp. <strong>Simplicity</strong> and
      <strong>efficiency</strong> are key elements in the YDRAY user experience, allowing you to send large and heavy files quickly
      and without complications.
    </p>
    <p>
      YDRAY offers <strong>customization</strong> options to suit your needs. You can add custom <strong>messages</strong> to your uploads,
      <strong>title</strong> associated with the download link and set <strong>expiration dates</strong> so the files are only available for a
      while limited. This flexibility allows you to have more control over your transfers and ensure that files are shared according to
      your preferences.
    </p>
    <h2>Conclusions</h2>
    <img
      src="../../../assets/images/send-big-files-conclusions.png"
      alt="Conclusions"
      title="Conclusions"
      height="auto"
      width="auto"
      loading="eager"
    />
    <p>
      YDRAY is positioned as a leading option to send <strong>large</strong>, <strong>oversized</strong> and
      <strong>heavy</strong> files completely <strong>for free</strong>. Its strengths in terms of <strong>storage capacity</strong>,
      <strong>security</strong> and <strong>ease of use</strong> set it apart from other competitors in the file transfer market,
      offering you a <strong>reliable</strong> and <strong>convenient</strong> solution for your file sharing and sending needs.
      Try YDRAY and discover an <strong>efficient</strong> and <strong>safe</strong> way to send and share large and heavy files,
      <strong>for free</strong> and <strong>without limitations</strong>.
    </p>
    `,
  },

  send_files_free: {
    title: `Send files for free`,
    description: `Send files for free`,
    content: `
      <h1>Send your files for free with YDRAY</h1>
      <h2>Do you need to send and share your files for free?</h2>
      <img
        src="../../../assets/images/send-free-files-doubts.png"
        alt="Send files for free with YDRAY"
        title="Send files for free with YDRAY"
        height="auto"
        width="auto"
        loading="eager"
      />
      <p>
        YDRAY is an excellent option to meet your needs. YDRAY stands out, as a platform for sending files for free,
        for its unique characteristics that differentiate it from its competitors, allowing you to send files for free
        efficiently and at no cost, that is, <strong>YDRAY allows you to send your files for free</strong> .
      </p>
      <h2>YDRAY Features</h2>
      <img
        src="../../../assets/images/send-free-files-characteristics.png"
        alt="YDRAY Features"
        title="YDRAY Features"
        height="auto"
        width="auto"
        loading="eager"
      />
      <p>
        YDRAY gives you the possibility to <strong>send files for free</strong>. You can transfer your documents, photos,
        videos and other files at no additional cost. This option is ideal for those who need to send files occasionally
        or who do not require advanced features.
      </p>
      <p>
        YDRAY also allows you <strong>easy file sharing</strong>. You can send a download link to your contacts, that they will be able
        to access the files <strong>quickly</strong> and <strong>comfortably</strong>. Sharing files becomes a process agile and convenient,
        since it is not necessary for the recipients to register on the platform to access the shared files.
      </p>
      <h2>Advantages of using YDRAY</h2>
      <img
        src="../../../assets/images/send-free-files-cost.png"
        alt="Advantages of using YDRAY"
        title="Advantages of using YDRAY"
        height="auto"
        width="auto"
        loading="lazy"
      />
      <p>
        One of YDRAY's significant advantages is its ability to <strong>send large files for free</strong>. YDRAY
        allows you to <strong>transfer large files</strong>, which is especially useful when you need to share presentations,
        high quality videos or your most creative projects. Forget size limits and <strong>send your large files</strong>
        with no problem.
      </p>
      <p>
        YDRAY also allows you to <strong>send files for free in an easy way</strong>. You can share a download link
        with your contacts, who will be able to access the files <strong>quickly</strong> and <strong>comfortably</strong>.
        Sending files for free becomes an <strong>agile</strong> and <strong>convenient</strong> process, since it is not
        necessary for the recipients to register on the platform to access the shared files.
      </p>
      <h2>Conclusions</h2>
      <img
        src="../../../assets/images/send-free-files-end.png"
        alt="Conclusions"
        title="Conclusions"
        height="auto"
        width="auto"
        loading="lazy"
      />
       <p>
         YDRAY gives you the ability to <strong>send files for free</strong>, focusing on <strong>simplicity</strong> and
         <strong>efficiency</strong>, with a totally revolutionary and innovative user experience . YDRAY excels at <strong>free
         secure file sending</strong> and offers free sharing options, making it an excellent <strong>alternative to its
         competitors</strong>. If you need to <strong>send files</strong> in an agile and free way, do not hesitate to take
         advantage of the advantages that YDRAY offers you. Send, share and enjoy a hassle-free experience with the
         <strong>market-leading file transfer platform</strong>.
      </p>
    `,
  },

  share_files: {
    title: "Share large files up to 10 GB for free",
    description: `
      YDRAY is the fastest and easiest way to safely transfer and share large files with maximum privacy and security up to 10 GB for free
    `,
    content: `
      <h1>Sharing files with YDRAY</h1>
      <p>
        As a business, <strong>privacy</strong> and <strong>security</strong> of the files you share are very important.
        YDRAY is a tool that can help you <a class="link" href="/en/share-files/free" title="Share files for free with YDRAY">share
        files for free</a> <strong>privately</strong>, ensuring the <strong>confidentiality</strong> of your clients' information
        and ensuring <strong>compliance</strong> of data protection regulations.
      </p>
      <h2>Easy to use</h2>
      <img
        src="../../../assets/images/share-files-easy.png"
        title="Ease of sending files"
        alt="Ease of sending files"
        height="auto"
        width="auto"
        loading="eager"
      />
      <p>
        YDRAY is a tool that it easy to use. Its <strong>intuitive</strong> interface allows you to
        <a class="link" href="/en/share-files/big" title="Share big files with YDRAY">share big files</a>
        quickly with just a few clicks. Does not require advanced technical knowledge, which makes it easy to use
        both for <strong>professional use</strong> and for <strong>personal use</strong>.
      </p>
      <h2>Privacy in your communications</h2>
      <img
        src="../../../assets/images/share-files-lock.png"
        alt="Security when sharing files"
        title="Security when sharing files"
        height="auto"
        width="auto"
        loading="lazy"
      />
      <p>
        YDRAY is committed to maintaining the privacy of your files. Does not store your files
        on their servers longer than necessary. Once the file has been transferred successfully and
        the deadline established in the submission has passed, it is <strong>automatically deleted</strong> from the YDRAY's servers.
        This <strong>reduces the risk</strong> of accidental exposure or <strong>unauthorized access</strong> to the shared files.
      </p>
      <p>
        YDRAY offers a <strong>secure environment</strong> for sending files. It uses encryption of
        end-to-end, which means files are <strong>encrypted</strong> before they leave your
        device and are only decrypted once they reach the recipient's device. This ensures that the
        Files are <strong>protected</strong> during the entire transfer process and prevent unauthorized access
        to confidential information.
      </p>
      <p>
        YDRAY also allows you to <strong>set passwords</strong> for the files you send by adding
        an additional layer of <strong>security</strong>. You can assign a unique password for each file, which
        ensures that only <strong>authorized recipients</strong> can access them. This function is
        especially useful when sending files with <strong>sensitive information</strong>.
      </p>
      <h2>Personalization of your uploads</h2>
      <img
        src="../../../assets/images/share-files-custom.png"
        alt="Customization when sharing files"
        title="Customization when sharing files"
        height="auto"
        width="auto"
        loading="lazy"
      />
      <p>
        Another YDRAY's valuable feature is the ability to <strong>customize your transfers</strong>. Can
        add your logo to the download link as a payment option with the YDRAY Pro account, which helps
        reinforce your business identity and provide a <strong>more professional</strong> experience to customers.
        This can be useful when you send files to external collaborators or even your clients giving a
        <strong>more corporate image</strong>.
      </p>
      <p>
        YDRAY also offers the option to set an <strong>expiration date</strong> for your transfers. This
        means that the shared files will only be available for download during a
        <strong>specific period of time</strong>. After the expiration date, the files are deleted
        automatically, further limiting exposure and ensuring that sensitive files. They are not stored
        indefinitely.
      </p>
      <h2>File Sharing Tips</h2>
      <img
        src="../../../assets/images/share-files-doubts.png"
        alt="File Sharing Tips"
        title="File Sharing Tips"
        height="auto"
        width="auto"
        loading="lazy"
      />
      <h3>How can I share files?</h3>
      <p>
        YDRAY is a <strong>reliable</strong> and <strong>safe</strong> platform to send
        and share files. With YDRAY, you can send <strong>large files</strong>
        quick and easy. Just visit YDRAY.com, select the files you want to share
        and <strong>generates the link</strong> to share either by email, Facebook,
        Instagram, X (Twitter) or Gmail. YDRAY will take care of sending and sharing your files
        private and secure. Discover a new way to send and share files with YDRAY!
      </p>
      <h3>What is the best file sharing platform?</h3>
      <p>
        The best file sharing platform is YDRAY. With YDRAY, you can send and
        share files <strong>fast</strong>, <strong>safe</strong> and <strong>reliable</strong> way.
        It is an <strong>easy to use</strong> tool that allows you to share large files
        without worrying about size limits. It has the <strong>highest security</strong> on the market
        and provides maximum privacy in your file shipments. Discover the experience of
        send and share files efficiently with YDRAY!
      </p>
      <h3>How can I share files securely?</h3>
      <p>
        With YDRAY, you can share files in a <strong>secure</strong> and <strong>reliable</strong> way.
        Ydray.com is a platform that allows you to send and share files in a <strong>protected</strong> way,
        using end-to-end encryption to guarantee the <strong>security of your data</strong>.
        Simply visit Ydray.com, select the files you want, and <strong>generate the
        link</strong> to share. YDRAY will take care of sending and sharing your files
        <strong>secure</strong> and <strong>reliable</strong>, protecting the privacy of your data during
        the process. Enjoy a safe way to send and share files with YDRAY!
      </p>
      <h3>How can you share a file or folder with another person?</h3>
      <p>
        With YDRAY, you can share files or folders with other people <strong>quickly</strong> and
        <strong>simple</strong>. Simply visit Ydray.com, select the files or folder with your
        files you want to share and click <strong>Generate Link</strong>. you can share your
        link by email, Facebook, Instagram, X (Twitter) or Gmail. YDRAY will take care of
        send and share files <strong>securely</strong>. Sharing has never been so easy
        with YDRAY!
      </p>
      <h2>In short, YDRAY</h2>
      <p>
        YDRAY is an <strong>effective</strong> and <strong>safe</strong> solution for sending and sharing files
        <strong>confidential</strong> in the context of your company. Its end-to-end encryption, the
        possibility to set passwords and automatic deletion of files guarantee the
        <strong>privacy</strong> and <strong>confidentiality</strong> of customer information.
        In addition, its ease of use and customization make YDRAY a convenient and
        reliable to send files privately and securely in your work practice.
      </p>
    `,
  },

  share_files_big: {
    title: `Share big files`,
    description: `Share big files`,
    content: `
    <h1>Share large files with YDRAY</h1>
    <p>
      In today's digital world, both at work and on a personal level, the need to share large files
      <strong>quickly</strong> and <strong>securely</strong> arises, a fact that has become increasingly important.
      Fortunately, platforms like YDRAY have emerged to make this process easier and meet the demands of the most
      demanding users.
    </p>
    <h2>YDRAY Features</h2>
    <img
      src="../../../assets/images/share-big-files-doubts.png"
      alt="Tips for sharing large files"
      title="Tips for sharing large files"
      height="auto"
      width="auto"
      loading="lazy"
    />
    <p>
      YDRAY stands out among its competitors by offering <strong>efficient</strong> and <strong>reliable</strong>
      solutions for <a class="link" href="/en/send-files/" title="Sharing files with YDRAY">sharing files</a>. One of YDRAY's
      key advantages is its generous <strong>file size limit</strong> of up to 10 GB, making it an ideal choice for
      those who need to <strong>share large files for free</strong>. This option is unique in the market. On the other
      hand, YDRAY's main competitor allows you to send files up to 2 GB for free, which is still a generous capacity
      compared to other services, but <strong>up to 5 times less compared to YDRAY</strong>.
    </p>
    <p>
      YDRAY positions itself as a platform for sharing and transferring large files with a very <strong>user-friendly</strong>
      interface that allows users to upload and share files <strong>quickly</strong> and <strong>easily</strong>.
      Simply select or drag the files you want to share, choose between the different possible free options,
      (such as putting <strong>submission title</strong>, entering a <strong>message</strong>, selecting the
      <strong>date expiration</strong> download link or password your file submission) and click the submit button.
      Within seconds, the recipient will receive a link to download the shared files. This streamlined process
      <strong>saves time</strong> and <strong>effort</strong> by eliminating the need to use more complex and
      less efficient methods to send large and heavy files.
    </p>
    <p>
      A distinctive feature of YDRAY is its focus on the <strong>privacy</strong> and <strong>security</strong> of
      shared files. The platform uses <strong>end-to-end encryption</strong> to ensure that your files are protected
      during the sending and receiving process. In addition, YDRAY offers advanced configuration options, such as
      <strong>setting a password for shared files</strong> or <strong>scheduling their expiration date</strong>.
      These features provide greater control and peace of mind for users who want to share files <strong>in a secure
      manner</strong>.
    </p>
    <p>
      YDRAY is positioned as the <strong>leading platform</strong> in terms of privacy when it comes to sharing large files,
      being a solid option when it comes to transferring large files and totally <strong>for free</strong>.
    </p>
    <p>
      Regarding the transfer speed, YDRAY strives to provide a <strong>fast</strong> and <strong>efficient</strong> experience.
      Its optimized infrastructure allows for <strong>agile</strong> file uploading and downloading, ensuring smooth
      transfer of large and heavy files.
    </p>
    <h2>Conclusions</h2>
    <img
      src="../../../assets/images/share-big-files-characteristics.png"
      alt="Conclusions when sharing large files"
      title="Conclusions when sharing large files"
      height="auto"
      width="auto"
      loading="lazy"
    />
    <p>
      In short, YDRAY is the best alternative to its competitors for <strong>sharing large files</strong>.
      YDRAY stands out for its <strong>generous file size limit of up to 10 GB totally free</strong>, its focus on
      <strong>privacy</strong> and <strong>security</strong>, as well as its advanced configuration options. Other
      alternatives offer a capacity of up to 2 GB for free. YDRAY works daily to adapt to the needs of modern
      users and provide <strong>efficient</strong> and <strong>reliable</strong> solutions for sharing large files,
      heavy files and large files. <strong>Don't wait any longer and start enjoying a smooth and secure experience
      to share your large files with YDRAY!</strong>
    </p>
    `,
  },

  share_files_free: {
    title: `Share files for free`,
    description: `Share files for free`,
    content: `
    <h1>Share files for free with YDRAY</h1>
    <p>
      YDRAY is an excellent option to <a class="link" href="/en/share-files/" title="Share files for free with YDRAY">share files</a>
      <strong>for free</strong>, that is, YDRAY is a completely <strong>free</strong> file transfer platform. YDRAY
      stands out with its unique features and strengths compared to its competitors, offering a <strong>superior user
      experience</strong> while sharing files.
    </p>
    <img
      src="../../../assets/images/share-free-files-doubts.png"
      alt="Share files for free"
      title="Share files for free"
      height="auto"
      width="auto"
      loading="lazy"
    />
    <h2>Advantages of using YDRAY</h2>
    <p>
      One of the main advantages of YDRAY is its ability to <strong>free file sharing without restrictions</strong>.
      While some services limit the size of the files you can send, YDRAY lets you <strong>share files</strong> up to
      10GB <strong>for free</strong>. This feature is especially valuable when you need to transfer and share high-quality
      media files, creative projects, or any other heavy content. With YDRAY, there are no limits to <strong>sharing
      your files for free</strong>.
    </p>
    <p>
      In addition to free file sharing, YDRAY stands out in terms of <strong>speed</strong> and <strong>performance</strong>.
      The platform uses an optimized upload infrastructure, which translates into <strong>fast and efficient</strong> transfers
      of your files. You can share files for free in a matter of minutes, streamlining your work processes and allowing you to
      send content <strong>more efficiently</strong>.
    </p>
    <p>
      YDRAY is a leader in </strong>user experience</strong> when it comes to sharing files totally free. The file sending platform
      places the user at the center of the file transfer process, providing a <strong>simple</strong>, <strong>intuitive</strong>
      and <strong>easy-to-use experience</strong>.
    </p>
    <p>
      Another strong point is its focus on the <strong>privacy</strong> and <strong>security</strong> of your files. It uses
      <strong>end-to-end encryption</strong> for all transfers, ensuring your data is protected throughout the process. In addition,
      <strong>YDRAY does not store your files on its servers for longer than necessary </strong>, which minimizes the risks of
      exposure and ensures the <strong>confidentiality of your documents </strong> and multimedia content. YDRAY is the leading free
      file sharing alternative in <strong>compliance with the GDPR (General Data Protection Regulation) </strong>.
    </p>
    <p>
      In terms of ease of use, YDRAY stands out with its <strong>intuitive interface</strong>, <strong>simple</strong> and <strong>friendly</strong>.
      The free file submission process is <strong>simple</strong>, even for non-techies. You just need to upload your files, select the available
      options and share your files for free. There are no complications or unnecessary steps, saving you <strong>time</strong> and
      <strong>effort</strong> in your daily tasks.
    </p>
    <p>
      Finally, YDRAY offers <strong>customization options</strong> in your file download links to suit your needs. You can add <strong>custom
      messages</strong> to your transfers, set </strong>link expiration dates</strong>, and control who can access your shared files with a
      </strong>password</strong>. These features give you more control over your file transfers and allow you to customize them to your
      preferences.
    </p>
    <img
      src="../../../assets/images/share-free-files-advantages.png"
      alt="Conclusions when sharing files for free"
      title="Conclusions when sharing files for free"
      height="auto"
      width="auto"
      loading="lazy"
    />
    <h2>Conclusions</h2>
    <p>
      In short, YDRAY is the best alternative among its competitors when it comes to <strong>free file sharing</strong>.
      YDRAY stands out with its strengths in terms of <strong>design</strong>, <strong>user experience</strong>,
      <strong>file size</strong>, <strong>speed</strong>, <strong> strong>privacy</strong> and <strong>ease of use</strong>.
      If you need to share files for free, YDRAY is the <strong>reliable</strong> and <strong>convenient</strong> solution.
      <strong>Discover with YDRAY a fast, secure and customizable way to share your files totally free!</strong>
    </p>
    `,
  },

  more_info: {
    title: "More Info",
    description: "Additional YDRAY Information",
    content: `
    <h1>More Info</h1>
    <h2 id="data-protection">Data Protection</h2>
    <p>In accordance with the provisions of Regulation (EU) 2016/679, of April 27, regarding the protection of Natural Persons with regard to the processing of personal data and the free circulation of these data (hereinafter , GDPR) we inform you about it.</p>
    <h2 id="responsible">Responsible</h2>
    <p>RECICLAONLINE S.L</p>
    <h2 id="porpouse">Purpose</h2>
    <p>Registration, management and service of sending large-capacity files through our website to third parties.</p>
    <h2 id="legitimation">Legitimization</h2>
    <p>Express consent of the user through the selection of the registration acceptance check box where his consent is verified.</p>
    <h2 id="recipient">Recipients</h2>
    <p>There is no planned transfer of data, except legal obligation.</p>
    <h2 id="rights">Rights</h2>
    <span>You can exercise the rights of access, rectification, deletion, opposition, portability and withdrawal of consent of your personal data at the email address <a class="link" href="mailto:privacidad@ydray.com" target="_blank" title="Send email to privacidad@ydray.com">privacidad@ydray.com</a></span>
    <h2 id="origin">Origin</h2>
    <p>The interested party himself</p>
    `,
  },
};
