import "react-international-phone/style.css";
import classNames from "classnames";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  CountrySelector,
  usePhoneInput,
  usePhoneValidation,
} from "react-international-phone";
import { isEmpty, isEmptyList } from "../../utils/validators";
import { useTranslation } from "react-i18next";

function PhoneField({
  name,
  label,
  placeholder,
  autoComplete,
  value,
  onChangeHandler,
  required,
}) {
  const { t } = useTranslation();

  const [isActive, setIsActive] = useState(false);

  const phoneInput = usePhoneInput({
    value: value ? value : "",
    onChange: (data) => {
      onChangeHandler(data.phone);
    },
  });

  useEffect(() => {
    setIsActive(!isEmpty(phoneInput.phone));
  }, [phoneInput.phone]);

  const phoneValidation = usePhoneValidation(phoneInput.phone, {
    country: phoneInput.country,
  });

  const error = useMemo(() => {
    if (required) {
      if (!isEmpty(phoneInput.phone)) {
        return !phoneValidation.isValid ? t("validators.phone") : "";
      } else {
        return t("validators.phone");
      }
    } else {
      const phoneChunks = !isEmpty(phoneInput.phone)
        ? phoneInput.phone.split(" ")
        : "";
      if (
        !isEmptyList(phoneChunks) &&
        Array(phoneChunks).length >= 1 &&
        !isEmpty(phoneChunks[1])
      ) {
        return !phoneValidation.isValid ? t("validators.phone") : "";
      } else {
        return "";
      }
    }
  }, [required, phoneInput.phone, phoneValidation, t]);

  const inputRef = useRef(null);

  return (
    <div className="phone-field__container">
      <div className="phone-field__content">
        {label && (
          <label
            htmlFor={name}
            className={classNames("phone-field__label", "smaller", {
              active: isActive,
            })}
          >
            {`${label}${required ? " *" : ""}`}
          </label>
        )}
        <div className="phone-field__phone-input">
          <CountrySelector
            selectedCountry={phoneInput.country}
            onSelect={(country) => phoneInput.setCountry(country.iso2)}
          />
          <input
            id={name}
            type="tel"
            className="phone-field__input"
            ref={inputRef}
            placeholder={placeholder}
            autoComplete={autoComplete}
            value={phoneInput.phone}
            onChange={phoneInput.handlePhoneValueChange}
          ></input>
        </div>
      </div>
      <p className={classNames("phone-field__error", { show: error })}>
        {error}
      </p>
    </div>
  );
}

export default PhoneField;
