import { useTranslation } from "react-i18next";

import Divider from "../divider";
import AdsComponent from "../ads-component";

function BlockUpload() {
  const { t } = useTranslation();

  return (
    <div className="block_upload__container">
      <div className="block_upload__card">
        <div className="block_upload__header">
          <span className="block_upload__logo"></span>
        </div>
        <div className="block_upload__body">
          <span className="block_upload__title">{t("block_upload.title")}</span>
          <span className="block_upload__message">
            {t("block_upload.message")}
          </span>
        </div>
        <div className="block_upload__footer">
          <Divider />
          <AdsComponent
            dataAdSlot={process.env.REACT_APP_G_AD_SLOT_DOWNLOADER}
          />
        </div>
      </div>
    </div>
  );
}

export default BlockUpload;
