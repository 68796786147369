import { useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import useGetLocalePath from "../../hooks/useGetLocalePath";

import {
  setBlur,
  setShowLoginDialog,
  setShowNewsletterRegisterDialog,
} from "../../redux/reducers/uiReducer";

import { Link } from "react-router-dom";
import Button from "../button";
import { isEmpty } from "../../utils/validators";

function ProAdComponent(props) {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { getLocalePath } = useGetLocalePath();
  const [route, setRoute] = useState(null);

  const dispatch = useDispatch();
  const { ui } = useSelector((state) => state);
  const { user } = useSelector((state) => state.user);

  const getNestedRoute = useCallback(
    (nestedRoute) => {
      return getLocalePath(`${t("routes.private.prefs.index")}/${nestedRoute}`);
    },
    [getLocalePath, t]
  );

  const clickHandler = useCallback(
    (evt) => {
      if (["PREPRODUCTION", "PRODUCTION"].includes(process.env.REACT_APP_ENV)) {
        dispatch(
          setShowNewsletterRegisterDialog(!ui.show_newsletter_register_dialog)
        );
        dispatch(setBlur(!ui.is_blur));
      } else {
        if (!user.logged_in) {
          dispatch(setShowLoginDialog(!ui.show_login_dialog));
          dispatch(setBlur(!ui.is_blur));
        } else {
          setRoute(getNestedRoute(t("routes.private.prefs.plans")));
        }
      }
    },
    [
      ui.is_blur,
      ui.show_login_dialog,
      ui.show_newsletter_register_dialog,
      user.logged_in,
      t,
      dispatch,
      getNestedRoute,
    ]
  );

  useEffect(() => {
    if (!isEmpty(route)) {
      navigate(route);
      setRoute(null);
    }
  }, [route, navigate]);

  return (
    <div className="pro-ad-content">
      <div className="pro-ad-content__header">
        <span className="icon-title icon-up-round-arrow">
          <span className="path1"></span>
          <span className="path2"></span>
        </span>
        <div className="text-title small-title">
          {t("uploader.pro_ad.title")}
        </div>
      </div>
      <div className="pro-ad-content__body">
        <p className="smaller">{t("uploader.pro_ad.subtitle")}</p>
        <Link to={getLocalePath(t("routes.common.home"))} title={t("app.name")}>
          <Button text pro onClick={(evt) => clickHandler(evt)}>
            <span className="icon icon-plus"></span>
            <span className="label small-title">{t("button.more_info")}</span>
          </Button>
        </Link>
      </div>
    </div>
  );
}

export default ProAdComponent;
