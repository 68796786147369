import { createSlice } from "@reduxjs/toolkit";
import { logDebug } from "../../utils/logger";

const initialState = {
  loading: true,
  items: [],
  selectedItem: null,
  selectedSubitem: null,
  filters: JSON.stringify({ search: "" }),
  orderBy: "",
  pagination: JSON.stringify({ start: 0, limit: 20, total: 0, more: true }),
};

const reducers = {
  setLoading: (state, action) => {
    state.loading = action.payload;
    logDebug("billingTableReducer", "setLoading", {
      loading: state.loading,
    });
  },
  setItems: (state, action) => {
    state.items = action.payload;
    logDebug("billingTableReducer", "setItems", {
      items: state.items,
    });
  },
  clearItems: (state, action) => {
    state.items = [];
    logDebug("billingTableReducer", "clearItems", {
      items: state.items,
    });
  },
  setSelectedItem: (state, action) => {
    state.selectedItem = JSON.stringify(action.payload);
    logDebug("billingTableReducer", "setSelectedItem", {
      selectedItem: JSON.parse(state.selectedItem),
    });
  },
  clearSelectedItem: (state, action) => {
    state.selectedItem = null;
    logDebug("billingTableReducer", "clearSelectedItem", {
      selectedItem: null,
    });
  },
  setFilters: (state, actions) => {
    state.filters = JSON.stringify(actions.payload);
    logDebug("billingTableReducer", "setFilters", {
      filters: state.filters,
    });
  },
  clearFilters: (state, actions) => {
    state.filters = JSON.stringify({ search: "" });
    logDebug("billingTableReducer", "clearFilters", {
      filters: state.filters,
    });
  },
  setOrderBy: (state, actions) => {
    state.orderBy = actions.payload;
    logDebug("billingTableReducer", "setOrderBy", {
      orderBy: state.orderBy,
    });
  },
  clearOrderBy: (state, actions) => {
    state.orderBy = "";
    logDebug("billingTableReducer", "clearOrderBy", {
      orderBy: state.orderBy,
    });
  },
  setPagination: (state, action) => {
    state.pagination = JSON.stringify(action.payload);
    logDebug("billingTableReducer", "setPagination", {
      pagination: JSON.parse(state.pagination),
    });
  },
  clearPagination: (state, action) => {
    state.pagination = JSON.stringify({
      start: 0,
      limit: 20,
      total: 0,
      more: true,
    });
    logDebug("billingTableReducer", "clearPagination", {
      pagination: JSON.parse(state.pagination),
    });
  },
};

const slice = createSlice({
  name: "billingTable",
  initialState: initialState,
  reducers: reducers,
});

export const { setForm, clearForm } = slice.actions;
export default slice.reducer;
