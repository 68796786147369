import { useRetry } from "@rpldy/retry-hooks";
import { asUploadButton } from "@rpldy/upload-button";
import { FILE_STATES, useAbortAll, useAbortItem } from "@rpldy/uploady";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  clearUploadInfo,
  removeFile,
  removeFiles,
  setDisabled,
  setGlobalProgress,
  setGlobalSize,
  setGlobalStatus,
  setShareType,
  setUploadStatus,
} from "../../../redux/reducers/uploaderReducer";
import {
  setFooterMenuCollapse,
  setShowFooterMenu,
} from "../../../redux/reducers/uiReducer";
import { errorToast } from "../../toast";
import { equalsIgnoringCase, isEmptyList } from "../../../utils/validators";
import Button from "../../button";
import FileItemList from "../file-item-list";
import { GLOBAL_STATUS, SHARE_TYPE, STATUS_UPLOADER } from "../utils/config";
import { logDebug } from "../../../utils/logger";
import UploadDropZone from "@rpldy/upload-drop-zone";

function DetailComponent() {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { uploader } = useSelector((state) => state.uploader);
  const { settings } = useSelector((state) => state.settings);

  const isGenerate = useMemo(
    () => equalsIgnoringCase(uploader.shareType, SHARE_TYPE.GENERATE),
    [uploader.shareType]
  );

  const isCopy = useMemo(
    () => equalsIgnoringCase(uploader.shareType, SHARE_TYPE.COPY),
    [uploader.shareType]
  );

  const enableUploader = useMemo(
    () => isGenerate && !uploader.disabled,
    [isGenerate, uploader.disabled]
  );

  const stateFiles = useMemo(
    () => Array.from(uploader.files),
    [uploader.files]
  );

  const errorList = useMemo(
    () =>
      stateFiles.filter((item) =>
        [
          FILE_STATES.ABORTED,
          FILE_STATES.ERROR,
          FILE_STATES.CANCELLED,
        ].includes(item.state)
      ),
    [stateFiles]
  );

  const uploadList = useMemo(
    () =>
      stateFiles.filter((item) =>
        [
          FILE_STATES.ADDED,
          FILE_STATES.PENDING,
          FILE_STATES.UPLOADING,
          FILE_STATES.FINISHED,
        ].includes(item.state)
      ),
    [stateFiles]
  );

  const labelNavigationBack = useMemo(() => {
    return t("uploader.upload_detail_content.back");
  }, [t]);

  const goFormUpload = useCallback(() => {
    if (!isEmptyList(stateFiles)) {
      dispatch(setFooterMenuCollapse(true));
      dispatch(setUploadStatus(STATUS_UPLOADER.FORM));
    } else {
      dispatch(removeFiles());
      dispatch(setGlobalSize(0));
      dispatch(setGlobalProgress(0));
      dispatch(setGlobalStatus(GLOBAL_STATUS.INIT));
      dispatch(setDisabled(false));
      dispatch(setUploadStatus(STATUS_UPLOADER.DRAG));
      dispatch(setShareType(SHARE_TYPE.GENERATE));
      dispatch(clearUploadInfo());
      dispatch(setFooterMenuCollapse(false));
    }
    dispatch(setShowFooterMenu(true));
  }, [dispatch, stateFiles]);

  const calcGlobalSize = useCallback((state, item) => {
    const files = state.files.filter((file) => item.id !== file.id);
    const globalSize = files
      .filter((file) => [FILE_STATES.FINISHED].includes(file.state))
      .reduce((sum, file) => sum + file.file.size, 0);
    return globalSize;
  }, []);

  const calcGlobalStatus = useCallback((state, item) => {
    const files = state.files.filter((file) => item.id !== file.id);
    const isSomeUploading = files.some((file) =>
      [FILE_STATES.PENDING, FILE_STATES.ADDED, FILE_STATES.UPLOADING].includes(
        file.state
      )
    );
    const isSomeFinished = files.some((file) =>
      [FILE_STATES.FINISHED].includes(file.state)
    );
    let globalStatus = "";
    if (isSomeUploading) {
      globalStatus = GLOBAL_STATUS.UPLOADING;
    } else if (isSomeFinished) {
      globalStatus = GLOBAL_STATUS.SUCCESS;
    } else {
      globalStatus = GLOBAL_STATUS.INIT;
    }
    return globalStatus;
  }, []);

  const calcGlobalProgress = useCallback((state, item) => {
    const files = state.files.filter((file) => item.id !== file.id);
    const isSomeUploading = files.some((file) =>
      [FILE_STATES.PENDING, FILE_STATES.ADDED, FILE_STATES.UPLOADING].includes(
        file.state
      )
    );
    const isSomeFinished = files.some((file) =>
      [FILE_STATES.FINISHED].includes(file.state)
    );
    let globalProgress = 0;
    if (isSomeUploading) {
      globalProgress = state.completed;
    } else if (isSomeFinished) {
      globalProgress = 100;
    } else {
      globalProgress = 0;
    }
    return globalProgress;
  }, []);

  const UploadButton = asUploadButton((props) => {
    return (
      <Button {...props} primary>
        <span className="icon icon-plus"></span>
        <span className="label">{t("button.add")}</span>
      </Button>
    );
  });

  const urlDeleteFile = useCallback(
    (file) => {
      return `https://${settings.server}.${process.env.REACT_APP_DOMAIN}/fileupload/?file=${file}`;
    },
    [settings.server]
  );

  const configDeleteFile = useMemo(() => {
    return {
      method: "DELETE",
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
  }, []);

  const retryAllError = useRetry();
  const onRetryAllError = useCallback(() => {
    logDebug("UploaderComponent.DetailComponent", "onRetryAllError", {});
    retryAllError();
  }, [retryAllError]);
  const abortAllError = useAbortAll();
  const onAbortAllError = useCallback(() => {
    logDebug("UploaderComponent.DetailComponent", "onAbortAllError", {
      errorList: errorList,
    });
    if (!isEmptyList(errorList)) {
      errorList.forEach((file) => {
        dispatch(removeFile(file));
      });
      return abortAllError();
    }
    return true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [abortAllError, errorList, dispatch]);

  const retryItemError = useRetry();
  const onRetryItemError = useCallback(
    (item) => {
      logDebug("UploaderComponent.DetailComponent", "onRetryItemError", {
        item: item,
      });
      retryItemError(item.id);
    },
    [retryItemError]
  );
  const abortItemError = useAbortItem();
  const onAbortItemError = useCallback(
    (item) => {
      logDebug("UploaderComponent.DetailComponent", "onAbortItemError", {
        item: item,
      });
      dispatch(removeFile(item));
      return abortItemError(item.id);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [abortItemError, dispatch]
  );

  const retryAll = useRetry();
  const onRetryAll = useCallback(() => {
    logDebug("UploaderComponent.DetailComponent", "onRetryAll", {});
    retryAll();
  }, [retryAll]);
  const abortAll = useAbortItem();
  const onAbortAll = useCallback(() => {
    logDebug("UploaderComponent.DetailComponent", "onAbortAll", {
      uploadList: uploadList,
    });
    uploadList.forEach((item) => {
      if ([FILE_STATES.FINISHED].includes(item.state)) {
        fetch(urlDeleteFile(item.file.serverName), configDeleteFile)
          .then((response) => response.json())
          .then((response) => {
            if (!response.error) {
              dispatch(removeFile(item));
            } else {
              abortAll(item.file.id);
              logDebug(
                "UploaderComponent.DetailComponent",
                "onAbortAll - fetch",
                { item: item.file }
              );
              errorToast(
                "deleteFile",
                response.error,
                t(`errors.${response.error}`, t("errors.default"))
              );
            }
          });
      } else {
        abortAll(item.file.id);
        logDebug("UploaderComponent.DetailComponent", "onAbortAll", {
          item: item.file,
        });
      }
    });
    dispatch(setGlobalSize(0));
    dispatch(setGlobalProgress(0));
    dispatch(setGlobalStatus(GLOBAL_STATUS.INIT));
    dispatch(clearUploadInfo());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadList, dispatch, abortAll, t]);

  const retryItem = useRetry();
  const onRetryItem = useCallback(
    (item) => {
      logDebug("UploaderComponent.DetailComponent", "onRetryItem", {
        item: item.file,
      });
      retryItem(item.id);
    },
    [retryItem]
  );
  const abortItem = useAbortItem();
  const onAbortItem = useCallback(
    async (item, uploaderState) => {
      logDebug("UploaderComponent.DetailComponent", "onAbortItem", {
        item: item.file,
      });
      if ([FILE_STATES.FINISHED].includes(item.state)) {
        logDebug("UploaderComponent.DetailComponent", "onAbortItem", {
          item: item.file,
          msg: "Need to delete file from servers...",
        });
        fetch(urlDeleteFile(item.file.serverName), configDeleteFile)
          .then((response) => response.json())
          .then((response) => {
            if (!response.error) {
              dispatch(removeFile(item));
            } else {
              abortItem(item.id);
              logDebug("UploaderComponent.DetailComponent", "deleteFile", {
                item: item,
                error: response.error,
                msg: t(`errors.${response.error}`, t("errors.default")),
              });
              errorToast(
                "UploaderComponent.DetailComponent - deleteFile",
                response.error,
                t(`errors.${response.error}`, t("errors.default"))
              );
            }
            dispatch(setGlobalSize(calcGlobalSize(uploaderState, item)));
            dispatch(setGlobalStatus(calcGlobalStatus(uploaderState, item)));
            dispatch(
              setGlobalProgress(calcGlobalProgress(uploaderState, item))
            );
          });
      } else {
        abortAll(item.id);
        dispatch(setGlobalSize(calcGlobalSize(uploaderState, item)));
        dispatch(setGlobalStatus(calcGlobalStatus(uploaderState, item)));
        dispatch(setGlobalProgress(calcGlobalProgress(uploaderState, item)));
        logDebug("UploaderComponent.DetailComponent", "onAbortItem", {
          item: item,
          msg: "Not need to delete file from servers...",
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [abortItem, dispatch, t]
  );

  return (
    <UploadDropZone
      className="detail-container"
      grouped={false}
      shouldHandleDrag={enableUploader}
    >
      <div className="detail-header">
        <div
          className="detail-header__label"
          onClick={(evt) => goFormUpload(evt)}
        >
          <span className="label-icon icon-nav-arrow-left"></span>
          <span className="label-msg">{labelNavigationBack}</span>
        </div>
        <div className="detail-header__actions">
          {enableUploader && <UploadButton />}
        </div>
      </div>
      <div className="detail-content">
        {enableUploader && !isEmptyList(errorList) && (
          <FileItemList
            title={t("uploader.upload_detail_content.title_errorWithCount", {
              count: errorList.length,
            })}
            edit
            list={errorList}
            showRetryAll
            onRetryAll={onRetryAllError}
            onRetryItem={onRetryItemError}
            showAbortAll
            onAbortAll={onAbortAllError}
            onAbortItem={onAbortItemError}
          />
        )}
        {enableUploader && !isEmptyList(uploadList) && (
          <FileItemList
            title={t("uploader.upload_detail_content.title_uploadsWithCount", {
              count: uploadList.length,
            })}
            edit
            list={uploadList}
            showRetryAll={false}
            onRetryAll={onRetryAll}
            onRetryItem={onRetryItem}
            showAbortAll={true}
            onAbortAll={onAbortAll}
            onAbortItem={(item) => onAbortItem(item, uploader)}
          />
        )}
        {(isCopy || (isGenerate && uploader.disabled)) &&
          !isEmptyList(uploadList) && (
            <FileItemList
              title={t(
                "uploader.upload_detail_content.title_uploadsWithCount",
                {
                  count: uploadList.length,
                }
              )}
              detail
              list={uploadList}
              showRetryAll={false}
              showAbortAll={false}
            />
          )}
      </div>
    </UploadDropZone>
  );
}

export default DetailComponent;
