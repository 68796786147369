import { useCallback } from "react";
import Media from "react-media";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { useTranslation } from "react-i18next";

import LanguagePath from "../../pages/LanguagePath";

import Landing from "../../pages/Landing";
import Press from "../../pages/Press";
import FAQs from "../../pages/FAQs";
import Privacy from "../../pages/Privacy";
import Cookies from "../../pages/Cookies";
import LegalNotice from "../../pages/LegalNotice";

import SendFiles from "../../pages/SendFiles";
import SendBigFiles from "../../pages/SendBigFiles";
import SendFreeFiles from "../../pages/SendFreeFiles";

import ShareFiles from "../../pages/ShareFiles";
import ShareBigFiles from "../../pages/ShareBigFiles";
import ShareFreeFiles from "../../pages/ShareFreeFiles";

import Uploads from "../../pages/Uploads";
import TransferDetail from "../../pages/TransferDetail";
import Preferences from "../../pages/Preferences";
import Plans from "../../pages/Plans";
import Bills from "../../pages/Bills";
import Personal from "../../pages/Personal";
import Pasword from "../../pages/Password";
import Custom from "../../pages/Custom";
import Contact from "../../pages/Contact";

import NoMatch from "../../pages/NoMatch";
import NavigateLng from "../../pages/NavigateLng";

function PageComponent() {
  const { t, i18n } = useTranslation();

  const getPublicRoutes = useCallback(
    (lng) => {
      return (
        <Route>
          <Route path="" element={<Landing />} index />
          <Route
            path={t("routes.public.about", { lng: lng })}
            element={<Landing params={{ targetId: "about" }} />}
          />
          <Route
            path={t("routes.public.plans", { lng: lng })}
            element={<Landing params={{ targetId: "plans" }} />}
          />
          <Route
            path={t("routes.public.contact", { lng: lng })}
            element={<Landing params={{ targetId: "contact" }} />}
          />
          <Route
            path={t("routes.public.newsletter_reg", { lng: lng })}
            element={<Landing params={{ targetId: "newsletter_register" }} />}
          />
          <Route
            path={t("routes.public.newsletter_unreg", { lng: lng })}
            element={<Landing params={{ targetId: "newsletter_unregister" }} />}
          />
          <Route
            path={t("routes.public.uploader", { lng: lng })}
            element={
              <Navigate replace to={t("routes.common.home", { lng: lng })} />
            }
          />
          <Route
            path={t("routes.public.press", { lng: lng })}
            element={<Press />}
          />
          <Route
            path={t("routes.public.faq", { lng: lng })}
            element={<FAQs />}
          />
          <Route
            path={t("routes.public.privacy", { lng: lng })}
            element={<Privacy />}
          />
          <Route
            path={t("routes.public.cookies", { lng: lng })}
            element={<Cookies />}
          />
          <Route
            path={t("routes.public.legal", { lng: lng })}
            element={<LegalNotice />}
          />
          <Route path={t("routes.public.send_files.index", { lng: lng })}>
            <Route path="" element={<SendFiles />} index />
            <Route
              path={t("routes.public.send_files.big", { lng: lng })}
              element={<SendBigFiles />}
            />
            <Route
              path={t("routes.public.send_files.free", { lng: lng })}
              element={<SendFreeFiles />}
            />
          </Route>
          <Route path={t("routes.public.share_files.index", { lng: lng })}>
            <Route path="" element={<ShareFiles />} index />
            <Route
              path={t("routes.public.share_files.big", { lng: lng })}
              element={<ShareBigFiles />}
            />
            <Route
              path={t("routes.public.share_files.free", { lng: lng })}
              element={<ShareFreeFiles />}
            />
          </Route>
        </Route>
      );
    },
    [t]
  );

  const getPrivateRoutes = useCallback(
    (lng) => {
      return (
        <Route>
          <Route path={t("routes.private.uploads.index", { lng: lng })}>
            <Route path="" element={<Uploads />} index />
            <Route
              path={t("routes.private.uploads.detail", { lng: lng })}
              element={<TransferDetail />}
            />
          </Route>
          <Route path={t("routes.private.prefs.index", { lng: lng })}>
            <Route path="" element={<Preferences />} index />
            <Route
              path={t("routes.private.prefs.plans", { lng: lng })}
              element={<Plans />}
            />
            <Route
              path={t("routes.private.prefs.bills", { lng: lng })}
              element={<Bills />}
            />
            <Route
              path={t("routes.private.prefs.personal", { lng: lng })}
              element={<Personal />}
            />
            <Route
              path={t("routes.private.prefs.pass", { lng: lng })}
              element={<Pasword />}
            />
            <Route
              path={t("routes.private.prefs.custom", { lng: lng })}
              element={<Custom />}
            />
            <Route
              path={t("routes.private.prefs.contact", { lng: lng })}
              element={<Contact />}
            />
          </Route>
        </Route>
      );
    },
    [t]
  );

  const getRoutesLng = useCallback(
    (lng) => {
      return (
        <Route path={`/${lng}`} element={<LanguagePath />}>
          {/* Public Routes */}
          {getPublicRoutes(lng)}
          {/* Private Routes */}
          {getPrivateRoutes(lng)}
        </Route>
      );
    },
    [getPrivateRoutes, getPublicRoutes]
  );

  return (
    <div className="page2__routes">
      <Media query="(min-width: 576px)">
        <Routes>
          <Route path="/" element={<Outlet />}>
            {/* Routes with language */}
            {i18n.languages.map((lng) => (
              <Route key={lng}>{getRoutesLng(lng)}</Route>
            ))}
            {/* Downloader Link */}
            <Route path="get/t/:id" element={<Landing />} />
            {/* Legacy routes */}
            <Route
              path="/enviar-archivos"
              element={<Navigate replace to="/es/enviar-archivos" />}
            />
            <Route
              path="/compartir-archivos"
              element={<Navigate replace to="/es/compartir-archivos" />}
            />
            {/* Home route */}
            <Route path="" element={<NavigateLng />} />
            {/* Not found routes */}
            <Route path="*" element={<NoMatch />} />
          </Route>
        </Routes>
      </Media>
    </div>
  );
}

export default PageComponent;
