import { DotPulse } from "@uiball/loaders";

function LoadingPage() {
  return (
    <div className="loading-container">
      <DotPulse size={40} speed={2} color="#777e8b" />
    </div>
  );
}

export default LoadingPage;
