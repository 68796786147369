import { createSlice } from "@reduxjs/toolkit";
import { logDebug } from "../../utils/logger";

const migrations = {
  0: (state) => {
    return {
      ...state,
      settings: {
        ...state.settings,
        status: "",
        server: "",
        csrf_token: "",
      },
    };
  },
  1: (state) => {
    return {
      ...state,
      settings: {
        ...state.settings,
        status: "",
        server: "",
        csrf_token: "",
        country: "",
        language: "",
      },
    };
  },
};
const currentVersion = 1;

const slice = createSlice({
  name: "settings",
  initialState: {
    settings: {
      status: "",
      language: "",
      country: "",
      csrf_token: "",
      server: "",
    },
  },
  reducers: {
    setSettings: (state, action) => {
      state.settings = action.payload;
      logDebug("settingsReducer", "setSettings", { settings: state.settings });
    },
    removeSettings: (state, action) => {
      state.settings = {
        status: "",
        language: "",
        country: "",
        csrf_token: "",
        server: "",
      };
      logDebug("settingsReducer", "removeSettings", {
        settings: state.settings,
      });
    },
  },
});

export const { setSettings, removeSettings } = slice.actions;
export { migrations, currentVersion };
export default slice.reducer;
