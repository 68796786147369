import * as yup from "yup";

const getUpdateUserSchema = (t) => {
  return yup.object().shape({
    name: yup
      .string()
      .required(t("validators.notEmpty"))
      .min(0, t("validators.minLength", { value: 0 }))
      .max(85, t("validators.maxLength", { value: 85 })),
    surname: yup
      .string()
      .required(t("validators.notEmpty"))
      .min(0, t("validators.minLength", { value: 0 }))
      .max(85, t("validators.maxLength", { value: 85 })),
    username: yup
      .string()
      .notRequired()
      .min(0, t("validators.minLength", { value: 0 }))
      .max(45, t("validators.maxLength", { value: 45 })),
    billing_name: yup
      .string()
      .notRequired()
      .min(0, t("validators.minLength", { value: 0 }))
      .max(85, t("validators.maxLength", { value: 85 })),
    vat: yup
      .string()
      .notRequired()
      .min(0, t("validators.minLength", { value: 0 }))
      .max(45, t("validators.maxLength", { value: 45 })),
    address: yup
      .string()
      .required(t("validators.notEmpty"))
      .min(0, t("validators.minLength", { value: 0 }))
      .max(145, t("validators.maxLength", { value: 145 })),
    zip_code: yup
      .string()
      .required(t("validators.notEmpty"))
      .min(0, t("validators.minLength", { value: 0 }))
      .max(45, t("validators.maxLength", { value: 45 })),
    country: yup
      .object()
      .shape({
        id: yup.number(),
        name: yup.string(),
        label: yup.string(),
        value: yup.string(),
      })
      .nullable(),
    state: yup
      .object()
      .shape({
        id: yup.number(),
        name: yup.string(),
        label: yup.string(),
        value: yup.string(),
      })
      .nullable(),
    city: yup
      .object()
      .shape({
        id: yup.number(),
        name: yup.string(),
        label: yup.string(),
        value: yup.string(),
      })
      .nullable(),
    phone: yup.string().notRequired(),
  });
};

export default getUpdateUserSchema;
