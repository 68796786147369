import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { warnToast } from "../components/toast";
import { logWarn } from "../utils/logger";
import { useTranslation } from "react-i18next";
import useGetLocalePath from "../hooks/useGetLocalePath";

function NoMatch() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  const { getLocalePath } = useGetLocalePath();

  useEffect(() => {
    warnToast(
      "NoMatch",
      "invalid_url",
      t(`errors.invalid_url`, t("errors.default"))
    );
    logWarn("NoMatch", "invalid_url", {
      url: location.pathname,
      msg: t(`errors.invalid_url`, t("errors.default")),
    });
    navigate(getLocalePath(t("routes.common.home")));
  }, [location.pathname, t, navigate, getLocalePath]);

  return <div></div>;
}

export default NoMatch;
