import { useCallback, useMemo } from "react";
import DropDown from "../dropdown";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { logDebug } from "../../utils/logger";
import { useDispatch } from "react-redux";
import { setBlur, setHeaderMenuOpen } from "../../redux/reducers/uiReducer";
import { isEmpty } from "../../utils/validators";
import { infoToast } from "../toast";

function LangSwitch({ disabled }) {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();

  const langs = useMemo(() => {
    return Object.keys(t("langs")).map((value) => {
      return { label: t("langs")[value], value: value };
    });
  }, [t]);

  const onChange = useCallback(
    (value) => {
      i18n.changeLanguage(value);
      logDebug("lang-switch", "onChange", { lng: value });

      if (!location.pathname.startsWith("/get/t/")) {
        navigate(`/${value}/`, { state: { targetId: "landing" } });
      } else {
        const el = document.getElementById("landing");
        if (!isEmpty(el)) {
          el.scrollIntoView({
            block: "start",
            inline: "start",
            behavior: "smooth",
          });
          infoToast(
            "lang-switch",
            "message.language",
            t("message.language", t("message.default"))
          );
        }
      }

      dispatch(setHeaderMenuOpen(false));
      dispatch(setBlur(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [i18n, location.pathname]
  );

  return (
    <div className="lang-switch__container">
      <DropDown
        options={langs}
        value={i18n.resolvedLanguage}
        disabled={disabled}
        onChange={onChange}
      ></DropDown>
    </div>
  );
}

export default LangSwitch;
