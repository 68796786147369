import classNames from "classnames";
import { useTranslation } from "react-i18next";
import Media from "react-media";
import Avatar from "../../../avatar";
import SpeechBallon from "../../../speech-ballon";
import { useCallback } from "react";

function SpeechBallonSection() {
  const { t } = useTranslation();

  const getReverse = (matches, odd) => {
    return [matches.xs, matches.sm, matches.md].some((i) => i === true) && !odd;
  };

  const getPointerDirection = (matches, odd) => {
    return [matches.lg, matches.xl, matches.xxl].some((i) => i === true)
      ? "bottom"
      : odd
      ? "right"
      : "left";
  };

  const numBallons = useCallback((matches) => {
    if ([matches.xxl].some((i) => i === true)) {
      return 5;
    } else if ([matches.xl].some((i) => i === true)) {
      return 4;
    } else if ([matches.lg].some((i) => i === true)) {
      return 3;
    } else {
      return 5;
    }
  }, []);

  return (
    <div className="speech-ballon__section">
      <div className="speech-ballon__section__header">
        <div className="speech-ballon__section__header__content">
          <p>{t("landing.body.speech_section.title")}</p>
        </div>
      </div>

      <div className="speech-ballon__section__body">
        <div className="speech-ballon__section__body__content">
          <Media
            queries={{
              xs: "(min-width: 0px) and (max-width: 576px)",
              sm: "(min-width: 577px) and (max-width: 768px)",
              md: "(min-width: 769px) and (max-width: 992px)",
              lg: "(min-width: 993px) and (max-width: 1200px)",
              xl: "(min-width: 1201px) and (max-width: 1400px)",
              xxl: "(min-width: 1401px)",
            }}
          >
            {(matches) =>
              [...Array(numBallons(matches)).keys()]
                .map((idx) => idx + 1)
                .map((idx) => {
                  return (
                    <div
                      className={classNames("speech-ballon__section__review", {
                        reverse: getReverse(matches, idx % 2),
                      })}
                      key={idx}
                    >
                      <SpeechBallon
                        msg={t(`landing.body.speech_section.msg${idx}`, {
                          interpolation: { escapeValue: true },
                        })}
                        pointerDirection={getPointerDirection(matches, idx % 2)}
                      />
                      <Avatar
                        src={`../../../assets/images/avatar${idx}.svg`}
                        alt={`avatar ${idx}`}
                        title={`avatar ${idx}`}
                        size={"40px"}
                        loading="lazy"
                      />
                    </div>
                  );
                })
            }
          </Media>
        </div>
      </div>
    </div>
  );
}

export default SpeechBallonSection;
