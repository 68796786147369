import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import AdsComponent from "../ads-component";
import Divider from "../divider";
import SEO from "../seo";

function MaintenanceMode() {
  const { t } = useTranslation();

  const titleSEO = useMemo(() => {
    return `${t("app.name")} - ${t("maintenance.title")}`;
  }, [t]);

  const descriptionSEO = useMemo(() => {
    return t("maintenance.message");
  }, [t]);

  return (
    <div>
      <SEO title={titleSEO} description={descriptionSEO} />
      <div className="maintenance__container">
        <div className="maintenance__card">
          <div className="maintenance__header">
            <span className="maintenance__logo"></span>
          </div>
          <div className="maintenance__body">
            <span className="maintenance__title">{t("maintenance.title")}</span>
            <span className="maintenance__message">
              {t("maintenance.message")}
            </span>
          </div>
          <div className="maintenance__footer">
            <Divider />
            <AdsComponent
              dataAdSlot={process.env.REACT_APP_G_AD_SLOT_DOWNLOADER}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MaintenanceMode;
