import { Controller, useForm } from "react-hook-form";
import getLoginSchema from "../../../schemas/loginSchema";
import { isEmpty } from "../../../utils/validators";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { errorToast } from "../../toast";
import { useNavigate } from "react-router-dom";
import useUserLogin from "../../../hooks/useUserLogin";
import { useCallback, useEffect, useMemo } from "react";
import { removeUser, setUser } from "../../../redux/reducers/userReducer";
import {
  setBlur,
  setShowLoginDialog,
  setTransferInit,
} from "../../../redux/reducers/uiReducer";
import TextField from "../../text-field";
import PasswordField from "../../password-field";
import Button from "../../button";
import {
  signUpUserMethods,
  trackLoginUser,
} from "../../../utils/google-analytics/events/user";
import { logDebug } from "../../../utils/logger";
import useGetLocalePath from "../../../hooks/useGetLocalePath";
import {
  clearItems,
  clearPagination,
  clearSelectedItem,
  clearSelectedSubitem,
  setLoading,
} from "../../../redux/reducers/transfersTableReducer";

function LoginForm({ recaptcha }) {
  const { t } = useTranslation();

  const { getLocalePath } = useGetLocalePath();

  const dispatch = useDispatch();

  const { ui } = useSelector((state) => state);
  const { settings } = useSelector((state) => state.settings);

  const token = useMemo(
    () =>
      !isEmpty(settings) && !isEmpty(settings.csrf_token)
        ? settings.csrf_token
        : "",
    [settings]
  );

  const loginSchema = getLoginSchema(t);
  const {
    register,
    control,
    watch,
    setValue,
    getValues,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      loginpass: "",
      csrf_token: token,
      "g-recaptcha-response": "",
    },
    values: {
      email: "",
      loginpass: "",
      csrf_token: token,
      "g-recaptcha-response": "",
    },
    mode: "all",
    criteriaMode: "all",
    resolver: yupResolver(loginSchema),
  });

  const changeRecaptcha = useCallback(
    (token) => {
      setValue("g-recaptcha-response", token);
      logDebug("login-form", "changeRecaptcha", {
        "g-recaptcha-response": getValues("g-recaptcha-response"),
      });
    },
    [getValues, setValue]
  );

  const resetRecaptcha = useCallback(() => {
    setValue("g-recaptcha-response", "");
    recaptcha.ref.reset();
    logDebug("login-form", "resetRecaptcha", {
      "g-recaptcha-response": recaptcha.ref.getValue(),
    });
  }, [recaptcha.ref, setValue]);

  const navigate = useNavigate();
  const { loading, data, error, loginHandler } = useUserLogin();
  useEffect(() => {
    if (!loading) {
      if (error) {
        dispatch(removeUser());
        resetRecaptcha();
        errorToast(
          "useUserLogin",
          error,
          t(`errors.${error}`, t("errors.default"))
        );
      } else if (data) {
        dispatch(setUser(data));
        resetRecaptcha();
        reset({
          email: "",
          loginpass: "",
          csrf_token: token,
          "g-recaptcha-response": "",
        });
        dispatch(setShowLoginDialog(!ui.show_login_dialog));
        dispatch(setBlur(!ui.is_blur));
        navigate(getLocalePath(t("routes.private.uploads.index")));
        dispatch(clearItems());
        dispatch(clearPagination());
        dispatch(clearSelectedItem());
        dispatch(clearSelectedSubitem());
        dispatch(setLoading(true));
        dispatch(setTransferInit(true));
        // GA4 Event Tracking
        trackLoginUser(signUpUserMethods.EMAIL);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, data, error]);

  const onSubmit = async (data, evt) => {
    if (recaptcha.loaded) {
      const token = !isEmpty(getValues("g-recaptcha-response"))
        ? getValues("g-recaptcha-response")
        : await recaptcha.ref.executeAsync();

      if (!isEmpty(token)) {
        resetRecaptcha();
        setValue("g-recaptcha-response", token);
        logDebug("LoginForm", "onSubmit", { data: data, evt: evt });
        loginHandler(getValues());
      }
    }
  };

  const onError = async (errors, evt) => {
    logDebug("LoginForm", "onError", { errors: errors, evt: evt });
    resetRecaptcha();
    errorToast("onError", errors, t("errors.error_form", t("errors.default")));
  };

  return (
    <form
      id="user_login_form"
      className="login__form"
      onSubmit={handleSubmit(onSubmit, onError)}
    >
      <TextField
        label={t("login_dialog.form.email")}
        autoComplete={"email"}
        value={watch("email")}
        register={register("email", {
          ...loginSchema.email,
          onChange: (evt) => setValue("email", evt.target.value),
          onBlur: (evt) => setValue("email", evt.target.value),
        })}
        disabled={loading}
        error={errors?.email?.message}
        required
      />
      <PasswordField
        label={t("login_dialog.form.loginpass")}
        autoComplete={"current-password"}
        value={watch("loginpass")}
        register={register("loginpass", {
          ...loginSchema.loginpass,
          onChange: (evt) => setValue("loginpass", evt.target.value),
          onBlur: (evt) => setValue("loginpass", evt.target.value),
        })}
        disabled={loading}
        error={errors?.loginpass?.message}
        required
      />
      <Controller
        name="csrf_token"
        control={control}
        defaultValue={token}
        render={({ field }) => <input hidden {...field}></input>}
      />
      <Controller
        name="g-recaptcha-response"
        control={control}
        defaultValue=""
        onChange={(token) => changeRecaptcha(token)}
        onExpired={() => resetRecaptcha()}
        onError={() => resetRecaptcha()}
        render={({ field }) => {
          <input hidden value={getValues("g-recaptcha-response")} {...field} />;
        }}
      />
      <div className="login__form__footer">
        <Button type="submit" primary text loading={loading}>
          <span className="label">{t("login_dialog.button.login")}</span>
        </Button>
      </div>
    </form>
  );
}

export default LoginForm;
