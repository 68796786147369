import { useCallback, useEffect, useMemo, useState } from "react";

import classNames from "classnames";

import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useGetLocalePath from "../../../hooks/useGetLocalePath";
import useWindowSize from "../../../hooks/useWindowSize";
import useTransferFiles from "../../../hooks/useTransferFiles";
import useDeleteTransferFile from "../../../hooks/useDeleteTransferFile";

import {
  equalsIgnoringCase,
  humanFileSize,
  isEmpty,
  isEmptyList,
} from "../../../utils/validators";
import { logDebug, logError } from "../../../utils/logger";
import { START_WITH_FILE } from "../../uploader-component/utils/config";

import Table from "../../table";
import { errorToast, successToast } from "../../toast";
import DeleteTransferFileDialog from "../../uploader-page/delete-transfer-file-dialog";
import {
  clearItems,
  clearPagination,
  clearSelectedItem,
  clearSelectedSubitem,
  setLoading,
} from "../../../redux/reducers/transfersTableReducer";
import {
  setTransferInit,
  setUserInit,
} from "../../../redux/reducers/uiReducer";

function TransferDetailTable(props) {
  const { mode, transfer, setTransfer } = props;

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { getLocalePath } = useGetLocalePath();

  const dispatch = useDispatch();

  const windowSize = useWindowSize();

  const [files, setFiles] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [deleteTransfer, setDeleteTransfer] = useState(false);
  const [showDeleteTransferFileDialog, setShowDeleteTransferFileDialog] =
    useState(false);

  const editMode = useMemo(() => {
    return equalsIgnoringCase(mode, "edit");
  }, [mode]);

  const titleFilesMemo = useMemo(() => {
    return t("uploads_detail.body.files.titleWithCount", {
      count: parseInt(transfer.num_files),
    });
  }, [t, transfer.num_files]);

  const getTitle = useCallback((value) => {
    return value.replace(START_WITH_FILE, "");
  }, []);

  const getSize = useCallback(
    (value) => {
      return humanFileSize(value, i18n.language, true, 2);
    },
    [i18n.language]
  );

  const getNumDownload = useCallback(
    (value) => {
      return t("uploads_detail.body.files.table.rows.downloadWithCount", {
        count: parseInt(value),
      });
    },
    [t]
  );

  const goToUploads = useCallback(
    (evt) => {
      setSelectedItem(null);
      setDeleteTransfer(false);
      setShowDeleteTransferFileDialog(false);
      dispatch(clearItems());
      dispatch(clearPagination());
      dispatch(clearSelectedItem());
      dispatch(clearSelectedSubitem());
      dispatch(setUserInit(true));
      dispatch(setLoading(true));
      dispatch(setTransferInit(true));
      navigate(getLocalePath(t("routes.private.uploads.index")));
    },
    [dispatch, navigate, getLocalePath, t]
  );

  const {
    loading: loadingDeleteTransferFile,
    data: dataDeleteTransferFile,
    error: errorDeleteTransferFile,
    deleteTransferFileHandler,
  } = useDeleteTransferFile();
  const acceptDeleteTransferFileHandler = useCallback(
    (evt) => {
      logDebug("TransferDetailTable", "acceptDeleteTransferFileHandler", {
        evt: evt,
      });
      if (
        !isEmpty(selectedItem) &&
        !isEmpty(selectedItem.idTransfer) &&
        !isEmpty(selectedItem.idFile)
      ) {
        deleteTransferFileHandler(selectedItem.idTransfer, selectedItem.idFile);
      }
    },
    [deleteTransferFileHandler, selectedItem]
  );
  useEffect(() => {
    if (!loadingDeleteTransferFile) {
      if (isEmpty(errorDeleteTransferFile)) {
        if (!isEmpty(dataDeleteTransferFile)) {
          successToast(
            "TransferDetailTable",
            "useDeleteTransferFile",
            t("message.default")
          );
          logDebug("TransferDetailTable", "useDeleteTransferFile", {
            msg: t("message.default"),
          });
          if (deleteTransfer) {
            goToUploads();
          } else {
            const idx = files.findIndex(
              (file) => file.idFile === selectedItem.idFile
            );
            const swap = [...files];
            swap.splice(idx, 1);
            setFiles(swap);
            const transferSize = swap.reduce(
              (acc, file) => acc + parseInt(file.fileSize),
              0
            );
            setTransfer({
              ...transfer,
              num_files: swap.length,
              size: transferSize,
            });
            setSelectedItem(null);
            setDeleteTransfer(false);
            setShowDeleteTransferFileDialog(false);
          }
        }
      } else {
        errorToast(
          "TransferTable",
          "useDeleteTransferFile",
          t(`errors.${errorDeleteTransferFile}`, t("errors.default"))
        );
        logError("TransferDetailTable", "useDeleteTransferFile", {
          msg: t(`errors.${errorDeleteTransferFile}`, t("errors.default")),
        });
        setSelectedItem(null);
        setDeleteTransfer(false);
        setShowDeleteTransferFileDialog(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingDeleteTransferFile]);

  const cancelDeleteTransferFileHandler = useCallback((evt) => {
    logDebug("TransferDetailTable", "cancelDeleteTransferFileHandler", {
      evt: evt,
    });
    setSelectedItem(null);
    setDeleteTransfer(false);
    setShowDeleteTransferFileDialog(false);
  }, []);

  const closeDeleteTransferFileHandler = useCallback((evt) => {
    logDebug("TransferDetailTable", "closeDeleteTransferFileHandler", {
      evt: evt,
    });
    setSelectedItem(null);
    setDeleteTransfer(false);
    setShowDeleteTransferFileDialog(false);
  }, []);

  const { loading, data, error, handler } = useTransferFiles();
  useEffect(() => {
    if (!isEmpty(transfer) && !isEmpty(transfer.idTransfer)) {
      handler(transfer.idTransfer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transfer.idTransfer]);

  useEffect(() => {
    if (!loading) {
      if (isEmpty(error)) {
        if (!isEmptyList(data)) {
          setFiles([...data]);
        } else {
          setFiles([]);
        }
      } else {
        errorToast(
          "TransferDetailTable",
          error,
          t(`errors.${error}`, t("errors.default"))
        );
        setFiles([]);
      }
    }
  }, [loading, data, error, t]);

  const RowFileDetail = (props) => {
    const { file } = props;

    const getFileName = useCallback((item) => {
      return item.fileName.replace(START_WITH_FILE, "");
    }, []);

    const getFileSize = useCallback((item) => {
      return humanFileSize(item.fileSize, i18n.language, true, 2);
    }, []);

    const getFileDownloads = useCallback((item) => {
      return Number(item.countDownload);
    }, []);

    return (
      <div className="cell__container title-more-info">
        <div className="cell__container__row">
          <div className="ellipsis__container">
            <div className="ellipsis__value title">{getFileName(file)}</div>
          </div>
        </div>
        <div className="cell__container__row">
          <div className="cell__value">
            <span className="icon icon-database"></span>
            <span className="label">{getFileSize(file)}</span>
          </div>
          <span className="icon separator icon-circle-alternate"></span>
          <div className="cell__value">
            <span className="icon icon-cloud-download"></span>
            <span className="label">{getFileDownloads(file)}</span>
          </div>
        </div>
      </div>
    );
  };

  const RowActions = (props) => {
    const { disabled, transfer, file } = props;

    const url = useMemo(() => {
      if (
        !isEmpty(file) &&
        !isEmpty(file.idTransfer) &&
        !isEmpty(file.idFile) &&
        !isEmpty(file.hash)
      ) {
        return `https://${process.env.REACT_APP_API}/get/tf/${file.idTransfer}/${file.idFile}/${file.hash}`;
      } else {
        return "";
      }
    }, [file]);

    const deleteFileHandler = useCallback(
      (evt, file) => {
        logDebug("TransferDetailTable", "deleteFileHandler", {
          evt: evt,
          file: file,
        });
        if (!disabled && !isEmpty(file)) {
          setSelectedItem(file);
          setDeleteTransfer(parseInt(transfer.num_files) === 1);
          setShowDeleteTransferFileDialog(true);
        }
      },
      [disabled, transfer.num_files]
    );

    return (
      <div className="cell__container actions">
        {/* eslint-disable-next-line react/jsx-no-target-blank */}
        <a
          href={url}
          target="_blank"
          className="downloader-list-item-container clickable"
          title={t("uploads.table.actions.download")}
        >
          <span className="action__icon icon-cloud-download" />
        </a>
        <span
          role="button"
          disabled={editMode}
          className={classNames("action__icon", "icon-delete-circled-outline", {
            disabled: editMode,
          })}
          title={t("uploads.table.actions.delete")}
          onClick={(evt) => deleteFileHandler(evt, file)}
        />
      </div>
    );
  };

  const columns = useMemo(() => {
    if (windowSize.width >= 576) {
      return [
        {
          id: "fileName",
          accessor: "fileName",
          Header: () => t("uploads_detail.body.files.table.columns.fileName"),
          Cell: ({ value, row }) => (
            <div className="cell__container" title={getTitle(value)}>
              <div className="ellipsis__container">
                <div className="ellipsis__value">{getTitle(value)}</div>
              </div>
            </div>
          ),
          Footer: () => {},
        },
        {
          id: "fileSize",
          accessor: "fileSize",
          Header: () => t("uploads_detail.body.files.table.columns.fileSize"),
          Cell: ({ value, row }) => (
            <div className="cell__container" title={getSize(value)}>
              <div className="ellipsis__container">
                <div className="ellipsis__value">{getSize(value)}</div>
              </div>
            </div>
          ),
          Footer: () => {},
        },
        {
          id: "countDownload",
          accessor: "countDownload",
          Header: () =>
            t("uploads_detail.body.files.table.columns.countDownload"),
          Cell: ({ value, row }) => (
            <div className="cell__container" title={getNumDownload(value)}>
              <div className="ellipsis__container">
                <div className="ellipsis__value">{getNumDownload(value)}</div>
              </div>
            </div>
          ),
          Footer: () => {},
        },
        {
          id: "actions",
          accessor: "actions",
          Headers: () => t("uploads_detail.body.files.table.columns.actions"),
          Cell: ({ value, row }) => (
            <RowActions
              disabled={editMode}
              transfer={transfer}
              file={row.original}
            />
          ),
          Footer: () => {},
        },
      ];
    } else {
      return [
        {
          id: "title",
          accessor: "title",
          Header: () => t("uploads_detail.body.files.table.columns.fileName"),
          Cell: ({ value, row }) => (
            <RowFileDetail transfer={transfer} file={row.original} />
          ),
          Footer: () => {},
        },
        {
          id: "actions",
          accessor: "actions",
          Headers: () => t("uploads_detail.body.files.table.columns.actions"),
          Cell: ({ value, row }) => (
            <RowActions
              disabled={editMode}
              transfer={transfer}
              file={row.original}
            />
          ),
          Footer: () => {},
        },
      ];
    }
  }, [
    editMode,
    transfer,
    getNumDownload,
    getSize,
    getTitle,
    t,
    windowSize.width,
  ]);

  return (
    <>
      <div className="uploads-detail__block__container files">
        <div className="uploads-detail__block__content">
          <div className="uploads-detail__block__title__container">
            <div className="uploads-detail__block__title__content">
              <span className="icon icon-empty-page"></span>
              <div className="ellipsis__container">
                <span className="ellipsis__value title" title={titleFilesMemo}>
                  {titleFilesMemo}
                </span>
              </div>
            </div>
          </div>
          <div className="uploads-detail__block__table__container">
            <div className="uploads-detail__block__table__content">
              <Table
                withHeaders={false}
                loading={loading}
                columns={columns}
                data={files}
              />
            </div>
          </div>
        </div>
      </div>
      <DeleteTransferFileDialog
        showDialog={showDeleteTransferFileDialog}
        deleteTransfer={deleteTransfer}
        closeHandler={(evt) => closeDeleteTransferFileHandler(evt)}
        acceptHandler={(evt) => acceptDeleteTransferFileHandler(evt)}
        cancelHandler={(evt) => cancelDeleteTransferFileHandler(evt)}
      />
    </>
  );
}

export default TransferDetailTable;
