import { useEffect } from "react";

import classNames from "classnames";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useInit, { SERVER_STATUS } from "./hooks/useInit";
import useUnload from "./hooks/useUnload";
import useUnsharedFiles from "./hooks/useUnsharedFiles";

import { removeUser, setUser } from "./redux/reducers/userReducer";
import { setLoading } from "./redux/reducers/uploaderReducer";
import { removeSettings, setSettings } from "./redux/reducers/settingsReducer";

import HeaderComponent from "./components/header-component";
import FooterComponent from "./components/footer-component";
import LoginDialog from "./components/login-dialog";
import RegisterDialog from "./components/register-dialog";
import ResetPasswordDialog from "./components/reset-password-dialog";
import NewsletterDialog from "./components/newsletter-dialog";
import SubscriptionDialog from "./components/subscription-dialog";
import CancelSubscriptionDialog from "./components/cancel-subscription-dialog";
import ContactDialog from "./components/contact-dialog";
import ConsentDialog from "./components/consent-dialog";
import BodyComponent from "./components/body-component";
import PageComponent from "./components/page-component";
import GoogleComponent from "./components/google-component";
import { ToastContainer } from "react-toastify";
import { errorToast } from "./components/toast";

import { isEmpty, equalsIgnoringCase } from "./utils/validators";
import { logDebug } from "./utils/logger";
import { GLOBAL_STATUS } from "./components/uploader-component/utils/config";

function App() {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { ui } = useSelector((state) => state);
  const { uploader } = useSelector((state) => state.uploader);

  const { loading, data, error, initHandler } = useInit();
  useEffect(() => {
    initHandler();
  }, [initHandler]);

  useEffect(() => {
    if ([...Object.values(loading)].every((item) => item === false)) {
      if (uploader.loading) {
        dispatch(setLoading(false));
      }
    } else {
      if (!uploader.loading) {
        dispatch(setLoading(true));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    if (!loading.settings) {
      if (isEmpty(error.settings)) {
        if (!isEmpty(data.settings)) {
          dispatch(setSettings(data.settings));
        } else {
          dispatch(removeSettings());
          errorToast(
            "useInit - settings",
            "empty settings",
            t("errors.default")
          );
        }
      } else {
        dispatch(removeSettings());
        errorToast(
          "useInit - settings",
          error.settings,
          t(`errors.${error.settings}`, t("errors.default"))
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading.settings, data.settings, error.settings, dispatch]);

  useEffect(() => {
    if (
      !loading.user &&
      equalsIgnoringCase(data?.settings?.status, SERVER_STATUS.ONLINE)
    ) {
      if (isEmpty(error.user)) {
        if (!isEmpty(data.user)) {
          dispatch(setUser(data.user));
        } else {
          dispatch(removeUser());
          errorToast("useInit - user", "empty user", t("errors.default"));
        }
      } else {
        dispatch(removeUser());
        logDebug("App", "useInit - user", { error: error.user });
        errorToast(
          "useInit - user",
          error.user,
          t(`errors.${error.user}`, t("errors.default"))
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading.user, data.user, error.user, dispatch, t]);

  useUnsharedFiles(
    data?.settings?.status,
    loading?.files,
    data?.files,
    error?.files
  );

  useUnload(
    equalsIgnoringCase(uploader.globalStatus, GLOBAL_STATUS.UPLOADING),
    t("message.reload_uploading_files")
  );

  return (
    <div>
      {<GoogleComponent />}
      <div className={classNames("grid", { blur: ui.is_blur })}>
        <div className="page1">
          <div className="page1__header">
            <HeaderComponent />
          </div>
          <div className="page1__body">
            <BodyComponent />
          </div>
          <div className="page1__footer">
            {ui.show_footer_menu && <FooterComponent />}
          </div>
        </div>
        <div className="page2">
          <PageComponent />
        </div>
      </div>
      {ui.show_login_dialog && <LoginDialog />}
      {ui.show_register_dialog && <RegisterDialog />}
      {ui.show_reset_password_dialog && <ResetPasswordDialog />}
      {ui.show_newsletter_register_dialog && <NewsletterDialog />}
      {ui.show_newsletter_unregister_dialog && <NewsletterDialog />}
      {ui.show_subscription_dialog && <SubscriptionDialog />}
      {ui.show_cancel_subscription_dialog && <CancelSubscriptionDialog />}
      {ui.show_contact_dialog && <ContactDialog />}
      {ui.show_consent_dialog && <ConsentDialog />}
      <ToastContainer />
    </div>
  );
}

export default App;
