import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { logDebug } from "../utils/logger";

export default function LanguagePath() {
  const { i18n } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();

  const locale = useMemo(() => {
    return location.pathname.split("/")[1];
  }, [location.pathname]);

  useEffect(() => {
    if (locale && i18n.resolvedLanguage !== locale) {
      if (i18n.languages.includes(locale)) {
        i18n.changeLanguage(locale);
        const slug = location.pathname.replace(`/${i18n.resolvedLanguage}`, "");
        const localePath = `/${locale}${slug}`;
        logDebug("LanguagePath", "navigate", {
          locale: locale,
          path: localePath,
        });
      } else {
        const path = location.pathname.replace(`/${locale}/`, "");
        navigate(path, { replace: true });
        logDebug("LanguagePath", "navigate", {
          locale: null,
          path: path,
        });
      }
    }
  }, [locale, i18n, location.pathname, navigate]);

  return <Outlet />;
}
