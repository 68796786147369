import { useCallback, useMemo } from "react";

import classNames from "classnames";

import { useTranslation } from "react-i18next";

import Select from "react-select";

import states from "./states.json";
import {
  equalsIgnoringCase,
  isEmpty,
  isEmptyList,
} from "../../utils/validators";

function SelectStateField(props) {
  const {
    name,
    label,
    placeHolder,
    loading,
    required,
    disabled,
    clearable,
    searchable,
    country_id,
    value,
    error,
    onChange,
    register,
  } = props;

  const { t } = useTranslation();

  const disabledMemo = useMemo(() => {
    return disabled || country_id <= 0;
  }, [disabled, country_id]);

  const options = useMemo(() => {
    let result = [];
    if (country_id > 0) {
      if (!isEmptyList(states)) {
        result = states
          .filter((state) => state.country_id === country_id)
          .map((state) => {
            return {
              id: state.id,
              name: state.name,
              label: state.name,
              value: state.state_code,
            };
          })
          .sort((a, b) => a.label.localeCompare(b.label));
      }
    }
    return result;
  }, [country_id]);

  const onChangeHandler = useCallback(
    (evt) => {
      !disabledMemo && onChange && onChange(evt);
    },
    [disabledMemo, onChange]
  );

  const selectedOption = useMemo(() => {
    if (!isEmpty(value) && value.id > 0) {
      const idx = options.findIndex((c) =>
        equalsIgnoringCase(value.value, c.value)
      );
      return options[idx];
    }
    return null;
  }, [options, value]);

  return (
    <div className="select-state-field__container">
      <div className="select-state-field__content">
        {label && (
          <label htmlFor={name} className="select-state-field__label smaller">
            {`${label}${required ? " *" : ""}`}
          </label>
        )}
        <Select
          {...register}
          id={name}
          name={name}
          placeholder={placeHolder}
          noOptionsMessage={() => t("placeholder.empty_list")}
          className="select-state-field__input"
          classNamePrefix="select-state-field"
          options={options}
          value={selectedOption}
          isLoading={loading}
          isDisabled={disabledMemo}
          isClearable={clearable}
          isSearchable={searchable}
          maxMenuHeight={175}
          menuPlacement="auto"
          menuShouldScrollIntoView={true}
          onChange={(evt) => onChangeHandler(evt)}
        />
        <p className={classNames("select-state-field__error", { show: error })}>
          {error}
        </p>
      </div>
    </div>
  );
}

export default SelectStateField;
export { SelectStateField, states };
