import * as yup from "yup";

const getContactSchema = (t) => {
  return yup.object().shape({
    name: yup
      .string()
      .required(t("validators.notEmpty"))
      .min(0, t("validators.minLength", { value: 0 }))
      .max(50, t("validators.maxLength", { value: 50 })),
    email: yup
      .string()
      .required(t("validators.notEmpty"))
      .email(t("validators.email"))
      .min(0, t("validators.minLength", { value: 0 }))
      .max(45, t("validators.maxLength", { value: 45 })),
    email2: yup
      .string()
      .required(t("validators.notEmpty"))
      .email(t("validators.email"))
      .min(0, t("validators.minLength", { value: 0 }))
      .max(45, t("validators.maxLength", { value: 45 }))
      .oneOf([yup.ref("email"), null], t("validators.compareFields")),
    phone: yup.string().notRequired(),
    reason: yup.string().required(t("validators.notEmpty")),
    message: yup
      .string()
      .required(t("validators.notEmpty"))
      .min(0, t("validators.minLength", { value: 0 }))
      .max(800, t("validators.maxLength", { value: 800 })),
    "g-recaptcha-response": yup.string().notRequired(),
    consent: yup.bool(),
    url: yup.string().notRequired(),
    resolution: yup.string().notRequired(),
  });
};

export default getContactSchema;
