import { useCallback, useEffect, useMemo, useRef } from "react";
import SEO from "../components/seo";
import LandingFooter from "../components/landing/landing-footer";
import { useTranslation } from "react-i18next";
import useGetLocalePath from "../hooks/useGetLocalePath";

function ShareBigFiles() {
  const { t, i18n } = useTranslation();

  const { getLocalePath } = useGetLocalePath();

  const shareBigFilesRef = useRef(null);
  useEffect(() => {
    shareBigFilesRef?.current?.scrollIntoView({
      block: "start",
      inline: "start",
      behavior: "smooth",
    });
  }, []);

  const getNestedRoute = useCallback(
    (nestedRoute) => {
      return getLocalePath(
        `${t("routes.public.share_files.index")}/${nestedRoute}`
      );
    },
    [getLocalePath, t]
  );

  const titleSEO = useMemo(() => {
    return `${t("app.name")} - ${t("share_files_big.title")}`;
  }, [t]);

  const descriptionSEO = useMemo(() => {
    return t("share_files_big.description");
  }, [t]);

  const urlSEO = useMemo(() => {
    return `${process.env.REACT_APP_PUBLIC_URL}${getNestedRoute(
      t("routes.public.share_files.big")
    )}`;
  }, [getNestedRoute, t]);

  const altLinksSEO = useMemo(() => {
    const publicURL = process.env.REACT_APP_PUBLIC_URL;
    const result = i18n.languages.map((lng) => {
      return {
        lng: lng,
        url: `${publicURL}/${lng}/${t("routes.public.share_files.index", {
          lng: lng,
        })}/${t("routes.public.share_files.big", {
          lng: lng,
        })}`,
      };
    });

    const defaultLng = result.find((url) => url.lng === "en");
    result.push({
      lng: "x-default",
      url: defaultLng.url,
    });

    return result;
  }, [i18n.languages, t]);

  return (
    <div>
      <SEO
        title={titleSEO}
        description={descriptionSEO}
        url={urlSEO}
        canonical={urlSEO}
        altLinks={altLinksSEO}
      />
      <div id="share_big_files" ref={shareBigFilesRef}>
        <div className="share-big-files-page__header"></div>
        <div className="share-big-files-page__body">
          <div
            className="share-big-files-page__content"
            dangerouslySetInnerHTML={{
              __html: t("share_files_big.content", {
                interpolation: { escapeValue: true },
              }),
            }}
          ></div>
        </div>
        <div className="share-big-files-page__footer">
          <LandingFooter />
        </div>
      </div>
    </div>
  );
}

export default ShareBigFiles;
