import * as yup from "yup";

const getLoginSchema = (t) => {
  return yup.object().shape({
    email: yup
      .string()
      .required(t("validators.notEmpty"))
      .email(t("validators.email"))
      .min(0, t("validators.minLength", { value: 0 }))
      .max(45, t("validators.maxLength", { value: 45 })),
    loginpass: yup
      .string()
      .required(t("validators.notEmpty"))
      .min(8, t("validators.minLength", { value: 8 }))
      .max(16, t("validators.maxLength", { value: 16 })),
    csrf_token: yup.string().notRequired(),
    "g-recaptcha-response": yup.string().notRequired(),
  });
};

export default getLoginSchema;
