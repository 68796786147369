import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { errorToast, successToast } from "../../toast";
import {
  setBlur,
  setShowNewsletterRegisterDialog,
} from "../../../redux/reducers/uiReducer";
import useRegisterNewsletter from "../../../hooks/useRegNewsletter";
import { equalsIgnoringCase, isEmpty } from "../../../utils/validators";
import { getNewsletterRegSchema } from "../../../schemas/newsletterSchema";
import TextField from "../../text-field";
import Button from "../../button";
import Checkbox from "../../checkbox";
import { logDebug } from "../../../utils/logger";
import { useLocation, useNavigate } from "react-router-dom";
import useGetLocalePath from "../../../hooks/useGetLocalePath";

function NewsletterRegForm({ recaptcha }) {
  const { t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();
  const { getLocalePath } = useGetLocalePath();

  const dispatch = useDispatch();

  const { ui } = useSelector((state) => state);
  const { settings } = useSelector((state) => state.settings);

  const token = useMemo(
    () =>
      !isEmpty(settings) && !isEmpty(settings.csrf_token)
        ? settings.csrf_token
        : "",
    [settings]
  );

  const newsletterRegSchema = getNewsletterRegSchema(t);
  const {
    register,
    control,
    watch,
    setValue,
    getValues,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email1: "",
      email2: "",
      conditions: false,
      csrf_token: token,
      "g-recaptcha-response": "",
    },
    values: {
      email1: "",
      email2: "",
      conditions: false,
      csrf_token: token,
      "g-recaptcha-response": "",
    },
    mode: "all",
    criteriaMode: "all",
    resolver: yupResolver(newsletterRegSchema),
  });

  const changeRecaptcha = useCallback(
    (token) => {
      setValue("g-recaptcha-response", token);
      recaptcha.ref.reset();
    },
    [recaptcha, setValue]
  );

  const resetRecaptcha = useCallback(() => {
    setValue("g-recaptcha-response", "");
    recaptcha.ref.reset();
  }, [recaptcha, setValue]);

  const { loading, data, error, newsletterRegHandler } =
    useRegisterNewsletter();
  useEffect(() => {
    if (!loading) {
      if (error) {
        errorToast(
          "useRegisterNewsletter",
          error,
          t(`errors.${error}`, t("errors.default"))
        );
      } else if (data) {
        reset({
          email1: "",
          email2: "",
          conditions: false,
          csrf_token: token,
          "g-recaptcha-response": "",
        });
        resetRecaptcha();
        successToast(
          "useRegisterNewsletter",
          data.result,
          t(`message.${data.result}`, t("message.default"))
        );
        dispatch(setBlur(!ui.is_blur));
        dispatch(
          setShowNewsletterRegisterDialog(!ui.show_newsletter_register_dialog)
        );
        if (
          equalsIgnoringCase(
            location.pathname,
            getLocalePath(t("routes.public.newsletter_reg"))
          )
        ) {
          navigate(getLocalePath(t("routes.common.home")));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, data, error]);

  const onSubmit = async (data, evt) => {
    if (recaptcha.loaded) {
      const token = !isEmpty(getValues("g-recaptcha-response"))
        ? getValues("g-recaptcha-response")
        : await recaptcha.ref.executeAsync();

      if (!isEmpty(token)) {
        recaptcha.ref.reset();
        setValue("g-recaptcha-response", token);
        logDebug("NewsletterForm", "onSubmit", { data: data, evt: evt });
        newsletterRegHandler(getValues());
      }
    }
  };

  const onError = async (errors, evt) => {
    logDebug("NewsletterForm", "onError", { errors: errors, evt: evt });
    recaptcha.ref.reset();
    errorToast("onError", errors, t("errors.error_form", t("errors.default")));
  };

  return (
    <form
      id="newsletter_register_form"
      className="newsletter__form"
      onSubmit={handleSubmit(onSubmit, onError)}
    >
      <TextField
        label={t("reg_newsletter_dialog.form.email1")}
        autoComplete={"email1"}
        value={watch("email1")}
        register={register("email1", {
          ...newsletterRegSchema.email1,
          onChange: (evt) => setValue("email1", evt.target.value),
          onBlur: (evt) => setValue("email1", evt.target.value),
        })}
        error={errors?.email1?.message}
        disabled={loading}
        required
      />
      <TextField
        label={t("reg_newsletter_dialog.form.email2")}
        autoComplete={"email2"}
        value={watch("email2")}
        register={register("email2", {
          ...newsletterRegSchema.email1,
          onChange: (evt) => setValue("email2", evt.target.value),
          onBlur: (evt) => setValue("email2", evt.target.value),
        })}
        error={errors?.email2?.message}
        disabled={loading}
        required
      />
      <Checkbox
        label={t("reg_newsletter_dialog.form.conditions")}
        value={watch("conditions")}
        disabled={loading}
        register={register("conditions", {
          ...newsletterRegSchema.conditions,
          onChange: () =>
            setValue("conditions", Boolean(getValues("conditions"))),
          onBlur: () =>
            setValue("conditions", Boolean(getValues("conditions"))),
        })}
        error={errors?.conditions?.message}
      />
      <Controller
        name="csrf_token"
        control={control}
        defaultValue={token}
        render={({ field }) => <input hidden {...field}></input>}
      />
      <Controller
        name="g-recaptcha-response"
        control={control}
        defaultValue=""
        onChange={(token) => changeRecaptcha(token)}
        onExpired={() => resetRecaptcha()}
        onError={() => resetRecaptcha()}
        render={({ field }) => {
          <input hidden value={getValues("g-recaptcha-response")} {...field} />;
        }}
      />
      <div className="login__form__footer">
        <Button
          type="submit"
          primary
          text
          loading={loading}
          disabled={!watch("conditions")}
        >
          <span className="label">{t("button.send")}</span>
        </Button>
      </div>
    </form>
  );
}

export default NewsletterRegForm;
