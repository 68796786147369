export const GET_CONFIG = {
  method: "GET",
  mode: "cors",
  credentials: "include",
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

export const POST_CONFIG = {
  method: "POST",
  mode: "cors",
  credentials: "include",
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

export const parseDataUrl = (data) => {
  const urlEncodedDataPairs = [];
  for (const [name, value] of Object.entries(data)) {
    urlEncodedDataPairs.push(
      `${encodeURIComponent(name)}=${encodeURIComponent(value)}`
    );
  }
  return urlEncodedDataPairs.join("&").replace(/%20/g, "+");
};
