import { useCallback, useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import useOnClickOutside from "../../hooks/useOnClickOutside";
import useSubscriptionCancel from "../../hooks/useSubscriptionCancel";

import {
  setBlur,
  setShowCancelSubscriptionDialog,
} from "../../redux/reducers/uiReducer";

import { isEmpty } from "../../utils/validators";
import { logDebug, logError } from "../../utils/logger";

import { errorToast, successToast } from "../toast";
import Button from "../button";

function CancelSubscriptionDialog() {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { ui } = useSelector((state) => state);
  const { user } = useSelector((state) => state.user);

  const [subscriptionID, setSubscriptionID] = useState(null);

  const closeDialogHandler = useCallback(() => {
    dispatch(
      setShowCancelSubscriptionDialog(!ui.show_cancel_subscription_dialog)
    );
    dispatch(setBlur(!ui.is_blur));
    setSubscriptionID(null);
  }, [dispatch, ui.show_cancel_subscription_dialog, ui.is_blur]);

  const cancelSubscriptionDialogRef = useRef(null);
  useOnClickOutside(cancelSubscriptionDialogRef, () => closeDialogHandler());

  const { loading, data, error, getSubscriptionCancel } =
    useSubscriptionCancel();
  useEffect(() => {
    if (!isEmpty(subscriptionID) && !loading) {
      if (!isEmpty(error)) {
        if (!isEmpty(data)) {
          closeDialogHandler();
          logDebug("CancelSubscriptionDialog", "getSubscriptionCancel", {
            key: data.message,
            msg: t(`message.${data.message}`, t("message.default")),
          });
          successToast(
            "CancelSubscriptionDialog",
            data.message,
            t(`message.${data.message}`, t("message.default"))
          );
        }
      } else {
        logError("CancelSubscriptionDialog", "getSubscriptionCancel", {
          key: error,
          msg: t(`errors.${error}`, t("errors.default")),
        });
        errorToast(
          "CancelSubscriptionDialog",
          error,
          t(`errors.${error}`, t("errors.default"))
        );
      }
    }
  }, [subscriptionID, loading, data, error, t, closeDialogHandler]);

  const cancelClickHandler = useCallback(
    (evt) => {
      if (!isEmpty(user) && !isEmpty(user.subscriptionID)) {
        getSubscriptionCancel(user.subscriptionID);
      }
    },
    [user, getSubscriptionCancel]
  );

  return (
    <div>
      <div className="wrapper"></div>
      <div
        ref={cancelSubscriptionDialogRef}
        className="subscription-cancel__dialog"
      >
        <div className="subscription-cancel__dialog__header">
          <div className="subscription-cancel__dialog__header__logo">
            <span className="icon icon-up-round-arrow">
              <span className="path1"></span>
              <span className="path2"></span>
            </span>
          </div>
          <div className="subscription-cancel__dialog__header__title header-3">
            {t("cancel_subscription_dialog.title")}
          </div>
          <div className="subscription-cancel__dialog__header__subtitle text-body">
            {t("cancel_subscription_dialog.subtitle")}
          </div>
        </div>
        <div className="subscription-cancel__dialog__body">
          <Button primary text onClick={(evt) => cancelClickHandler(evt)}>
            <span className="label">{t("button.cancel_plan")}</span>
          </Button>
        </div>
        <div className="subscription-cancel__dialog__footer"></div>
      </div>
    </div>
  );
}

export default CancelSubscriptionDialog;
