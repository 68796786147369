import * as yup from "yup";

const getUploadsSchema = (t) => {
  return yup.object().shape({
    search: yup.string().notRequired(),
    orderBy: yup.string().notRequired(),
  });
};

export default getUploadsSchema;
