import { useEffect } from "react";

const useUnload = (isUnsafeTabClose, message) => {
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isUnsafeTabClose) {
        event.returnValue = message;
        return message;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isUnsafeTabClose, message]);
};

export default useUnload;
