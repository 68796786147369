import HeaderMenu from "../header-menu";
import Logo from "../logo";
import Toolbar from "../toolbar";

function HeaderComponent() {
  return (
    <div>
      <div className="header-container">
        <Logo />
        <Toolbar />
      </div>
      <HeaderMenu />
    </div>
  );
}

export default HeaderComponent;
