import { useCallback, useMemo, useState } from "react";
import { START_WITH_FILE } from "../../uploader-component/utils/config";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

function DownloaderShareListItem({ item }) {
  const { t } = useTranslation();

  const [downloaded, setDownloaded] = useState(false);

  const getUrl = useMemo(() => {
    return `https://${process.env.REACT_APP_API}/get/tf/${item.idTransfer}/${item.idFile}/${item.hash}`;
  }, [item.idTransfer, item.idFile, item.hash]);

  const fileName = useMemo(() => {
    return String(item.fileName).replace(START_WITH_FILE, "");
  }, [item.fileName]);

  const onDownloadClick = useCallback(
    (evt) => {
      if (!downloaded) {
        setDownloaded(true);
      }
    },
    [downloaded]
  );

  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a
      className="downloader-list-item-container clickable"
      href={getUrl}
      target="_blank"
      onClick={() => onDownloadClick()}
    >
      <div className="downloader-list-item__label" title={fileName}>
        <span className="icon-item icon-empty-page"></span>
        <span className="ellipsis__container">
          <span className="ellipsis__value">{fileName}</span>
        </span>
      </div>
      <span
        className={classNames("icon-item", "icon-cloud-download", {
          downloaded: downloaded,
        })}
        title={t("button.download")}
      ></span>
    </a>
  );
}

export default DownloaderShareListItem;
