import { useEffect, useState } from "react";
import { isEmpty } from "../utils/validators";

function useHasScrollbar(element) {
  // Initialize state with false scrollX/scrollY
  const [hasScrollX, setHasScrollX] = useState(false);
  const [hasScrollY, setHasScrollY] = useState(false);

  useEffect(() => {
    // Handler to call on window resize
    function handleResizeWindow() {
      if (!isEmpty(element)) {
        setHasScrollX(element.scrollWidth >= element.clientWidth);
        setHasScrollY(element.scrollHeight >= element.clientHeight);
      } else {
        setHasScrollX(false);
        setHasScrollY(false);
      }
    }
    // Add event listener
    window.addEventListener("resize", handleResizeWindow);
    // Call handler right away so state gets updated with initial window size
    handleResizeWindow();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResizeWindow);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element]); // Empty array ensures that effect is only run on mount

  return { hasScrollX, hasScrollY };
}

export default useHasScrollbar;
