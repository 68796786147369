import { createSlice } from "@reduxjs/toolkit";
import { logDebug } from "../../utils/logger";

const slice = createSlice({
  name: "transfersTable",
  initialState: {
    loading: true,
    items: [],
    selectedItem: null,
    selectedSubitem: null,
    filters: JSON.stringify({ search: "" }),
    orderBy: "",
    pagination: JSON.stringify({ start: 0, limit: 20, total: 0, more: true }),
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
      logDebug("transfersTableReducer", "setLoading", {
        loading: state.loading,
      });
    },
    setItems: (state, action) => {
      state.items = action.payload;
      logDebug("transfersTableReducer", "setItems", {
        items: state.items,
      });
    },
    clearItems: (state, action) => {
      state.items = [];
      logDebug("transfersTableReducer", "clearItems", {
        items: state.items,
      });
    },
    setSelectedItem: (state, action) => {
      state.selectedItem = JSON.stringify(action.payload);
      logDebug("transfersTableReducer", "setSelectedItem", {
        selectedItem: JSON.parse(state.selectedItem),
      });
    },
    clearSelectedItem: (state, action) => {
      state.selectedItem = null;
      logDebug("transfersTableReducer", "clearSelectedItem", {
        selectedItem: null,
      });
    },
    setSelectedSubitem: (state, action) => {
      state.selectedSubitem = JSON.stringify(action.payload);
      logDebug("transfersTableReducer", "setSelectedSubitem", {
        selectedSubitem: JSON.parse(state.selectedSubitem),
      });
    },
    clearSelectedSubitem: (state, action) => {
      state.selectedSubitem = null;
      logDebug("transfersTableReducer", "clearSelectedSubitem", {
        selectedSubitem: null,
      });
    },
    setFilters: (state, actions) => {
      state.filters = JSON.stringify(actions.payload);
      logDebug("transfersTableReducer", "setFilters", {
        filters: state.filters,
      });
    },
    clearFilters: (state, actions) => {
      state.filters = JSON.stringify({ search: "" });
      logDebug("transfersTableReducer", "clearFilters", {
        filters: state.filters,
      });
    },
    setOrderBy: (state, actions) => {
      state.orderBy = actions.payload;
      logDebug("transfersTableReducer", "setOrderBy", {
        orderBy: state.orderBy,
      });
    },
    clearOrderBy: (state, actions) => {
      state.orderBy = "";
      logDebug("transfersTableReducer", "clearOrderBy", {
        orderBy: state.orderBy,
      });
    },
    setPagination: (state, action) => {
      state.pagination = JSON.stringify(action.payload);
      logDebug("transfersTableReducer", "setPagination", {
        pagination: JSON.parse(state.pagination),
      });
    },
    clearPagination: (state, action) => {
      state.pagination = JSON.stringify({
        start: 0,
        limit: 20,
        total: 0,
        more: true,
      });
      logDebug("transfersTableReducer", "clearPagination", {
        pagination: JSON.parse(state.pagination),
      });
    },
  },
});

export const {
  setLoading,
  setItems,
  clearItems,
  setSelectedItem,
  clearSelectedItem,
  setSelectedSubitem,
  clearSelectedSubitem,
  setFilters,
  clearFilters,
  setOrderBy,
  clearOrderBy,
  setPagination,
  clearPagination,
} = slice.actions;
export default slice.reducer;
