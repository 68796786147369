import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import classNames from "classnames";

import { isEmpty } from "../../utils/validators";

function TextField(props) {
  const {
    type,
    label,
    placeholder,
    autoComplete,
    disabled,
    hidden,
    required,
    pattern,
    value,
    error,
    register,
  } = props;
  const { ref, name, onChange, onFocus, onBlur } = register;

  const textFieldRef = useRef(null);

  const [isActive, setIsActive] = useState(false);

  const title = useMemo(() => {
    if (isEmpty(placeholder)) {
      if (!isEmpty(value)) {
        return `${value}`;
      } else {
        return "";
      }
    } else {
      if (!isEmpty(value)) {
        return `${placeholder} - ${value}`;
      } else {
        return `${placeholder}`;
      }
    }
  }, [placeholder, value]);

  const isFocusHandler = useCallback(() => {
    const activeElement = document.activeElement;
    if (
      !isEmpty(activeElement) &&
      !isEmpty(textFieldRef.current) &&
      textFieldRef.current === document.activeElement
    ) {
      setIsActive(true);
    } else {
      setIsActive(!isEmpty(placeholder) || !isEmpty(value));
    }
  }, [placeholder, value]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => isFocusHandler(), [value, placeholder]);

  const onFocusHandler = useCallback(
    (evt) => {
      onFocus && onFocus(evt);
      isFocusHandler();
    },
    [onFocus, isFocusHandler]
  );

  const onBlurHandler = useCallback(
    (evt) => {
      onBlur && onBlur(evt);
      isFocusHandler();
    },
    [onBlur, isFocusHandler]
  );

  const onChangeHandler = useCallback(
    (evt) => {
      onChange && onChange(evt);
      isFocusHandler();
    },
    [onChange, isFocusHandler]
  );

  return (
    <div
      className={classNames("text-field__container", {
        unlabelled: isEmpty(label),
        hidden: hidden,
        disabled: disabled,
      })}
      title={title}
    >
      <div className="text-field__content">
        <div className="text-field__ellipsis">
          <input
            {...register}
            id={name}
            name={name}
            type={type}
            className="text-field__input"
            placeholder={placeholder}
            value={value}
            disabled={disabled}
            hidden={hidden}
            required={required}
            autoComplete={autoComplete}
            pattern={pattern}
            ref={(value) => {
              ref(value);
              textFieldRef.current = value;
            }}
            onFocus={(evt) => onFocusHandler(evt)}
            onChange={(evt) => onChangeHandler(evt)}
            onBlur={(evt) => onBlurHandler(evt)}
          ></input>
        </div>
        {label && (
          <label
            htmlFor={name}
            className={classNames("text-field__label", "smaller", {
              active: isActive,
            })}
          >
            {`${label}${required ? " *" : ""}`}
          </label>
        )}
      </div>
      <p
        className={classNames("text-field__error", { show: error })}
        title={error}
      >
        {error}
      </p>
    </div>
  );
}

export default TextField;
