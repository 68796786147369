export const START_WITH_FILE = "YDRAY-";

export const FILE_SIZES = {
  // Chunk File Size
  CHUNK_FILE_SIZE: 2097152,
  // Max File Sizes
  UNREGISTERED_MAX_FILE_SIZE: 10737418240,
  FREE_USER_MAX_FILE_SIZE: 10737418240,
  PRO_USER_MAX_SIZE_SIZE: 21474836480,
  // Max Uploading Sizes:
  UNREGISTERED_MAX_UPLOADING_SIZE: 10737418240,
  FREE_USER_MAX_UPLOADING_SIZE: 10737418240,
  PRO_USER_MAX_UPLOADING_SIZE: 21474836480,
  /*
  UNREGISTERED_MAX_FILE_SIZE: 10485760, // 10737418240,
  FREE_USER_MAX_FILE_SIZE: 20971520, // 10737418240,
  PRO_USER_MAX_SIZE_SIZE: 52428800, // 21474836480,
  UNREGISTERED_MAX_UPLOADING_SIZE: 10485760, // 10737418240,
  FREE_USER_MAX_UPLOADING_SIZE: 20971520, // 10737418240,
  PRO_USER_MAX_UPLOADING_SIZE: 52428800, // 21474836480
  */
};

export const MAX_FILES_PER_UPLOAD = 50;

export const GLOBAL_STATUS = {
  INIT: "INIT",
  ADDING: "ADDING",
  UPLOADING: "UPLOADING",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
};

export const STATUS_UPLOADER = {
  INIT: "UPLOADER_INIT",
  DRAG: "UPLOADER_DRAG",
  FORM: "UPLOADER_FORM",
  DETAIL: "UPLOADER_DETAIL",
  ERROR: "UPLOADER_ERROR",
};

export const SHARE_TYPE = {
  GENERATE: "SHARE_GENERATE",
  COPY: "SHARE_COPY",
};
