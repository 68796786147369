import { useCallback, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { isEmpty } from "../../utils/validators";
import { useTranslation } from "react-i18next";

export const SEO = (params) => {
  const { title, description, canonical, url, altLinks } = params;

  const { t, i18n } = useTranslation();

  const nameMemo = useMemo(() => t("app.name"), [t]);

  const imgMemo = useMemo(
    () => `${process.env.REACT_APP_PUBLIC_URL}/assets/images/logo.png`,
    []
  );

  const imgAltMemo = useMemo(
    () => `${t("app.name")} - ${t("landing.title")}`,
    [t]
  );

  const canonicalMemo = useMemo(() => {
    return !isEmpty(canonical) ? canonical : "";
  }, [canonical]);

  const altLinksMemo = useCallback(() => {
    return altLinks?.map((item) => {
      return (
        <link
          rel="alternate"
          key={item.url}
          href={item.url}
          hreflang={item.lng}
        />
      );
    });
  }, [altLinks]);

  return (
    <Helmet htmlAttributes={{ lang: i18n.resolvedLanguage }}>
      {/* Metatags  */}
      <title>{title}</title>
      <meta name="description" content={description} />
      {/* Links */}
      {!isEmpty(canonicalMemo) && <link rel="canonical" href={canonicalMemo} />}
      {altLinksMemo()}
      {/* Open Graph */}
      <meta property="og:type" content={"article"} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={imgMemo} />
      <meta property="og:image:secure_url" content={imgMemo} />
      <meta property="og:image:alt" content={imgAltMemo} />
      <meta property="og:site_name" content={nameMemo} />
      {/* Twitter */}
      <meta name="twitter:card" content={"summary"} />
      <meta name="twitter:site" content={"@Ydray_"} />
      <meta name="twitter:creator" content={"@Ydray_"} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imgMemo} />
      <meta name="twitter:image:alt" content={imgAltMemo} />
      <meta name="twitter:url" content={url} />
    </Helmet>
  );
};

export default SEO;
