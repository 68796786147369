import classNames from "classnames";
import { useMemo } from "react";

function Checkbox(props) {
  const { label, value, required, register, error } = props;
  const { name, ref } = register;

  const labelMemo = useMemo(() => {
    return `${label}${required ? " *" : ""}`;
  }, [label, required]);

  return (
    <div className="checkbox__container">
      <label htmlFor={name} className="checkbox__label">
        {value && <span className="checkbox__icon icon-check"></span>}
        <input
          ref={ref}
          id={name}
          name={name}
          type="checkbox"
          className="checkbox__input rounded"
          value={value}
          {...register}
        />
        <span
          className="checkbox__label"
          dangerouslySetInnerHTML={{
            __html: labelMemo,
          }}
        ></span>
      </label>
      <p className={classNames("checkbox__error", { show: error })}>{error}</p>
    </div>
  );
}

export default Checkbox;
