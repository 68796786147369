import { useCallback, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useOnClickOutside from "../../hooks/useOnClickOutside";

import {
  setBlur,
  setShowLoginDialog,
  setShowRegisterDialog,
  setShowResetPasswordDialog,
} from "../../redux/reducers/uiReducer";

import RegisterForm from "./register-form";
import ReCAPTCHA from "react-google-recaptcha";

function RegisterDialog() {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { ui } = useSelector((state) => state);

  const recaptchaRef = useRef(null);
  const [loaded, setLoaded] = useState(false);
  const asyncScriptOnLoad = useCallback((data) => setLoaded(data.loaded), []);

  const closeRegisterDialog = useCallback(() => {
    dispatch(setShowRegisterDialog(!ui.show_register_dialog));
    dispatch(setBlur(!ui.is_blur));
  }, [ui.show_register_dialog, ui.is_blur, dispatch]);

  const openLoginDialog = useCallback(() => {
    dispatch(setShowLoginDialog(!ui.show_login_dialog));
    dispatch(setShowRegisterDialog(!ui.show_register_dialog));
  }, [ui.show_register_dialog, ui.show_login_dialog, dispatch]);

  const openResetPasswordDialog = useCallback(() => {
    dispatch(setShowResetPasswordDialog(!ui.show_reset_password_dialog));
    dispatch(setShowRegisterDialog(!ui.show_register_dialog));
  }, [ui.show_reset_password_dialog, ui.show_register_dialog, dispatch]);

  const dialogRegisterRef = useRef(null);
  useOnClickOutside(dialogRegisterRef, () => closeRegisterDialog());

  return (
    <div>
      <div className="wrapper"></div>
      <div ref={dialogRegisterRef} className="register__dialog">
        <div className="register__dialog__header">
          <span className="register__dialog__header__logo"></span>
          <div className="register__dialog__header__title header-3">
            {t("register_dialog.title")}
          </div>
          <div className="register__dialog__header__subtitle text-body">
            {t("register_dialog.subtitle")}
          </div>
        </div>
        <div className="register__dialog__body">
          <RegisterForm
            recaptcha={{ ref: recaptchaRef.current, loaded: loaded }}
          />
        </div>
        <div className="register__dialog__footer">
          <span className="switch__msg text-body">
            {t("register_dialog.form.login_account")}
            <span className="switch__button" onClick={() => openLoginDialog()}>
              {t("register_dialog.form.logIn")}
            </span>
          </span>
          <span className="switch__msg text-body">
            {t("register_dialog.form.reset_password")}
            <span
              className="switch__button"
              onClick={() => openResetPasswordDialog()}
            >
              {t("register_dialog.form.remind_me")}
            </span>
          </span>
        </div>
      </div>
      <ReCAPTCHA
        ref={recaptchaRef}
        className="register-reCAPTCHA"
        sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
        badge="bottomright"
        size="invisible"
        asyncScriptOnLoad={(data) => asyncScriptOnLoad(data)}
      />
    </div>
  );
}

export default RegisterDialog;
