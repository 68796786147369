import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Media from "react-media";
import { useDispatch, useSelector } from "react-redux";
import {
  setBlur,
  setHeaderMenuOpen,
  setShowContactDialog,
  setShowNewsletterRegisterDialog,
} from "../../../redux/reducers/uiReducer";
import { equalsIgnoringCase, isEmpty } from "../../../utils/validators";
import useGetLocalePath from "../../../hooks/useGetLocalePath";
import LangSwitch from "../../lang-switch";
import { logError } from "../../../utils/logger";

function LandingFooter() {
  const { t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();
  const { getLocalePath } = useGetLocalePath();

  const dispatch = useDispatch();
  const { ui } = useSelector((state) => state);

  const goHome = () => {
    if (
      equalsIgnoringCase(
        location.pathname,
        getLocalePath(t("routes.common.home"))
      )
    ) {
      const el = document.getElementById("landing");
      if (!isEmpty(el)) {
        el.scrollIntoView({
          block: "start",
          inline: "start",
          behavior: "smooth",
        });
      }

      const headerAboutLink = document.getElementById("header-about-link");
      if (!isEmpty(headerAboutLink)) {
        headerAboutLink.classList.toggle("active", false);
      }
      const footerAboutLink = document.getElementById("footer-about-link");
      if (!isEmpty(footerAboutLink)) {
        footerAboutLink.classList.toggle("active", false);
      }
      const headerPlansLink = document.getElementById("header-plans-link");
      if (!isEmpty(headerPlansLink)) {
        headerPlansLink.classList.toggle("active", false);
      }
      const footerPlansLink = document.getElementById("footer-plans-link");
      if (!isEmpty(footerPlansLink)) {
        footerPlansLink.classList.toggle("active", false);
      }
    } else {
      navigate(getLocalePath(t("routes.common.home")), {
        state: { targetId: "landing" },
      });
    }

    dispatch(setHeaderMenuOpen(false));
  };

  const openContactDialog = () => {
    dispatch(setShowContactDialog(!ui.show_contact_dialog));
    dispatch(setBlur(!ui.is_blur));
    dispatch(setHeaderMenuOpen(false));
  };

  const openNewsletterDialog = () => {
    dispatch(
      setShowNewsletterRegisterDialog(!ui.show_newsletter_register_dialog)
    );
    dispatch(setBlur(!ui.is_blur));
    dispatch(setHeaderMenuOpen(false));
  };

  const showRevocationMessage = () => {
    try {
      window.googlefc.callbackQueue.push({
        CONSENT_DATA_READY: () => window.googlefc.showRevocationMessage(),
      });
    } catch (error) {
      logError("LandingFooter", "showRevocationMessage", { error: error });
    }
  };

  return (
    <div className="landing__footer">
      <Media
        queries={{
          xs: "(min-width: 0px) and (max-width: 576px)",
          sm: "(min-width: 577px) and (max-width: 768px)",
          md: "(min-width: 769px) and (max-width: 992px)",
          lg: "(min-width: 993px) and (max-width: 1200px)",
          xl: "(min-width: 1201px) and (max-width: 1400px)",
          xxl: "(min-width: 1401px)",
        }}
      >
        {(matches) =>
          [matches.xl, matches.xxl].some((i) => i === true) && (
            <div className="landing__footer__content">
              <div className="landing__footer__logos">
                <div className="logo-container" onClick={() => goHome()}>
                  <img
                    className="logo"
                    src="../../../assets/images/ydray-icon.svg"
                    title={t("logo.title")}
                    alt={t("logo.alt")}
                    height="auto"
                    width="auto"
                    loading="lazy"
                  />
                  <img
                    className="wordmark"
                    src="../../../assets/images/ydray-wordmark-black.svg"
                    title={t("wordmark.title")}
                    alt={t("wordmark.alt")}
                    height="auto"
                    width="auto"
                    loading="lazy"
                  />
                </div>
                <div className="social-container">
                  <a
                    href={t("social_media.links.youtube")}
                    target="_blank"
                    rel="noopener noreferrer"
                    title={t("social_media.youtube")}
                    onClick={() => dispatch(setHeaderMenuOpen(false))}
                  >
                    <span className="social-text">
                      {t("social_media.youtube")}
                    </span>
                    <span className="social-icon icon-youtube"></span>
                  </a>
                  <a
                    href={t("social_media.links.linkedin")}
                    target="_blank"
                    rel="noopener noreferrer"
                    title={t("social_media.linkedin")}
                    onClick={() => dispatch(setHeaderMenuOpen(false))}
                  >
                    <span className="social-text">
                      {t("social_media.linkedin")}
                    </span>
                    <span className="social-icon icon-linkedin"></span>
                  </a>
                  <a
                    href={t("social_media.links.twitter")}
                    target="_blank"
                    rel="noopener noreferrer"
                    title={t("social_media.twitter")}
                    onClick={() => dispatch(setHeaderMenuOpen(false))}
                  >
                    <span className="social-text">
                      {t("social_media.twitter")}
                    </span>
                    <span className="social-icon icon-twitter-x"></span>
                  </a>
                  <a
                    href={t("social_media.links.facebook")}
                    target="_blank"
                    rel="noopener noreferrer"
                    title={t("social_media.facebook")}
                    onClick={() => dispatch(setHeaderMenuOpen(false))}
                  >
                    <span className="social-text">
                      {t("social_media.facebook")}
                    </span>
                    <span className="social-icon icon-facebook"></span>
                  </a>
                  <a
                    href={t("social_media.links.instagram")}
                    target="_blank"
                    rel="noopener noreferrer"
                    title={t("social_media.instagram")}
                    onClick={() => dispatch(setHeaderMenuOpen(false))}
                  >
                    <span className="social-text">
                      {t("social_media.instagram")}
                    </span>
                    <span className="social-icon icon-instagram"></span>
                  </a>
                  <LangSwitch />
                </div>
              </div>
              <div className="landing__footer__columns">
                <div className="footer__column">
                  <Link
                    to={getLocalePath(t("routes.public.press"))}
                    className={"footer__column-item"}
                    title={t("landing.footer.press")}
                    onClick={() => dispatch(setHeaderMenuOpen(false))}
                  >
                    {t("landing.footer.press")}
                  </Link>
                  <div
                    className={"footer__column-item"}
                    title={t("landing.footer.contact")}
                    onClick={() => openContactDialog()}
                  >
                    {t("landing.footer.contact")}
                  </div>
                  <div
                    className={"footer__column-item"}
                    title={t("landing.footer.newsletter")}
                    onClick={() => openNewsletterDialog()}
                  >
                    {t("landing.footer.newsletter")}
                  </div>
                  <Link
                    to={getLocalePath(t("routes.public.faq"))}
                    className={"footer__column-item"}
                    title={t("landing.footer.faq")}
                    onClick={() => dispatch(setHeaderMenuOpen(false))}
                  >
                    {t("landing.footer.faq")}
                  </Link>
                </div>
                <div className="footer__column">
                  <Link
                    to={getLocalePath(t("routes.public.privacy"))}
                    className={"footer__column-item"}
                    title={t("landing.footer.privacy_policy")}
                    onClick={() => dispatch(setHeaderMenuOpen(false))}
                  >
                    {t("landing.footer.privacy_policy")}
                  </Link>
                  <Link
                    to={getLocalePath(t("routes.public.cookies"))}
                    className={"footer__column-item"}
                    title={t("landing.footer.cookie_policy")}
                    onClick={() => dispatch(setHeaderMenuOpen(false))}
                  >
                    {t("landing.footer.cookie_policy")}
                  </Link>
                  <div
                    className={"footer__column-item"}
                    title={t("landing.footer.consent_cookies")}
                    onClick={() => showRevocationMessage()}
                  >
                    {t("landing.footer.consent_cookies")}
                  </div>
                  <Link
                    to={getLocalePath(t("routes.public.legal"))}
                    className={"footer__column-item"}
                    title={t("landing.footer.legal_warn")}
                    onClick={() => dispatch(setHeaderMenuOpen(false))}
                  >
                    {t("landing.footer.legal_warn")}
                  </Link>
                </div>
                <div className="footer__column">
                  <Link
                    to={getLocalePath(t("routes.public.send_files.index"))}
                    className={"footer__column-item"}
                    title={t("landing.footer.send_files")}
                    onClick={() => dispatch(setHeaderMenuOpen(false))}
                  >
                    {t("landing.footer.send_files")}
                  </Link>
                  <Link
                    to={getLocalePath(t("routes.public.share_files.index"))}
                    className={"footer__column-item"}
                    title={t("landing.footer.share_files")}
                    onClick={() => dispatch(setHeaderMenuOpen(false))}
                  >
                    {t("landing.footer.share_files")}
                  </Link>
                </div>
              </div>
            </div>
          )
        }
      </Media>

      <Media
        queries={{
          xs: "(min-width: 0px) and (max-width: 576px)",
          sm: "(min-width: 577px) and (max-width: 768px)",
          md: "(min-width: 769px) and (max-width: 992px)",
          lg: "(min-width: 993px) and (max-width: 1200px)",
          xl: "(min-width: 1201px) and (max-width: 1400px)",
          xxl: "(min-width: 1401px)",
        }}
      >
        {(matches) =>
          [matches.xs, matches.sm, matches.md, matches.lg].some(
            (i) => i === true
          ) && (
            <div>
              <div className="landing__footer__content">
                <div className="landing__footer__columns">
                  <div className="footer__column">
                    <Link
                      to={getLocalePath(t("routes.public.press"))}
                      className={"footer__column-item"}
                      title={t("landing.footer.press")}
                      onClick={() => dispatch(setHeaderMenuOpen(false))}
                    >
                      {t("landing.footer.press")}
                    </Link>
                    <div
                      className={"footer__column-item"}
                      title={t("landing.footer.contact")}
                      onClick={() => openContactDialog()}
                    >
                      {t("landing.footer.contact")}
                    </div>
                    <div
                      className={"footer__column-item"}
                      title={t("landing.footer.newsletter")}
                      onClick={() => openNewsletterDialog()}
                    >
                      {t("landing.footer.newsletter")}
                    </div>
                    <Link
                      to={getLocalePath(t("routes.public.faq"))}
                      className={"footer__column-item"}
                      title={t("landing.footer.faq")}
                      onClick={() => dispatch(setHeaderMenuOpen(false))}
                    >
                      {t("landing.footer.faq")}
                    </Link>
                    <Link
                      to={getLocalePath(t("routes.public.privacy"))}
                      className={"footer__column-item"}
                      title={t("landing.footer.privacy_policy")}
                      onClick={() => dispatch(setHeaderMenuOpen(false))}
                    >
                      {t("landing.footer.privacy_policy")}
                    </Link>
                    <Link
                      to={getLocalePath(t("routes.public.cookies"))}
                      className={"footer__column-item"}
                      title={t("landing.footer.cookie_policy")}
                      onClick={() => dispatch(setHeaderMenuOpen(false))}
                    >
                      {t("landing.footer.cookie_policy")}
                    </Link>
                    <div
                      className={"footer__column-item"}
                      title={t("landing.footer.consent_cookies")}
                      onClick={() => showRevocationMessage()}
                    >
                      {t("landing.footer.consent_cookies")}
                    </div>
                    <Link
                      to={getLocalePath(t("routes.public.legal"))}
                      className={"footer__column-item"}
                      title={t("landing.footer.legal_warn")}
                      onClick={() => dispatch(setHeaderMenuOpen(false))}
                    >
                      {t("landing.footer.legal_warn")}
                    </Link>
                    <Link
                      to={getLocalePath(t("routes.public.send_files.index"))}
                      className={"footer__column-item"}
                      title={t("landing.footer.send_files")}
                      onClick={() => dispatch(setHeaderMenuOpen(false))}
                    >
                      {t("landing.footer.send_files")}
                    </Link>
                    <Link
                      to={getLocalePath(t("routes.public.share_files.index"))}
                      className={"footer__column-item"}
                      title={t("landing.footer.share_files")}
                      onClick={() => dispatch(setHeaderMenuOpen(false))}
                    >
                      {t("landing.footer.share_files")}
                    </Link>
                  </div>
                </div>
                <div className="landing__footer__logos">
                  <div className="logo-container" onClick={() => goHome()}>
                    <img
                      className="logo"
                      src="../../../assets/images/ydray-icon-plain-black.svg"
                      title={t("logo.title")}
                      alt={t("logo.alt")}
                      loading="lazy"
                      width="40px"
                      height="40px"
                    />
                  </div>
                </div>
              </div>
              <div className="landing__footer__row">
                <div className="social-container">
                  <a
                    href={t("social_media.links.youtube")}
                    target="_blank"
                    rel="noopener noreferrer"
                    title={t("social_media.youtube")}
                    onClick={() => dispatch(setHeaderMenuOpen(false))}
                  >
                    <span className="social-text">
                      {t("social_media.youtube")}
                    </span>
                    <span className="social-icon icon-youtube"></span>
                  </a>
                  <a
                    href={t("social_media.links.linkedin")}
                    target="_blank"
                    rel="noopener noreferrer"
                    title={t("social_media.linkedin")}
                    onClick={() => dispatch(setHeaderMenuOpen(false))}
                  >
                    <span className="social-text">
                      {t("social_media.linkedin")}
                    </span>
                    <span className="social-icon icon-linkedin"></span>
                  </a>
                  <a
                    href={t("social_media.links.twitter")}
                    target="_blank"
                    rel="noopener noreferrer"
                    title={t("social_media.twitter")}
                    onClick={() => dispatch(setHeaderMenuOpen(false))}
                  >
                    <span className="social-text">
                      {t("social_media.twitter")}
                    </span>
                    <span className="social-icon icon-twitter-x"></span>
                  </a>
                  <a
                    href={t("social_media.links.facebook")}
                    target="_blank"
                    rel="noopener noreferrer"
                    title={t("social_media.facebook")}
                    onClick={() => dispatch(setHeaderMenuOpen(false))}
                  >
                    <span className="social-text">
                      {t("social_media.facebook")}
                    </span>
                    <span className="social-icon icon-facebook"></span>
                  </a>
                  <a
                    href={t("social_media.links.instagram")}
                    target="_blank"
                    rel="noopener noreferrer"
                    title={t("social_media.instagram")}
                    onClick={() => dispatch(setHeaderMenuOpen(false))}
                  >
                    <span className="social-text">
                      {t("social_media.instagram")}
                    </span>
                    <span className="social-icon icon-instagram"></span>
                  </a>
                </div>
                <div className="social-container">
                  <LangSwitch />
                </div>
              </div>
            </div>
          )
        }
      </Media>
    </div>
  );
}

export default LandingFooter;
