import { useCallback, useMemo, useState } from "react";

const useGetTransfer = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getUrl = useCallback((id) => {
    return `https://${process.env.REACT_APP_API}/get/transfer/${id}`;
  }, []);

  const config = useMemo(() => {
    return {
      method: "GET",
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
  }, []);

  const getTransferHandler = useCallback(
    (id) => {
      try {
        setLoading(true);
        fetch(getUrl(id), config)
          .then((response) => response.json())
          .then((data) => {
            setError(data.error);
            delete data.error;
            setData(data.files);
          })
          .catch((error) => setError(error))
          .finally(() => setLoading(false));
      } catch (error) {
        setError(error);
      }
    },
    [getUrl, config]
  );

  return { loading, data, error, getTransferHandler };
};

export default useGetTransfer;
