import { useTranslation } from "react-i18next";

import Button from "../../button";
import Dialog from "../../dialog";

function DeleteTransferDialog(props) {
  const { t } = useTranslation();

  const { showDialog, closeHandler, acceptHandler, cancelHandler } = props;

  const HeaderDialog = (props) => {
    return (
      <div className="dialog__header__container">
        <span className="dialog__header__logo warn"></span>
        <div className="dialog__header__title header-3">
          {t("dialog.types.warn")}
        </div>
      </div>
    );
  };

  const BodyDialog = (props) => {
    return (
      <div className="dialog__content">
        <p>{t("uploads.message.delete_transfer")}</p>
      </div>
    );
  };

  const FooterDialog = (props) => {
    const { acceptHandler, cancelHandler } = props;
    return (
      <div className="buttons">
        <Button primary text onClick={(evt) => acceptHandler(evt)}>
          <span className="small-title">{t("button.accept")}</span>
        </Button>
        <Button secondary text onClick={(evt) => cancelHandler(evt)}>
          <span className="small-title">{t("button.cancel")}</span>
        </Button>
      </div>
    );
  };

  return (
    <Dialog
      header={<HeaderDialog />}
      body={<BodyDialog />}
      footer={
        <FooterDialog
          acceptHandler={(evt) => acceptHandler(evt)}
          cancelHandler={(evt) => cancelHandler(evt)}
        />
      }
      showDialog={showDialog}
      canCloseOutside={false}
      closeHandler={(evt) => closeHandler(evt)}
    />
  );
}

export default DeleteTransferDialog;
