import classNames from "classnames";

function Tag({ listClass, text }) {
  const clazzes = classNames("tag", ...listClass);

  return (
    <span className={clazzes} title={text}>
      {text}
    </span>
  );
}

export default Tag;
