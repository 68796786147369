import ReactGA4 from "react-ga4";
import { logDebug } from "../logger";

/**
 * Initialize GA4
 *
 * @param {object} options
 *
 */
const initGA4 = (options = null) => {
  // Initialize options GA4
  var opts = options || {};
  // Initialize GA4
  ReactGA4.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID, opts);
  // Logger
  logDebug("google-analytics", "initGA4", {
    id: process.env.REACT_APP_GA_MEASUREMENT_ID,
    options: opts,
    msg: "Google Analytics was initialized...",
  });
};

/**
 * Send a custom Google Analytics 4 Event
 *
 * @param {string} name Specifies the event category. Must not be empty.
 * @param {object} params Specifies the event label. Must not be empty.
 *
 * @see https://developers.google.com/analytics/devguides/collection/ga4/events?client_type=gtag
 */
const sendEventGA4 = (name, params) => {
  // Send GA4 Event
  ReactGA4.gtag("event", name, params);
  // Logger
  logDebug("google-analytics", "GA4 event", { name: name, params: params });
};

export default initGA4;
export { initGA4, sendEventGA4 };
