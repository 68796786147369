import { useCallback, useEffect, useMemo } from "react";

import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, Link } from "react-router-dom";
import useGetLocalePath from "../hooks/useGetLocalePath";
import useChangePassword from "../hooks/useChangePassword";

import { yupResolver } from "@hookform/resolvers/yup";
import getPasswordSchema from "../schemas/passwordSchema";

import SEO from "../components/seo";
import Button from "../components/button";
import Divider from "../components/divider";
import PasswordField from "../components/password-field";
import { errorToast, successToast } from "../components/toast";

import { logDebug } from "../utils/logger";

function Pasword() {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const { getLocalePath } = useGetLocalePath();

  const { user } = useSelector((state) => state.user);
  useEffect(() => {
    if (!user.logged_in) {
      navigate(getLocalePath(t("routes.common.home")));
    }
  });

  const passwordSchema = getPasswordSchema(t);
  const {
    register,
    watch,
    setValue,
    getValues,
    handleSubmit,
    reset,
    formState: { isSubmitSuccessful, errors },
  } = useForm({
    defaultValues: {
      oldpass: "",
      pass1: "",
      pass2: "",
    },
    mode: "all",
    criteriaMode: "all",
    resolver: yupResolver(passwordSchema),
  });

  const { loading, data, error, changePassHandler } = useChangePassword();
  useEffect(() => {
    if (!loading) {
      if (error) {
        errorToast(
          "useChangePassword",
          error,
          t(`errors.${error}`, t("errors.default"))
        );
      } else if (!error && isSubmitSuccessful) {
        reset({
          oldpass: "",
          pass1: "",
          pass2: "",
        });
        successToast(
          "useChangePassword",
          "message.mail_check",
          t("message.mail_check")
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, data, error]);

  const onSubmit = (data, evt) => {
    logDebug("Password", "onSubmit", { data: data, evt: evt });
    changePassHandler(getValues());
  };

  const onError = (errors, evt) => {
    logDebug("Password", "onError", { errors: errors, evt: evt });
    errorToast("onError", errors, t("errors.error_form", t("errors.default")));
  };

  const getNestedRoute = useCallback(
    (nestedRoute) => {
      return getLocalePath(`${t("routes.private.prefs.index")}/${nestedRoute}`);
    },
    [getLocalePath, t]
  );

  const titleSEO = useMemo(() => {
    return `${t("app.name")} - ${t("password.title")}`;
  }, [t]);

  const descriptionSEO = useMemo(() => {
    return t("password.description");
  }, [t]);

  const urlSEO = useMemo(() => {
    return `${process.env.REACT_APP_PUBLIC_URL}${getNestedRoute(
      t("routes.private.prefs.pass")
    )}`;
  }, [getNestedRoute, t]);

  const altLinksSEO = useMemo(() => {
    const publicURL = process.env.REACT_APP_PUBLIC_URL;
    const result = i18n.languages.map((lng) => {
      return {
        lng: lng,
        url: `${publicURL}/${lng}/${t("routes.private.prefs.index", {
          lng: lng,
        })}/${t("routes.private.prefs.pass", {
          lng: lng,
        })}`,
      };
    });

    const defaultLng = result.find((url) => url.lng === "en");
    result.push({
      lng: "x-default",
      url: defaultLng.url,
    });

    return result;
  }, [i18n.languages, t]);

  return (
    <>
      <SEO
        title={titleSEO}
        description={descriptionSEO}
        url={urlSEO}
        canonical={urlSEO}
        altLinks={altLinksSEO}
      />
      <div className="password-page__container">
        <div className="password-page__content">
          <div className="plans-page__title">
            <Link to={t("routes.common.go_back")}>
              <div className="link-container">
                <span className="icon icon-nav-arrow-left"></span>
                <span className="label small-title">{t("password.title")}</span>
              </div>
            </Link>
            <Divider />
          </div>
          <div className="password-container">
            <div className="password-content">
              <form
                id="password-form"
                className="password-form"
                onSubmit={handleSubmit(onSubmit, onError)}
              >
                <PasswordField
                  label={t("password.form.oldpass")}
                  autoComplete="old-password"
                  value={watch("oldpass")}
                  register={register("oldpass", {
                    ...passwordSchema.oldpass,
                    onChange: (evt) => setValue("oldpass", evt.target.value),
                    onBlur: (evt) => setValue("oldpass", evt.target.value),
                  })}
                  disabled={loading}
                  error={errors?.oldpass?.message}
                  required
                />
                <PasswordField
                  label={t("password.form.pass1")}
                  autoComplete="new-password"
                  value={watch("pass1")}
                  register={register("pass1", {
                    ...passwordSchema.pass1,
                    onChange: (evt) => setValue("pass1", evt.target.value),
                    onBlur: (evt) => setValue("pass1", evt.target.value),
                  })}
                  disabled={loading}
                  error={errors?.pass1?.message}
                  required
                />
                <PasswordField
                  label={t("password.form.pass2")}
                  autoComplete="new-password"
                  value={watch("pass2")}
                  register={register("pass2", {
                    ...passwordSchema.pass2,
                    onChange: (evt) => setValue("pass2", evt.target.value),
                    onBlur: (evt) => setValue("pass2", evt.target.value),
                  })}
                  disabled={loading}
                  error={errors?.pass2?.message}
                  required
                />
                <div className="password-form__button">
                  <Button type="submit" primary text loading={loading}>
                    <span className="label">{t("button.save")}</span>
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Pasword;
