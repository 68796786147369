import { useCallback, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import getUploadsSchema from "../../../../schemas/uploadsSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { errorToast, warnToast } from "../../../toast";
import SearchInput from "../../../search-input";
import DropDown from "../../../dropdown";

import {
  clearFilters,
  clearOrderBy,
  setFilters,
  setOrderBy,
} from "../../../../redux/reducers/transfersTableReducer";
import { logDebug, logError, logWarn } from "../../../../utils/logger";
import { isEmpty } from "../../../../utils/validators";

function TransferTableFilter(props) {
  const { total, filters, orderBy, loading, onSearch, onSearchClear, onOrder } =
    props;

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const orderByOpts = useMemo(() => {
    return [
      { label: t("uploads.table.columns.title"), value: "title ASC" },
      { label: t("uploads.table.columns.newest"), value: "dispatched DESC" },
      { label: t("uploads.table.columns.oldest"), value: "dispatched ASC" },
      { label: t("uploads.table.columns.until"), value: "until ASC" },
      { label: t("uploads.table.columns.size"), value: "size DESC" },
    ];
  }, [t]);

  const minCharsToSearch = useMemo(() => {
    return 3;
  }, []);

  const uploadsSchema = getUploadsSchema(t);
  const { control, watch, setValue, handleSubmit } = useForm({
    defaultValues: {
      search: filters.search,
      orderBy: orderBy,
    },
    mode: "all",
    criteriaMode: "all",
    resolver: yupResolver(uploadsSchema),
  });

  const onSubmit = (data, evt) => {
    logDebug("TransferTableFilter", "onSubmit", { data: data, evt: evt });
    if (
      isEmpty(data.search) ||
      (!isEmpty(data.search) && data.search.length >= minCharsToSearch)
    ) {
      setValue("search", data.search);
      dispatch(setFilters({ search: data.search }));
      setValue("orderBy", data.orderBy);
      dispatch(setOrderBy(data.orderBy));
      onSearch({ search: data.search, orderBy: data.orderBy });
    } else {
      logWarn("TransferTableFilter", "onSubmit", {
        msg: t("errors.search_chars", { count: minCharsToSearch }),
      });
      warnToast(
        "TransferTableFilter",
        "onSubmit",
        t("errors.search_chars", { count: minCharsToSearch })
      );
    }
  };

  const onError = (errors, evt) => {
    logDebug("TransferTableFilter", "onError", { errors: errors, evt: evt });
    errorToast("onError", errors, t("errors.error_form", t("errors.default")));
    setValue("search", "");
    dispatch(clearFilters());
    setValue("orderBy", "");
    dispatch(clearOrderBy());
  };

  const searchOnChange = useCallback(
    (value) => {
      setValue("search", value);
      dispatch(setFilters({ ...filters, search: value }));
    },
    [dispatch, filters, setValue]
  );

  const searchOnBlur = useCallback(
    (value) => {
      if (
        isEmpty(value) ||
        (!isEmpty(value) && value.length >= minCharsToSearch)
      ) {
        setValue("search", value);
        dispatch(setFilters({ ...filters, search: value }));
        setValue("orderBy", "");
        dispatch(clearOrderBy());
        onSearch({ ...filters, search: value });
      } else {
        logWarn("TransferTableFilter", "searchOnBlur", {
          msg: t("errors.search_chars", { count: minCharsToSearch }),
          filters: { ...filters },
        });
        warnToast(
          "TransferTableFilter",
          "searchOnBlur",
          t("errors.search_chars", { count: minCharsToSearch })
        );
      }
    },
    [minCharsToSearch, setValue, dispatch, filters, onSearch, t]
  );

  const searchOnClear = useCallback(
    (value) => {
      if (isEmpty(value)) {
        setValue("search", value);
        dispatch(setFilters({ ...filters, search: value }));
        setValue("orderBy", "");
        dispatch(clearOrderBy());
        onSearchClear({ ...filters, search: value });
      } else {
        logError("TransferTableFilter", "searchOnClear", { value: value });
        errorToast("TransferTableFilter", "searchOnClear", t("errors.default"));
      }
    },
    [dispatch, filters, onSearchClear, setValue, t]
  );

  const searchOnOrder = useCallback(
    (value) => {
      if (!isEmpty(value)) {
        setValue("orderBy", value);
        dispatch(setOrderBy(value));
        onOrder(value);
      } else {
        logError("TransferTableFilter", "searchOnOrder", { value: value });
        errorToast("TransferTableFilter", "searchOnOrder", t("errors.default"));
      }
    },
    [dispatch, onOrder, setValue, t]
  );

  return (
    <form
      id="uploads_filter_form"
      className="uploads__content__body__table__actions"
      onSubmit={handleSubmit(onSubmit, onError)}
    >
      <div className="uploads__content__body__table__actions__stats">
        <div
          className="stat result-count"
          title={t("uploads.body.results", { count: total })}
        >
          <span className="icon icon-cloud-upload"></span>
          <div className="stat__ellipsis">
            <span className="stat__value">
              {t("uploads.body.results", { count: total })}
            </span>
          </div>
        </div>
      </div>
      <div className="uploads__content__body__table__actions__search">
        <Controller
          name="search"
          control={control}
          defaultValue={""}
          render={({ register }) => (
            <SearchInput
              value={watch("search")}
              clearable
              onChange={(value) => searchOnChange(value)}
              onBlur={(value) => searchOnBlur(value, minCharsToSearch)}
              onClear={(value) => searchOnClear(value)}
              register={register}
              disabled={loading}
            />
          )}
        />
      </div>
      <div className="uploads__content__body__table__actions__order">
        <Controller
          name="orderBy"
          control={control}
          defaultValue={""}
          render={({ register }) => (
            <DropDown
              options={orderByOpts}
              placeHolder={t("dropdown.order")}
              value={watch("orderBy")}
              onChange={(value) => searchOnOrder(value)}
              register={register}
              disabled={loading}
            />
          )}
        />
      </div>
    </form>
  );
}

export default TransferTableFilter;
