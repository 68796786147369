import classNames from "classnames";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Divider from "../../divider";
import Loading from "../../loading";
import { isEmpty } from "../../../utils/validators";
import { useInView } from "react-intersection-observer";
import {
  setIframeLoading,
  setIframeShow,
} from "../../../redux/reducers/uiReducer";
import { useDispatch, useSelector } from "react-redux";

function LandingIframe() {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { ui } = useSelector((state) => state);

  const { ref: landingRef, inView: landingVisible } = useInView();
  useEffect(() => {
    if (landingVisible) {
      const headerAboutLink = document.getElementById("header-about-link");
      if (!isEmpty(headerAboutLink)) {
        headerAboutLink.classList.toggle("active", false);
      }
      const footerAboutLink = document.getElementById("footer-about-link");
      if (!isEmpty(footerAboutLink)) {
        footerAboutLink.classList.toggle("active", false);
      }
      const headerPlansLink = document.getElementById("header-plans-link");
      if (!isEmpty(headerPlansLink)) {
        headerPlansLink.classList.toggle("active", false);
      }
      const footerPlansLink = document.getElementById("footer-plans-link");
      if (!isEmpty(footerPlansLink)) {
        footerPlansLink.classList.toggle("active", false);
      }
    }
  }, [landingVisible]);

  const goToLandingAbout = () => {
    const el = document.getElementById("about");
    if (!isEmpty(el)) {
      el.scrollIntoView({
        block: "start",
        inline: "start",
        behavior: "smooth",
      });

      const headerAboutLink = document.getElementById("header-about-link");
      if (!isEmpty(headerAboutLink)) {
        headerAboutLink.classList.toggle("active", true);
      }
      const footerAboutLink = document.getElementById("footer-about-link");
      if (!isEmpty(footerAboutLink)) {
        footerAboutLink.classList.toggle("active", true);
      }
      const headerPlansLink = document.getElementById("header-plans-link");
      if (!isEmpty(headerPlansLink)) {
        headerPlansLink.classList.toggle("active", false);
      }
      const footerPlansLink = document.getElementById("footer-plans-link");
      if (!isEmpty(footerPlansLink)) {
        footerPlansLink.classList.toggle("active", false);
      }
    }
  };

  const srcUrl = useMemo(() => {
    return process.env.REACT_APP_ADS;
  }, []);

  return (
    <div ref={landingRef} className="landing-iframe__container">
      {ui.is_iframe_loading && <Loading />}
      <div className={classNames("ads", { hidden: ui.is_iframe_loading })}>
        <iframe
          className="landing-iframe__content"
          src={srcUrl}
          title="iframe"
          loading="lazy"
          scrolling="no"
          onLoad={() => {
            dispatch(setIframeLoading(false));
            dispatch(setIframeShow(true));
          }}
        ></iframe>
        <Divider />
        <div
          className="landing-iframe__link"
          onClick={() => goToLandingAbout()}
        >
          <span className="small-title">{t("footer_menu.about_us")}</span>
          <span className="icon-nav-arrow-down"></span>
        </div>
      </div>
    </div>
  );
}

export default LandingIframe;
