export const TRANSLATIONS = {
  app: {
    name: "YDRAY",
  },

  routes: {
    // Common routes
    common: {
      home: "/",
      go_back: "..",
    },
    // Public routes
    public: {
      about: "sobre-nosotros",
      plans: "planes",
      press: "prensa",
      newsletter_reg: "alta-newsletter",
      newsletter_unreg: "baja-newsletter",
      contact: "contacto",
      faq: "preguntas-frecuentes",
      cookies: "politica-cookies",
      privacy: "politica-privacidad",
      legal: "informacion-legal",
      send_files: {
        index: "enviar-archivos",
        big: "grandes",
        free: "gratis",
      },
      share_files: {
        index: "compartir-archivos",
        big: "grandes",
        free: "gratis",
      },
      uploader: "uploader",
    },
    // Private routes
    private: {
      uploads: {
        index: "subidas",
        detail: "detalle-transferencia",
      },
      prefs: {
        index: "preferencias",
        plans: "planes",
        bills: "facturacion",
        pass: "cambiar-password",
        contact: "contacto",
        custom: "personalizar-enlaces",
        personal: "cambiar-datos-usuario",
      },
    },
  },

  langs: {
    es: "Castellano",
    en: "English",
  },

  button: {
    accept: "Aceptar",
    add: "Añadir",
    save: "Guardar",
    search: "Buscar",
    close: "Cerrar",
    menu: "Menú",
    send: "Enviar",
    upload: "Subir",
    download: "Descargar",
    more_info: "Más Información",
    try: "Probar",
    cancel: "Cancelar",
    cancelAll: "Cancelar todos",
    reupload: "Resubir",
    reuploadAll: "Resubir todos",
    cancel_plan: "Cancelar Plan",
    contact: "Contactar",
    waiting_list: "Lista de Espera",
    subscription: "Suscribirse",
    actual_plan: "Plan Actual",
  },

  dialog: {
    types: {
      info: "Información",
      warn: "Advertencia",
      error: "Error",
      success: "Éxito",
      share: "Compartir",
    },
  },

  placeholder: {
    search: "Buscar...",
    empty: "(campo vacío)",
    empty_list: "Lista vacía",
    empty_table: "No se han encontrado resultados",
  },

  social_media: {
    mail: "Email",
    gmail: "Gmail",
    youtube: "Youtube",
    twitter: "X (Twitter)",
    facebook: "Facebook",
    whatsapp: "WhatsApp",
    linkedin: "LinkedIn",
    instagram: "Instagram",
    share_ph: "Compartir...",
    share_msg: "Te envío estos archivos por YDRAY",
    links: {
      youtube: "https://www.youtube.com/channel/UCUEyel0jLWYlOxr8HuXUEqA",
      twitter: "https://twitter.com/Ydray_",
      facebook: "https://www.facebook.com/Ydray",
      instagram: "https://www.instagram.com/ydray.com_",
      linkedin: "https://www.linkedin.com/company/ydray",
    },
  },

  subscription: {
    free: "FREE",
    pro: "PRO",
    business: "BUSINESS",
  },

  errors: {
    default: "Ha ocurrido un error, por favor, inténtalo de nuevo más tarde",
    empty: "El campo {{field}} no puede estar vacío",
    already_registered: "Ya existe un usuario registrado",
    invalid_email: "El correo no es válido",
    field_too_long: "El campo {{field}} es demasiado largo",
    field_too_short: "El campo {{field}} es demasiado corto",
    invalid_email_or_pass: "El correo o la contraseña no son válidos",
    pass_not_equal: "Las contraseñas no coinciden",
    pass_too_long: "La contraseña es demasiado larga",
    pass_too_short: "La contraseña es demasiado corta",
    msg_too_long: "El comentario es demasiado largo",
    title_too_long: "El título es demasiado largo",
    invalid_old_pass: "La contraseña antigua no coincide",
    new_pass_not_equal: "La contraseña nueva no coincide",
    existed_file: "Ya existe un archivo con el mismo nombre",
    file_too_big: "El archivo es demasiado grande",
    max_upload_size:
      "Se ha excedido el tamaño total permitido por subida (máximo {{size}})",
    fileType: "El tipo de archivo no está permitido",
    error_db:
      "Ha ocurrido un error de base de datos, por favor inténtalo de nuevo más tarde",
    bbdd: "Ha ocurrido un error de base de datos, por favor inténtalo de nuevo más tarde",
    unauthenticated: "No tienes permisos para subir archivos",
    forbidden: "No tienes permisos para realizar la acción",
    expired: "El enlace de descarga ha expirado",
    password_protected: "El enlace de descarga está protegido por contraseña",
    wrong_pass: "La contraseña no coincide",
    not_confirmed: "El email proporcionado no ha sido confirmado",
    maintenance:
      "El servicio se encuentra en mantenimiento. Por favor, inténtalo de nuevo más tarde",
    incorrect_captcha: "El captcha no es correcto",
    incorrect_email: "El correo no es válido",
    missing_message: "El mensaje no es correcto",
    missing_name: "El nombre/empresa no es correcto",
    conditions_not_accepted: "Las condiciones deben ser aceptadas",
    invalid_url: "🤔 ¡Ups! Parece que aquí no hay nada...",
    error_form: "Por favor, resuelve los problemas del formulario",
    max_file_per_upload:
      "El número máximo de archivos por subida es de {{count}} archivos",
    server_maintenance: "El servidor se encuentra en mantenimiento",
    search_chars:
      "Introduce al menos {{count}} caracteres para buscar transferencias",
    invalid_opt: "La opción seleccionada no está registrada",
    expired_date: "Caducada",
    exp_transfer_date: "Transferencia caducada",
    files:
      "Ha ocurrido un error de cookies, por favor inténtalo de nuevo más tarde",
    cors_server:
      "Ha ocurrido un error de CORS, por favor inténtalo de nuevo más tarde",
    adblock_detected: "AdBlock detectado",
    iab_cookie_not_detected: "La cadena de TC no se detecta",
    iab_cookie_expired:
      "La última actualización de la cadena de TC fue hace más de 13 meses",
    not_correct_user:
      "La sesión del usuario no coincide con el de la suscripción",
    user_is_pro: "El usuario ya tiene una suscripción PRO",
    not_logged_user: "La sesión del usuario no es válida",
    not_found_subscription: "La suscripción no se ha encontrado",
    not_id_sent:
      "No se ha enviado el identificador de la suscripción del usuario",
    not_confirmed_subscription:
      "No se ha podido confirmar el estado de la suscripción",
    not_cancelled_subscription:
      "No se ha podido cancelar la suscripción del usuario",
    error_subscription: "Ha ocurrido un error al realizar la suscripción",
    cancel_subcription_process:
      "Suscripción no realizada. Se ha cerrado el popup antes de tiempo",
  },

  message: {
    default: "La operación se ha realizado correctamente",
    language: "¡El idioma ha cambiado correctamente! 🌎",
    clipboard: "¡Copiado al portapapeles! 📋",
    have_not_uploaded_files: "Tienes archivos sin compartir",
    reload_uploading_files:
      "Hay una subida activa, ¿quieres abandonar la página?",
    mail_check: "Te hemos enviado un email para confirmar el cambio",
    create_user_ok: "Se ha enviado un correo para validar el nuevo usuario",
    not_found: "No existen resultados para la búsqueda solicitada",
    prevent_reload:
      "Hay cambios sin guardar en la página. Si continúa con la recarga de la página, estos cambios se perderán. ¿Desea continuar?",
    adblock_not_detected: "AdBlock no detectado",
    iab_cookie_not_expired:
      "La última actualización de la cadena de TC fue hace menos de 13 meses",
    ads_not_allowed: "Los anuncios no están permitidos",
    allow_ads:
      "Los anuncios son seguros e informativos, por favor deshabilita adblocker",
    refresh_ads: "Actualizando anuncios",
    user_changed_to_pro: "La suscripción se ha realizado correctamente",
  },

  validators: {
    integer: "El campo tiene que ser un valor entero",
    positive: "El campo tiene que ser un valor positivo",
    negative: "El campo tiene que ser un valor negativo",
    phone: "El campo no es un número de teléfono válido",
    notEmpty: "El campo no puede estar vacío",
    email: "El campo no es una dirección de correo válida",
    compareFields: "El valor del campo no concuerda con el anterior",
    lessThan: "El campo no puede ser menor que {{value}}",
    moreThan: "El campo no puede ser mayor que {{value}}",
    minLength: "El campo no puede ser menor que {{value}} caracteres",
    maxLength: "El campo no puede ser mayor que {{value}} caracteres",
    checkTrue: "El campo tiene que ser marcado",
  },

  maintenance: {
    title: "Estamos de mantenimiento",
    message:
      "El servidor se encuentra en mantenimiento. Por favor, vuelve en unos minutos para compartir y enviar tus archivos.",
  },

  block_upload: {
    title: "YDRAY ya está abierto en otra ventana",
    message:
      "Sigue enviando y compartiendo archivos con YDRAY en la otra ventana",
  },

  error_upload: {
    title: "Ha ocurrido un error en YDRAY",
    message: "Ha ocurrido un error, por favor, inténtalo de nuevo más tarde",
  },

  downloader_expired: {
    title: "Tu enlace ha expirado",
    message: "El enlace de descarga ha expirado",
  },

  banner: {
    pro_plan: {
      message:
        "Recuerda que puedes suscribirte al plan PRO para tener más capacidad de almacenamiento y subidas ilimitadas.",
    },
  },

  tooltip: {
    feature_pro_message:
      "Suscríbete a la cuenta Pro para tener acceso a esta sección",
  },

  logo: {
    title: "Logo de YDRAY",
    alt: "Logo de YDRAY",
  },

  wordmark: {
    title: "YDRAY",
    alt: "YDRAY",
  },

  header_menu: {
    about_us: "Sobre Nosotros",
    plans: "Planes",
    account: "Cuenta",
    uploads: "Subidas",
  },

  footer_menu: {
    about_us: "Sobre Nosotros",
    plans: "Planes",
    account: "Cuenta",
    login: "Iniciar Sesión",
    uploads: "Subidas",
    preferences: "Preferencias",
  },

  login_dialog: {
    title: "Inicia Sesión",
    subtitle: "",
    form: {
      email: "Email",
      loginpass: "Contraseña",
      create_account: "¿No tienes una cuenta?",
      logIn: "Regístrate",
      reset_password: "¿Has olvidado la contraseña?",
      remind_me: "Recordar",
    },
    button: {
      login: "Iniciar Sesión",
    },
  },

  register_dialog: {
    title: "Crear Cuenta",
    subtitle: "Después podrás elegir el plan que prefieras",
    form: {
      username: "Nombre de Usuario",
      email: "Email",
      pass1: "Contraseña",
      pass2: "Repite la contraseña",
      login_account: "¿Ya tienes una cuenta?",
      logIn: "Inicia sesión",
      reset_password: "¿Has olvidado la contraseña?",
      remind_me: "Recordar",
    },
    button: {
      create: "Crear Cuenta",
    },
  },

  reset_password_dialog: {
    title: "¿Has olviado la contraseña?",
    subtitle: "¡No te preocupes, te ayudaremos!",
    form: {
      email: "Email",
      login_account: "¿Ya tienes una cuenta?",
      logIn: "Inicia sesión",
      create_account: "¿No tienes una cuenta?",
      register: "Regístrate",
    },
    button: {
      remind_me: "Recordar",
    },
  },

  reg_newsletter_dialog: {
    title: "Newsletter",
    subtitle:
      "¡Apúntate a nuestra newsletter! Te avisaremos cuando el registro esté disponible",
    form: {
      email1: "Email",
      email2: "Repite el email",
      conditions: `
        He leído, entiendo y acepto la <a class="link" href="/es/politica-privacidad" title="política de privacidad">política de privacidad</a>.`,
    },
  },

  unreg_newsletter_dialog: {
    title: "Newsletter",
    subtitle:
      "Para darte de baja de nuestra newsletter, por favor introduce el correo con el que te apuntaste",
    form: {
      email: "Email",
    },
  },

  consent_dialog: {
    title: "Te damos la bienvenida a ydray.com",
    subtitle:
      "ydray.com solicita tu consentimiento para usar tus datos personales con estos objetivos:",
    ad: "Anuncios y contenido personalizados, medición de anuncios y del contenido, información sobre el público y desarrollo de productos",
    storage: "Almacenar o acceder a información en un dispositivo",
    moreinfo: "Más información",
    moreinfo_p1:
      "Tus datos personales se tratarán y la información de tu dispositivo (cookies, identificadores únicos y otros datos del dispositivo) podrá ser almacenada, consultada y compartida con proveedores externos o utilizada específicamente pos este sitio web o aplicación.",
    moreinfo_p2:
      "Es posible que algunos proveedores traten tus datos personales en virtud de un interés legítimo, algo a lo que puedes oponerte gestionando tus opciones a continuación. Busca un enlace al final de esta página o en nuestra política de privacidad para revocar el consentimiento.",
    button: {
      management_ops: "Gestionar Opciones",
      consent: "Consentir",
    },
  },

  subscription_dialog: {
    title: "Gracias por elegir el plan PRO",
    subtitle: "Para realizar el pago, conéctate a tu cuenta",
  },

  cancel_subscription_dialog: {
    title: "Cancelar Plan",
    subtitle: "Para cancelar el plan, haz click en el botón",
  },

  dropdown: {
    placeholder: "Seleccione...",
    order: "Ordenar por",
    day_one: "día",
    day_other: "días",
    dayWithCount_one: "{{count}} día",
    dayWithCount_other: "{{count}} días",
    undefined: "Indefinido",
  },

  uploader: {
    add_files_content: {
      title: "Añade tus archivos a compartir",
      subtitle:
        "Pulsa añadir o suelta aquí tus archivos para que empiece la carga",
      list_items: `
        <li class="list-item">
          <span class="item-icon smaller icon-check"></span>
          <span class="item-text smaller">Compartir y enviar archivos grandes hasta 10 GB gratis</span>
        </li>
        <li class="list-item">
          <span class="item-icon smaller icon-check"></span>
          <span class="item-text smaller">Compartir y enviar archivos grandes sin necesidad de registro</span>
        </li>
        <li class="list-item">
          <span class="item-icon smaller icon-check"></span>
          <span class="item-text smaller">Máxima privacidad al compartir y enviar archivos</span>
        </li>
      `,
      privacy_consent: `
        Para enviar y compartir tus archivos, rogamos aceptes nuestra <a class="link" href='/es/politica-privacidad/' title="política de privacidad">política de privacidad</a>,
        <a class="link" href="/es/informacion-legal/" title="condiciones del servicio">condiciones del servicio</a> y <a class="link" href="/es/politica-cookies" title="política de cookies">política de cookies</a>.
        Usamos cookies con fines funcionales y estadísticos, y cookies de terceros con fines publicitarios.
      `,
    },
    share_content: {
      title: "¡Listo para compartir!",
      button: {
        generate: "Generar enlace",
        copy: "Copiar enlace",
        share: "Compartir",
        new: "Nueva subida",
      },
    },
    upload_files_content: {
      add_files: "Añade archivos",
      file_one: "archivo",
      file_other: "archivos",
      fileWithCount_one: "{{count}} archivo",
      fileWithCount_other: "{{count}} archivos",
      uploading_file_one: "Subiendo archivo",
      uploading_file_other: "Subiendo archivos",
      uploading_fileWithCount_one: "Subiendo {{count}} archivo",
      uploading_fileWithCount_other: "Subiendo {{count}} archivos",
      uploaded_file_one: "Archivo subido",
      uploaded_file_other: "Archivo subidos",
      uploaded_fileWithCount_one: "{{count}} archivo subido",
      uploaded_fileWithCount_other: "{{count}} archivos subidos",
      title: "Título",
      comment: "Comentario",
      deleteIn: "Eliminar en",
      password: "Contraseña",
      background: "Fondo",
    },
    upload_detail_content: {
      add_files: "Añade archivos",
      back: "Volver",
      title_error_one: "error",
      title_error_other: "errores",
      title_errorWithCount_one: "{{count}} error",
      title_errorWithCount_other: "{{count}} errores",
      title_uploads_one: "archivo",
      title_uploads_other: "archivos",
      title_uploadsWithCount_one: "{{count}} archivo",
      title_uploadsWithCount_other: "{{count}} archivos",
    },
    pro_ad: {
      title: "Versión PRO",
      subtitle:
        "Recuerda que puedes suscribirte para tener más capacidad de almacenamiento y subidas ilimitadas",
    },
  },

  downloader: {
    title: "¡Listo para descargar!",
    subtitle: "Antes introduce tu contraseña",
    file_one: "archivo",
    file_other: "archivos",
    fileWithCount_one: "{{count}} archivo",
    fileWithCount_other: "{{count}} archivos",
    list: {
      file_one: "archivo",
      file_other: "archivos",
      fileWithCount_one: "{{count}} archivo",
      fileWithCount_other: "{{count}} archivos",
    },
  },

  preferences: {
    title: "Preferencias",
    description: "Página donde cambiar tus preferencias en tu cuenta de ydray",
    subtitle: {
      username: "Hola, {{username}}",
      billing: "Facturación",
      account: "Cuenta",
    },
    items: {
      plans: {
        title: "Planes",
        subtitle: "Eres {{subscription}}",
      },
      bills: {
        title: "Facturas",
        subtitle: "Estado, documentos",
      },
      personal: {
        title: "Datos personales",
        subtitle: "Nombre, usuario, dirección",
      },
      password: {
        title: "Contraseña",
        subtitle: "Editar contraseña",
      },
      customize: {
        title: "Personalizar",
        subtitle: "Foto, fondo, URL",
      },
      contact: {
        title: "Contacto",
        subtitle: "¿En qué podemos ayudar?",
      },
      logout: {
        title: "Cerrar Sesión",
      },
    },
  },

  plans: {
    title: "Planes",
    description:
      "Página donde cambiar tu plan contratado en tu cuenta de ydray",
    free: {
      title: "YDRAY free",
      content: `
      <ul class="list">
        <li class="list-item">
          <span class="icon-check"></span>
          <span class="small">Servicio Gratuito</span>
        </li>
        <li class="list-item">
          <span class="icon-check"></span>
          <span class="small">Enviar y compartir archivos grandes hasta 10 GB</span>
        </li>
        <li class="list-item">
          <span class="icon-check"></span>
          <span class="small">Hasta 50 archivos por transferencia</span>
        </li>
        <li class="list-item">
          <span class="icon-check"></span>
          <span class="small">20 destinatarios máximo</span>
        </li>
        <li class="list-item">
          <span class="icon-check"></span>
          <span class="small">Descargas de archivos limitadas</span>
        </li>
      </ul>`,
    },
    pro: {
      title: "YDRAY pro",
      content: `
      <ul class="list">
        <li class="list-item">
          <span class="icon-check"></span>
          <span class="small">Almacenamiento de archivos de 128 GB</span>
          </li>
        <li class="list-item">
          <span class="icon-check"></span>
          <span class="small">Enviar y compartir archivos de hasta 20 GB</span>
          </li>
        <li class="list-item">
          <span class="icon-check"></span>
          <span class="small">Archivos ilimitados por transferencia</span>
          </li>
        <li class="list-item">
          <span class="icon-check"></span>
          <span class="small">Destinatarios ilimitados para tus envios</span>
          </li>
        <li class="list-item">
          <span class="icon-check"></span>
          <span class="small">Los archivos enviados no expiran</span>
          </li>
        <li class="list-item">
          <span class="icon-check"></span>
          <span class="small">Descarga de archivos ilimatadas</span>
          </li>
      </ul>`,
    },
    business: {
      title: "YDRAY business",
      content: `
      <ul class="list">
        <li class="list-item">
          <span class="icon-check"></span>
          <span class="small">Servidor Exclusivo para su empresa</span>
          </li>
        <li class="list-item">
          <span class="icon-check"></span>
          <span class="small">Dominio para transferir archivos personalizable</span>
          </li>
        <li class="list-item">
          <span class="icon-check"></span>
          <span class="small">Imagen corporativa</span>
          </li>
        <li class="list-item">
          <span class="icon-check"></span>
          <span class="small">Enviar y compartir archivos grandes</span>
          </li>
        <li class="list-item">
          <span class="icon-check"></span>
          <span class="small">Control total de sus archivos</span>
          </li>
        <li class="list-item">
          <span class="icon-check"></span>
          <span class="small">Opciones de seguridad avanzadas</span>
          </li>
        <li class="list-item">
          <span class="icon-check"></span>
          <span class="small">Permita a sus clientes subir archivos</span>
          </li>
      </ul>`,
    },
  },

  bills: {
    title: "Facturas",
    description: "Resumen de las facturas del usuario",
    table: {
      columns: {
        date: "Fecha",
        plan: "Plan",
        amount: "Cantidad",
        status: "Estado",
        actions: "Acciones",
      },
    },
  },

  personal: {
    title: "Datos Personales",
    description:
      "Página donde cambiar tus datos personales en tu cuenta de ydray",
    form: {
      user_title: "Datos de Cuenta",
      name: "Nombre",
      surname: "Apellidos",
      username: "Alias",
      address_title: "Datos de facturación",
      billing_name: "Nombre de la Empresa",
      address: "Dirección",
      vat: "CIF/NIF",
      zip_code: "Código Postal",
      country: "País",
      city: "Ciudad",
      state: "Estado/Provincia",
      phone: "Teléfono",
    },
  },

  password: {
    title: "Contraseña",
    description: "Página donde cambiar tu contraseña en tu cuenta ydray",
    form: {
      oldpass: "Contraseña antigua",
      pass1: "Nueva contraseña",
      pass2: "Repetir nueva contraseña",
    },
  },

  custom: {
    title: "Personalizar",
    description: "Página donde personalizar tu cuenta ydray",
  },

  contact: {
    title: "Contacto",
    description: "Página de contacto con YDRAY",
    subtitle: "¿En qué podemos ayudar?",
    emailLb: "Email",
    email: "info@ydray.com",
    form: {
      name: "Nombre / Empresa",
      email: "Email",
      email2: "Repetir email",
      phone: "Número de teléfono",
      reason: "Motivo",
      reasonph: "Selecciona el motivo...",
      reasons: {
        1: "Información sobre lo planes de YDRAY",
        2: "Necesito ayuda para subir y transferir archivos",
        3: "Necesito ayuda para descargar archivos",
        4: "Necesito ayuda al usar YDRAY",
        5: "Otro",
      },
      message: "Escribe tu mensaje",
      consentLb: `He leído, entiendo y acepto la <a class="link" href="/es/politica-privacidad" title="política de privacidad">política de privacidad</a>.`,
    },
  },

  uploads: {
    title: "Subidas",
    description: "Resumen de subidas de archivos de tu cuenta ydray",
    header: {
      title: "Hola, {{username}}",
      used_storage: "{{value}} utilizado",
      total_storage: "{{value}} total",
      used_storage_ph: "{{value}}% de espacio utilizado",
      uploads: "transferencias",
      uploads_ph: "{{count}} transferencias",
      files: "archivos",
      files_ph: "{{count}} archivos",
    },
    body: {
      results: "{{count}} resultados",
    },
    table: {
      columns: {
        idTransfer: "ID",
        server: "Servidor",
        password: "Contraseña",
        title: "Título",
        message: "Comentario",
        dispatched: "Fecha Creación",
        newest: "Nuevas primero",
        oldest: "Antigüas primero",
        until: "Caducidad",
        num_files: "Archivos",
        size: "Tamaño",
        actions: "Acciones",
      },
      rows: {
        file_one: "archivo",
        file_other: "archivos",
        fileWithCount_one: "{{count}} archivo",
        fileWithCount_other: "{{count}} archivos",
      },
      actions: {
        copy: "Copiar",
        share: "Compartir",
        delete: "Borrar",
        expand: "Expandir",
        detail: "Detalle",
        download: "Descargar",
        menu: {
          copy: "Copiar",
          share: "Compartir",
          delete: "Borrar",
        },
      },
    },
    message: {
      delete_transfer:
        "Al borrar la transferencia, todos los archivos asociados se borrarán también, ¿desea continuar?",
      delete_file:
        "Se borrará el archivo asociado a la transferencia, ¿desea continuar?",
      delete_transfer_file:
        "Al borrar el único archivo asociado, la transferencia también se borrará, ¿desea continuar?",
    },
  },

  uploads_detail: {
    title: "Detalle Transferencia",
    description: "Detalle de una transferencia de archivos en ydray",
    header: {
      title: "Detalle Transferencia",
    },
    body: {
      transfer: {
        title: "Transferencia de {{size}}",
        menu: {
          edit: "Editar",
          undo: "Deshacer",
          save: "Guardar",
          delete: "Borrar",
          copy: "Copiar",
          share: "Compartir",
        },
        form: {
          title: "Título",
          titleph: "Título de la transferencia",
          message: "Mensaje",
          messageph: "Mensaje de la transferencia",
          size: "Tamaño",
          shareLink: "Link",
          shareLinkph: "Link de la transferencia",
          dispatched: "Fecha Creación",
          dispatchedph: "Fecha de creación de la transferencia",
          until: "Fecha Caducidad",
          untilph: "Fecha de caducidad de la transferencia",
        },
      },
      files: {
        title_one: "Archivo",
        title_other: "Archivos",
        titleWithCount_one: "{{count}} archivo",
        titleWithCount_other: "{{count}} archivos",
        table: {
          columns: {
            idFile: "Id Archivo",
            idTransfer: "Id Transferencia",
            fileName: "Nombre",
            fileSize: "Tamaño",
            countDownload: "Descargas",
            actions: "Acciones",
            deleted: "Borrado",
          },
          rows: {
            download_one: "descarga",
            download_other: "descargas",
            downloadWithCount_one: "{{count}} descarga",
            downloadWithCount_other: "{{count}} descargas",
          },
        },
      },
    },
  },

  landing: {
    title: "Enviar y compartir archivos grandes hasta 10 GB gratis",
    description: `
      YDRAY es la forma más rápida y fácil de enviar, transferir y compartir tus archivos grandes y pesados con la máxima seguridad y privacidad hasta 10 GB gratis
    `,
    hero: {
      title:
        "YDRAY permite compartir y enviar archivos de manera sencilla y privada",
      subtitle:
        "Con YDRAY puedes enviar y compartir archivos grandes de forma segura de hasta 10 GB gratis, sin necesidad de registro y con descargas ilimitadas",
      button: {
        files: {
          title: "Archivos",
          subtitle: "Empieza a Compartir",
        },
        privacy: {
          title: "Privacidad",
          subtitle: "Descubre el stándar",
        },
      },
      img: {
        title:
          "YDRAY permite compartir y enviar tus archivos grandes de manera sencilla, rápida y privada",
        alt: "YDRAY permite compartir y enviar tus archivos grandes de manera sencilla, rápida y privada",
      },
    },
    body: {
      about_section: {
        title: "Sobre nosotros",
        description:
          "Más de 25.000 personas confian en YDRAY para enviar y compartir sus archivos grandes a diario",
      },
      speech_section: {
        title:
          "Más de 25.000 personas confian en YDRAY para enviar y compartir sus archivos grandes a diario",
        msg1: `
        <span class="msg">
          "La velocidad para enviar y compartir archivos grandes en YDRAY es impresionante."
        </span>
        <span class="firm">Diego S.</span>
      `,
        msg2: `
        <span class="msg">
          "Me encanta YDRAY para compartir y enviar archivos grandes y pesados."
        </span>
        <span class="firm">Sara M.</span>
      `,
        msg3: `
        <span class="msg">
          "Me gusta que se pueda compartir los archivos sin necesidad de registro en YDRAY."
        </span>
        <span class="firm">Nadia B.</span>
      `,
        msg4: `
        <span class="msg">
          "Recomiendo YDRAY para enviar y compartir archivos grandes de forma rápida y segura."
        </span>
        <span class="firm">Jaime Q.</span>
      `,
        msg5: `
        <span class="msg">
          "He usado YDRAY para enviar y compartir archivos grandes y no he tenido problemas."
        </span>
        <span class="firm">José S.</span>
        `,
      },
      how_section: {
        subtitle: "cómo funciona YDRAY",
        title: "Fácil y sencillo",
        step1: {
          title: "Sube tus archivos",
          subtitle:
            "Acepte los terminos legales y suba sus archivos grandes a enviar y compartir.",
        },
        step2: {
          title: "Personaliza tu envío",
          subtitle: "Nombre, descripción, contraseña y mucho más.",
        },
        step3: {
          title: "Comparte tu enlace",
          subtitle: "Con quien quieras y como quieras.",
        },
        help: {
          title: "¿Tienes alguna duda?",
          subtitle: "¡Contacta con nosotros y te ayudaremos!",
        },
      },
      plans_section: {
        subtitle: "Planes de YDRAY",
        title: "Un plan para ti",
        description: `
          <p>
            YDRAY ofrece diferentes planes de pago que te permiten
            disfrutar de funciones adicionales al enviar y compartir tus
            archivos grandes. Con el plan YDRAY Pro, puedes enviar archivos
            grandes de hasta 20 GB. Además, obtienes un mayor control sobre
            tus enlaces de descarga y la posibilidad de establecer fechas
            de descarga.
          </p>
          <p>
            Si necesitas aún más capacidad y funciones avanzadas,
            puedes optar por el plan YDRAY Business, que te permite
            enviar y compartir archivos grandes sin limite de tamaño y
            ofrece opciones adicionales de almacenamiento y colaboración.
            Con estos planes de pago, YDRAY garantiza una experiencia
            para enviar y compartir archivos grandes más completa y versátil.
          </p>
          <p class="coming-soon">
            Los siguientes planes estarán disponibles próximamente. Mientras tanto,
            te recordamos que puede utilizar YDRAY para enviar y transferir archivos
            sin necesidad de crear ninguna cuenta, simplemente utilizando la consola
            <span class="left-side">situada en la parte izquierda de la pantalla </span>
            para subir, compartir el enlace de descarga y descargar archivos.
          </p>
        `,
      },
      why_section: {
        subtitle: "¿Por qué YDRAY?",
        title: "Enviar archivos grandes más facil que nunca",
        description: `
        <p>
          YDRAY ofrece una alternativa sólida a otras plataformas de transferencia de archivos al brindar una experiencia
          excepcional al enviar, transferir y compartir archivos grandes de forma rápida segura y con la máxima privacidad.
        </p>
        `,
      },
      secure_section: {
        subtitle: "¿Es seguro YDRAY?",
        title: "Compartir archivos de forma segura",
        description: `
        <p>
          Con YDRAY, puedes disfrutar de una plataforma fácil de usar y segura que te permite enviar archivos grandes.
          YDRAY prioriza la seguridad y la privacidad, ofreciendo cifrado de extremo a extremo para todas las
          transferencias de archivos. Esto significa que sus archivos están protegidos contra accesos no autorizados e
          interceptación durante la transmisión.
        </p>
        `,
      },
      privacy_section: {
        subtitle: "¿Cómo garantiza YDRAY la máxima privacidad?",
        title: "Envía y comparte archivos grandes con la máxima privacidad",
        description: `
          <p>
            YDRAY garantiza máxima privacidad a la hora de enviar, compartir y transferir archivos grandes aplicando las políticas
            más estrictas en materia de protección de datos como la Ley General de Protección de Datos europea (RGPD)
            Reglamento (UE) 2016/679.
          </p>
          <p>
            YDRAY tiene una política de privacidad clara y transparente que describe cómo se recopilan,
            utilizan y protegen los datos de los usuarios. Esta política cumple con las leyes y regulaciones de protección de datos
            aplicables y demuestra el compromiso de YDRAY con la privacidad de sus usuarios a la hora de enviar y compartir sus archivos grandes.
          </p>
        `,
      },
      tips_section: {
        subtitle: "Consejos para protegerte",
        title: "Consejos para proteger tu privacidad en Internet",
        tip1: {
          icon: "system",
          title: "Actualiza el sistema operativo",
          description: `
            <p>
              Tener actualizado el sistema operativo de su ordenador o dispositivo móvil le protegerá contra multitud de
              ciberamenazas.
            </p>
          `,
        },
        tip2: {
          icon: "password",
          title: "Utiliza contraseñas seguras",
          description: `
            <p>
              Usa contraseñas robustas de al menos 10 caracteres y que combinen letras, números y símbolos.
            </p>
          `,
        },
        tip3: {
          icon: "auth",
          title: "Usa autenticación 2FA",
          description: `
            <p>
              La mejor forma de proteger tu cuenta es activando un doble autenticación, a través de un código o el uso
              de aplicaciones de generación de códigos.
            </p>
          `,
        },
        tip4: {
          icon: "email",
          title: "Vigila los emails desconocidos",
          description: `
            <p>
              Mucho cuidado con los emails de fuentes desconocidas, evitar los enlaces o descargar archivos adjuntos.
            </p>
          `,
        },
        tip5: {
          icon: "wifi",
          title: "Cuidado con las WiFi públicas",
          description: `
            <p>
              Cuando utilice redes WIFI públicas evite realizar compras y/o acceder a servicios que requieran
              introducir una contraseña.
            </p>
          `,
        },
        tip6: {
          icon: "social",
          title: "Limita las redes sociales",
          description: `
            <p>
              ¡No publiques más información de la necesaria! Revisa quien puede acceder a tu información y
              publicaciones.
            </p>
          `,
        },
        tip7: {
          icon: "store",
          title: "Aplicaciones solo desde sitios oficiales",
          description: `
            <p>
              Instala las aplicaciones de los sitios oficiales de confianza (web desarrollador oficial, Play Store o Apple Store).
            </p>
          `,
        },
        tip8: {
          icon: "antivirus",
          title: "Usa un Antivirus",
          description: `
            <p>
              Procura que todos tus dispositivos cuenten con un software de seguridad de muy buena calidad protegiéndolos en todo momento.
            </p>
          `,
        },
        tip9: {
          icon: "cloud",
          title: "Realiza copias de seguridad",
          description: `
            <p>
              Lo mejor es siempre tener una copia de seguridad de la información de tu móvil y tu ordenador en la nube.
            </p>
          `,
        },
      },
    },
    footer: {
      press: "prensa",
      contact: "contacto",
      newsletter: "newsletter",
      faq: "Preguntas Frecuentes",
      privacy_policy: "política privacidad",
      cookie_policy: "política cookies",
      legal_warn: "aviso legal",
      consent_cookies: "Configuración de Cookies",
      send_files: "Enviar archivos",
      share_files: "Compartir archivos",
    },
  },

  uploader_page: {
    title: "Enviar y compartir archivos grandes hasta 10 GB gratis",
    description: `
      YDRAY es la forma más rápida y fácil de enviar, transferir y compartir tus archivos grandes y pesados con la máxima seguridad y privacidad hasta 10 GB gratis
    `,
  },

  press: {
    title: "Prensa",
    description:
      "Página donde encontrar los enlaces a las noticias donde encontrar a ydray",
    content: `
    <h1>YDRAY en la prensa internacional</h1>
    <h2>Noticias</h2>
    <h3>2023</h3>
    <div class="news-item">
      <h4>
        <a href="https://www.tvn-2.com/casi-las-cinco/video-ydray-aplicacion-permite-descargar-10g_8_2058955.html"
          class="news-item-link link"
          target="_blank"
          rel="noopener noreferrer"
          title="YDRAY es una aplicación que te permite descargar hasta 10G"
        >
          YDRAY es una aplicación que te permite descargar hasta 10G - (14/06/2023)
        </a>
      </h4>
      <p class="news-item-desc" title="Vídeo | YDRAY es una aplicación que te permite descargar hasta 10G - Casi Las Cinco | TVN Panamá">
        Vídeo | YDRAY es una aplicación que te permite descargar hasta 10G - Casi Las Cinco | TVN Panamá.
      </p>
    </div>
    <div class="news-item">
      <h4>
        <a href="https://www.adslzone.net/como-se-hace/gmail/enviar-adjuntos-superar-limite/"
          class="news-item-link link"
          target="_blank"
          rel="noopener noreferrer"
          title="Cómo enviar archivos adjuntos de más de 25 MB en Gmail"
        >
          Cómo enviar archivos adjuntos de más de 25 MB en Gmail - (13/03/2023)
        </a>
      </h4>
      <p class="news-item-desc" title="Truco para enviar archivos adjuntos de más de 50 MB en mensajes de correo electrónico de Gmail usando un servicio paralelo.">
      Truco para enviar archivos adjuntos de más de 50 MB en mensajes de correo electrónico de Gmail usando un servicio paralelo.
      </p>
    </div>
    <div class="news-item">
      <h4>
        <a href="https://www.adslzone.net/listas/mejores-webs/alternativas-wetransfer/"
          class="news-item-link link"
          target="_blank"
          rel="noopener noreferrer"
          title="Envía archivos grandes gratis con estas alternativas a WeTransfer"
        >
          Envía archivos grandes gratis con estas alternativas a WeTransfer - (07/03/2023)
        </a>
      </h4>
      <p class="news-item-desc" title="Las mejores alternativas a WeTransfer para enviar archivos grandes gratis por correo electrónico o por enlace y sin registro.">
      Las mejores alternativas a WeTransfer para enviar archivos grandes gratis por correo electrónico o por enlace y sin registro.
      </p>
    </div>
    <h3>2022</h3>
    <div class="news-item">
      <h4>
        <a href="https://www.semana.com/tecnologia/articulo/google-drive-asi-puede-liberar-espacio-en-su-cuenta/202231/"
          class="news-item-link link"
          target="_blank"
          rel="noopener noreferrer"
          title="Google Drive: así puede liberar espacio en su cuenta"
        >
          Google Drive: así puede liberar espacio en su cuenta - (01/11/2022)
        </a>
      </h4>
      <p class="news-item-desc" title="Google Drive es utilizado para guardar archivos multimedia como fotos, videos y audios, entre otros documentos. ">
      Google Drive es utilizado para guardar archivos multimedia como fotos, videos y audios, entre otros documentos. 
      </p>
    </div>
    <div class="news-item">
      <h4>
        <a href="https://www.adslzone.net/noticias/internet/mejores-webs-enviar-archivos-grandes-sin-limitacion-tamano/"
          class="news-item-link link"
          target="_blank"
          rel="noopener noreferrer"
          title="El tamaño no importa: las mejores webs para enviar archivos grandes por Internet"
        >
          El tamaño no importa: las mejores webs para enviar archivos grandes por Internet (26/09/2022)
        </a>
      </h4>
      <p class="news-item-desc" title="Enviar archivos por Internet es una tarea de lo más sencilla y con multitud de posibilidades. Sin embargo, a veces hay limitaciones por tamaño.">
      Enviar archivos por Internet es una tarea de lo más sencilla y con multitud de posibilidades. Sin embargo, a veces hay limitaciones por tamaño.
      </p>
    </div>
    <div class="news-item">
      <h4>
        <a href="https://www.elsuplemento.es/premiado/ydray-premio-nacional-de-tecnologia-2022-en-la-categoria-soluciones-tecnologicas"
          class="news-item-link link"
          target="_blank"
          rel="noopener noreferrer"
          title="YDRAY, Premio Nacional de Tecnología 2022 en la categoría Soluciones Tecnológicas | El Suplemento"
        >
          YDRAY, Premio Nacional de Tecnología 2022 en la categoría Soluciones Tecnológicas - (25/05/2022)
        </a>
      </h4>
      <p class="news-item-desc" title="YDRAY es la plataforma de gestión y envío de archivos online de referencia en España y América">
      YDRAY es la plataforma de gestión y envío de archivos online de referencia en España y América
      </p>
    </div>
    <div class="news-item">
      <h4>
        <a href="https://computerhoy.com/tutoriales/tecnologia/como-mandar-emails-adjuntos-25-mb-gmail-970223"
          class="news-item-link link"
          target="_blank"
          rel="noopener noreferrer"
          title="Cómo mandar emails adjuntos de más de 25 MB en Gmail"
        >
          Cómo mandar emails adjuntos de más de 25 MB en Gmail - (27/01/2022)
        </a>
      </h4>
      <p class="news-item-desc" title="Te contamos como puedes mandar un archivo adjunto de más de 35 GB en un correo electrónico de Gmail. Es mucho más sencillo de lo puedes imaginar.">
      Te contamos como puedes mandar un archivo adjunto de más de 35 GB en un correo electrónico de Gmail. Es mucho más sencillo de lo puedes imaginar.
      </p>
    </div>
    <div class="news-item">
      <h4 class="news-item-title">
        <a href="https://www.emprendedores.es/ayudas/estudio-de-ydray-com-sobre-uso-de-la-nube/"
          class="news-item-link link"
          target="_blank"
          title="Ydray.com estudio sobre el uso de la nube en 2021"
          rel="noopener noreferrer">
            Ydray.com estudio sobre el uso de la nube en 2021 - (02/12/2021)
        </a>
      </h4>
      <p
      class="news-item-desc"
      title="Ydray.com, la empresa de transferencia de archivos, presenta los resultados de su estudio sobre hábitos y usos de la nube. Los usuarios de este tipo de plataformas la emplean para enviar archivos de entre 100 y 500 megas, tanto para fines profesionales como particulares."
      >
      Ydray.com, la empresa de transferencia de archivos, presenta los resultados de su estudio sobre hábitos y usos de la nube.
      Los usuarios de este tipo de plataformas la emplean para enviar archivos de entre 100 y 500 megas, tanto para fines profesionales como particulares.
      </p>
    </div>
    <div class="news-item">
      <h4>
        <a href="https://vistarmagazine.com/como-enviar-archivos-pesados-desde-cuba-sin-usar-wetransfer/"
          class="news-item-link link"
          target="_blank"
          rel="noopener noreferrer"
          title="¿Cómo enviar archivos pesados desde Cuba sin usar WeTransfer?"
        >
          ¿Cómo enviar archivos pesados desde Cuba sin usar WeTransfer? (09/07/2021)
        </a>
      </h4>
      <p class="news-item-desc" title="Si necesitas enviar archivos pesados desde Cuba hay más de una manera de hacerlo. Descubre aquí otras opciones además de la popular WeTransfer">
      Si necesitas enviar archivos pesados desde Cuba hay más de una manera de hacerlo. Descubre aquí otras opciones además de la popular WeTransfer
      </p>
    </div>
    <div class="news-item">
      <h4>
        <a href="https://miracomohacerlo.com/enviar-videos-pesados-imgenes-audios-archivos-whatsapp/"
          class="news-item-link link"
          target="_blank"
          rel="noopener noreferrer"
          title="Cómo Enviar Vídeos Pesados, Imágenes, Audios y Archivos de más de 100 MB por WhatsApp | Mira Cómo Hacerlo"
        >
          Cómo Enviar Vídeos Pesados, Imágenes, Audios y Archivos de más de 100 MB por WhatsApp - (31/03/2021)
        </a>
      </h4>
      <p class="news-item-desc" title="¡No es imposible! Hay distintas formas de compartir archivos, fotos y videos de más de 100 MB por WhatsApp y aquí te contamos cómo ?">
      ¡No es imposible! Hay distintas formas de compartir archivos, fotos y videos de más de 100 MB por WhatsApp y aquí te contamos cómo ?
      </p>
    </div>
    <div class="news-item">
      <h4>
        <a href="https://miracomohacerlo.com/que-es-wetransfer-y-como-funciona/"
          class="news-item-link link"
          target="_blank"
          rel="noopener noreferrer"
          title="WeTransfer: ¿Qué es y como funciona? Aprende cómo Enviar y Recibir Archivos Pesados | Mira Cómo Hacerlo"
        >
          WeTransfer: ¿Qué es y como funciona? Aprende cómo Enviar y Recibir Archivos Pesados - (28/02/2021)
        </a>
      </h4>
      <p class="news-item-desc" title="¿Cuáles son los pasos para poder enviar archivos pesados a través de WeTransfer ? Conoce: ¿Qué es y como funciona?">
      ¿Cuáles son los pasos para poder enviar archivos pesados a través de WeTransfer ? Conoce: ¿Qué es y como funciona?
      </p>
    </div>
    <h3>2020</h3>
    <div class="news-item">
      <h4>
        <a href="https://www.tekcrispy.com/2020/11/11/ydray-transferir-archivos/"
          class="news-item-link link"
          target="_blank"
          rel="noopener noreferrer"
          title="YDRAY, un servicio para transferir archivos de hasta 10GB gratis"
        >
          YDRAY, un servicio para transferir archivos de hasta 10GB gratis - (11/11/2020)
        </a>
      </h4>
      <p class="news-item-desc" title="YDRAY es un sitio web en donde podrás transferir archivos de hasta 10GB de forma segura y completamente gratuita.">
      YDRAY es un sitio web en donde podrás transferir archivos de hasta 10GB de forma segura y completamente gratuita.
      </p>
    </div>
    <div class="news-item">
      <h4>
        <a href="https://intereconomia.com/noticia/ydray-amplia-su-servicio-gratuito-de-envio-de-archivos-online-hasta-los-10-gb-20201026-1300/"
          class="news-item-link link"
          target="_blank"
          rel="noopener noreferrer"
          title="YDRAY amplía su servicio gratuito de envío de archivos online hasta los 10 Gb"
        >
          YDRAY amplía su servicio gratuito de envío de archivos online hasta los 10 Gb - (20/10/2020)
        </a>
      </h4>
      <p class="news-item-desc" title="El nuevo contexto generado por la actual crisis del Covid 19 ha cambiado la vida y los hábitos de todo el mundo, hecho ante el que YDRAY no ha podido permanecer impasible, lo que le ha llevado a aplicar una serie de medidas en su versión gratuita de la plataforma Ydray.com">
      El nuevo contexto generado por la actual crisis del Covid 19 ha cambiado la vida y los hábitos de todo el mundo, hecho ante el que YDRAY no ha podido permanecer impasible, lo que le ha llevado a aplicar una serie de medidas en su versión gratuita de la plataforma Ydray.com
      </p>
    </div>
    <div class="news-item">
      <h4>
        <a href="https://www.xataka.com/servicios/21-servicios-gratis-para-enviar-archivos-grandes#:~:text=Ydray,a%20un%20m%C3%A1ximo%20de%2020."
          class="news-item-link link"
          target="_blank"
          rel="noopener noreferrer"
          title="21 servicios gratis para enviar archivos grandes"
        >
          21 servicios gratis para enviar archivos grandes - (29/09/2020)
        </a>
      </h4>
      <p class="news-item-desc" title="Tener que compartir grandes cantidades de información es una necesidad bastante habitual en algunos trabajos y también en el ámbito del ocio. Una sesión de...">
      Tener que compartir grandes cantidades de información es una necesidad bastante habitual en algunos trabajos y también en el ámbito del ocio. Una sesión de...
      </p>
    </div>
    <div class="news-item">
      <h4>
        <a href="https://www.europapress.es/portaltic/internet/noticia-servicios-gratuitos-permiten-enviar-archivos-pesados-20200927130036.html"
          class="news-item-link link"
          target="_blank"
          rel="noopener noreferrer"
          title="Servicios gratuitos que permiten enviar archivos pesados"
        >
          Servicios gratuitos que permiten enviar archivos pesados - (27/09/2020)
        </a>
      </h4>
      <p class="news-item-desc" title="Hoy en día son muchas las personas que necesitan compartir archivos de gran peso de forma...">
      Hoy en día son muchas las personas que necesitan compartir archivos de gran peso de forma...
      </p>
    </div>
    <div class="news-item">
      <h4>
        <a href="https://geekinsider.com/ydray-an-emerging-yet-robust-file-sharing-solution/"
          class="news-item-link link"
          target="_blank"
          rel="noopener noreferrer"
          title="YDRAY: An Emerging Yet Robust File Sharing Solution"
        >
          YDRAY: An Emerging Yet Robust File Sharing Solution - (02/10/2020)
        </a>
      </h4>
      <p class="news-item-desc" title="Based out of Europe, YDRAY is a file transfer service that allows you to share large files of up to 10 GB each. The easy-to-use solution allows you to directly share these files with designated">
      Based out of Europe, YDRAY is a file transfer service that allows you to share large files of up to 10 GB each. The easy-to-use solution allows you to directly share these files with designated
      </p>
    </div>
    <div class="news-item">
      <h4>
        <a href="https://roastbrief.com.mx/2020/10/ydray-com-amplia-su-servicio-gratuito-con-motivo-de-la-situacion-excepcional/"
          class="news-item-link link"
          target="_blank"
          rel="noopener noreferrer"
          title="Ydray.com amplía su servicio gratuito con motivo de la situación excepcional"
        >
          Ydray.com amplía su servicio gratuito con motivo de la situación excepcional - (01/10/2020)
        </a>
      </h4>
      <p class="news-item-desc" title="YDRAY es una plataforma para el envío gratuito online de archivos de hasta 5 Gb (actualmente 10Gb) de forma sencilla">
      YDRAY es una plataforma para el envío gratuito online de archivos de hasta 5 Gb (actualmente 10Gb) de forma sencilla
      </p>
    </div>
    <div class="news-item">
      <h4>
        <a href="https://www.eluniversal.com.mx/techbit/cinco-herramientas-para-enviar-archivos-pesados/"
          class="news-item-link link"
          target="_blank"
          rel="noopener noreferrer"
          title="Cinco herramientas para enviar archivos pesados"
        >
          Cinco herramientas para enviar archivos pesados - (03/05/2020)
        </a>
      </h4>
      <p class="news-item-desc" title="Con estos servicios podrás enviar archivos grandes sin preocuparte de restricciones de tamaño">
      Con estos servicios podrás enviar archivos grandes sin preocuparte de restricciones de tamaño
      </p>
    </div>
    <div class="news-item">
      <h4>
        <a href="https://www.dineroenimagen.com/management/lista-de-apps-y-sitios-premium-que-ahora-son-gratis/121197"
          class="news-item-link link"
          target="_blank"
          rel="noopener noreferrer"
          title="Lista de apps y sitios premium que ahora son gratis"
        >
          Lista de apps y sitios premium que ahora son gratis - (02/04/2020)
        </a>
      </h4>
      <p class="news-item-desc" title="Muchas, en realidad, muchas compañías han estado apoyando la situación actual poniendo sus servicios de forma gratuita para todas las pers">
      Muchas, en realidad, muchas compañías han estado apoyando la situación actual poniendo sus servicios de forma gratuita para todas las pers
      </p>
    </div>
    <div class="news-item">
      <h4>
        <a href="https://www.emprendedores.es/ayudas/ayuda-crear-empresa-negocio/recursos-gratuitos-emprendedores/"
          class="news-item-link link"
          target="_blank"
          rel="noopener noreferrer"
          title="Recursos gratuitos a disposición de los emprendedores en tiempos de crisis - Emprendedores"
        >
          Recursos gratuitos a disposición de los emprendedores en tiempos de crisis - Emprendedores - (19/03/2020)
        </a>
      </h4>
      <p class="news-item-desc" title="La mayoría de estos recursos han sido dispuestos de forma gratuita por otros emprendedores para ayudar a sus colegas en distintas áreas de negocio ante la situación actual. Estos son algunos de los que se ofrecen.">
      La mayoría de estos recursos han sido dispuestos de forma gratuita por otros emprendedores para ayudar
      a sus colegas en distintas áreas de negocio ante la situación actual. Estos son algunos de los que se ofrecen.
      </p>
    </div>
    <h3>2019</h3>
    <div class="news-item">
      <h4>
        <a href="https://www.businessinsider.es/7-plataformas-servicios-compartir-gratis-archivos-5gb-501917"
          class="news-item-link link"
          target="_blank"
          rel="noopener noreferrer"
          title="Estas 7 plataformas y servicios te permitirán compartir archivos de más de 5GB online y gratis"
        >
          Estas 7 plataformas y servicios te permitirán compartir archivos de más de 5GB online y gratis - (26/10/2019)
        </a>
      </h4>
      <p class="news-item-desc" title="Enviar archivos de gran tamaño a otras personas es posible gracias a distintas plataformas gratuitas, te contamos cuáles son algunas de las más fiables y cómodas.">
      Enviar archivos de gran tamaño a otras personas es posible gracias a distintas plataformas gratuitas, te contamos cuáles son algunas de las más fiables y cómodas.
      </p>
    </div>
    <div class="news-item">
      <h4>
        <a href="https://www.businessinsider.es/7-plataformas-servicios-compartir-gratis-archivos-5gb-501917"
          class="news-item-link link"
          target="_blank"
          rel="noopener noreferrer"
          title="7 plataformas y servicios para compartir gratis archivos de más de 5GB"
        >
          7 plataformas y servicios para compartir gratis archivos de más de 5GB - (26-10-2019)
        </a>
      </h4>
      <p class="news-item-desc" title="Enviar archivos de gran tamaño a otras personas es posible gracias a distintas plataformas gratuitas, te contamos cuáles son algunas de las más fiables y cómodas.">
      Enviar archivos de gran tamaño a otras personas es posible gracias a distintas plataformas gratuitas, te contamos cuáles son algunas de las más fiables y cómodas.
      </p>
    </div>
    <div class="news-item">
      <h4>
        <a href="https://www.tusequipos.com/2019/07/06/10-recursos-gratuitos-para-compartir-archivos-grandes/"
          class="news-item-link link"
          target="_blank"
          rel="noopener noreferrer"
          title="10 recursos gratuitos para compartir archivos grandes"
        >
          10 recursos gratuitos para compartir archivos grandes - (06/07/2019)
        </a>
      </h4>
      <p class="news-item-desc" title="¿Estas buscando opciones para compartir archivos grandes con tus contactos?">
      ¿Estas buscando opciones para compartir archivos grandes con tus contactos?
      </p>
    </div>
    <div class="news-item">
      <h4>
        <a href="https://www.interempresas.net/TIC/Articulos/245230-Guardar-archivos-en-la-nube-Que-tener-en-cuenta.html"
          class="news-item-link link"
          target="_blank"
          rel="noopener noreferrer"
          title="¿Qué tener en cuenta para guardar archivos en la nube?"
        >
          ¿Qué tener en cuenta para guardar archivos en la nube? - (09/05/2019)
        </a>
      </h4>
      <p class="news-item-desc" title="El almacenamiento de archivos ha evolucionado espectacularmente en los últimos 30/40 años. Empezamos por guardar nuestros datos y archivos informáticos en voluminosos equipos de hardware. Después, se pasó a los discos duros portátiles">
      El almacenamiento de archivos ha evolucionado espectacularmente en los últimos 30/40 años. Empezamos por guardar nuestros datos
      y archivos informáticos en voluminosos equipos de hardware. Después, se pasó a los discos duros portátiles
      </p>
    </div>
    <div class="news-item">
      <h4>
        <a href="https://www.muycomputer.com/2019/04/08/como-enviar-archivos-pesados-gratis/"
          class="news-item-link link"
          target="_blank"
          rel="noopener noreferrer"
          title="9 herramientas para enviar archivos pesados gratis por Internet"
        >
          9 herramientas para enviar archivos pesados gratis por Internet - (08/04/2019)
        </a>
      </h4>
      <p class="news-item-desc" title="Aunque el correo electrónico o la mensajería instantánea son fundamentales en nuestro día a día, también tienen limitaciones. Una de las más importantes es el tamaño máximo de los archivos que podemos enviar adjuntos y, para ayudaros a solventarla, en este práctico recopilamos nuestras nueve soluciones favoritas para solucionar este problema.">  
      Aunque el correo electrónico o la mensajería instantánea son fundamentales en nuestro día a día, también tienen limitaciones.
      Una de las más importantes es el tamaño máximo de los archivos que podemos enviar adjuntos y, para ayudaros a solventarla,
      en este práctico recopilamos nuestras nueve soluciones favoritas para solucionar este problema.
      </p>
    </div>
    <div class="news-item">
      <h4>
        <a href="https://applicantes.com/ydray-renueva-competir-dropbox-wetransfer/"
          class="news-item-link link"
          target="_blank"
          rel="noopener noreferrer"
          title="YDRAY se renueva para competir con Dropbox y Wetransfer"
        >
          YDRAY se renueva para competir con Dropbox y Wetransfer - (04/01/2019)
        </a>
      </h4>
      <p class="news-item-desc" title="YDRAY, el servicio para enviar archivos online de gran tamaño de origen español, ha iniciado 2019 con una renovación de su plataforma. Ydray.com ha añadido tres grandes novedades. En primer lugar, sus usuarios de la versión gratuita ya tienen la posibilidad de proteger los archivos con una c">
      YDRAY, el servicio para enviar archivos online de gran tamaño de origen español, ha iniciado 2019 con una renovación de su plataforma. Ydray.com ha añadido tres grandes novedades. En primer lugar, sus usuarios de la versión gratuita ya tienen la posibilidad de proteger los archivos con una c
      </p>
    </div>
    <h3>2018</h3>
    <div class="news-item">
      <h4>
        <a href="https://tools.robingood.com/24188/send-and-share-files-as-large-as-5gb-without-using-email-ydray"
          class="news-item-link link"
          target="_blank"
          rel="noopener noreferrer"
          title="Send and share files as large as 5GB without using email: YDRAY"
        >
          Send and share files as large as 5GB without using email: YDRAY - (15/01/2018)
        </a>
      </h4>
      <p class="news-item-desc" title="Free web app that allows to send files as large as 5GB to anyone without registration or login. Free version allows to distribute files to up to 20 recipients and to include as many as 50 files in one-go. Files sent are stored and made available for 7 days">
      Free web app that allows to send files as large as 5GB to anyone without registration or login. Free version allows to distribute files to up to 20 recipients and to include as many as 50 files in one-go. Files sent are stored and made available for 7 days
      </p>
    </div>
    `,
  },

  faq: {
    title: "Preguntas Frecuentes",
    description: "Página de preguntas frecuentes de YDRAY",
    content: `
    <h1>Preguntas Frecuentes</h1>
    <h2>¿Cómo se puede enviar un archivo utilizando Ydray.com?</h2>
    <p>Para enviar un archivo usando Ydray.com, simplemente visite la página web y haga clic en el botón <b>Agregar archivos</b>.</p>
    <p>Luego, seleccione o arrastre y suelte el archivo(s) que desea enviar desde su ordenador.</p>
    <p>Puede agregar un mensaje o direcciones de correo electrónico de destinatarios y luego hacer clic en el botón <b>Enviar</b> para comenzar la carga.</p>
    <p>Si se registra en Ydray.com, también puede elegir cuándo se eliminará el enlace y configurar una contraseña.</p>
    <h2>¿Cómo recibo un archivo utilizando Ydray.com?</h2>
    <p>Cuando alguien le envía un archivo utilizando Ydray.com, recibirá una notificación por correo electrónico con un enlace de descarga.</p>
    <p>Simplemente haga clic en el enlace para descargar el archivo.</p>
    <h2>¿Existe un límite en el tamaño de archivo que puedo enviar con Ydray.com?</h2>
    <p>Sí, Ydray.com tiene un límite de 10 GB por transferencia.</p>
    <p>Si necesita enviar archivos más grandes, puede utilizar su servicio de pago, YDRAY Pro.</p>
    <h2>¿Es seguro utilizar Ydray.com para la transferencia de archivos?</h2>
    <p>Sí, Ydray.com es una plataforma segura que utiliza cifrado de extremo a extremo para todas las transferencias de archivos.</p>
    <p>Esto significa que sus archivos están protegidos contra el acceso y la interceptación no autorizados durante la transmisión.</p>
    <h2>¿Por qué debería usar Ydray.com en lugar de otras plataformas de transferencia de archivos?</h2>
    <p>Hay algunas diferencias clave que pueden hacer que Ydray.com sea una mejor opción que otros servicios de envio y transferencia de archivos":</p>
    <h3>Seguridad</h3>
    <p>Ydray.com prioriza la seguridad y la privacidad, ofreciendo cifrado de extremo a extremo para todas las transferencias de archivos. Esto significa que sus archivos están protegidos contra accesos no autorizados e interceptación durante la transmisión.</p>
    <h3>Tamaño de archivo ilimitado</h3>
    <p>Con Ydray.com, puede enviar archivos de hasta 10 GB por transferencia, mientras que otras plataformas de transferencia y envio de archivos tienen un límite de 2 GB por transferencia.</p>
    <h3>Sin anuncios ni distracciones</h3>
    <p>Ydray.com no muestra anuncios ni otras distracciones durante el proceso de transferencia, mientras que otros muestran anuncios y mensajes promocionales.</li>
    <h3>Cargas más rápidas</h3>
    <p>Ydray.com utiliza una infraestructura de carga altamente optimizada y escalable, lo que permite una carga de archivos más rápida en comparación con otros proveedores de servicios.</p>
    <p>En general, Ydray.com ofrece una experiencia de transferencia de archivos más segura, personalizable y profesional en comparación con otras plataformas de transferencia de archivos.</p>
    `,
  },

  privacy: {
    title: "Política de Privacidad",
    description: "Explicación de la política de privacidad de YDRAY",
    content: `
    <h1>Política de Privacidad</h1>
    <p>De conformidad con lo dispuesto en el Reglamento (UE) 2016/679, de 27 de abril, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos (en adelante, RGPD).</p>
    <h2>¿Quién es el responsable del tratamiento de sus datos?</h2>
    <div class="table-container">
      <table>
        <tr>
          <td>Nombre</td>
          <td>RECICLAONLINE S.L.</td>
        </tr>
        <tr>
          <td>CIF</td>
          <td>B13538053</td>
        </tr>
        <tr>
          <td>Dirección</td>
          <td>Tr.ª Manzana 10, 13300, Valdepeñas, C. Real</td>
        </tr>
        <tr>
          <td>Email</td>
          <td><a class="link" href="mailto:privacidad@ydray.com" target="_blank" title="Enviar email a privacidad@ydray.com">privacidad@ydray.com</a></td>
        </tr>
      </table>
    </div>
    <p>En primer lugar queremos agradecerte que uses nuestro servicio. El hecho de que estés leyendo ahora mismo este punto indica que la privacidad es tan importante para ti como lo es para nosotros, ENHORABUENA!!!</p>
    <h2>¿En qué nos diferenciamos de otros servicios?</h2>
    <p>Uno de nuestros principales valores añadidos es la dura legislación que tenemos que cumplir al ser una empresa Española.</p>
    <p>Es fácil comprobar cómo grandes proveedores de “otros” servicios se remiten a leyes o tratados extranjeros, (principalmente leyes de California) y no nos informan de la ubicación física de sus servidores ni de las medidas de privacidad adoptadas. Esto tiene una repercusión muy importante sobre la privacidad de Tus Archivo, ya que normalmente, de modo inconsciente tendemos a pensar que al usar esos servicios Tus Archivos seguirán siendo Tus Archivos, cosa la cual, no siempre se cumple. En nuestro caso Tus Archivos SI seguirán siendo Tus Archivos y nunca nos cederás derechos para que sean Nuestros Archivos.</p>
    <h2>Pero, ¿Cómo es posible esto?</h2>
    <p>Te pedimos que leas detenidamente el siguiente párrafo, pues es muy aclaratorio respecto a lo que concierne a Privacidad.</p>
    <p>Es bastante común que con otros proveedores de servicios regidos bajo las leyes de California se cedan los derechos para poder divulgar, copiar, revelar datos almacenados, incluso poder modificar la propiedad y contenido del mismo.</p>
    <p>Sin embargo, nuestras condiciones de uso del servicio exponen que:</p>
    <p>YDRAY no tiene derecho alguno sobre marcas, nombres comerciales, signos distintivos, diseños y/o datos almacenados en sus servidores. Asimismo, no divulgará, copiará ni revelará ningún dato almacenado, por lo que nunca accederá a ellos a no ser que exista causa justificada por problemas derivados de procedimientos judiciales o por ser solicitados por las fuerzas y cuerpos de seguridad del estado. Por ello, YDRAY mantiene un registro de accesos de las direcciones IP de los usuarios.</p>
    <h2>¿Con qué finalidad tratamos sus datos?</h2>
    <p>Sus datos personales sólo se utilizarán para las siguientes finalidades: registro, gestión y servicio de envío de archivos de gran capacidad a través de nuestra web a terceros. En el caso de que sea cliente gestión contable, fiscal y administrativa. También, si procede a su inscripción, para el envío de newslwtter relacionada con nuestros servicios, novedades y comunicaciones.</p>
    <h2>¿Cuál es la legitimación para el tratamiento de sus datos personales?</h2>
    <p>Consentimiento expreso del usuario a través de la selección de la casilla check del registro donde verifica su consentimiento en el caso de envío de archivos y newsletter. Medidas precontractuales en el caso de solicitudes de presupuesto u otras informaciones en relación a nuestros servicios. Si es cliente para la ejecución de un contrato como cliente. El cumplimiento de obligaciones legales aplicables a la actividad, derivadas de la legislación mercantil y tributaria.</p>
    <h2>¿Por cuánto tiempo conservamos sus datos personales?</h2>
    <p>Los datos serán conservados una semana desde que usted realice el envío. Si es cliente, los datos personales que nos proporciona serán conservados mientras se mantenga nuestra relación contractual o mercantil. No obstante a partir de la fecha de la terminación de nuestra relación contractual o mercantil sus datos serán conservados:</p>
    <ul>
      <li>UN AÑO las derivadas de conexiones realizadas por internet con nuestro sitio web, correo electrónico y llamadas telefónicas de voz, mensajería instantánea, SMS o MMS En el caso que sea cliente nuestro sus datos serán conservados.</li>
      <li>CUATRO AÑOS a efectos fiscales. Los libros de contabilidad y otros libros registros obligatorios según la normativa tributaria que proceda (IRPF, IVA, IS, etc) así como los soportes documentales que justifiquen las anotaciones registradas en los libros.</li>
      <li>SEIS AÑOS se conservarán los datos personales incluidos en los libros obligatorios, documentación y justificantes concernientes a mi actividad profesional de acuerdo al Código de Comercio.</li>
      <li>INDEFINIDAMENTE en el caso de la newsletter, a no ser que revoque su consentimiento. En cada correo recibirá una opción para darse la baja en la suscripción.</li>
    </ul>
    <h2>¿Cómo hemos obtenido sus datos personales?</h2>
    <p>Los datos obtenidos no los ha proporcionado usted.</p>
    <h2>¿A qué destinatarios se comunicarán sus datos personales?</h2>
    <p>No existe ninguna cesión de datos prevista, salvo obligación legal.</p>
    <h2>¿Cuáles son sus derechos cuando nos facilita sus datos personales?</h2>
    <p>Cualquier interesado podrá solicitar el ejercicio de los siguientes derechos:</p>
    <ul>
      <li>Derecho de acceso: El interesado tendrá derecho a obtener confirmación de si se están tratando o no datos personales que le conciernen.</li>
      <li>Derecho de rectificación: El interesado tendrá derecho a obtener la rectificación de los datos personales inexactos que le conciernan o incompletos.</li>
      <li>Derecho de supresión: El interesado tendrá derecho a obtener la supresión de los datos personales que le conciernan cuando los datos personales ya no sean necesarios en relación con los fines para los que fueron recogidos o tratados de otro modo.</li>
      <li>Derecho de limitación: Podrá solicitar la limitación del tratamiento de sus datos personales, en cuyo caso únicamente los conservaría para el ejercicio o la defensa de reclamaciones.</li>
      <li>Derecho a la portabilidad de sus datos: Por lo que puede solicitarnos que sus datos personales automatizados sean cedidos o transferidos a cualquier otra empresa que nos indique en un formato estructurado, inteligible y automatizado.</li>
      <li>Derecho de retirar el consentimiento: Usted tendrá derecho a retirar el consentimiento en cualquier momento, sin que ello afecte a la licitud del tratamiento basado en el consentimiento antes de su retirada.</li>
      <li>Derecho de oposición: El interesado tendrá derecho a oponerse al tratamiento de sus datos.</li>
    </ul>
    <h2>¿Cómó podrá ejercitar estos derechos?</h2>
    <p>Ponemos a su disposición si nos lo solicitan los formularios donde podrá ejercitar estos derechos. Podrá solicitarnos en el correo <a class="link" href="mailto:info@ydray.com" target="_blank" title="Enviar email a info@ydray.com">info@ydray.com</a> indicándonos el derecho que quiere ejercitar y le enviaremos el formulario correspondiente. El ejercicio de los derechos deberá llevarse a cabo mediante comunicación dirigida al correo electrónico <a class="link" href="mailto:privacidad@ydray.com" target="_blank" title="privacidad@ydray.com">privacidad@ydray.com</a> o bien en el domicilio postal Travesía Manzana 10, 13300, Valdepeñas, Ciudad Real.</p>
    <h2>¿Quién puede ejercitar los derechos?</h2>
    <p>Los derechos de los interesados son personalísimos, por lo tanto, serán ejercidos por el titular de los datos acreditando debidamente su identidad (para ello se le solicitará el DNI o equivalente).</p>
    <p>También podrá ejercerse a través de representación legal, en cuyo caso, además del DNI del interesado o equivalente, habrá de aportarse DNI y documento acreditativo auténtico de la representación del tercero.</p>
    <h2>¿Cuál será nuestra obligación cuando ejercita alguno de los derechos?</h2>
    <p>El responsable del tratamiento deberá contestar la solicitud que se le dirija en todo caso, con independencia de que figuren o no datos personales del afectado o interesado en sus tratamientos.</p>
    <p>En el caso de que la solicitud no reúna los requisitos especificados, el responsable del fichero deberá solicitar la subsanación de los mismos.</p>
    <p>El responsable de tratamiento responderá a las solicitudes en el plazo de un mes a partir de la recepción de la solicitud. Dicho plazo podrá prorrogarse otros dos meses en caso necesario, teniendo en cuenta la complejidad y el número de solicitudes. El responsable informará al interesado de cualquiera de dichas prórrogas en el plazo de un mes a partir de la recepción de la solicitud, indicando los motivos de la dilación.</p>
    <h2>Derecho de reclamación a la autoridad de control</h2>
    <p>Usted, podrá solicitar la tutela de derechos que no hayan sido debidamente atendidos a la Agencia Española de Protección de datos. Bien a través de la sede electrónica de su portal web (<a class="link" href="www.agpd.es" title="www.agpd.es">www.agpd.es</a>), o bien mediante escrito dirigido a su dirección postal (C/ Jorge Juan, 6, 28001-Madrid).</p>
    <h2>¿Qué información recopilamos?</h2>
    <p>En general, puede usar el Sitio Web sin facilitar ninguna información de carácter personal. Disponemos de varias secciones en nuestra página web en las cuales existen formularios de recogida de datos personales que son necesarios para poder prestarle los servicios que nos solicita. Cuando nos remite alguno de estos formularios solicitándonos información, el usuario garantiza la autenticidad, exactitud y veracidad de toda la información que nos facilite, comprometiéndose a mantener actualizados los datos de carácter personal que nos facilite de forma que los mismos respondan, en todo momento, a su situación real. El Usuario será el único responsable de las manifestaciones falsas o inexactas y de los perjuicios que las mismas pudieran causar.</p>
    <h2>¿Qué medidas adoptamos para mantener segura su información?</h2>
    <p>Aplicamos medidas técnicas y físicas razonables para proteger la información que recogemos a través del Sitio Web.</p>
    `,
  },

  cookies: {
    title: "Política de Cookies",
    description: "Explicación de la política de cookies de YDRAY",
    content: `
    <h1>Política de Cookies</h1>
    <p>Por favor, lea detenidamente esta declaración de privacidad antes de usar este Sitio, conocerá la forma en que usamos los cookies.</p>
    <p><b>El uso de este Sitio implica la aceptación de nuestra política de cookies. En caso contrario no use este Sitio.</b></p>
    <h2>1. Uso de cookies</h2>
    <h3>1.1. ¿Qué es una cookie?</h3>
    <p>Una cookie es un pequeño archivo, normalmente formado por letras y números, enviado por el servidor de nuestra web al archivo de cookies de su navegador. Ello permite a nuestra web recordar su presencia al establecerse una nueva conexión entre nuestro servidor y su navegador, así como mejorar la calidad del servicio analizando sus hábitos de navegación.</p>
    <h3>1.2. ¿Qué tipo de cookies emplea este Sitio?</h3>
    <h4>1.2.1. Cookies de sesión</h4>
    <p>Son cookies temporales que permanecen en el archivo de cookies de su navegador mientras está en nuestra página web. Estos cookies son necesarios para ciertas aplicaciones o funciones y permiten que este Sitio funcione adecuadamente.</p>
    <h4>1.2.2. Cookies de análisis</h4>
    <p>Son aquellas que permiten al responsable de las mismas el seguimiento y análisis del comportamiento de los usuarios de los sitios web a los que están vinculadas. La información recogida mediante este tipo de cookies se utiliza en la medición de la actividad de los sitios web y para la elaboración de perfiles de navegación de los usuarios de dichos sitios con el fin de introducir mejoras en función de su análisis de los datos de uso que hacen los usuarios del servicio.</p>
    <div class="table-container">
      <table>
        <thead>
          <tr>
            <td>Cookie</td>
            <td>Tipo</td>
            <td>Función</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>YDRAY_COM</td>
            <td>Sesión</td>
            <td>Correcto Funcionamiento de la página</td>
          </tr>
          <tr>
            <td>ydray</td>
            <td>Sesión</td>
            <td>Correcto Funcionamiento de la página</td>
          </tr>
          <tr>
            <td>st_ydy</td>
            <td>Sesión</td>
            <td>Correcto Funcionamiento de la página</td>
          </tr>
          <tr>
            <td>st_hmac</td>
            <td>Sesión</td>
            <td>Correcto Funcionamiento de la página</td>
          </tr>
          <tr>
            <td>csrf_cookie</td>
            <td>Sesión</td>
            <td>Correcto Funcionamiento de la página</td>
          </tr>
          <tr>
            <td>AcceptCookies</td>
            <td>Sesión</td>
            <td>Correcto Funcionamiento de la página</td>
          </tr>
          <tr>
            <td>_ga</td>
            <td>Analítica</td>
            <td>Obtener información de la navegación, medir y analizar la audiencia</td>
          </tr>
          <tr>
            <td>_gat</td>
            <td>Analítica</td>
            <td>Obtener información de la navegación, medir y analizar la audiencia</td>
          </tr>
          <tr>
            <td>_gid</td>
            <td>Analítica</td>
            <td>Obtener información de la navegación, medir y analizar la audiencia</td>
          </tr>
          <tr>
            <td>_asc</td>
            <td>Analítica</td>
            <td>Obtener información de la navegación, medir y analizar la audiencia</td>
          </tr>
          <tr>
            <td>_auc</td>
            <td>Analítica</td>
            <td>Obtener información de la navegación, medir y analizar la audiencia</td>
          </tr>
        </tbody>
      </table>
    </div>
    <p>En otros sitios de YDRAY se instalan cookies de terceros a todos sus visitantes, aunque no sean usuarios registrados en las correspondientes plataformas.</p>
    <h4>1.2.3. Google Analytics</h4>
    <p>Sus finalidades están descritas en la <a class="link" href="http://www.google.com/intl/es/policies/" target="_blank" title="Página de Políticas de Google">Página de Políticas de Google</a>.</p>
    <h4>1.2.4. Facebook</h4>
    <p>Sus finalidades están descritas en la <a class="link" href="https://www.facebook.com/help/cookies" target="_blank" title="Página de Cookies de Facebook">Página de Cookies de Facebook</a>.</p>
    <h4>1.2.5. X (Twitter)</h4>
    <p>Sus finalidades están descritas en la <a class="link" href="https://twitter.com/privacy" target="_blank" title="Página de Privacidad de X (Twitter)">Página de Privacidad de X (Twitter)</a>.</p>
    <h4>1.2.6. Youtube</h4>
    <p>Sus finalidades están descritas en la <a class="link" href="http://www.youtube.com/yt/policyandsafety/es/policy.html" target="_blank" title="Página de Privacidad de Youtube">Página de Privacidad de Youtube</a>.</p>
    <h2>2. Habilitación y deshabilitación de los cookies y de recursos similares</h2>
    <h3>2.1. Internet Explorer</h3>
    <p>Herramientas → Opciones de Internet → Privacidad → Configuración.</p>
    <p>Para más información, puede consultar el <a class="link" href="http://windows.microsoft.com/es-ES/windows/support" target="_blank" title="soporte de Microsoft">soporte de Microsoft</a> o la Ayuda del navegador.</p>
    <h3>2.2. Firefox</h3>
    <p>Herramientas → Opciones → Privacidad → Historial → Configuración Personalizada.</p>
    <p>Para más información, puede consultar el <a class="link" href="http://support.mozilla.org/es/home" target="_blank" title="soporte de Mozilla">soporte de Mozilla</a> o la Ayuda del navegador.</p>
    <h3>2.3. Chrome</h3>
    <p>Configuración → Mostrar opciones avanzadas → Privacidad → Configuración de contenido.</p>
    <p>Para más información, puede consultar el <a class="link" href="http://support.google.com/chrome/?hl=es" target="_blank" title="soporte de Google">soporte de Google</a> o la Ayuda del navegador.</p>
    <h3>2.4. Safari</h3>
    <p>Preferencias → Seguridad.</p>
    <p>Para más información, puede consultar el <a class="link" href="http://www.apple.com/es/support/safari/" target="_blank" title="soporte de Apple">soporte de Apple</a> o la Ayuda del navegador.</p>
    `,
  },

  legal_warn: {
    title: "Aviso Legal",
    description: "Aviso Legal de YDRAY",
    content: `
    <h1>Aviso Legal</h1>
    <p>Gracias por confiar en Ydray.com, en este punto te explicamos el objetivo del portal web y el cumplimiento con la Ley 34/2002.</p>
    <h2>Objetivo del portal web</h2>
    <p>Este portal web tiene como objetivo el poder enviar, compartir y almacenar archivos de gran tamaño entre usuarios, empresas y contactos de forma sencilla y segura.</p>
    <p>Nuestros servidores se encuentran localizados en Europa, siendo todos ellos servidores dedicados. Para nosotros, la privacidad de sus archivos es lo primero, por ello, huimos de la famosa nube, un sistema muy discutido en cuanto a privacidad y cobertura legal , y que utiliza la totalidad de nuestra competencia.</p>
    <h2>Información de cumplimento de Ley 34/2002</h2>
    <p>En cumplimiento de la Ley 34/2002, de 11 de Julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico, le informamos que RECICLAONLINE SL, domiciliada en Travesía Manzana 10, 13300, Valdepeñas, Ciudad Real, con CIF n º B13538053 e inscrita en el Registro de CIUDAD REAL, Tomo 550, Libro 0, folio 206, hoja CR-21866 inscripción 1ª, es la titular de la página www.ydray.com</p>
    `,
  },

  send_files: {
    title: "Enviar archivos grandes hasta 10 GB gratis",
    description: `
      YDRAY es la manera más fácil y eficaz de enviar y transferir archivos grandes de forma rápida con la máxima
      privacidad y seguridad hasta 10 GB gratis
    `,
    content: `
      <h1>Enviar archivos con YDRAY</h1>
      <p>
        YDRAY es una herramienta <strong>versátil</strong> y <strong>práctica</strong> que puede brindarte
        múltiples beneficios tanto en <strong>tu trabajo</strong> como en <strong>tu vida personal</strong>.
        Con su capacidad para <a class="link" href="/es/enviar-archivos/grandes" title="Enviar archivos de grandes
        con YDRAY">enviar archivos de grandes</a> de manera rápida y sencilla, YDRAY se ha convertido en una
        solución <strong>fiable</strong> y <strong>segura</strong> para gestionar tus archivos digitales. A
        continuación, exploraremos cómo YDRAY puede ayudarte en ambos ámbitos.
      </p>
      <h2>YDRAY en el trabajo</h2>
      <img
        src="../../../assets/images/send-files-working.png"
        alt="Enviando archivos en el entorno laboral"
        title="Enviando archivos en el entorno laboral"
        height="auto"
        width="auto"
        loading="eager"
      />
      <p>
        YDRAY es una herramienta <strong>imprescindible</strong> para enviar y compartir archivos con
        compañeros, colaboradores y clientes. Puedes enviar fácilmente presentaciones, documentos,
        imágenes, videos y otros archivos relacionados con tu trabajo. Ya sea que necesites enviar un
        archivo a un compañeros en la misma o distinta oficina, colaboradores de otras ciudades o a un
        cliente en el extranjero, YDRAY te permite hacerlo de <strong>manera eficiente</strong>,
        <strong>rápida</strong> y <strong>sin complicaciones</strong>. Puedes enviar y compartir archivos
        de gran tamaño sin preocuparte por las limitaciones de tamaño de los correos electrónicos o los
        sistemas de mensajería tradicionales.
      </p>
      <p>
        Además con sus opciones a la hora de enviar archivos, YDRAY también te permite
        colaborar de manera efectiva en proyectos de equipo. Puedes enviar carpetas enteras
        con tus colaboradores, lo que facilita <strong>la colaboración</strong> y <strong>el intercambio de ideas</strong>.
        Los miembros del equipo pueden agregar y descargar archivos, mantenerse actualizados con las últimas
        versiones y trabajar de manera conjunta. Esta funcionalidad promueve <strong>la productividad</strong>
        y <strong>la eficiencia</strong> en el entorno laboral, ya que elimina la necesidad de enviar múltiples
        correos electrónicos o utilizar dispositivos de almacenamiento externo.
      </p>
      <h2>YDRAY en tu vida personal</h2>
      <img
        src="../../../assets/images/send-files-friends.png"
        title="Enviando archivos en el entorno personal"
        alt="Enviando archivos en el entorno personal"
        height="auto"
        width="auto"
        loading="lazy"
      />
      <p>
        YDRAY también es útil para enviar archivos a <strong>amigos</strong> y <strong>familiares</strong>.
        Puedes compartir fotos y videos de tus últimas vacaciones, celebraciones de boda o cumpleaños, así
        documentos importantes o cualquier otro archivo que desees enviar o compartir con tus seres
        queridos. YDRAY <strong>facilita</strong> la labor de enviar estos archivos, incluso si son de <strong>gran
        tamaño</strong>, lo que te permite mantener a tus seres queridos actualizados y compartir momentos
        significativos.
      </p>
      <p>
        YDRAY es una herramienta que te brinda <strong>tranquilidad</strong> en términos de <strong>privacidad</strong> y
        <strong>seguridad</strong>. Utiliza cifrado de extremo a extremo para proteger tus archivos durante la
        transferencia, lo que significa que solo tú y los destinatarios autorizados pueden acceder a ellos.
        Esto es especialmente importante cuando se trata de enviar archivos confidenciales o sensibles.
        Puedes tener la <strong>confianza</strong> de que tus archivos están protegidos y se comparten de manera segura
        a través de YDRAY. Además ofrece la posibilidad de enviar y compartir tus archivos asignando una
        contraseña al envío de los mismos, dando una capa extra de <strong>protección</strong>.
      </p>
      <h2>Consejos para enviar archivos</h2>
      <img
        src="../../../assets/images/send-files-doubts.png"
        title="Consejos para enviar archivos"
        alt="Consejos para enviar archivos"
        height="auto"
        width="auto"
        loading="lazy"
      />
      <h3>¿Cómo se pueden enviar los archivos?</h3>
      <p>
        Con YDRAY, puedes enviar archivos de <strong>manera sencilla</strong>. Solo visita ydray.com,
        selecciona los archivos que deseas compartir y <strong>genera un link</strong> para compartir
        por email, Facebook, Instagram, X (Twitter), Whatsapp o Gmail. Haz click en generar enlace e YDRAY
        se encargará de compartir tus archivos de forma segura y con la máxima privacidad. ¡Así de fácil!
      </p>
      <h3>¿Cuál es la mejor plataforma para compartir archivos?</h3>
      <p>
        La mejor plataforma para enviar y compartir archivos es Ydray.com. Con su interfaz
        <strong>intuitiva</strong> y <strong>fácil de usar</strong>, puedes enviar archivos de gran
        tamaño de manera rápida y segura. YDRAY te permite compartir archivos con amigos, compañeros
        de trabajo, amigos o clientes de forma sencilla, haciendo que la experiencia de
        compartir archivos sea eficiente y confiable.
        ¡Descubre YDRAY y disfruta de una forma práctica de enviar y compartir archivos en línea!
      </p>
      <h3>¿Dónde puedo enviar archivos pesados gratis?</h3>
      <p>
        YDRAY es la solución perfecta para <a class="link" href="/es/enviar-archivos/gratis"
        title="Enviar archivos gratis con YDRAY">enviar archivos gratis</a>. Con YDRAY, puedes enviar
        archivos de <strong>gran tamaño</strong> de manera fácil y rápida a cualquier persona en todo
        el mundo. Simplemente visita Ydray.com, selecciona los archivos que deseas enviar y genera el
        enlace para compartir tus archivos. ¡Comparte archivos sin límites y de forma gratuita con YDRAY!
      </p>
      <h3>¿Cómo se llama la página para enviar archivos?</h3>
      <p>
        La página popularmente conocida para enviar y compartir archivos se llama YDRAY. Con
        YDRAY, puedes enviar y compartir archivos fácilmente de forma <strong>segura</strong> y
        <strong>rápida</strong>. Simplemente visita ydray.com, selecciona los archivos que deseas
        enviar y compártelos con tus destinatarios. ¡Así podrás enviar y compartir archivos
        de manera eficiente con YDRAY!
      </p>
      <h2>En definitiva, YDRAY</h2>
      <p>
        YDRAY es una <strong>herramienta valiosa</strong> para enviar y compartir archivos tanto
        en el trabajo como en tu vida personal. <strong>Facilita</strong> la transferencia de archivos
        de gran tamaño, <strong>fomenta</strong> la colaboración en equipo y ofrece seguridad y
        privacidad en tus envíos. Ya sea para proyectos laborales o para compartir momentos especiales
        con tus seres queridos, YDRAY es una opción <strong>confiable</strong> y <strong>conveniente</strong>
        que simplificará tus tareas de envío y compartir archivos.
      </p>
    `,
  },

  send_files_big: {
    title: `Enviar archivos grandes`,
    description: `Enviar archivos grandes`,
    content: `
    <h1>Envía tus archivos grandes con YDRAY</h1>
    <h2>¿Necesitas enviar tus archivos grandes?</h2>
    <img
      src="../../../assets/images/send-big-files-doubts.png"
      alt="Enviar archivos grandes con YDRAY"
      title="Enviar archivos grandes con YDRAY"
      height="auto"
      width="auto"
      loading="eager"
    />
    <p>
      YDRAY es la plataforma más poderosa que te permite enviar archivos grandes y pesados,
      es decir de <strong>gran tamaño</strong>, de manera <strong>eficiente</strong> y <strong>segura</strong>.
      Sin embargo, YDRAY destaca de sus competidores, brindándote una experiencia superior a la hora de
      <strong>compartir archivos grandes</strong>.
    </p>
    <p>
      Una de las ventajas clave de YDRAY es su capacidad para
      <a class="link" href="/es/enviar-archivos" title="Enviar archivos con YDRAY">enviar archivos</a>
      de forma gratuita. A diferencia de muchos otros servicios, con YDRAY puedes <strong>enviar
      archivos grandes de hasta 10GB</strong> sin costes adicionales, es decir, totalmente <strong>gratis</strong>.
      Esto es especialmente interesante cuando necesitas <strong>enviar</strong> presentaciones, proyectos multimedia,
      diseños en 3D o archivos pesados que exceden los límites de tamaño de otros servicios. YDRAY te brinda la
      libertad de <strong>enviar archivos grandes y pesados</strong> sin restricciones de tamaño y sin gastar dinero extra.
    </p>
    <h2>Caraterísticas de YDRAY</h2>
    <img
      src="../../../assets/images/send-big-files-characteristics.png"
      alt="Características de YDRAY"
      title="Características de YDRAY"
      height="auto"
      width="auto"
      loading="eager"
    />
    <p>
      YDRAY sobresale en términos de <strong>seguridad</strong> y <strong>privacidad</strong>. Utilizando
      <strong>cifrado de extremo a extremo</strong> para todas las transferencias de archivos pesados,
      lo que garantiza que tus datos estén <strong>protegidos</strong> durante la transmisión. YDRAY se 
      compromete con la <strong>confidencialidad</strong> de tus archivos grandes y te brinda <strong>tranquilidad</strong>
      al saber que tus documentos y contenido multimedia estarán <strong>seguros</strong> y solo accesibles
      para los destinatarios autorizados. YDRAY es <strong>la mejor opción</strong> para enviar archivos pesados en
      cumplimiento con la <strong>GDPR (General Data Protection Regulation)</strong>.
    </p>
    <p>
      YDRAY tiene una <strong>interfaz intuitiva</strong> y <strong>fácil de usar</strong>.
      YDRAY sitúa al usuario en el centro de la proceso, cuenta con la mejor <strong>experiencia de usuario</strong>
      a la hora de <strong>transferir archivos</strong>. El proceso a la hora de enviar archivos grandes se simplifica
      al máximo, lo que te permite <strong>ahorrar tiempo</strong> y <strong>esfuerzo</strong>.
      Con solo unos pocos clics, puedes subir tus archivos pesados, seleccionar entre las múltiples opciones gratuitas y
      <strong>compartir el enlace de descarga</strong>, ya sea copiando el enlace, utilizando tu email, Gmail, Facebook,
      X (Twitter) e incluso por Whatsapp. La <strong>simplicidad</strong> y la <strong>eficiencia</strong> son
      elementos clave en la experiencia de usuario de YDRAY, lo que te permite enviar archivos grandes y
      pesados de manera rápida y sin complicaciones.
    </p>
    <p>
      YDRAY ofrece opciones de <strong>personalización</strong> para adaptarse a tus necesidades. Puedes agregar <strong>mensajes</strong>
      personalizados a tus envíos, <strong>título</strong> asociado al enlace de descarga y establecer <strong>fechas de caducidad</strong>
      para que los archivos estén disponibles solo por un tiempo limitado. Esta flexibilidad te permite tener un mayor control sobre tus
      transferencias y asegurarte de que los archivos se compartan según tus preferencias.
    </p>
    <h2>Conclusiones</h2>
    <img
      src="../../../assets/images/send-big-files-conclusions.png"
      alt="Conclusiones"
      title="Conclusiones"
      height="auto"
      width="auto"
      loading="eager"
    />
    <p>
      YDRAY se posiciona como una opción destacada para strong>enviar archivos <grandes</strong>, de <strong>gran tamaño</strong> y
      <strong>pesados</strong> de manera totalmente <strong>gratuita</strong>. Sus fortalezas en términos de <strong>capacidad de almacenamiento</strong>,
      <strong>seguridad</strong> y <strong>facilidad de uso</strong> la distinguen de otros competidores en el mercado de transferencia de archivos,
      ofreciéndote una solución <strong>confiable</strong> y <strong>conveniente</strong> para tus necesidades de envío y compartición de archivos.
      Prueba YDRAY y descubre una forma <strong>eficiente</strong> y <strong>segura</strong> de enviar y compartir archivos grandes y pesados,
      <strong>gratis</strong> y <strong>sin limitaciones</strong>.
    </p>
    `,
  },

  send_files_free: {
    title: `Enviar archivos gratis`,
    description: `Envia archivos gratis`,
    content: `
      <h1>Envía tus archivos gratis con YDRAY</h1>
      <h2>¿Necesitas enviar tus archivos de forma gratuita?</h2>
      <img
        src="../../../assets/images/send-free-files-doubts.png"
        alt="Enviar archivos gratis con YDRAY"
        title="Enviar archivos gratis con YDRAY"
        height="auto"
        width="auto"
        loading="eager"
      />
      <p>
        YDRAY es una excelente opción para satisfacer tus necesidades. YDRAY destaca, como plataforma de envío archivos gratis,
        por sus características únicas que la diferencia de sus competidores, permitiéndote enviar archivos gratis de manera eficiente
        y sin coste alguno, es decir, <strong>YDRAY te permite enviar tus archivos gratis</strong>.
      </p>
      <h2>Características de YDRAY</h2>
      <img
        src="../../../assets/images/send-free-files-characteristics.png"
        alt="Características de YDRAY"
        title="Características de YDRAY"
        height="auto"
        width="auto"
        loading="eager"
      />
      <p>
        YDRAY te brinda la posibilidad de <strong>enviar archivos gratis</strong>. Puedes transferir tus documentos, fotos, videos
        y demás archivos sin coste adicional. Esta opción es ideal para aquellos que necesitan enviar archivos de manera ocasional
        o que no requieren de funcionalidades avanzadas.
      </p>
      <p>
        YDRAY también te permite <strong>enviar archivos gratis de manera sencilla</strong>. Puedes compartir un enlace de descarga a tus contactos,
        que podrán acceder a los archivos de forma <strong>rápida</strong> y <strong>cómoda</strong>. Enviar archivos gratis se vuelve un proceso
        <strong>ágil</strong> y <strong>conveniente</strong>, ya que no es necesario que los destinatarios se registren en la plataforma para acceder
        a los archivos compartidos.
      </p>
      <h2>Ventajas de usar YDRAY</h2>
      <img
        src="../../../assets/images/send-free-files-cost.png"
        alt="Ventajas de usar YDRAY"
        title="Ventajas de usar YDRAY"
        height="auto"
        width="auto"
        loading="lazy"
      />
      <p>
        Una de las ventajas significativas de YDRAY es su capacidad para <strong>enviar archivos gratis</strong>. YDRAY te permite
        <strong>enviar archivos de gran tamaño</strong>, lo cual es especialmente útil cuando necesitas compartir presentaciones,
        videos en alta calidad o tus proyectos más creativos. Olvídate de los límites de tamaño y <strong>envía tus archivos gratis</strong>
        sin problemas.
      </p>
      <p>
        YDRAY te ofrece opciones <strong>gratuitas</strong> para <strong>compartir archivos grandes</strong>. No solo podrás
        <a class="link" href="/es/enviar-archivos" title="Enviar archivos con YDRAY">enviar archivos</a>, sino que también podrás
        <strong>enviarlos gratis</strong>. Esto es especialmente valioso para profesionales y empresas que necesitan <strong>enviar archivos</strong>
        de manera regular, sin incurrir en gastos innecesarios. Tienes opciones para configurar el <strong>envio de archivos totalmente
        gratuitas</strong>, tales como seleccionar una contraseña o la caducidad del enlace generado para compartir tus archivos.
      </p>
      <h2>Conclusiones</h2>
      <img
        src="../../../assets/images/send-free-files-end.png"
        alt="Conclusiones"
        title="Conclusiones"
        height="auto"
        width="auto"
        loading="lazy"
      />
      <p>
        YDRAY te brinda la posibilidad de <strong>enviar archivos de forma gratuita</strong>, enfocándose en la <strong>simplicidad</strong>
        y la <strong>eficiencia</strong>, con una experiencia de usuario totalmente revolucionaria e innovadora. YDRAY destaca en el <strong>envío seguro
        de archivos gratis</strong> y ofrece opciones gratuitas para compartir, por lo que la convierte en una excelente <strong>alternativa frente a sus
        competidores</strong>. Si necesitas <strong>enviar archivos</strong> de manera ágil y gratuita, no dudes en aprovechar las ventajas
        que te ofrece YDRAY. Envía, comparte y disfruta de una experiencia sin complicaciones con la <strong>plataforma líder en el mercado de
        transferencia de archivos</strong>.
      </p>
    `,
  },

  share_files: {
    title: "Compartir archivos grandes y pesados hasta 10 GB gratis",
    description: `
      YDRAY es la forma más rápida y fácil de transferir y compartir archivos grandes y pesados de forma segura con la máxima privacidad y seguridad hasta 10 GB gratis
    `,
    content: `
      <h1>Compartir archivos con YDRAY</h1>
      <p>
        Como empresa, la <strong>privacidad</strong> y <strong>seguridad</strong> de los archivos que compartes son de mucha
        importancia. YDRAY es una herramienta que puede ayudarte a <a class="link" href="/es/compartir-archivos/gratis"
        title="compartir archivos gratis con YDRAY">compartir archivos gratis</a> de manera privada,
        asegurando la <strong>confidencialidad</strong> de la información de tus clientes y garantizando el cumplimiento
        de las regulaciones de protección de datos.
      </p>
      <h2>Fácil de usar</h2>
      <img
        src="../../../assets/images/share-files-easy.png"
        alt="Facilidad al enviar archivos"
        title="Facilidad al enviar archivos"
        height="auto"
        width="auto"
        loading="eager"
      />
      <p>
        YDRAY es una herramienta fácil de usar. Su interfaz <strong>intuitiva</strong> te permite
        <a class="link" href="/es/compartir-archivos/grandes" title="Enviar archivos grandes con YDRAY">enviar archivos grandes</a>
        rápidamente con solo unos pocos clics. No requiere de conocimientos técnicos avanzados, lo que facilita su uso
        tanto para <strong>usos profesionales</strong> como para <strong>usos personales</strong>.
      </p>
      <h2>Privacidad en tus comunicaciones</h2>
      <img
        src="../../../assets/images/share-files-lock.png"
        alt="Seguridad al compartir archivos"
        title="Seguridad al compartir archivos"
        height="auto"
        width="auto"
        loading="lazy"
      />
      <p>
        YDRAY se compromete a mantener la privacidad de tus archivos. No almacena tus archivos
        en sus servidores más tiempo del necesario. Una vez que el archivo se ha transferido con éxito y
        ha pasado el plazo establecido en el envío, se <strong>elimina automáticamente</strong> de los servidores
        de YDRAY. Esto <strong>reduce el riesgo</strong> de exposición accidental o <strong>acceso no autorizado</strong> a
        los archivos compartidos.
      </p>
      <p>
        YDRAY ofrece un <strong>entorno seguro</strong> para el envío de archivos. Utiliza encriptación de
        extremo a extremo, lo que significa que los archivos se <strong>cifran</strong> antes de salir de tu
        dispositivo y solo se descifran una vez que llegan al dispositivo del destinatario. Esto garantiza que los
        archivos estén <strong>protegidos</strong> durante todo el proceso de transferencia y evita el acceso no autorizado
        a la información confidencial.
      </p>
      <p>
        YDRAY también te permite <strong>establecer contraseñas</strong> para los archivos que envías agregando
        una capa adicional de <strong>seguridad</strong>. Puedes asignar una contraseña única para cada archivo, lo que
        garantiza que solo los <strong>destinatarios autorizados</strong> puedan acceder a ellos. Esta función es
        especialmente útil cuando envías archivos con <strong>información sensible</strong>.
      </p>
      <h2>Personalización de tus envíos</h2>
      <img
        src="../../../assets/images/share-files-custom.png"
        alt="Personalización de tus archivos compartidos"
        title="Personalización de tus archivos compartidos"
        height="auto"
        width="auto"
        loading="lazy"
      />
      <p>
        Otra característica valiosa de YDRAY es la capacidad de <strong>personalizar tus transferencias</strong>. Puedes
        agregar tu logotipo al enlace de descarga como opción de pago con la cuenta Pro, lo que ayuda a
        reforzar la identidad de tu negocio y brinda una experiencia <strong>más profesional</strong> a los
        destinatarios. Esto puede ser útil cuando envías archivos a colaboradores externos o incluso a tus
        clientes dando una <strong>imagen más corporativa</strong>.
      </p>
      <p>
        YDRAY también ofrece la opción de configurar una <strong>fecha de caducidad</strong> para tus transferencias. Esto
        significa que los archivos compartidos solo estarán disponibles para su descarga durante un
        <strong>período específico de tiempo</strong>. Después de la fecha de caducidad, los archivos se eliminan
        automáticamente, lo que limita aún más la exposición y garantiza que los archivos confidenciales
        no queden almacenados indefinidamente.
      </p>
      <h2>Consejos para compartir archivos</h2>
      <img
        src="../../../assets/images/share-files-doubts.png"
        alt="Consejos para compartir archivos"
        title="Consejos para compartir archivos"
        height="auto"
        width="auto"
        loading="lazy"
      />
      <h3>¿Cómo se pueden compartir archivos?</h3>
      <p>
        YDRAY es una plataforma <strong>confiable</strong> y <strong>segura</strong> para enviar
        y compartir archivos. Con YDRAY, puedes enviar <strong>archivos grandes</strong> de manera
        rápida y sencilla. Simplemente visita Ydray.com, selecciona los archivos que deseas compartir
        y <strong>genera el enlace</strong> a compartir ya sea por correo electrónico, Facebook,
        Instagram, X (Twitter) o Gmail. YDRAY se encargará de enviar y compartir tus archivos de forma
        privada y segura. ¡Descubre una nueva forma de enviar y compartir archivos con YDRAY!
      </p>
      <h3>¿Cuál es la mejor plataforma para compartir archivos?</h3>
      <p>
        La mejor plataforma para compartir archivos es YDRAY. Con YDRAY, puedes enviar y
        compartir archivos de forma <strong>rápida</strong>, <strong>segura</strong> y <strong>confiable</strong>.
        Es una herramienta <strong>fácil de usar</strong> que te permite compartir archivos grandes
        sin preocuparte por límites de tamaño. Cuenta con la <strong>mayor seguridad</strong> de mercado
        y proporciona la máxima privacidad en tus envíos de archivos ¡Descubre la experiencia de
        enviar y compartir archivos de manera eficiente con YDRAY!
      </p>
      <h3>¿Cómo se pueden compartir archivos de manera segura?</h3>
      <p>
        Con YDRAY, puedes compartir archivos de manera <strong>segura</strong> y <strong>confiable</strong>.
        Ydray.com es una plataforma que te permite enviar y compartir archivos de forma <strong>protegida</strong>,
        utilizando encriptación de extremo a extremo para garantizar la <strong>seguridad de tus datos</strong>.
        Simplemente visita Ydray.com, selecciona los archivos que deseas, y <strong>genera el
        enlace</strong> a compartir. YDRAY se encargará de enviar y compartir tus archivos de manera
        <strong>segura</strong> y <strong>confiable</strong>, protegiendo la privacidad de tus datos durante
        el proceso. ¡Disfruta de una forma segura de enviar y compartir archivos con YDRAY!
      </p>
      <h3>¿Cómo puedes compartir un archivo o carpeta con otra persona?</h3>
      <p>
        Con YDRAY, puedes compartir archivos o carpetas con otras personas de <strong>manera rápida</strong> y
        <strong>sencilla</strong>. Simplemente visita Ydray.com, selecciona los archivos o carpeta con tus
        archivos que deseas compartir y haz clic en <strong>Generar enlace</strong>. Podras compartir tu
        enlace por correo electrónico, Facebook, Instagram, X (Twitter) o Gmail. YDRAY se encargará de
        enviar y compartir los archivos <strong>forma segura</strong>. ¡Compartir nunca fue tan fácil
        con YDRAY!
      </p>
      <h2>En definitiva, YDRAY</h2>
      <p>
        YDRAY es una solución <strong>eficaz</strong> y <strong>segura</strong> para enviar y compartir archivos
        <strong>confidenciales</strong> en el contexto de tu empresa. Su encriptación de extremo a extremo, la
        posibilidad de establecer contraseñas y la eliminación automática de archivos garantizan la
        <strong>privacidad</strong> y <strong>confidencialidad</strong> de la información de los clientes.
        Además, su facilidad de uso y personalización hacen de YDRAY una herramienta conveniente y
        confiable para enviar archivos de forma privada y segura en tu práctica laboral.
      </p>
    `,
  },

  share_files_big: {
    title: `Compartir archivos grandes`,
    description: `Compartir archivos grandes`,
    content: `
    <h1>Compartir archivos grandes con YDRAY</h1>
    <p>
      En el mundo digital actual, tanto en tu trabajo como a nivel personal, surge la
      necesidad de compartir archivos grandes manera <strong>rápida</strong> y <strong>segura</strong>,
      hecho que se ha vuelto cada vez más importante. Afortunadamente, plataformas
      como YDRAY han surgido para facilitar este proceso y satisfacer las demandas de los
      usuarios más exigentes.
    </p>
    <h2>Características de YDRAY</h2>
    <img
      src="../../../assets/images/share-big-files-doubts.png"
      alt="Consejos para compartir archivos grandes"
      title="Consejos para compartir archivos grandes"
      height="auto"
      width="auto"
      loading="lazy"
    />
    <p>
      YDRAY destaca entre sus competidores al ofrecer soluciones <strong>eficientes</strong>
      y <strong>confiables</strong> para
      <a class="link" href="/es/compartir-archivos" title="Compartir archivos con YDRAY">compartir archivos</a>.
      Una de las ventajas clave de YDRAY es su generoso <strong>límite de tamaño</strong> de archivo de hasta 10 GB,
      lo cual lo convierte en una opción ideal para aquellos que necesitan <strong>compartir archivos
      de gran tamaño de forma gratuita</strong>. Esta opción es única en el mercado. Por otro lado,
      el principal competidor de YDRAY permite enviar archivos de hasta 2 GB de forma gratuita, lo cual
      sigue siendo una capacidad generosa en comparación con otros servicios, pero
      <strong>hasta 5 veces menor comparada con YDRAY</strong>.
    </p>
    <p>
      YDRAY se posiciona como una plataforma para compartir y transferir archivos
      grandes con una interfaz muy <strong>fácil de usar</strong> que permite a los
      usuarios subir y compartir archivos de manera <strong>rápida</strong> y
      <strong>sencilla</strong>. Simplemente selecciona o arrastra los archivos que deseas compartir,
      elige entre las diferentes opciones gratuitas posibles, (tales como poner <strong>título al envío</strong>,
      introducir un <strong>mensaje</strong>, seleccionar la <strong>fecha de caducidad</strong> el enlace
      de descarga o poner una contraseña a tu envío de archivos) y haz clic en el botón de envío.
      En cuestión de segundos, el destinatario recibirá un enlace para descargar los archivos compartidos.
      Este proceso simplificado <strong>ahorra tiempo</strong> y <strong>esfuerzo</strong>,
      eliminando la necesidad de utilizar métodos más complejos y menos eficientes para enviar archivos
      grandes y pesados.
    </p>
    <p>
      Una característica distintiva de YDRAY es su enfoque en la <strong>privacidad</strong> y
      <strong>seguridad</strong> de los archivos compartidos. La plataforma utiliza un 
      <strong>cifrado de extremo a extremo</strong> para garantizar que tus archivos estén protegidos
      durante el proceso de envío y recepción.
      Además, YDRAY ofrece opciones avanzadas de configuración, como <strong>establecer una
      contraseña para los archivos compartidos</strong> o <strong>programar su fecha de expiración</strong>.
      Estas características brindan un mayor control y tranquilidad a los usuarios que desean
      compartir archivos de <strong>manera segura</strong>.
    </p>
    <p>
      YDRAY se posiciona como la <strong>plataforma líder</strong> a nivel de privacidad a la hora de compartir
      archivos pesados, siendo una opción solida a la hora de transferir archivos grandes y totalmente <strong>gratis</strong>.
    </p>
    <p>
      En cuanto a la velocidad de transferencia, YDRAY se esfuerza por proporcionar una
      experiencia <strong>rápida</strong> y <strong>eficiente</strong>. Su infraestructura optimizada permite
      una carga y descarga de archivos <strong>ágil</strong>, lo que garantiza una transferencia fluida de
      archivos de gran tamaño y pesados.
    </p>
    <h2>Conclusiones</h2>
    <img
      src="../../../assets/images/share-big-files-characteristics.png"
      alt="Conclusiones al compartir archivos grandes"
      title="Conclusiones al compartir archivos grandes"
      height="auto"
      width="auto"
      loading="lazy"
    />
    <p>
      En resumen YDRAY es la mejor alternativa a sus competidores para <strong>compartir
      archivos grandes</strong>. YDRAY destaca por su <strong>generoso límite de tamaño de archivo de hasta
      10 GB totalmente gratis</strong>, su enfoque en la <strong>privacidad</strong> y <strong>seguridad</strong>,
      así como por sus opciones avanzadas de configuración. Otras alternativas ofrecen una
      capacidad de hasta 2 GB de forma gratuita. YDRAY trabaja diariamente para adaptarse a
      las necesidades de los usuarios modernos y proporcionar soluciones <strong>eficientes</strong> y
      <strong>confiables</strong> para compartir archivos grandes, archivos pesados y archivos de
      gran tamaño. <strong>¡No esperes más y comienza a disfrutar de una experiencia fluida y segura
      para compartir tus archivos grandes con YDRAY!</strong>
    </p>
    `,
  },

  share_files_free: {
    title: `Compartir archivos gratis`,
    description: `Compartir archivos gratis`,
    content: `
    <h1>Compartir archivos gratis con YDRAY</h1>
    <p>
      YDRAY es una excelente opción para compartir archivos <strong>gratis</strong> y
      <strong>sin coste</strong>, es decir, YDRAY es una plataforma de transferencia de archivos totalmente
      <strong>gratuita</strong>. YDRAY se destaca con sus características únicas y puntos fuertes en comparación con
      sus competidores, ofreciendo una <strong>experiencia superior al usuario</strong> compartiendo archivos.
    </p>
    <img
      src="../../../assets/images/share-free-files-doubts.png"
      alt="Compartir archivos gratis"
      title="Compartir archivos gratis"
      height="auto"
      width="auto"
      loading="lazy"
    />
    <h2>Ventajas de usar YDRAY</h2>
    <p>
      Una de las principales ventajas de YDRAY es su capacidad para
      <a class="link" href="/es/compartir-archivos/" title="Compartir archivos con YDRAY">compartir archivos</a>
      <strong>sin restricciones</strong>. Mientras que algunos servicios limitan el tamaño de los archivos que
      puedes enviar, YDRAY te permite <strong>compartir archivos</strong> de hasta 10GB <strong>gratis</strong>.
      Esta característica es especialmente valiosa cuando necesitas transferir y compartir archivos multimedia de
      alta calidad, proyectos creativos o cualquier otro contenido pesado. Con YDRAY, no hay límites para
      <strong>compartir tus archivos gratis</strong>.
    </p>
    <p>
      Además de compartir archivos gratis, YDRAY destaca en términos de <strong>velocidad</strong> y
      <strong>rendimiento</strong>. La plataforma utiliza una infraestructura de carga optimizada,
      lo que se traduce en <strong>transferencias rápidas</strong> y <strong>eficientes</strong> de tus
      archivos. Puedes compartir archivos gratis en cuestión de minutos, lo que agiliza tus procesos de trabajo y
      te permite enviar contenido de manera <strong>más eficiente</strong>.
    </p>
    <p>
      YDRAY es líder en </strong>experiencia de usuario</strong> a la hora de compartir archivos
      totalmente gratis. La plataforma de envío de archivos sitúa al usuario en el
      centro del proceso de transferencia de archivos, otorgándole de una experiencia <strong>sencilla</strong>,
      <strong>intuitiva</strong> y <strong>fácil de usar</strong>.
    </p>
    <p>
      Otro punto fuerte es su enfoque en la <strong>privacidad</strong> y <strong>seguridad</strong>
      de tus archivos. Utiliza <strong>cifrado de extremo a extremo</strong> para todas las transferencias, garantizando que
      tus datos estén protegidos durante todo el proceso. Además, <strong>YDRAY no almacena tus
      archivos en sus servidores más tiempo del necesario</strong>, lo que minimiza los riesgos de
      exposición y asegura la <strong>confidencialidad de tus documentos</strong> y contenido multimedia.
      YDRAY es la alternativa líder para compartir archivos gratis en <strong>cumplimiento con la
      GDPR (General Data Protection Regulation)</strong>.
    </p>
    <p>
      En términos de facilidad de uso, YDRAY se destaca con su <strong>interfaz intuitiva</strong>,
      <strong>sencilla</strong> y <strong>amigable</strong>. El proceso de envío de archivos gratis
      es <strong>simple</strong>, incluso para aquellos que no son expertos en tecnología. Solo necesitas cargar
      tus archivos, seleccionar las opciones disponibles y compartir tus archivos gratis.
      No hay complicaciones ni pasos innecesarios, lo que te <strong>ahorra tiempo</strong> y
      <strong>esfuerzo</strong> en tus tareas diarias.
    </p>
    <p>
      Por último, YDRAY ofrece <strong>opciones de personalización</strong> en tus enlaces de descarga de
      archivos para adaptarse a tus necesidades. Puedes agregar <strong>mensajes personalizados</strong>
      a tus transferencias, establecer </strong>fechas de caducidad</strong> del enlace y controlar quién
      puede acceder a tus archivos compartidos mediante una </strong>contraseña</strong>.
      Estas características te brindan un mayor control sobre tus transferencias de archivos y te permiten
      personalizarlas según tus preferencias.
    </p>
    <img
      src="../../../assets/images/share-free-files-advantages.png"
      alt="Conclusiones al compartir archivos gratis"
      title="Conclusiones al compartir archivos gratis"
      height="auto"
      width="auto"
      loading="lazy"
    />
    <h2>Conclusiones</h2>
    <p>
      En resumen, YDRAY es la mejor alternativa entre sus competidores a la hora de <strong>compartir archivos gratis</strong>.
      YDRAY destaca con sus puntos fuertes en términos de <strong>diseño</strong>, <strong>experiencia de usuario</strong>,
      <strong>tamaño de archivo</strong>, <strong>velocidad</strong>, <strong>privacidad</strong> y
      <strong>facilidad de uso</strong>. Si necesitas compartir archivos gratis, YDRAY es la solución <strong>confiable</strong>
      y <strong>conveniente</strong>. <strong>¡Descubre con YDRAY una forma rápida, segura y personalizable de compartir tus
      archivos totalmente gratis!</strong>
    </p>
    `,
  },

  more_info: {
    title: "Más Información",
    description: "Información adicional de YDRAY",
    content: `
    <h1>Más Información</h1>
    <h2 id="data-protection">Protección de datos</h2>
    <p>De conformidad con lo dispuesto en el Reglamento (UE) 2016/679, de 27 de abril, relativo a la protección de las Personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos (en adelante, RGPD) le informamos sobre ello.</p>
    <h2 id="responsible">Responsable</h2>
    <p>RECICLAONLINE S.L</p>
    <h2 id="porpouse">Finalidad</h2>
    <p>Registro, gestión y servicio de envío de archivos de gran capacidad a través de nuestra web a terceros.</p>
    <h2 id="legitimation">Legitimación</h2>
    <p>Consentimiento expreso del usuario a través de la selección de la casilla check de aceptación del registro donde verifica su consentimiento.</p>
    <h2 id="recipient">Destinatarios</h2>
    <p>No existe ninguna cesión de datos prevista, salvo obligación legal.</p>
    <h2 id="rights">Derechos</h2>
    <span>Podrá ejercitar los derechos de acceso, rectificación, supresión, oposición, portabilidad y retirada de consentimiento de sus datos personales en la dirección de correo electrónico <a class="link" href="mailto:privacidad@ydray.com" target="_blank" title="Enviar email a privacidad@ydray.com">privacidad@ydray.com</a></span>
    <h2 id="origin">Procedencia</h2>
    <p>El propio interesado</p>
    `,
  },
};
