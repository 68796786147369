import { useCallback, useMemo, useState } from "react";
import { errorToast, warnToast } from "../components/toast";
import { useTranslation } from "react-i18next";
import { logError, logWarn } from "../utils/logger";
import { equalsIgnoringCase } from "../utils/validators";
import { isEmpty } from "@rpldy/shared";

const SERVER_STATUS = {
  ONLINE: "online",
  MAINTENANCE: "maintenance",
};

const useInit = () => {
  const { t } = useTranslation();

  const [loadingS, setLoadingS] = useState(true);
  const [dataS, setDataS] = useState(null);
  const [errorS, setErrorS] = useState(null);

  const [loadingU, setLoadingU] = useState(true);
  const [dataU, setDataU] = useState(null);
  const [errorU, setErrorU] = useState(null);

  const [loadingF, setLoadingF] = useState(true);
  const [dataF, setDataF] = useState(null);
  const [errorF, setErrorF] = useState(null);

  const infoURL = useMemo(() => {
    return `https://${process.env.REACT_APP_API}/info/`;
  }, []);

  const userURL = useMemo(() => {
    return `https://${process.env.REACT_APP_API}/user/`;
  }, []);

  const filesURL = useCallback((server) => {
    return `https://${server}.${process.env.REACT_APP_DOMAIN}/fileupload/`;
  }, []);

  const config = useMemo(() => {
    return {
      method: "GET",
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
  }, []);

  const isServerOnline = useCallback((status) => {
    return equalsIgnoringCase(status, SERVER_STATUS.ONLINE);
  }, []);

  const isServerMaintenance = useCallback((status) => {
    return equalsIgnoringCase(status, SERVER_STATUS.MAINTENANCE);
  }, []);

  const infoHandler = useCallback(async () => {
    const response = await fetch(infoURL, config);
    const json = await response.json();
    return json;
  }, [infoURL, config]);

  const userHandler = useCallback(async () => {
    const response = await fetch(userURL, config);
    const json = await response.json();
    return json;
  }, [userURL, config]);

  const filesHandler = useCallback(
    async (server) => {
      const response = await fetch(filesURL(server), config);
      const json = await response.json();
      return json;
    },
    [filesURL, config]
  );

  const initHandler = useCallback(async () => {
    try {
      // Get Info
      setLoadingS(true);
      const infoResponse = await infoHandler();
      if (!isEmpty(infoResponse)) {
        setErrorS(infoResponse.error);
        delete infoResponse.error;
        setDataS(infoResponse);
      }
      setLoadingS(false);
      // Check Server Status
      if (!isEmpty(infoResponse)) {
        if (isServerOnline(infoResponse.status)) {
          // Get User
          setLoadingU(true);
          const userResponse = await userHandler();
          if (!isEmpty(userResponse)) {
            setErrorU(userResponse.error);
            delete userResponse.error;
            setDataU(userResponse);
          }
          setLoadingU(false);
          // Get Files
          setLoadingF(true);
          const filesResponse = await filesHandler(infoResponse.server);
          if (!isEmpty(filesResponse)) {
            setErrorF(filesResponse.error);
            delete filesResponse.error;
            setDataF(filesResponse.files);
          }
          setLoadingF(false);
        } else if (isServerMaintenance(infoResponse.status)) {
          logWarn("useInit", "initHandler", {
            error: "server_maintenance",
            msg: t("errors.server_maintenance", t("errors.default")),
          });
          warnToast(
            "useInit",
            "server_maintenance",
            t("errors.server_maintenance", t("errors.default"))
          );
        } else {
          logError("useInit", "initHandler", {
            error: "unknown_server_status",
            status: infoResponse.status,
            msg: t("errors.unknown_server_status", t("errors.default")),
          });
          errorToast(
            "useInit",
            "unknown_server_status",
            t("errors.unknown_server_status", t("errors.default"))
          );
        }
      }
    } catch (error) {
      logError("useInit", "initHandler", {
        error: error,
        msg: t(`errors.${error}`, t("errors.default")),
      });
      errorToast(
        "useInit",
        "initHandler",
        t(`errors.${error}`, t("errors.default"))
      );
    }
  }, [
    isServerOnline,
    isServerMaintenance,
    infoHandler,
    userHandler,
    filesHandler,
    t,
  ]);

  return {
    loading: {
      settings: loadingS,
      user: loadingU,
      files: loadingF,
    },
    data: {
      settings: dataS,
      user: dataU,
      files: dataF,
    },
    error: {
      settings: errorS,
      user: errorU,
      files: errorF,
    },
    initHandler,
  };
};

export default useInit;
export { SERVER_STATUS };
