import { FILE_STATES } from "@rpldy/uploady";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import Button from "../../button";
import Divider from "../../divider";
import ProgressBar from "../../progress-bar";
import { useCallback, useMemo } from "react";
import { START_WITH_FILE } from "../utils/config";

function FileItem({ item, detail, showRetry, onRetry, showAbort, onAbort }) {
  const { t } = useTranslation();

  const getIcon = useCallback(
    (state) => {
      return {
        "icon-empty-page":
          [
            FILE_STATES.ADDED,
            FILE_STATES.PENDING,
            FILE_STATES.UPLOADING,
          ].includes(state) || detail,
        "icon-warning-circled-outline": [
          FILE_STATES.ABORTED,
          FILE_STATES.CANCELLED,
          FILE_STATES.ERROR,
        ].includes(state),
        "icon-check": state === FILE_STATES.FINISHED,
      };
    },
    [detail]
  );

  const getColorIcon = useCallback(
    (state) => {
      return {
        warning: false,
        info: [
          FILE_STATES.ADDED,
          FILE_STATES.PENDING,
          FILE_STATES.UPLOADING,
        ].includes(state),
        error: [
          FILE_STATES.ABORTED,
          FILE_STATES.ERROR,
          FILE_STATES.CANCELLED,
        ].includes(state),
        success: state === FILE_STATES.FINISHED,
        detail: detail,
      };
    },
    [detail]
  );

  const getType = useCallback(
    (state) => {
      let type = "";
      switch (state) {
        case FILE_STATES.PENDING:
        case FILE_STATES.ADDED:
        case FILE_STATES.UPLOADING:
          type = "info";
          break;
        case FILE_STATES.FINISHED:
          type = detail ? "detail" : "success";
          break;
        case FILE_STATES.ABORTED:
        case FILE_STATES.ERROR:
        case FILE_STATES.CANCELLED:
          type = "error";
          break;
        default:
          type = "";
          break;
      }
      return type;
    },
    [detail]
  );

  const fileName = useMemo(() => {
    return item.file.name.replace(START_WITH_FILE, "");
  }, [item.file.name]);

  return (
    <div className="file-item-container">
      <div className="row">
        <div className="file-item__label">
          <span
            className={classNames(
              "file-item__icon",
              getIcon(item.state),
              getColorIcon(item.state)
            )}
          ></span>
          <span className="ellipsis__container" title={fileName}>
            <span className="ellipsis__value">{fileName}</span>
          </span>
        </div>
        <div className="file-item__actions">
          {!detail && showRetry && (
            <Button secondary icon onClick={(evt) => onRetry(item)}>
              <span
                className="icon icon-refresh-double"
                title={t("button.reupload")}
              ></span>
              <span className="label" title={t("button.reupload")}>
                {t("button.reupload")}
              </span>
            </Button>
          )}
          {!detail && showAbort && (
            <Button secondary icon onClick={(evt) => onAbort(item)}>
              <span
                className="icon icon-delete-circled-outline"
                title={t("button.cancel")}
              ></span>
              <span className="label" title={t("button.cancel")}>
                {t("button.cancel")}
              </span>
            </Button>
          )}
        </div>
      </div>
      <div className="row">
        {!detail && (
          <ProgressBar type={getType(item.state)} value={item.completed} />
        )}
        {detail && <Divider />}
      </div>
    </div>
  );
}

export default FileItem;
