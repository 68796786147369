import { useEffect, useMemo } from "react";

import detectBrowserLanguage from "detect-browser-language";

import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import useGetLocalePath from "../hooks/useGetLocalePath";

import DownloaderComponent from "../components/downloader-component";

import { isEmpty } from "../utils/validators";

function Downloader() {
  const { t, i18n } = useTranslation();

  const { id } = useParams();
  const navigate = useNavigate();
  const { getLocalePath } = useGetLocalePath();

  const localeLng = useMemo(() => {
    const userLocale = detectBrowserLanguage();
    let locale = userLocale.split("-")[0];
    if (!isEmpty(locale)) {
      if (!i18n.languages.includes(locale)) {
        locale = "en";
      }
    } else {
      locale = "en";
    }
    return locale;
  }, [i18n.languages]);

  useEffect(() => {
    i18n.changeLanguage(localeLng);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isEmpty(id)) {
      navigate(getLocalePath(t("routes.common.home")));
    }
  });

  return <DownloaderComponent transferId={id} />;
}

export default Downloader;
