import classNames from "classnames";

function PlanCard({ plan, icon, title, button, content }) {
  return (
    <div className={classNames("plan-card__container", { [plan]: plan })}>
      <div className="plan-card__container__header">
        {icon}
        {title}
        {button}
      </div>
      <div className="plan-card__container__body">{content}</div>
      <div className="plan-card__container__footer"></div>
    </div>
  );
}

export default PlanCard;
