import { useEffect, useMemo } from "react";

import classNames from "classnames";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useGetUserSubscriptionStatus from "../../hooks/useGetUserSubscriptionStatus";

import { isEmpty } from "../../utils/validators";
import { logDebug, logError } from "../../utils/logger";

function AdsComponent({ dataAdSlot }) {
  const { ui } = useSelector((state) => state);

  const { t } = useTranslation();

  const { isUnregisteredUser, isFreeUser } = useGetUserSubscriptionStatus();

  const isAllowedAds = useMemo(() => {
    return ui.show_ads && (isUnregisteredUser || isFreeUser);
  }, [isFreeUser, isUnregisteredUser, ui.show_ads]);

  useEffect(() => {
    try {
      if (isAllowedAds) {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
        logDebug("AdsComponent", "adsStatusHandler", {
          adsbygoogle: !isEmpty(window.adsbygoogle) ? window.adsbygoogle : null,
          googletag: !isEmpty(window.googletag) ? window.googletag : null,
          value: isAllowedAds,
          msg: t("message.default"),
        });
      } else {
        logDebug("AdsComponent", "adsStatusHandler", {
          value: isAllowedAds,
          msg: t("message.ads_not_allowed"),
        });
      }
    } catch (error) {
      logError("AdsComponent", "adsbygoogle", {
        error: error,
        msg: t("errors.default"),
      });
    }
  }, [isAllowedAds, t]);

  return (
    <div className={classNames("ads-container")}>
      {isAllowedAds && (
        <ins
          className="adsbygoogle"
          style={{ display: "inline-block", width: "280px", height: "80px" }}
          data-ad-client={process.env.REACT_APP_G_AD_CLIENT}
          data-ad-slot={dataAdSlot}
        ></ins>
      )}
    </div>
  );
}

export default AdsComponent;
