import thunk from "redux-thunk";
import { combineReducers } from "redux";
import { persistStore } from "redux-persist";
import { configureStore } from "@reduxjs/toolkit";

import {
  uiReducer,
  personalReducer,
  passwordReducer,
  contactReducer,
  transfersTableReducer,
  settingsReducer,
  userReducer,
  uploaderReducer,
  consentPrivacyReducer,
} from "../reducers";

const combinedReducers = combineReducers({
  // State Storage
  ui: uiReducer,
  personal: personalReducer,
  password: passwordReducer,
  contact: contactReducer,
  transfersTable: transfersTableReducer,
  // Session Storage
  settings: settingsReducer,
  // Local Storage
  user: userReducer,
  uploader: uploaderReducer,
  consentPrivacy: consentPrivacyReducer,
});

const store = configureStore({
  reducer: combinedReducers,
  middleware: [thunk],
  devTools: true,
  trace: true,
});

export default store;
export const persistor = persistStore(store);
