// import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import { Menu, MenuItem, MenuDivider } from "@szhsin/react-menu";
import classNames from "classnames";

function FloatingMenu(props) {
  const { containerRef, menuButton, align, menuItems, menuItemClick } = props;
  return (
    <Menu
      boundingBoxRef={containerRef}
      menuButton={menuButton}
      gap={10}
      align={align}
      position="anchor"
      viewScroll="close"
    >
      {menuItems.map((menuItem, idx) => {
        return (
          <>
            <MenuItem
              key={menuItem.value}
              value={menuItem.value}
              disabled={menuItem.disabled}
              onClick={menuItemClick}
            >
              <div className="szh-menu__item__container">
                <span
                  className={classNames("icon", {
                    [menuItem.icon]: menuItem.icon,
                  })}
                ></span>
                <span className="label">{menuItem.label}</span>
              </div>
            </MenuItem>
            {idx < menuItems.length - 1 && <MenuDivider />}
          </>
        );
      })}
    </Menu>
  );
}

export default FloatingMenu;
