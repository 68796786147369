import { sendEventGA4 } from "../index";

const loginUserMethods = {
  EMAIL: "email",
};

/**
 * This event indicates that a user has logged in.
 *
 * @param {string} method How this user is logged in.
 *
 * @see Google Analytics Documentation - https://developers.google.com/analytics/devguides/collection/ga4/reference/events?sjid=10165854985360166638-EU&client_type=gtag#login
 */
const trackLoginUser = (method) => {
  const name = "login";
  const params = {
    method: method,
  };
  sendEventGA4(name, params);
};

const signUpUserMethods = {
  EMAIL: "email",
};

/**
 * This event indicates that a user has signed up for an account.
 *
 * @param {string} method How this user is signed up.
 *
 * @see Google Analytics Documentation - https://developers.google.com/analytics/devguides/collection/ga4/reference/events?sjid=10165854985360166638-EU&client_type=gtag#sign_up
 */
const trackSignUpUser = (method) => {
  const name = "sign_up";
  const params = {
    method: method,
  };
  sendEventGA4(name, params);
};

export { loginUserMethods, signUpUserMethods };
export { trackLoginUser, trackSignUpUser };
