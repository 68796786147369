import { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import SEO from "../components/seo";
import LandingFooter from "../components/landing/landing-footer";
import useGetLocalePath from "../hooks/useGetLocalePath";

function LegalNotice() {
  const { t, i18n } = useTranslation();
  const { getLocalePath } = useGetLocalePath();

  const legal_container_ref = useRef(null);

  useEffect(() => {
    legal_container_ref?.current?.scrollIntoView({
      block: "start",
      inline: "start",
      behavior: "smooth",
    });
  }, []);

  const titleSEO = useMemo(() => {
    return `${t("app.name")} - ${t("legal_warn.title")}`;
  }, [t]);

  const descriptionSEO = useMemo(() => {
    return t("legal_warn.description");
  }, [t]);

  const urlSEO = useMemo(() => {
    return `${process.env.REACT_APP_PUBLIC_URL}${getLocalePath(
      t("routes.public.legal")
    )}`;
  }, [getLocalePath, t]);

  const altLinksSEO = useMemo(() => {
    const publicURL = process.env.REACT_APP_PUBLIC_URL;
    const result = i18n.languages.map((lng) => {
      return {
        lng: lng,
        url: `${publicURL}/${lng}/${t("routes.public.legal", { lng: lng })}`,
      };
    });

    const defaultLng = result.find((url) => url.lng === "en");
    result.push({
      lng: "x-default",
      url: defaultLng.url,
    });

    return result;
  }, [i18n.languages, t]);

  return (
    <div>
      <SEO
        title={titleSEO}
        description={descriptionSEO}
        url={urlSEO}
        canonical={urlSEO}
        altLinks={altLinksSEO}
      />
      <div ref={legal_container_ref}>
        <div className="legal__header"></div>
        <div className="legal__body">
          <div
            className="legal__content"
            dangerouslySetInnerHTML={{
              __html: t("legal_warn.content", {
                interpolation: { escapeValue: true },
              }),
            }}
          ></div>
        </div>
        <div className="legal__footer">
          <LandingFooter />
        </div>
      </div>
    </div>
  );
}

export default LegalNotice;
