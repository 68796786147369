/**
 * Function that validates a value is empty
 *
 * @param {string} value Value to validate
 * @returns True if value is empty, false otherwise
 */
export const isEmpty = (value) => {
  return value === undefined || value === null || value === "";
};

/**
 * Function that validates an object is empty
 *
 * @param {Object} value Object value to validate
 * @returns True if value is empty, false otherwise
 */
export const isEmptyObject = (value) => {
  return isEmpty(value) || Object.keys(value).length === 0;
};

/**
 * Function that validates a array is empty
 *
 * @param {Array} value Value to validate
 * @returns True if value is empty, false otherwise
 */
export const isEmptyList = (value) => {
  return (
    isEmpty(value) || (Array.isArray(value) && Array.from(value).length === 0)
  );
};

/**
 * Function that validates an email
 *
 * @param {string} value Value to validate
 * @returns True if value is ok, false otherwise
 */
export const isValidEmail = (value) => {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);
};

/**
 * Function that validate the short of a String
 *
 * @param {string} value Value to validate
 * @param {number} count Number of characters to compare
 * @returns True if value is ok, false otherwise
 */
export const isTooShort = (value, count) => {
  return (
    typeof value === "string" && !isEmpty(value) && String(value).length < count
  );
};

/**
 * Function that validate the long of a String
 *
 * @param {string} value Value to validate
 * @param {number} count Number of characters to compare
 * @returns True if value is ok, false otherwise
 */
export const isTooLong = (value, count) => {
  return (
    typeof value === "string" && !isEmpty(value) && String(value).length > count
  );
};

/**
 * Function that validate that two string are the same ignoring case
 *
 * @param {string} value1 First value to validate
 * @param {string} value2 Second value to validate
 * @returns True is two values are tue same, false otherwhise
 */
export const equalsIgnoringCase = (value1, value2) => {
  if (!isEmpty(value1) && !isEmpty(value2)) {
    return value1.toUpperCase().localeCompare(value2.toUpperCase()) === 0;
  } else {
    return value1 === value2;
  }
};

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param locale Locale code to use when formatting
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export function humanFileSize(bytes, locale, si = false, dp = 1) {
  const formatter = Intl.NumberFormat(locale, {
    style: "decimal",
    maximumSignificantDigits: dp,
    roundingMode: "halfTrunc",
  });

  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return `${formatter.format(bytes)} B`;
  }

  const units = si
    ? ["KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return `${formatter.format(bytes.toFixed(dp))} ${units[u]}`;
}

/**
 * Return decimal separator
 *
 * @return decimal separator.
 */
export function getDecimalSeparator(locale) {
  const number = 1.1;
  return Intl.NumberFormat(locale)
    .formatToParts(number)
    .find((part) => part.type === "decimal").value;
}

/**
 * Return thousand separator
 *
 * @return thousand separator.
 */
export function getThousandSeparator(locale) {
  const number = 1000.1;
  return Intl.NumberFormat(locale)
    .formatToParts(number)
    .find((part) => part.type === "group").value;
}
