import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import createMigrate from "redux-persist/es/createMigrate";
import storageSession from "redux-persist/lib/storage/session";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";

import consentPrivacyReducer, {
  currentVersion as consentPrivacyCurrentVersion,
  migrations as consentPrivacyMigrations,
} from "./consentPrivacyReducer";

import userReducer, {
  currentVersion as userCurrentVersion,
  migrations as userMigrations,
} from "./userReducer";

import settingsReducer, {
  currentVersion as settingCurrentVersion,
  migrations as settingMigrations,
} from "./settingsReducer";

import uploaderReducer, {
  currentVersion as uploaderCurrentVersion,
  migrations as uploaderMigrations,
} from "./uploaderReducer";

import uiReducer from "./uiReducer";
import personalReducer from "./personalReducer";
import passwordReducer from "./passwordReducer";
import customizeReducer from "./customizeReducer";
import contactReducer from "./contactReducer";
import transfersTableReducer from "./transfersTableReducer";
import billingTableReducer from "./billingTableReducer";

const persistedConsentMsgReducer = persistReducer(
  {
    key: "consentPrivacy",
    storage: storage,
    whitelist: ["consentPrivacy"],
    version: consentPrivacyCurrentVersion,
    migrate: createMigrate(consentPrivacyMigrations, {
      debug: ["DEVELOPMENT"].includes(process.env.REACT_APP_ENV),
    }),
    stateReconciler: autoMergeLevel2,
    debug: ["DEVELOPMENT"].includes(process.env.REACT_APP_ENV),
  },
  consentPrivacyReducer
);

const persistedUserReducer = persistReducer(
  {
    key: "user",
    storage: storage,
    whitelist: ["user"],
    version: userCurrentVersion,
    migrate: createMigrate(userMigrations, {
      debug: ["DEVELOPMENT"].includes(process.env.REACT_APP_ENV),
    }),
    stateReconciler: autoMergeLevel2,
    debug: ["DEVELOPMENT"].includes(process.env.REACT_APP_ENV),
  },
  userReducer
);

const persistedSettingsReducer = persistReducer(
  {
    key: "settings",
    storage: storageSession,
    whitelist: ["settings"],
    version: settingCurrentVersion,
    migrate: createMigrate(settingMigrations, {
      debug: ["DEVELOPMENT"].includes(process.env.REACT_APP_ENV),
    }),
    stateReconciler: autoMergeLevel2,
    debug: ["DEVELOPMENT"].includes(process.env.REACT_APP_ENV),
  },
  settingsReducer
);

const persistedUploaderReducer = persistReducer(
  {
    key: "uploader",
    storage: storage,
    whitelist: ["uploader"],
    version: uploaderCurrentVersion,
    migrate: createMigrate(uploaderMigrations, {
      debug: ["DEVELOPMENT"].includes(process.env.REACT_APP_ENV),
    }),
    stateReconciler: autoMergeLevel2,
    debug: ["DEVELOPMENT"].includes(process.env.REACT_APP_ENV),
  },
  uploaderReducer
);

export {
  persistedConsentMsgReducer as consentPrivacyReducer,
  persistedSettingsReducer as settingsReducer,
  persistedUserReducer as userReducer,
  persistedUploaderReducer as uploaderReducer,
  uiReducer,
  personalReducer,
  passwordReducer,
  customizeReducer,
  contactReducer,
  transfersTableReducer,
  billingTableReducer,
};
