import { useTranslation } from "react-i18next";
import Media from "react-media";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { isEmpty } from "../../../utils/validators";
import { useInView } from "react-intersection-observer";
import useGetLocalePath from "../../../hooks/useGetLocalePath";

function LandingHeader() {
  const { t } = useTranslation();
  const { getLocalePath } = useGetLocalePath();

  const { ref: aboutRef, inView: aboutVisible } = useInView();
  useEffect(() => {
    if (aboutVisible) {
      const headerAboutLink = document.getElementById("header-about-link");
      if (!isEmpty(headerAboutLink)) {
        headerAboutLink.classList.toggle("active", true);
      }
      const footerAboutLink = document.getElementById("footer-about-link");
      if (!isEmpty(footerAboutLink)) {
        footerAboutLink.classList.toggle("active", true);
      }
      const headerPlansLink = document.getElementById("header-plans-link");
      if (!isEmpty(headerPlansLink)) {
        headerPlansLink.classList.toggle("active", false);
      }
      const footerPlansLink = document.getElementById("footer-plans-link");
      if (!isEmpty(footerPlansLink)) {
        footerPlansLink.classList.toggle("active", false);
      }
    } else {
      const headerAboutLink = document.getElementById("header-about-link");
      if (!isEmpty(headerAboutLink)) {
        headerAboutLink.classList.toggle("active", false);
      }
      const footerAboutLink = document.getElementById("footer-about-link");
      if (!isEmpty(footerAboutLink)) {
        footerAboutLink.classList.toggle("active", false);
      }
      const headerPlansLink = document.getElementById("header-plans-link");
      if (!isEmpty(headerPlansLink)) {
        headerPlansLink.classList.toggle("active", false);
      }
      const footerPlansLink = document.getElementById("footer-plans-link");
      if (!isEmpty(footerPlansLink)) {
        footerPlansLink.classList.toggle("active", false);
      }
    }
  }, [aboutVisible]);

  return (
    <div ref={aboutRef} id="about" className="landing__header">
      <div className="landing__header__hero">
        <div className="landing__header__hero__content">
          <div className="landing__header__hero__logo">
            <img
              src="../../../assets/images/folder-file-graphic.svg"
              alt={t("landing.hero.img.alt")}
              title={t("landing.hero.img.title")}
              loading="eager"
              width="auto"
              height="auto"
            ></img>
          </div>
          <div className="landing__header__hero__title">
            <h1>{t("landing.hero.title")}</h1>
          </div>
          <div className="landing__header__hero__subtitle">
            <p>{t("landing.hero.subtitle")}</p>
          </div>
          <div className="landing__header__hero__buttons">
            <Media query="(max-width: 576px)">
              <Link
                to={getLocalePath(t("routes.public.uploader"))}
                title={t("uploader_page.title")}
              >
                <button className="button button-primary">
                  <div className="content">
                    <span className="label-title">
                      {t("landing.hero.button.files.title")}
                    </span>
                    <span className="label-subtitle">
                      {t("landing.hero.button.files.subtitle")}
                    </span>
                  </div>
                </button>
              </Link>
            </Media>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingHeader;
