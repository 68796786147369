import { useCallback, useMemo } from "react";

import classNames from "classnames";

import { useTranslation } from "react-i18next";

import Select from "react-select";

import cities from "./cities.json";
import { isEmpty, isEmptyList } from "../../utils/validators";

function SelectCityField(props) {
  const {
    name,
    label,
    placeHolder,
    loading,
    required,
    disabled,
    clearable,
    searchable,
    state_id,
    country_id,
    value,
    error,
    onChange,
    register,
  } = props;

  const { t } = useTranslation();

  const disabledMemo = useMemo(() => {
    return disabled || country_id <= 0 || state_id <= 0;
  }, [disabled, country_id, state_id]);

  const options = useMemo(() => {
    let result = [];
    if (!isEmptyList(cities)) {
      if (country_id > 0 && state_id > 0) {
        result = cities
          .filter(
            (city) =>
              city.country_id === country_id && city.state_id === state_id
          )
          .map((city) => {
            return {
              id: city.id,
              name: city.name,
              label: city.name,
              value: city.name,
            };
          })
          .sort((a, b) => a.label.localeCompare(b.label));
      }
    }
    return result;
  }, [country_id, state_id]);

  const onChangeHandler = useCallback(
    (evt) => {
      !disabledMemo && onChange && onChange(evt);
    },
    [disabledMemo, onChange]
  );

  const selectedOption = useMemo(() => {
    if (!isEmpty(value) && value.id > 0) {
      const idx = options.findIndex((c) => value.value === c.value);
      return options[idx];
    }
    return null;
  }, [options, value]);

  return (
    <div className="select-city-field__container">
      <div className="select-city-field__content">
        {label && (
          <label htmlFor={name} className="select-city-field__label smaller">
            {`${label}${required ? " *" : ""}`}
          </label>
        )}
        <Select
          {...register}
          id={name}
          name={name}
          placeholder={placeHolder}
          noOptionsMessage={() => t("placeholder.empty_list")}
          className="select-city-field__input"
          classNamePrefix="select-city-field"
          options={options}
          value={selectedOption}
          isLoading={loading}
          isDisabled={disabledMemo}
          isClearable={clearable}
          isSearchable={searchable}
          maxMenuHeight={175}
          menuPlacement="auto"
          menuShouldScrollIntoView={true}
          onChange={(evt) => onChangeHandler(evt)}
        />
        <p className={classNames("select-city-field__error", { show: error })}>
          {error}
        </p>
      </div>
    </div>
  );
}

export default SelectCityField;
export { SelectCityField, cities };
