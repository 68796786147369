import { useCallback, useMemo } from "react";

import classNames from "classnames";

import { Ring } from "@uiball/loaders";

function Button(props) {
  const {
    type = "button",
    clazzes = [],
    children,
    primary = false,
    secondary = false,
    pro = false,
    share = false,
    text = false,
    icon = false,
    disabled = false,
    onClick,
    onDoubleClick,
    loading = false,
  } = props;

  const getClasess = useMemo(() => {
    return clazzes.join(", ");
  }, [clazzes]);

  const disabledButton = useMemo(() => {
    return disabled || loading;
  }, [disabled, loading]);

  const onClickBtn = useCallback(
    (evt) => {
      if (!disabledButton && onClick) {
        onClick(evt);
      }
    },
    [disabledButton, onClick]
  );

  const onDoubleClickBtn = useCallback(
    (evt) => {
      if (!disabledButton && onDoubleClick) {
        onDoubleClick(evt);
      }
    },
    [disabledButton, onDoubleClick]
  );

  return (
    <div className="button-container">
      <button
        type={type}
        className={classNames(
          "button",
          {
            "button-primary": primary,
            "button-secondary": secondary,
            "button-text": text,
            "button-icon": icon,
            "button-pro": pro,
            "button-share": share,
            "button-disabled": disabled,
            "button-loading": loading,
          },
          [...getClasess]
        )}
        disabled={disabledButton}
        onClick={(evt) => onClickBtn(evt)}
        onDoubleClick={(evt) => onDoubleClickBtn(evt)}
      >
        {loading && <Ring size={20} lineWeight={7} speed={2} color="white" />}
        {children}
      </button>
    </div>
  );
}

export default Button;
