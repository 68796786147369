import { useCallback, useEffect, useMemo, useState } from "react";
import { isEmpty } from "../../utils/validators";
import classNames from "classnames";

function PasswordField(props) {
  const {
    label,
    autoComplete,
    disabled,
    hidden,
    required,
    value,
    error,
    register,
    onChange,
  } = props;
  const { name, ref } = register;

  const [isActive, setIsActive] = useState(false);
  const [showPass, setShowPass] = useState(false);

  const type = useMemo(() => {
    return showPass ? "text" : "password";
  }, [showPass]);

  const onChangeHandler = useCallback(
    (evt) => {
      onChange && onChange(evt);
    },
    [onChange]
  );

  useEffect(() => {
    setIsActive(!isEmpty(value));
  }, [value]);

  return (
    <div
      className={classNames("password-field__container", {
        unlabelled: isEmpty(label),
        hidden: hidden,
        disabled: disabled,
      })}
    >
      <div className="password-field__content">
        <input
          type={type}
          id={name}
          name={name}
          ref={ref}
          className="password-field__input"
          disabled={disabled}
          hidden={hidden}
          required={required}
          autoComplete={autoComplete}
          onChange={(evt) => onChangeHandler(evt)}
          {...register}
        ></input>
        {label && (
          <label
            htmlFor={name}
            className={classNames("password-field__label", "smaller", {
              active: isActive,
            })}
          >
            {`${label}${required ? " *" : ""}`}
          </label>
        )}
        <span
          className={classNames("password-field__switch-type", {
            "icon-eye-off": showPass,
            "icon-eye-empty": !showPass,
          })}
          onClick={() => setShowPass(!showPass)}
        ></span>
      </div>
      <p
        className={classNames("password-field__error", { show: error })}
        title={error}
      >
        {error}
      </p>
    </div>
  );
}

export default PasswordField;
