import { useCallback, useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useSendContact from "../../hooks/useSendContact";
import useWindowSize from "../../hooks/useWindowSize";
import { yupResolver } from "@hookform/resolvers/yup";
import getContactSchema from "../../schemas/contactSchema";
import Button from "../button";
import Checkbox from "../checkbox";
import PhoneField from "../phone-field";
import TextAreaField from "../text-area-field";
import TextField from "../text-field";
import DropdownField from "../dropdown-field";
import { errorToast, successToast } from "../toast";
import { setShowContactDialog } from "../../redux/reducers/uiReducer";
import { setBlur } from "../../redux/reducers/uiReducer";
import { isEmpty } from "../../utils/validators";
import { logDebug } from "../../utils/logger";

function ContactForm(props) {
  const { recaptcha } = props;

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const windowSize = useWindowSize();
  const { ui } = useSelector((state) => state);
  const { user } = useSelector((state) => state.user);

  const reasonOpts = useMemo(() => {
    return Object.entries(t("contact.form.reasons")).map((entry) => {
      return { value: entry[1], label: entry[1] };
    });
  }, [t]);

  const url = useMemo(() => {
    return window.location.href;
  }, []);

  const resolution = useMemo(() => {
    return `${window.screen.width}x${window.screen.height}`;
  }, []);

  const textAreaStyle = useMemo(() => {
    return {
      height: `${windowSize.width >= 576 ? 55 : 25}px`,
    };
  }, [windowSize.width]);

  const name = useMemo(() => {
    return !isEmpty(user) && user.logged_in && !isEmpty(user.name)
      ? user.name
      : "";
  }, [user]);

  const email = useMemo(() => {
    return !isEmpty(user) && user.logged_in && !isEmpty(user.email)
      ? user.email
      : "";
  }, [user]);

  const phone = useMemo(() => {
    return !isEmpty(user) && user.logged_in && !isEmpty(user.phone)
      ? user.phone
      : "";
  }, [user]);

  const reason = useMemo(() => {
    return !isEmpty(ui.contact_reason) ? ui.contact_reason : "";
  }, [ui.contact_reason]);

  const contactSchema = getContactSchema(t);
  const {
    register,
    reset,
    control,
    watch,
    getValues,
    setValue,
    handleSubmit,
    formState: { isSubmitSuccessful, errors },
  } = useForm({
    defaultValues: {
      name: name,
      email: email,
      email2: "",
      tlf: phone,
      reason: reason,
      message: "",
      "g-recaptcha-response": "",
      consent: false,
      url: url,
      resolution: resolution,
    },
    mode: "all",
    criteriaMode: "all",
    resolver: yupResolver(contactSchema),
  });

  const changeRecaptcha = useCallback(
    (token) => {
      setValue("g-recaptcha-response", token);
      recaptcha.ref.reset();
    },
    [recaptcha, setValue]
  );

  const resetRecaptcha = useCallback(() => {
    setValue("g-recaptcha-response", "");
    recaptcha.ref.reset();
  }, [recaptcha, setValue]);

  const { loading, data, error, sendContactHandler } = useSendContact();
  useEffect(() => {
    if (!loading) {
      if (error) {
        errorToast(
          "useSendContact",
          error,
          t(`errors.${error}`, t("errors.default"))
        );
      } else if (!error && isSubmitSuccessful) {
        reset({
          name: name,
          email: email,
          email2: "",
          tlf: phone,
          reason: reason,
          message: "",
          "g-recaptcha-response": "",
          consent: false,
          url: url,
          resolution: resolution,
        });
        resetRecaptcha();
        dispatch(setShowContactDialog(!ui.show_contact_dialog));
        dispatch(setBlur(!ui.is_blur));
        successToast("useSendContact", "message.default", t("message.default"));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, data, error]);

  const onSubmit = async (data, evt) => {
    if (recaptcha.loaded) {
      const token = !isEmpty(getValues("g-recaptcha-response"))
        ? getValues("g-recaptcha-response")
        : await recaptcha.ref.executeAsync();

      if (!isEmpty(token)) {
        recaptcha.ref.reset();
        setValue("g-recaptcha-response", token);
        logDebug("ContactForm", "onSubmit", { data: data, evt: evt });
        sendContactHandler(getValues());
      }
    }
  };

  const onError = (errors, evt) => {
    logDebug("ContactForm", "onError", { errors: errors, evt: evt });
    resetRecaptcha();
    errorToast("onError", errors, t("errors.error_form", t("errors.default")));
  };

  return (
    <div className="contact-form__container">
      <form
        id="contact_form"
        className="contact-form__form"
        method="post"
        noValidate
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        <TextField
          type="text"
          label={t("contact.form.name")}
          autoComplete="name"
          value={watch("name")}
          register={register("name", {
            ...contactSchema.name,
            onChange: (evt) => setValue("name", evt.target.value),
            onBlur: (evt) => setValue("name", evt.target.value),
          })}
          error={errors?.name?.message}
          disabled={loading}
          required
        />
        <TextField
          type="email"
          label={t("contact.form.email")}
          autoComplete="email"
          value={watch("email")}
          register={register("email", {
            ...contactSchema.email,
            onChange: (evt) => setValue("email", evt.target.value),
            onBlur: (evt) => setValue("email", evt.target.value),
          })}
          error={errors?.email?.message}
          disabled={loading}
          required
        />
        <TextField
          type="email"
          label={t("contact.form.email2")}
          autoComplete="email2"
          value={watch("email2")}
          register={register("email2", {
            ...contactSchema.email2,
            onChange: (evt) => setValue("email2", evt.target.value),
            onBlur: (evt) => setValue("email2", evt.target.value),
          })}
          error={errors?.email2?.message}
          disabled={loading}
          required
        />
        <Controller
          name="tlf"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <PhoneField
              label={t("contact.form.phone")}
              value={watch("tlf")}
              disabled={loading}
              error={errors?.tlf?.message}
              onChangeHandler={(phone) => {
                setValue("tlf", phone);
              }}
              {...field}
            />
          )}
        />
        <Controller
          name="reason"
          control={control}
          defaultValue={""}
          render={({ field }) => (
            <DropdownField
              options={reasonOpts}
              value={watch("reason")}
              placeHolder={t("contact.form.reasonph")}
              onChange={(value) => setValue("reason", value)}
              disabled={loading}
              required
              error={errors?.reason?.message}
              {...field}
            />
          )}
        />
        <TextAreaField
          label={t("contact.form.message")}
          style={textAreaStyle}
          value={watch("message")}
          register={register("message", {
            ...contactSchema.message,
            onChange: (evt) => setValue("message", evt.target.value),
            onBlur: (evt) => setValue("message", evt.target.value),
          })}
          error={errors?.message?.message}
          disabled={loading}
          required
        />
        <Checkbox
          label={t("contact.form.consentLb")}
          value={watch("consent")}
          register={register("consent", {
            ...contactSchema.consent,
            onChange: (evt) =>
              setValue("consent", Boolean(getValues("consent"))),
            onBlur: (evt) => setValue("consent", Boolean(getValues("consent"))),
          })}
          error={errors?.consent?.message}
        />
        <Controller
          name="g-recaptcha-response"
          control={control}
          defaultValue=""
          onChange={(token) => changeRecaptcha(token)}
          onExpired={() => resetRecaptcha()}
          onError={() => resetRecaptcha()}
          render={({ field }) => {
            <input
              hidden
              value={getValues("g-recaptcha-response")}
              {...field}
            />;
          }}
        />
        <Controller
          name="resolution"
          control={control}
          defaultValue=""
          render={({ field }) => {
            <input hidden value={getValues("resolution")} {...field} />;
          }}
        />
        <Controller
          name="url"
          control={control}
          defaultValue=""
          render={({ field }) => {
            <input hidden value={getValues("url")} {...field} />;
          }}
        />
        <Button
          type="submit"
          primary
          text
          loading={loading}
          disabled={!watch("consent")}
        >
          <span className="label">{t("button.send")}</span>
        </Button>
      </form>
    </div>
  );
}

export default ContactForm;
