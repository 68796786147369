import classNames from "classnames";
import { useTranslation } from "react-i18next";

function SecurityTipsSection() {
  const { t } = useTranslation();

  return (
    <div className="security_tips__section">
      <div className="security_tips__section__header"></div>
      <div className="security_tips__section__body">
        <div className="security_tips__section__body__content">
          <div className="security_tips__section__body__container">
            <div className="security_tips__section__container__subtitle">
              <span>{t("landing.body.tips_section.subtitle")}</span>
            </div>
            <div className="security_tips__section__container__title">
              <h2>{t("landing.body.tips_section.title")}</h2>
            </div>
          </div>
          <div className="security_tips__section__body__container">
            <div className="security_tips__card_container">
              {[...Array(9).keys()]
                .map((idx) => idx + 1)
                .map((idx) => {
                  return (
                    <div key={idx} className="security_tips__card">
                      <div
                        className={classNames(
                          "security_tips__card__logo",
                          t(`landing.body.tips_section.tip${idx}.icon`)
                        )}
                      ></div>
                      <h3 className="security_tips__card__title">
                        {t(`landing.body.tips_section.tip${idx}.title`)}
                      </h3>
                      <div
                        className="security_tips__card__description"
                        dangerouslySetInnerHTML={{
                          __html: t(
                            `landing.body.tips_section.tip${idx}.description`,
                            {
                              interpolation: { escapeValue: true },
                            }
                          ),
                        }}
                      ></div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      <div className="security_tips__section__footer"></div>
    </div>
  );
}

export default SecurityTipsSection;
