import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import classNames from "classnames";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useGetUserSubscriptionStatus from "../../hooks/useGetUserSubscriptionStatus";

import useWindowSize from "../../hooks/useWindowSize";
import useGetLocalePath from "../../hooks/useGetLocalePath";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { Link, useLocation, useNavigate } from "react-router-dom";

import {
  setBlur,
  setFooterMenuCollapse,
  setLoading,
  setShowLoginDialog,
  setShowNewsletterRegisterDialog,
  setTransferInit,
} from "../../redux/reducers/uiReducer";
import {
  clearFilters,
  clearItems,
  clearOrderBy,
  clearPagination,
} from "../../redux/reducers/transfersTableReducer";

import Tag from "../tag";

import { equalsIgnoringCase, isEmpty } from "../../utils/validators";

function FooterMenu() {
  const { t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();
  const { getLocalePath } = useGetLocalePath();

  const dispatch = useDispatch();

  const windowSize = useWindowSize();
  const { ui } = useSelector((state) => state);
  const { user } = useSelector((state) => state.user);
  const { isProUser } = useGetUserSubscriptionStatus();

  const menuRef = useRef(null);
  const [open, setOpen] = useState(false);
  useOnClickOutside(menuRef, () => setOpen(false));

  const aboutLinkRef = useRef(null);
  const plansLinkRef = useRef(null);
  const uploapsLinkRef = useRef(null);
  const prefsLinkRef = useRef(null);

  const goToLandingAbout = useCallback(() => {
    if (
      equalsIgnoringCase(
        location.pathname,
        getLocalePath(t("routes.common.home"))
      ) ||
      location.pathname.startsWith("/get/t/")
    ) {
      aboutLinkRef.current.classList.toggle("active", true);
      plansLinkRef.current.classList.toggle("active", false);
      const el = document.getElementById("about");
      if (!isEmpty(el)) {
        el?.scrollIntoView({
          block: "start",
          inline: "start",
          behavior: "smooth",
        });

        const headerAboutLink = document.getElementById("header-about-link");
        if (!isEmpty(headerAboutLink)) {
          headerAboutLink.classList.toggle("active", true);
        }
        const footerAboutLink = document.getElementById("footer-about-link");
        if (!isEmpty(footerAboutLink)) {
          footerAboutLink.classList.toggle("active", true);
        }
        const headerPlansLink = document.getElementById("header-plans-link");
        if (!isEmpty(headerPlansLink)) {
          headerPlansLink.classList.toggle("active", false);
        }
        const footerPlansLink = document.getElementById("footer-plans-link");
        if (!isEmpty(footerPlansLink)) {
          footerPlansLink.classList.toggle("active", false);
        }
      }
    } else {
      navigate(getLocalePath(t("routes.common.home")), {
        state: { targetId: "about" },
      });
    }
  }, [getLocalePath, location.pathname, navigate, t]);

  const goToLandingPlans = useCallback(() => {
    if (
      equalsIgnoringCase(
        location.pathname,
        getLocalePath(t("routes.common.home"))
      ) ||
      location.pathname.startsWith("/get/t/")
    ) {
      aboutLinkRef.current.classList.toggle("active", false);
      plansLinkRef.current.classList.toggle("active", true);
      const el = document.getElementById("plans");
      if (!isEmpty(el)) {
        el?.scrollIntoView({
          block: "start",
          inline: "start",
          behavior: "smooth",
        });

        const headerAboutLink = document.getElementById("header-about-link");
        if (!isEmpty(headerAboutLink)) {
          headerAboutLink.classList.toggle("active", false);
        }
        const footerAboutLink = document.getElementById("footer-about-link");
        if (!isEmpty(footerAboutLink)) {
          footerAboutLink.classList.toggle("active", false);
        }
        const headerPlansLink = document.getElementById("header-plans-link");
        if (!isEmpty(headerPlansLink)) {
          headerPlansLink.classList.toggle("active", true);
        }
        const footerPlansLink = document.getElementById("footer-plans-link");
        if (!isEmpty(footerPlansLink)) {
          footerPlansLink.classList.toggle("active", true);
        }
      }
    } else {
      navigate(getLocalePath(t("routes.common.home")), {
        state: { targetId: "plans" },
      });
    }
  }, [getLocalePath, location.pathname, navigate, t]);

  const showLoginDialog = useCallback(() => {
    if (["PREPRODUCTION", "PRODUCTION"].includes(process.env.REACT_APP_ENV)) {
      dispatch(
        setShowNewsletterRegisterDialog(!ui.show_newsletter_register_dialog)
      );
      dispatch(setBlur(!ui.is_blur));
    } else {
      dispatch(setShowLoginDialog(!ui.show_login_dialog));
      dispatch(setBlur(!ui.is_blur));
    }
  }, [
    dispatch,
    ui.is_blur,
    ui.show_login_dialog,
    ui.show_newsletter_register_dialog,
  ]);

  const goToUploads = useCallback(() => {
    const headerAboutLink = document.getElementById("header-about-link");
    if (!isEmpty(headerAboutLink)) {
      headerAboutLink.classList.toggle("active", false);
    }
    const footerAboutLink = document.getElementById("footer-about-link");
    if (!isEmpty(footerAboutLink)) {
      footerAboutLink.classList.toggle("active", false);
    }
    const headerPlansLink = document.getElementById("header-plans-link");
    if (!isEmpty(headerPlansLink)) {
      headerPlansLink.classList.toggle("active", false);
    }
    const footerPlansLink = document.getElementById("footer-plans-link");
    if (!isEmpty(footerPlansLink)) {
      footerPlansLink.classList.toggle("active", false);
    }
    const headerUploadsLink = document.getElementById("header-uploads-link");
    if (!isEmpty(headerUploadsLink)) {
      headerUploadsLink.classList.toggle("active", true);
    }
    const footerUploadsLink = document.getElementById("footer-uploads-link");
    if (!isEmpty(footerUploadsLink)) {
      footerUploadsLink.classList.toggle("active", true);
    }
    const headerPrefsLink = document.getElementById("header-prefs-link");
    if (!isEmpty(headerPrefsLink)) {
      headerPrefsLink.classList.toggle("active", false);
    }
    const footerPrefsLink = document.getElementById("footer-prefs-link");
    if (!isEmpty(footerPrefsLink)) {
      footerPrefsLink.classList.toggle("active", false);
    }
    dispatch(clearItems());
    dispatch(clearFilters());
    dispatch(clearOrderBy());
    dispatch(clearPagination());
    dispatch(setLoading(true));
    dispatch(setTransferInit(true));
  }, [dispatch]);

  const goToPreferences = useCallback(() => {
    const headerAboutLink = document.getElementById("header-about-link");
    if (!isEmpty(headerAboutLink)) {
      headerAboutLink.classList.toggle("active", false);
    }
    const footerAboutLink = document.getElementById("footer-about-link");
    if (!isEmpty(footerAboutLink)) {
      footerAboutLink.classList.toggle("active", false);
    }
    const headerPlansLink = document.getElementById("header-plans-link");
    if (!isEmpty(headerPlansLink)) {
      headerPlansLink.classList.toggle("active", false);
    }
    const footerPlansLink = document.getElementById("footer-plans-link");
    if (!isEmpty(footerPlansLink)) {
      footerPlansLink.classList.toggle("active", false);
    }
    const headerUploadsLink = document.getElementById("header-uploads-link");
    if (!isEmpty(headerUploadsLink)) {
      headerUploadsLink.classList.toggle("active", false);
    }
    const footerUploadsLink = document.getElementById("footer-uploads-link");
    if (!isEmpty(footerUploadsLink)) {
      footerUploadsLink.classList.toggle("active", false);
    }
    const headerPrefsLink = document.getElementById("header-prefs-link");
    if (!isEmpty(headerPrefsLink)) {
      headerPrefsLink.classList.toggle("active", true);
    }
    const footerPrefsLink = document.getElementById("footer-prefs-link");
    if (!isEmpty(footerPrefsLink)) {
      footerPrefsLink.classList.toggle("active", true);
    }
  }, []);

  const openMenuHandler = useCallback(() => {
    if (ui.footer_menu_collapse) {
      setOpen(!open);
    }
  }, [ui.footer_menu_collapse, open]);

  const menuButton = useMemo(
    () =>
      ui.footer_menu_collapse
        ? t("footer_menu.login")
        : t("footer_menu.account"),
    [ui.footer_menu_collapse, t]
  );

  const userName = useMemo(
    () => user.username || user.email,
    [user.username, user.email]
  );

  useEffect(() => {
    if (ui.show_footer_menu) {
      dispatch(setFooterMenuCollapse(windowSize.height <= 800));
    }
  }, [ui.show_footer_menu, windowSize.height, dispatch]);

  return (
    <div
      ref={menuRef}
      className={classNames("footer-menu", {
        "drop-up": ui.footer_menu_collapse,
      })}
      onClick={(evt) => openMenuHandler(evt)}
    >
      {ui.footer_menu_collapse && (
        <button className="footer-menu__button">
          <span className="icon icon-nav-arrow-up"></span>
          <span className="label small-title">
            {user ? t("footer_menu.account") : t("button.menu")}
          </span>
        </button>
      )}
      <nav className={classNames("footer-menu__content", { open: open })}>
        {!user.logged_in && (
          <ul className="footer-menu-items">
            <li
              ref={aboutLinkRef}
              id="footer-about-link"
              className="footer-menu-item"
              title={t("footer_menu.about_us")}
              onClick={() => goToLandingAbout()}
            >
              <span className="icon-logo"></span>
              <span className="small-title">{t("footer_menu.about_us")}</span>
              <span className="icon-nav-arrow-right"></span>
            </li>
            <li
              ref={plansLinkRef}
              id="footer-plans-link"
              className="footer-menu-item"
              title={t("footer_menu.plans")}
              onClick={() => goToLandingPlans()}
            >
              <span className="icon-stats-square-up-outline"></span>
              <span className="small-title">{t("footer_menu.plans")}</span>
              <span className="icon-nav-arrow-right"></span>
            </li>
            <li
              className="footer-menu-item"
              onClick={() => showLoginDialog()}
              title={menuButton}
            >
              <span className="icon-user-outline"></span>
              <span className="small-title">{menuButton}</span>
              <span className="icon-nav-arrow-right"></span>
            </li>
          </ul>
        )}
        {user.logged_in && (
          <ul className="footer-menu-items">
            <Link
              ref={uploapsLinkRef}
              id="footer-uploads-link"
              to={getLocalePath(t("routes.private.uploads.index"))}
              className={classNames("footer-menu-item", "active")}
              title={t("footer_menu.uploads")}
              onClick={() => goToUploads()}
            >
              <span className="icon-cloud-upload"></span>
              <span className="small-title">{t("footer_menu.uploads")}</span>
              <span className="icon-nav-arrow-right"></span>
            </Link>
            <Link
              ref={prefsLinkRef}
              id="footer-prefs-link"
              to={getLocalePath(t("routes.private.prefs.index"))}
              className="footer-menu-item"
              title={t("footer_menu.preferences")}
              onClick={() => goToPreferences()}
            >
              <span className="user-info-avatar"></span>
              <span className="user-info-text">
                <div className="user-info-text__ellipsis">
                  <span className="user-info-text__value small-title">
                    {userName}
                  </span>
                </div>
                {isProUser && (
                  <Tag listClass={["pro"]} text={t("subscription.pro")} />
                )}
              </span>
              <span className="icon-nav-arrow-right"></span>
            </Link>
          </ul>
        )}
      </nav>
    </div>
  );
}

export default FooterMenu;
