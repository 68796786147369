import moment from "moment";
import "moment/locale/es";

export const DATE_FORMAT = {
  compare: "YYYY-MM-DD HH:mm:ss",
  default_es: "DD/MM/YY HH:mm",
  default_en: "MM/DD/YY HH:mm",
};

export function format(date, format) {
  var localDate = moment(moment.utc(date)).local();
  return moment(localDate).format(format);
}

export function expirationFormat(dateInit, dateEnd, language, endOf = "day") {
  return dateEnd.locale(language).endOf(endOf).from(dateInit);
}

export default moment;
