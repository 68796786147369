import { useMemo } from "react";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { equalsIgnoringCase, isEmpty } from "../utils/validators";

const useGetUserSubscriptionStatus = () => {
  const { t } = useTranslation();

  const { user } = useSelector((state) => state.user);

  const isUnregisteredUser = useMemo(() => {
    return !isEmpty(user) && isEmpty(user.subscription);
  }, [user]);

  const isFreeUser = useMemo(() => {
    return (
      !isEmpty(user) &&
      !isEmpty(user.subscription) &&
      equalsIgnoringCase(user.subscription, t("subscription.free"))
    );
  }, [user, t]);

  const isProUser = useMemo(() => {
    return (
      !isEmpty(user) &&
      !isEmpty(user.subscription) &&
      equalsIgnoringCase(user.subscription, t("subscription.pro"))
    );
  }, [user, t]);

  const isBusinessUser = useMemo(() => {
    return (
      !isEmpty(user) &&
      !isEmpty(user.subscription) &&
      equalsIgnoringCase(user.subscription, t("subscription.business"))
    );
  }, [user, t]);

  return { isUnregisteredUser, isFreeUser, isProUser, isBusinessUser };
};

export default useGetUserSubscriptionStatus;
