import { useCallback, useEffect, useState } from "react";

import classNames from "classnames";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { setConsentPrivacy } from "../../../redux/reducers/consentPrivacyReducer";

import Media from "react-media";
import Button from "../../button";
import Divider from "../../divider";
import UploadDropZone from "@rpldy/upload-drop-zone";
import { asUploadButton } from "@rpldy/upload-button";

function AddFilesComponent({ form = false }) {
  const { t } = useTranslation();

  const { consentPrivacy } = useSelector((state) => state.consentPrivacy);

  const [list, setList] = useState(false);
  const [uploading, setUploading] = useState(false);

  const dispatch = useDispatch();

  const UploadButton = asUploadButton((props) => {
    return (
      <Button {...props} primary disabled={!uploading}>
        <span className="label small-title">{t("button.add")}</span>
      </Button>
    );
  });

  useEffect(() => {
    if (consentPrivacy) {
      setList(true);
      setUploading(false);
    } else {
      setList(false);
      setUploading(true);
    }
  }, [consentPrivacy]);

  const acceptCookiesHandler = useCallback(() => {
    dispatch(setConsentPrivacy(false));
  }, [dispatch]);

  return (
    <UploadDropZone
      className={classNames("add-files-container", {
        bgGrey: uploading && !form,
        "form-component": form,
      })}
      grouped={false}
      shouldHandleDrag={uploading}
    >
      {!form && (
        <div>
          <div className="add-files__header">
            <span
              className={classNames("icon-title", {
                "icon-cloud-upload": consentPrivacy,
                "icon-drag-hand-gesture": !consentPrivacy,
              })}
            ></span>
            <span className="text-title small-title">
              {t("uploader.add_files_content.title")}
            </span>
          </div>
          <div className="add-files__body">
            {list && (
              <ul
                className="list"
                dangerouslySetInnerHTML={{
                  __html: t("uploader.add_files_content.list_items", {
                    interpolation: { escapeValue: true },
                  }),
                }}
              ></ul>
            )}

            {consentPrivacy && (
              <div className="privacy-consent">
                <Divider />
                <p
                  className="smaller"
                  dangerouslySetInnerHTML={{
                    __html: t("uploader.add_files_content.privacy_consent", {
                      interpolation: { escapeValue: true },
                    }),
                  }}
                ></p>
                <Button
                  primary
                  text
                  onClick={(evt) => acceptCookiesHandler(evt)}
                >
                  <span className="label">{t("button.accept")}</span>
                </Button>
              </div>
            )}

            {uploading && (
              <p className="smaller">
                <Media
                  queries={{
                    xs: "(min-width: 0px) and (max-width: 576px)",
                    sm: "(min-width: 577px) and (max-width: 768px)",
                    md: "(min-width: 769px) and (max-width: 992px)",
                    lg: "(min-width: 993px) and (max-width: 1200px)",
                    xl: "(min-width: 1201px) and (max-width: 1400px)",
                    xxl: "(min-width: 1401px)",
                  }}
                >
                  {(matches) =>
                    [matches.md, matches.lg, matches.xl, matches.xxl].some(
                      (i) => i === true
                    ) && t("uploader.add_files_content.subtitle")
                  }
                </Media>
              </p>
            )}

            {uploading && <UploadButton />}
          </div>
        </div>
      )}

      {form && (
        <div className="add-files__header">
          <span className="icon-title icon-cloud-upload"></span>
          <span className="text-title small-title">
            {t("uploader.add_files_content.title")}
          </span>
          {<UploadButton />}
        </div>
      )}
    </UploadDropZone>
  );
}

export default AddFilesComponent;
