import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setConsentPrivacy } from "../../redux/reducers/consentPrivacyReducer";
import { setBlur, setShowConsentDialog } from "../../redux/reducers/uiReducer";
import Divider from "../divider";
import classNames from "classnames";
import Button from "../button";

function ConsentDialog() {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  const { ui } = useSelector((state) => state);
  const { consentPrivacy } = useSelector((state) => state);

  const closeConsentDialog = (evt) => {
    dispatch(setShowConsentDialog(!ui.show_consent_dialog));
    dispatch(setBlur(!ui.is_blur));
  };

  const acceptClickHandler = (evt) => {
    dispatch(setConsentPrivacy(!consentPrivacy));
    dispatch(setShowConsentDialog(!ui.show_consent_dialog));
    dispatch(setBlur(!ui.is_blur));
  };

  return (
    <div>
      <div className="wrapper" onClick={(evt) => closeConsentDialog()}></div>
      <div className="consent-dialog">
        <div className="consent-dialog__header">
          <div className="title small-title">{t("consent_dialog.title")}</div>
          <Divider />
          <div className="subtitle header-4">
            {t("consent_dialog.subtitle")}
          </div>
        </div>
        <div className="consent-dialog__body">
          <div className="item">
            <div className="item__header">
              <span className="item__icon icon-user"></span>
              <span className="item__label small-title">
                {t("consent_dialog.ad")}
              </span>
            </div>
          </div>
          <Divider />
          <div className="item">
            <div className="item__header">
              <span className="item__icon icon-computer"></span>
              <span className="item__label small-title">
                {t("consent_dialog.storage")}
              </span>
            </div>
          </div>
          <Divider />
          <div className="item">
            <div className="item__header" onClick={() => setOpen(!open)}>
              <span
                className={classNames("item__icon", {
                  "icon-nav-arrow-down": open,
                  "icon-nav-arrow-up": !open,
                })}
              ></span>
              <span className="item__label small-title">
                {t("consent_dialog.moreinfo")}
              </span>
            </div>
            <div className={`item__collapsed-content ${open ? "open" : ""}`}>
              <p className="smaller-title">{t("consent_dialog.moreinfo_p1")}</p>
              <p className="smaller-title">{t("consent_dialog.moreinfo_p2")}</p>
            </div>
          </div>
        </div>
        <div className="consent-dialog__footer">
          <Button secondary text onClick={(evt) => evt.preventDefault()}>
            <span className="small-title">
              {t("consent_dialog.button.management_ops")}
            </span>
          </Button>
          <Button primary text onClick={(evt) => acceptClickHandler(evt)}>
            <span className="small-title">
              {t("consent_dialog.button.consent")}
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ConsentDialog;
