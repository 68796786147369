import { useMemo } from "react";
import { Navigate } from "react-router-dom";
import detectBrowserLanguage from "detect-browser-language";
import SEO from "../components/seo";
import { useTranslation } from "react-i18next";
import useGetLocalePath from "../hooks/useGetLocalePath";
import { isEmpty } from "../utils/validators";

function NavigateLng() {
  const { t, i18n } = useTranslation();

  const { getLocalePath } = useGetLocalePath();

  const titleSEO = useMemo(() => {
    return `${t("app.name")} - ${t("landing.title")}`;
  }, [t]);

  const descriptionSEO = useMemo(() => {
    return t("landing.description");
  }, [t]);

  const urlSEO = useMemo(() => {
    return `${process.env.REACT_APP_PUBLIC_URL}${getLocalePath(
      t("routes.common.home")
    )}`;
  }, [getLocalePath, t]);

  const altLinksSEO = useMemo(() => {
    const publicURL = process.env.REACT_APP_PUBLIC_URL;
    const result = i18n.languages.map((lng) => {
      return {
        lng: lng,
        url: `${publicURL}/${lng}/`,
      };
    });

    const defaultLng = result.find((url) => url.lng === "en");
    result.push({
      lng: "x-default",
      url: defaultLng.url,
    });

    return result;
  }, [i18n.languages]);

  const userLng = useMemo(() => {
    const userLocale = detectBrowserLanguage();
    let locale = userLocale.split("-")[0];
    if (!isEmpty(locale)) {
      if (!i18n.languages.includes(locale)) {
        locale = "en";
      }
    } else {
      locale = "en";
    }
    return locale;
  }, [i18n.languages]);

  return (
    <>
      <SEO
        title={titleSEO}
        description={descriptionSEO}
        url={urlSEO}
        canonical={urlSEO}
        altLinks={altLinksSEO}
      />
      <Navigate replace to={`/${userLng}/`} />;
    </>
  );
}

export default NavigateLng;
