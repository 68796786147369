import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  setBlur,
  setHeaderMenuOpen,
  setShowLoginDialog,
  setShowNewsletterRegisterDialog,
} from "../../redux/reducers/uiReducer";
import { useLocation, useNavigate } from "react-router-dom";
import { equalsIgnoringCase, isEmpty } from "../../utils/validators";
import useGetLocalePath from "../../hooks/useGetLocalePath";
import LangSwitch from "../lang-switch";

function HeaderMenu() {
  const { t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();
  const { getLocalePath } = useGetLocalePath();

  const dispatch = useDispatch();

  const { ui } = useSelector((state) => state);

  const goToLandingAbout = () => {
    if (
      equalsIgnoringCase(
        location.pathname,
        getLocalePath(t("routes.common.home"))
      )
    ) {
      const el = document.getElementById("about");
      if (!isEmpty(el)) {
        el?.scrollIntoView({
          block: "start",
          inline: "start",
          behavior: "smooth",
        });
      }
      const headerAboutLink = document.getElementById("header-about-link");
      if (!isEmpty(headerAboutLink)) {
        headerAboutLink.classList.toggle("active", true);
      }
      const footerAboutLink = document.getElementById("footer-about-link");
      if (!isEmpty(footerAboutLink)) {
        footerAboutLink.classList.toggle("active", true);
      }
      const headerPlansLink = document.getElementById("header-plans-link");
      if (!isEmpty(headerPlansLink)) {
        headerPlansLink.classList.toggle("active", false);
      }
      const footerPlansLink = document.getElementById("footer-plans-link");
      if (!isEmpty(footerPlansLink)) {
        footerPlansLink.classList.toggle("active", false);
      }
    } else {
      navigate(getLocalePath(t("routes.common.home")), {
        state: { targetId: "about" },
      });
    }

    dispatch(setHeaderMenuOpen(false));
  };

  const goToLandingPlans = () => {
    if (
      equalsIgnoringCase(
        location.pathname,
        getLocalePath(t("routes.common.home"))
      )
    ) {
      const el = document.getElementById("plans");
      if (!isEmpty(el)) {
        el?.scrollIntoView({
          block: "start",
          inline: "start",
          behavior: "smooth",
        });
      }
      const headerAboutLink = document.getElementById("header-about-link");
      if (!isEmpty(headerAboutLink)) {
        headerAboutLink.classList.toggle("active", false);
      }
      const footerAboutLink = document.getElementById("footer-about-link");
      if (!isEmpty(footerAboutLink)) {
        footerAboutLink.classList.toggle("active", false);
      }
      const headerPlansLink = document.getElementById("header-plans-link");
      if (!isEmpty(headerPlansLink)) {
        headerPlansLink.classList.toggle("active", true);
      }
      const footerPlansLink = document.getElementById("footer-plans-link");
      if (!isEmpty(footerPlansLink)) {
        footerPlansLink.classList.toggle("active", true);
      }
    } else {
      navigate(getLocalePath(t("routes.common.home")), {
        state: { targetId: "plans" },
      });
    }

    dispatch(setHeaderMenuOpen(false));
  };

  const showLoginDialog = () => {
    if (["PREPRODUCTION", "PRODUCTION"].includes(process.env.REACT_APP_ENV)) {
      dispatch(setHeaderMenuOpen(!ui.header_menu_open));
      dispatch(
        setShowNewsletterRegisterDialog(!ui.show_newsletter_register_dialog)
      );
      dispatch(setBlur(!ui.is_blur));
    } else {
      dispatch(setHeaderMenuOpen(!ui.header_menu_open));
      dispatch(setShowLoginDialog(!ui.show_login_dialog));
      dispatch(setBlur(!ui.is_blur));
    }
  };

  return (
    <nav
      className={classNames("header-menu", { open: ui.header_menu_open })}
      style={{ maxHeight: `${ui.header_menu_open ? 200 : 0}px` }}
    >
      <ul className="header-menu__items">
        <li
          id="header-about-link"
          className="header-menu__item"
          onClick={() => goToLandingAbout()}
        >
          <span className="icon-logo"></span>
          <span className="small-title">{t("header_menu.about_us")}</span>
          <span className="icon-nav-arrow-right"></span>
        </li>
        <li
          id="header-plans-link"
          className="header-menu__item"
          onClick={() => goToLandingPlans()}
        >
          <span className="icon-stats-square-up-outline"></span>
          <span className="small-title">{t("header_menu.plans")}</span>
          <span className="icon-nav-arrow-right"></span>
        </li>
        <li className="header-menu__item" onClick={() => showLoginDialog()}>
          <span className="icon-user-outline"></span>
          <span className="small-title">{t("header_menu.account")}</span>
          <span className="icon-nav-arrow-right"></span>
        </li>
        <li
          className="header-menu__item"
          style={{ justifyContent: "flex-end" }}
        >
          <LangSwitch />
        </li>
      </ul>
    </nav>
  );
}

export default HeaderMenu;
