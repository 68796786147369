import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useGetLocalePath from "../../hooks/useGetLocalePath";

import { Link, useLocation } from "react-router-dom";

import {
  setHeaderMenuOpen,
  setLoading,
  setTransferInit,
} from "../../redux/reducers/uiReducer";
import {
  clearFilters,
  clearItems,
  clearOrderBy,
  clearPagination,
} from "../../redux/reducers/transfersTableReducer";

import Button from "../button";

function Toolbar() {
  const { t } = useTranslation();
  const location = useLocation();
  const { getLocalePath } = useGetLocalePath();

  const dispatch = useDispatch();
  const { ui } = useSelector((state) => state);
  const { user } = useSelector((state) => state.user);

  const manageOpenMenu = useCallback(
    (isOpen) => {
      dispatch(setHeaderMenuOpen(isOpen));
    },
    [dispatch]
  );

  const goToUploads = useCallback(() => {
    manageOpenMenu(false);
    dispatch(clearItems());
    dispatch(clearFilters());
    dispatch(clearOrderBy());
    dispatch(clearPagination());
    dispatch(setLoading(true));
    dispatch(setTransferInit(true));
  }, [dispatch, manageOpenMenu]);

  return (
    <div className="toolbar">
      {location.pathname !== getLocalePath(t("routes.public.uploader")) && (
        <Link
          to={getLocalePath(t("routes.public.uploader"))}
          title={t("uploader_page.title")}
        >
          <Button secondary icon onClick={() => manageOpenMenu(false)}>
            <span className="icon icon-circle-add-alternate"></span>
            <span className="label small-title">{t("button.add")}</span>
          </Button>
        </Link>
      )}
      {!user.logged_in && (
        <Button
          secondary
          icon
          onClick={() => manageOpenMenu(!ui.header_menu_open)}
        >
          <span
            className={`icon ${
              ui.header_menu_open ? "icon-delete-circled-outline" : "icon-menu"
            }`}
          ></span>
          <span className="label small-title">{t("button.menu")}</span>
        </Button>
      )}
      {user.logged_in && (
        <>
          <Link to={getLocalePath(t("routes.private.uploads.index"))}>
            <Button secondary icon onClick={() => goToUploads()}>
              <span className="icon icon-cloud-upload"></span>
            </Button>
          </Link>
          <Link to={getLocalePath(t("routes.private.prefs.index"))}>
            <Button secondary icon onClick={() => manageOpenMenu(false)}>
              <span className="user-info-avatar"></span>
            </Button>
          </Link>
        </>
      )}
    </div>
  );
}

export default Toolbar;
