import { useCallback, useEffect, useMemo, useState } from "react";

import classNames from "classnames";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useWindowSize from "../../../hooks/useWindowSize";
import useCopyToClipboard from "../../../hooks/useCopyToClipboard.ts";
import useDeleteTransfer from "../../../hooks/useDeleteTransfer";
import useUpdateTransfer from "../../../hooks/useUpdateTransfer";

import { yupResolver } from "@hookform/resolvers/yup";
import getUpdateTransferSchema from "../../../schemas/updateTransferSchema";

import TextField from "../../text-field";
import TextAreaField from "../../text-area-field";
import LinkField from "../../link-field";
import Menu from "../../menu";
import DeleteTransferDialog from "../../uploader-page/delete-transfer-dialog";
import ShareTransferDialog from "../../uploader-page/share-transfer-dialog/index.jsx";
import { errorToast, successToast } from "../../toast";

import { logDebug, logError, logWarn } from "../../../utils/logger";
import {
  equalsIgnoringCase,
  humanFileSize,
  isEmpty,
} from "../../../utils/validators";
import { DATE_FORMAT, format } from "../../../utils/moment";
import useUnload from "../../../hooks/useUnload";

function TransferDetailInfo(props) {
  const { mode, setMode, transfer, setTransfer } = props;

  const { t, i18n } = useTranslation();
  const windowSize = useWindowSize();

  const menuActions = useMemo(() => {
    return {
      EDIT: "edit",
      UNDO: "undo",
      SAVE: "save",
      DELETE: "delete",
      COPY: "copy",
      SHARE: "share",
    };
  }, []);

  const [oldTransfer, setOldTransfer] = useState(null);
  const [showShareDialog, setShowShareDialog] = useState(false);
  const [showDeleteTransferDialog, setShowDeleteTransferDialog] =
    useState(false);

  const editMode = useMemo(() => {
    return equalsIgnoringCase(mode, "edit");
  }, [mode]);

  const menuOpts = useMemo(() => {
    return [
      {
        label: t("uploads_detail.body.transfer.menu.edit"),
        icon: "icon-pencil",
        value: menuActions.EDIT,
        disabled: false,
        visible: !editMode,
      },
      {
        label: t("uploads_detail.body.transfer.menu.undo"),
        icon: "icon-undo",
        value: menuActions.UNDO,
        disabled: false,
        visible: editMode,
      },
      {
        label: t("uploads_detail.body.transfer.menu.save"),
        icon: "icon-save",
        value: menuActions.SAVE,
        disabled: false,
        visible: editMode,
      },
      {
        label: t("uploads_detail.body.transfer.menu.delete"),
        icon: "icon-delete-circled-outline",
        value: menuActions.DELETE,
        disabled: editMode,
        visible: true,
      },
      {
        label: t("uploads_detail.body.transfer.menu.copy"),
        icon: "icon-copy",
        value: menuActions.COPY,
        disabled: editMode,
        visible: true,
      },
      {
        label: t("uploads_detail.body.transfer.menu.share"),
        icon: "icon-share",
        value: menuActions.SHARE,
        disabled: editMode,
        visible: true,
      },
    ];
  }, [menuActions, editMode, t]);

  const [copiedText, copy] = useCopyToClipboard();
  const sharedLink = useCallback((idTransfer) => {
    return !isEmpty(idTransfer)
      ? `${process.env.REACT_APP_PUBLIC_URL}/get/t/${idTransfer}`
      : "";
  }, []);

  const titleMemo = useMemo(() => {
    return transfer.title;
  }, [transfer.title]);

  const messageMemo = useMemo(() => {
    return transfer.message;
  }, [transfer.message]);

  const titleTransferTitleMemo = useMemo(() => {
    const size = humanFileSize(transfer.size, i18n.language, true, 2);
    return t("uploads_detail.body.transfer.title", { size: size });
  }, [i18n.language, t, transfer.size]);

  const sharedLinkMemo = useMemo(() => {
    return !isEmpty(transfer.idTransfer)
      ? `${process.env.REACT_APP_PUBLIC_URL}/get/t/${transfer.idTransfer}`
      : "";
  }, [transfer.idTransfer]);

  const passwordMemo = useMemo(() => {
    return transfer.password;
  }, [transfer.password]);

  const createDateMemo = useMemo(() => {
    return format(transfer.dispatched, DATE_FORMAT[`default_${i18n.language}`]);
  }, [i18n.language, transfer.dispatched]);

  const expirationDateMemo = useMemo(() => {
    return format(transfer.until, DATE_FORMAT[`default_${i18n.language}`]);
  }, [i18n.language, transfer.until]);

  const updateTransferSchema = getUpdateTransferSchema(t);
  const {
    register,
    watch,
    setValue,
    getValues,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",
      message: "",
      shareLink: "",
      password: "",
      dispatched: "",
      until: "",
    },
    values: {
      title: titleMemo,
      message: messageMemo,
      shareLink: sharedLinkMemo,
      password: passwordMemo,
      dispatched: createDateMemo,
      until: expirationDateMemo,
    },
    mode: "all",
    criteriaMode: "all",
    resolver: yupResolver(updateTransferSchema),
  });

  const {
    loading: loadingUpdateTransfer,
    data: dataUpdateTransfer,
    error: errorUpdateTransfer,
    updateTransferHandler,
  } = useUpdateTransfer();
  useEffect(() => {
    if (!loadingUpdateTransfer) {
      if (isEmpty(errorUpdateTransfer)) {
        if (!isEmpty(dataUpdateTransfer)) {
          successToast(
            "TransferDetailInfo",
            "updateTransferHandler",
            t("message.default")
          );
          logDebug("TransferDetailInfo", "updateTransferHandler", {
            msg: t("message.default"),
            result: dataUpdateTransfer,
          });
          setTransfer({ ...transfer });
          setOldTransfer({ ...transfer });
          setShowDeleteTransferDialog(false);
        }
      } else {
        errorToast(
          "TransferDetailInfo",
          "updateTransferHandler",
          t(`errors.${errorUpdateTransfer}`, t("errors.default"))
        );
        logError("TransferDetailInfo", "updateTransferHandler", {
          msg: t(`errors.${errorUpdateTransfer}`, t("errors.default")),
          error: errorUpdateTransfer,
        });
        setShowDeleteTransferDialog(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingUpdateTransfer]);

  const menuItemClickHandler = useCallback(
    (opt) => {
      logDebug("TransferDetailInfo", "menuItemClickHandler", {
        opt: opt,
        transfer: transfer,
      });
      if (!isEmpty(opt) && !opt.disabled) {
        switch (opt.value) {
          case menuActions.EDIT:
            setOldTransfer({ ...transfer });
            setMode("edit");
            break;
          case menuActions.UNDO:
            setTransfer({ ...oldTransfer });
            clearErrors();
            setValue("title", oldTransfer.title);
            setValue("message", oldTransfer.message);
            successToast("menuItemClickHandler", "undo", t("message.default"));
            setMode("detail");
            break;
          case menuActions.SAVE:
            setMode("detail");
            if (!isEmpty(transfer) && !isEmpty(transfer.idTransfer)) {
              transfer.title = getValues("title");
              transfer.message = getValues("message");
              updateTransferHandler({ ...transfer });
            }
            break;
          case menuActions.DELETE:
            setShowDeleteTransferDialog(true);
            break;
          case menuActions.COPY:
            const sharedLinkURL = sharedLink(transfer.idTransfer);
            copy(sharedLinkURL);
            successToast(
              "menuItemClickHandler",
              sharedLinkURL,
              t("message.clipboard")
            );
            break;
          case menuActions.SHARE:
            setShowShareDialog(true);
            break;
          default:
            logWarn("TransferDetailInfo", "menuItemClickHandler", {
              opt: opt,
              transfer: transfer,
              msg: t("errors.invalid_opt"),
            });
            break;
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [menuActions, copiedText, copy, sharedLink, t, transfer]
  );

  useUnload(editMode, t("message.prevent_reload"));

  const cancelDeleteTransferHandler = useCallback((evt) => {
    logDebug("TransferDetailInfo", "cancelDeleteTransferHandler", {
      evt: evt,
    });
    setShowDeleteTransferDialog(false);
  }, []);

  const closeDeleteTransferHandler = useCallback((evt) => {
    logDebug("TransferDetailInfo", "closeDeleteTransferHandler", {
      evt: evt,
    });
    setShowDeleteTransferDialog(false);
  }, []);

  const {
    loading: loadingDeleteTransfer,
    data: dataDeleteTransfer,
    error: errorDeleteTransfer,
    deleteTransferHandler,
  } = useDeleteTransfer();
  const acceptDeleteTransferHandler = useCallback(
    (evt) => {
      logDebug("TransferDetailInfo", "acceptDeleteTransferHandler", {
        evt: evt,
      });
      if (!isEmpty(transfer) && !isEmpty(transfer.idTransfer)) {
        deleteTransferHandler(transfer.idTransfer);
      }
    },
    [deleteTransferHandler, transfer]
  );
  useEffect(() => {
    if (!loadingDeleteTransfer) {
      if (isEmpty(errorDeleteTransfer)) {
        if (!isEmpty(dataDeleteTransfer)) {
          successToast(
            "TransferDetailInfo",
            "useDeleteTransfer",
            t("message.default")
          );
          logDebug("TransferDetailInfo", "useDeleteTransfer", {
            msg: t("message.default"),
          });
          setShowDeleteTransferDialog(false);
        }
      } else {
        errorToast(
          "TransferDetailInfo",
          "useDeleteTransfer",
          t(`errors.${errorDeleteTransfer}`, t("errors.default"))
        );
        logError("TransferDetailInfo", "useDeleteTransfer", {
          msg: t(`errors.${errorDeleteTransfer}`, t("errors.default")),
        });
        setShowDeleteTransferDialog(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingDeleteTransfer]);

  const closeShareHandler = useCallback((evt) => {
    setShowShareDialog(false);
    logDebug("TransferDetailInfo", "closeShareHandler", { evt: evt });
  }, []);

  const onSubmit = async (data, evt) => {
    logDebug("TransferDetailInfo", "onSubmit", { data: data, evt: evt });
    if (!isEmpty(transfer) && !isEmpty(transfer.idTransfer)) {
      transfer.title = getValues("title");
      transfer.message = getValues("message");
      updateTransferHandler({ ...transfer });
    }
  };

  const onError = async (errors, evt) => {
    logDebug("TransferDetailInfo", "onError", { errors: errors, evt: evt });
    errorToast("onError", errors, t("errors.error_form", t("errors.default")));
  };

  const Toolbar = (props) => {
    return windowSize.width >= 992 ? (
      <div className="toolbar__container">
        {menuOpts
          .filter((opt) => opt.visible)
          .map((opt) => {
            return (
              <span
                role="button"
                key={opt.value}
                title={opt.label}
                disabled={opt.disabled}
                className={classNames("toolbar__icon", {
                  [opt.icon]: opt.icon,
                  disabled: opt.disabled,
                })}
                onClick={(evt) => menuItemClickHandler(opt)}
              ></span>
            );
          })}
      </div>
    ) : (
      <Menu
        icon="icon-more-alternate"
        options={menuOpts.filter((opt) => opt.visible)}
        onChange={(opt) => menuItemClickHandler(opt)}
      />
    );
  };

  return (
    <>
      <div className="uploads-detail__block__container info">
        <div className="uploads-detail__block__content">
          <div className="uploads-detail__block__title__container">
            <div className="uploads-detail__block__title__content">
              <span className="icon icon-cloud-upload"></span>
              <div className="ellipsis__container">
                <span
                  className="ellipsis__value title"
                  title={titleTransferTitleMemo}
                >
                  {titleTransferTitleMemo}
                </span>
              </div>
              <Toolbar />
            </div>
          </div>
          <div className="uploads-detail__block__form__container">
            <div className="uploads-detail__block__form__content">
              <form
                id="transfer_detail_form"
                onSubmit={handleSubmit(onSubmit, onError)}
              >
                <div className="field title">
                  <TextField
                    label={t("uploads_detail.body.transfer.form.title")}
                    placeholder={t("uploads_detail.body.transfer.form.titleph")}
                    value={watch("title")}
                    register={register("title", {
                      ...updateTransferSchema.title,
                      onChange: (evt) => setValue("title", evt.target.value),
                      onBlur: (evt) => setValue("title", evt.target.value),
                    })}
                    disabled={!editMode}
                    error={errors?.title?.message}
                  />
                </div>
                <div className="field sharelink">
                  <LinkField
                    label={t("uploads_detail.body.transfer.form.shareLink")}
                    placeholder={t(
                      "uploads_detail.body.transfer.form.shareLinkph"
                    )}
                    value={watch("shareLink")}
                    register={register("shareLink", {
                      ...updateTransferSchema.shareLink,
                    })}
                    disabled={true}
                    error={errors?.shareLink?.message}
                  />
                </div>
                <div className="field dispatched">
                  <TextField
                    label={t("uploads_detail.body.transfer.form.dispatched")}
                    placeholder={t(
                      "uploads_detail.body.transfer.form.dispatchedph"
                    )}
                    value={watch("dispatched")}
                    register={register("dispatched", {
                      ...updateTransferSchema.dispatched,
                    })}
                    disabled={true}
                    error={errors?.dispatched?.message}
                  />
                </div>
                <div className="field until">
                  <TextField
                    label={t("uploads_detail.body.transfer.form.until")}
                    placeholder={t("uploads_detail.body.transfer.form.untilph")}
                    value={watch("until")}
                    register={register("until", {
                      ...updateTransferSchema.until,
                    })}
                    disabled={true}
                    error={errors?.until?.message}
                  />
                </div>
                <div className="field message">
                  <TextAreaField
                    label={t("uploads_detail.body.transfer.form.message")}
                    placeholder={t(
                      "uploads_detail.body.transfer.form.messageph"
                    )}
                    style={{ height: "55px" }}
                    value={watch("message")}
                    register={register("message", {
                      ...updateTransferSchema.message,
                      onChange: (evt) => setValue("message", evt.target.value),
                      onBlur: (evt) => setValue("message", evt.target.value),
                    })}
                    disabled={!editMode}
                    error={errors?.message?.message}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <DeleteTransferDialog
        showDialog={showDeleteTransferDialog}
        closeHandler={(evt) => closeDeleteTransferHandler(evt)}
        acceptHandler={(evt) => acceptDeleteTransferHandler(evt)}
        cancelHandler={(evt) => cancelDeleteTransferHandler(evt)}
      />
      <ShareTransferDialog
        showDialog={showShareDialog}
        idTransfer={transfer.idTransfer}
        closeHandler={(evt) => closeShareHandler(evt)}
      />
    </>
  );
}

export default TransferDetailInfo;
